import React, { useState } from "react";
import img2 from "../images/frontend UI materials/Request a call/user.png";
import img4 from "../images/frontend UI materials/Request a call/phone.png";
import img5 from "../images/Login and Signup materials/Vector.png";
import img6 from "../images/Login and Signup materials/Vector1.svg";
import Dr from "../images/Login and Signup materials/Ultimate Healthcare2.png";
import Female_Dr from "../images/Login and Signup materials/10-109014_female-doctor-transparent-images-medical-assistant.png";
import Bg from "../images/Light-Blue-.png";
import Header from "../pages/HomePage/Header";
import { useNavigate } from "react-router-dom";
import siteCofig from "../urlFile";
import axios from "axios";

const Register = () => {
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");

  const [userData, setUserData] = useState({
    username: "",
    phone: localStorage.getItem("mobileNumber"),
    email: "",
  });

  const navigate = useNavigate();
  const handleRoute = (path) => {
    navigate(path);
  };

  const validateName = (value) => {
    if (!value.trim()) {
      setNameError("Name is required");
      return false;
    } else {
      setNameError("");
      return true;
    }
  };

  const validateEmail = (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(value)) {
      setEmailError("Please enter a valid email");
      return false;
    } else {
      setEmailError("");
      return true;
    }
  };

  // const validatePhone = (value) => {
  //   const phoneRegex = /^[0-9]{10}$/;
  //   if (!phoneRegex.test(value)) {
  //     setPhoneError("Please enter a valid number");
  //     return false;
  //   } else {
  //     setPhoneError("");
  //     return true;
  //   }
  // };

  const handleFormSubmit = () => {
    const isNameValid = validateName(userData.username);
    const isEmailValid = validateEmail(userData.email);

    if (isNameValid && isEmailValid) {
      console.log("Form submitted successfully");
      setUserData((prev) => ({ ...prev, email: "", username: "" }));
    } else {
      console.log("Form submission failed. Please check the errors.");
    }
  };

  // POST API
  const handleGetRequest = async () => {
    let reqBody = {
      username: userData.username,
      phone: localStorage.getItem("mobileNumber"),
      email: userData.email,
    };
    try {
      const response = await axios.post(
        siteCofig.BASE_URL + siteCofig.USER_REGISTER,
        reqBody
      );
      const resData = response.data;
      if (resData.msg === "Sucsess") {
        localStorage.setItem("userId", String(resData.id));
        localStorage.setItem("userName", String(userData.username));
        navigate("/");
      }

      console.log(resData);
    } catch (error) {}
  };

  return (
    <>
      <Header />
      <div
        className="flex pt-28 pb-14 justify-center items-center p-8"
        style={{ backgroundImage: `url(${Bg})` }}
      >
        <div
          className="flex bg-[#FFF] justify-center items-center w-full rounded-[10px] gap-4 sm:w-[67%] xl:w-[52%]"
          style={{ boxShadow: "4px 4px 10.3px 4px rgba(0, 0, 0, 0.20)" }}
        >
          <div className="flex flex-col lg:w-[57%] justify-center items-center gap-3 p-3 rounded-tr-[10px] lg:rounded-tr-[0px] rounded-br-[10px] lg:rounded-br-[10px] rounded-tl-[10px] rounded-bl-[10px] w-full bg-[#FFFFFF]">
            <p className="text-[#666161] font-poppins font-normal text-center lg:text-[20px] sm:text-[16px] text-[14px]">
              Your phone number is not registered yet. Let us know the basic
              details for registration.{" "}
            </p>
            <p className=" text-lg text-[#00B4D8] font-bold">New User</p>
            {/* Number box */}
            <div className="space-x-2 flex border-1 w-full sm:w-[350px] items-center bg-[#FFF] shadow-[0px_4px_4px_0px rgba(0, 0, 0, 0.08)] rounded-[10px] border-[1px solid rgba(0, 0, 0, 0.20)]">
              <span className="ml-[2px]">
                <img
                  className="w-[27px] h-[27px] ml-1"
                  src={img2}
                  alt="Error"
                />
              </span>
              <input
                className="py-3 placeholder:text-lg sm:placeholder:text-xl rounded-[10px] w-full sm:w-[280px] h-[50px] font-poppins text-[rgba(0, 0, 0, 0.20)] font-normal focus:outline-none text-xl text-extrabold pl-2"
                placeholder="Phone number"
                type="tel"
                id="phone"
                value={userData.phone}
                disabled
              />
            </div>
            {/* name box */}
            <div className="space-x-2 flex border-1 w-full sm:w-[350px] items-center rounded-[10px] border-[1px solid rgba(0, 0, 0, 0.20)] bg-[#FFF] shadow-[0px_4px_4px_0px rgba(0, 0, 0, 0.08)]">
              <span className="ml-[2px]">
                <img
                  className="w-[27px] h-[27px] ml-1"
                  src={img4}
                  alt="Error"
                />
              </span>
              <input
                className="py-3 placeholder:text-lg sm:placeholder:text-xl rounded-[10px] h-[50px] w-full font-poppins focus:outline-none text-xl font-normal text-[rgba(0, 0, 0, 0.20)] text-extrabold pl-2"
                type="text"
                placeholder="Full Name"
                id="name"
                value={userData.username}
                onChange={(e) =>
                  setUserData((prev) => ({ ...prev, username: e.target.value }))
                }
              />
            </div>
            <div className="w-full sm:w-[350px]">
              {nameError && (
                <p className="text-danger self-start -mt-2">{nameError}</p>
              )}
            </div>
            {/* Email */}
            <div className="space-x-2 flex border-1 w-full sm:w-[350px] rounded-[10px] bg-[#FFF] border-[1px solid rgba(0, 0, 0, 0.20)] shadow-[0px_4px_4px_0px rgba(0, 0, 0, 0.08)]">
              <span className="ml-[3px] mt-2.5">
                <img
                  className="w-[27px] h-[27px] ml-1"
                  src={img5}
                  alt="Error"
                />
              </span>
              <input
                className="py-3 placeholder:text-lg sm:placeholder:text-xl rounded-[10px] w-full h-[50px] font-poppins text-[rgba(0, 0, 0, 0.20)] font-normal focus:outline-none text-xl text-extrabold pl-2"
                placeholder="Email"
                type="email"
                id="email"
                value={userData.email}
                onChange={(e) =>
                  setUserData((prev) => ({ ...prev, email: e.target.value }))
                }
              />
            </div>
            <div className="w-full sm:w-[350px]">
              {emailError && (
                <p className="text-danger self-start -mt-2">{emailError}</p>
              )}
            </div>
            <div
              className="flex w-full justify-center sm:w-[350px] items-center bg-[#10CAF2] rounded-[10px]"
              onClick={() => handleGetRequest()}
            >
              <button className="text-center font-poppins rounded-md bg-[#10CAF2] text-extrabold text-[24px] h-12 text-[#FFF] font-normal">
                Continue
              </button>
            </div>
            <div className="flex mt-1 justify-center items-center gap-2">
              <span>
                <img src={img6} alt="" className=" h-4 w-4 " />
              </span>
              <p
                className="sm:text-lg text-md font-poppins text-[#07A50D] font-semibold cursor-pointer"
                onClick={() => handleRoute("/login")}
              >
                Back to sign in
              </p>
            </div>
            <div className=" text-[#979797]">
              <p className="text-sm text-center">
                We will send an OTP on above given phone number
              </p>
            </div>
          </div>
          <div className="relative hidden lg:block">
            <img
              className="h-[570px] w-[355px] rounded-tr-[10px] rounded-br-[10px] style={{background: 'linear-gradient(89deg, #00B4D8 0.5%, #037CB3 45.18%, #081972 99.57%)'}}"
              src={Dr}
              alt=""
              style={{
                background:
                  "linear-gradient(89deg, #00B4D8 0.5%, #037CB3 45.18%, #081972 99.57%)",
              }}
            />
            <img
              className="absolute top-[416px] w-[550px] h-[310px] -ml-10 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
              src={Female_Dr}
              alt=""
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
