// import React from "react";
// import FadeLoader from "react-spinners/FadeLoader";
// import Lottie from "lottie-react";
// import heartshape from "./heartshape.json";

// const PageLoader = () => {
//   return (
//     // <div
//     //   className="flex items-center justify-center h-screen z-50 "
//     //   style={{ background: "rgba(0, 0, 0, 0.5)", position: "fixed", top: 0, left: 0, right: 0, bottom: 0 }}
//     // >
//     //   <FadeLoader color={'#36d7b7'} loading={true} size={100} />
//     // </div>
//     <div
//     className="flex items-center justify-center h-screen z-50"
//     style={{
//       background: "rgba(0, 0, 0, 0.5)",
//       position: "fixed",
//       top: 0,
//       left: 0,
//       right: 0,
//       bottom: 0,
//       display: "flex",
//       alignItems: "center",
//       justifyContent: "center"
//     }}
//   >
//     <div
//       style={{
//         width: "40%",
//         height: "40%",
//       }}
//     >
//       <Lottie animationData={heartshape} />
//     </div>
//   </div>
//   )
// }

// export default PageLoader
import React from "react";
import Lottie from "lottie-react";
import heartshape from "./heartshape.json";

const PageLoader = () => {
  return (
    <div
      className="flex items-center justify-center top-1/2 h-screen z-50"
      style={{
        background: "rgba(0, 0, 0, 0.5)",
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div className="  sm:w-[40%] sm:h-[40%]  ">
        <Lottie animationData={heartshape} />
      </div>
    </div>
  );
};

export default PageLoader;
