import React, { useState, useEffect } from "react";
import Header from "../Header";
import img1 from "../../../images/Pathlab/medicine_pic.png";
import Swal from "sweetalert2";

const RequestReport = () => {
  const [selectedOption, setSelectedOption] = useState("unpaid");

  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
  };

  // useEffect(() => {
  //   console.log("Selected option:", selectedOption);
  // }, [selectedOption]);

  // const myAlert = () => {
  //   if (!handleValidation()) {
  //     Swal.fire({
  //       title: "Request for Report Submitted Successfully",
  //       text: "Go to My Report Section",
  //       icon: "success",
  //       customClass: {
  //         confirmButton: "custom-ok-button-class",
  //       },
  //     });
  //   } else {
  //     console.log("Validation succeeded");
  //   }
  // };

  // const myAlert = async () => {
  //   if (!handleValidation()) {
  //     const userId = localStorage.getItem("userId");
  //     try {
  //       const formDataToSend = new FormData();
  //       formDataToSend.append("user_id", userId);
  //       formDataToSend.append("patient_name", formData.patientName);
  //       formDataToSend.append("patient_phone", formData.phoneNumber);
  //       formDataToSend.append("paymode", selectedOption.toString());
  //       formDataToSend.append("lab_name", formData.pathlabName);
  //       formDataToSend.append("test_date", formData.testDate);
  //       formDataToSend.append("lab_address", formData.address);
  //       formDataToSend.append("referred_by", formData.referredBy);
  //       formDataToSend.append("age", formData.age);
  //       formDataToSend.append("attach_recipt", formData.file.name);

  //       const response = await fetch('https://app.healthcrad.com/request_report.php', {
  //         method: 'POST',
  //         body: formDataToSend
  //       });

  //       if (response.ok) {
  //         console.log('Image Uploaded Successfully');
  //       } else {
  //         console.error('Error uploading image:', response.statusText);
  //       }
  //     } catch (error) {
  //       console.error('Error:', error);
  //     }
  //   } else {
  //     console.log('Validation succeeded');
  //   }
  // };

  const myAlert = async () => {
    const userId = localStorage.getItem("userId");
    const formDataToSend = new FormData();
    formDataToSend.append("user_id", userId);
    formDataToSend.append("patient_name", formData.patientName);
    formDataToSend.append("patient_phone", formData.phoneNumber);
    formDataToSend.append("paymode", selectedOption.toString());
    formDataToSend.append("lab_name", formData.pathlabName);
    formDataToSend.append("test_date", formData.testDate);
    formDataToSend.append("lab_address", formData.address);
    formDataToSend.append("referred_by", formData.referredBy);
    formDataToSend.append("age", formData.age);
    formDataToSend.append("attach_recipt", formData.file);
    try {
      const response = await fetch(
        "https://app.healthcrad.com/request_report.php",
        {
          method: "POST",
          body: formDataToSend,
        }
      );

      if (response.ok) {
        console.log("Data Uploaded Successfully");
      } else {
        console.error("Error uploading data:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // Validation
  const [formData, setFormData] = useState({
    pathlabName: "",
    address: "",
    patientName: "",
    age: "",
    referredBy: "",
    testDate: "",
    phoneNumber: "",
    file: null,
  });

  const [fileName, setFileName] = useState("");

  const [validationMessage, setValidationMessage] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const uploadedFile = e.target.files[0];
    if (uploadedFile) {
      setFileName(uploadedFile.name);
      setFormData({ ...formData, file: uploadedFile });
    }
  };

  const handleValidation = () => {
    if (
      !formData.pathlabName ||
      !formData.address ||
      !formData.patientName ||
      !formData.age ||
      !formData.referredBy ||
      !formData.testDate ||
      !formData.phoneNumber ||
      !formData.file
    ) {
      setValidationMessage("Please fill in all fields and upload a file.");
    } else {
      setValidationMessage("");
    }
  };

  return (
    <>
      <Header />
      <div className="pt-28 pb-24">
        <div className="flex justify-center items-center">
          <div className="cursor-default flex items-center justify-center -mt-3 mb-8  w-48 h-10 border-[1px] border-[#10CAF2] bg-[#FFF] rounded-[10px]">
            <p className="text-[#00B4D8] font-poppins text-xl">
              Request a Report
            </p>
          </div>
        </div>
        <div className="flex flex-col justify-center items-center">
          <div
            className="sm:w-[95%] md:w-[95%] lg:w-[85%] xl:w-[55%] w-full bg-[#F5F2F2] border-[1px] border-[#00000033] flex-col flex rounded-[10px] gap-3 p-6"
            style={{ boxShadow: "4px 4px 4px 0px rgba(0, 0, 0, 0.20)" }}
          >
            {/* 1st */}
            <div className="flex sm:flex-row flex-col justify-between sm:gap-0 gap-3 items-center">
              <div className="flex flex-col  sm:ml-0">
                <label
                  className="text-[#000] font-poppins font-bold"
                  htmlFor="name"
                >
                  Test Receipt
                </label>
                <div className="w-[260px] flex sm:w-[270px] p-1 font-poppins gap-3 items-center h-[42px] focus:outline-none rounded-[10px] border-[1px] border-[#00000040] bg-[#FFF]">
                  <label
                    htmlFor="file-upload"
                    className="bg-[#00B4D8] font-medium ml-2 self-center text-white py-1 px-2 rounded-[10px] cursor-pointer"
                  >
                    Upload
                  </label>
                  <p className="self-center text-[#666161] sm:text-[16px] text-sm overflow-hidden whitespace-nowrap  truncate">
                    {fileName ? fileName : "If you have any"}
                    {/* w-[180px] md:w-[150px] lg:w-[200px] xl:w-[250px] */}
                  </p>
                  <input
                    id="file-upload"
                    // type="file"
                    className="hidden"
                    type="file"
                    name="file"
                    onChange={handleFileChange}
                    // onChange={handleCombinedChange}
                    // onChange={}
                  />
                </div>
              </div>
              <div className="flex flex-col ">
                <label
                  className="text-[#000] font-poppins font-bold"
                  htmlFor="name"
                >
                  Pathlab Name
                </label>
                <input
                  type="text"
                  className="w-[260px] border-[1px] border-[#00000040] bg-[#FFF] sm:w-[270px] h-[40px] p-3 placeholder:text-[18px] pl-[8px] focus:outline-none rounded-[10px]"
                  name="pathlabName"
                  value={formData.pathlabName}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            {/* 2nd */}
            <div className="flex sm:flex-row flex-col sm:gap-0 gap-3 justify-between items-center">
              <div className="flex flex-col">
                <label
                  className="text-[#000] font-poppins font-bold"
                  htmlFor="name"
                >
                  Pathlab Address
                </label>
                <input
                  type="text"
                  className="w-[260px] sm:w-[270px] h-[40px] p-3 placeholder:text-[18px] pl-[8px] focus:outline-none rounded-[10px] border-[1px] border-[#00000040] bg-[#FFF]"
                  name="address"
                  value={formData.address}
                  onChange={handleInputChange}
                />
              </div>
              <div className="flex flex-col ">
                <label
                  className="text-[#000] font-poppins font-bold"
                  htmlFor="name"
                >
                  Patient Name
                </label>
                <input
                  className="w-[260px] sm:w-[270px] h-[40px] p-3 placeholder:text-[18px] pl-[8px] focus:outline-none rounded-[10px] border-[1px] border-[#00000040] bg-[#FFF]"
                  name="patientName"
                  value={formData.patientName}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            {/* 3rd */}
            <div className="flex sm:flex-row flex-col sm:gap-0 gap-3 justify-between items-center">
              <div className="flex flex-col ">
                <label
                  className="text-[#000] font-poppins font-bold"
                  htmlFor="name"
                >
                  Patient Age
                </label>
                <input
                  type="tel"
                  className="w-[260px] sm:w-[270px] h-[40px] p-3 placeholder:text-[18px] pl-[8px] focus:outline-none rounded-[10px] border-[1px] border-[#00000040] bg-[#FFF]"
                  name="age"
                  value={formData.age}
                  onChange={handleInputChange}
                />
              </div>
              <div className="flex flex-col ">
                <label
                  className="text-[#000] font-poppins font-bold"
                  htmlFor="name"
                >
                  Referred By
                </label>
                <input
                  className="w-[260px] sm:w-[270px] h-[40px] p-3 placeholder:text-[18px] pl-[8px] focus:outline-none rounded-[10px] border-[1px] border-[#00000040] bg-[#FFF]"
                  type="text"
                  name="referredBy"
                  value={formData.referredBy}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            {/* 4th */}
            <div className="flex sm:flex-row flex-col sm:gap-0 gap-3 justify-between items-center">
              <div className="flex flex-col  ">
                <label
                  className="text-[#000] font-poppins font-bold"
                  htmlFor="name"
                >
                  Test Date
                </label>
                <input
                  type="text"
                  className="w-[260px] sm:w-[270px] h-[40px] p-3 placeholder:text-[18px] pl-[8px] focus:outline-none rounded-[10px] border-[1px] border-[#00000040] bg-[#FFF]"
                  name="testDate"
                  value={formData.testDate}
                  onChange={handleInputChange}
                />
              </div>
              <div className="flex flex-col ">
                <label
                  className="text-[#000] font-poppins font-bold"
                  htmlFor="name"
                >
                  Phone Number
                </label>
                <input
                  className="w-[260px] sm:w-[270px] h-[40px] p-3 placeholder:text-[18px] pl-[8px] focus:outline-none rounded-[10px] border-[1px] border-[#00000040] bg-[#FFF]"
                  type="text"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            {/* Radio Button */}
            <div className="flex flex-row sm:justify-start justify-center gap-1">
              <label
                htmlFor="option1"
                className="text-[#000] font-poppins font-bold"
              >
                {" "}
                Test Payment:{" "}
              </label>
              <input
                id="option1"
                type="radio"
                name="options"
                value="paid"
                className="h-4 w-4 text-[#00B4D8] focus:ring-[#00B4D8] mt-1 border-gray-300 rounded cursor-pointer"
                onChange={handleRadioChange}
                checked={selectedOption === "paid"}
              />
              <label
                htmlFor="option1"
                className={`font-poppins ${
                  selectedOption === "paid"
                    ? "text-[#00B4D8] font-bold"
                    : "text-[#000]"
                }`}
              >
                Paid
              </label>
              <input
                id="option2"
                type="radio"
                name="options"
                value="unpaid"
                className="h-4 w-4 text-[#00B4D8] focus:ring-[#00B4D8] mt-1 border-[#00B4D8] rounded cursor-pointer"
                onChange={handleRadioChange}
                checked={selectedOption === "unpaid"}
              />
              <label
                htmlFor="option2"
                className={`font-poppins ${
                  selectedOption === "unpaid"
                    ? "text-[#00B4D8] font-bold"
                    : "text-[#000]"
                }`}
              >
                Unpaid
              </label>
            </div>
            {validationMessage && (
              <div className="text-red-500 text-sm mb-4">
                {validationMessage}
              </div>
            )}
            <div className="hidden sm:block">
              <div className="flex justify-center items-center sm:justify-center">
                <div
                  className="bg-[#00B4D8] w-[260px] sm:w-44 h-10 rounded-[10px] flex items-center justify-center cursor-pointer"
                  onClick={() => myAlert()}
                >
                  <button className="text-[#FFF] font-poppins text-xl">
                    Request Report
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* mobile view button design start */}
          <div
            className=" sm:hidden fixed z-50 flex justify-around items-center bg-[#00B4D8]   w-full bottom-0 "
            onClick={() => myAlert()}
          >
            <div className="select-none  w-[170px]  h-11  flex items-center justify-center cursor-pointer">
              <button className="w-40 h-10 self-end font-poppins text-[#FFF] text-xl">
                Request Report
              </button>
            </div>
          </div>
          {/* mobile view button design end */}
        </div>
      </div>
    </>
  );
};

export default RequestReport;
