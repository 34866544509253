import React, { useState } from "react";
import Google_review from "../../images/Review/Google_review_img.png";
import Stars from "../../images/Review/Stars.png";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import Sumit from "../../images/Review/sumit_raj.png";
import Abhi from "../../images/Review/Abhi.png";
import Amlesh from "../../images/Review/amlesh.png";
import Mantu from "../../images/Review/mantu.png";
import Pragati from "../../images/Review/pragati.png";
import Suman from "../../images/Review/suman.png";
import Saurav from "../../images/Review/Saurabh.png";

const Review = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [slider, setSlider] = useState(null);
  const settings = {
    infinite: true,
    dots: true,
    speed: 200,
    slidesToShow: 4,
    slidesToScroll: 1,
    beforeChange: (current, next) => setCurrentSlide(next + 2),
    autoplay: true,
    speed: 800,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2.3,
          slidesToScroll: 1,
        },
        classes: "lg:gap-4",
      },
      {
        breakpoint: 850,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
        classes: "md:gap-3",
      },
      {
        breakpoint: 760,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
        },
        classes: "sm:gap-2",
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
        classes: "gap-3",
      },
    ],
    appendDots: (dots) => (
      <div
        style={{
          bottom: "10px",
        }}
      >
        <ul className="sm:hidden"> {dots} </ul>
      </div>
    ),
  };

  return (
    <>
      <div className="pt-16  ">
        <div className="flex flex-row justify-center items-center  ">
          <p className="text-[#2F2D2D] text-[18px] sm:text-[16px] lg:text-[24px] xl:text-[30px] font-semibold font-lato ">
            Read what our customers say
          </p>
        </div>
        <div className="w-full flex flex-row justify-center items-center">
          <div className=" ">
            <div className="mt-16 sm:mt-14 mb-4 flex flex-col justify-center items-center hidden sm:block">
              <img
                src={Google_review}
                alt=""
                srcset=""
                className="sm:w-[250px] sm:h-[100px] w-[125px] h-[50px]   "
              />
              <div
                className="cursor-pointer select-none flex justify-center items-center w-20 h-6 sm:w-32 sm:h-9 rounded-[10px]"
                style={{
                  background:
                    "linear-gradient(180deg, #1A30A1 0%, #16A9CA 100%)",
                }}
              >
                <Link
                  className="flex items-center"
                  to="https://play.google.com/store/apps/details?id=com.foundercode.healthcrad_user"
                  target="_blank"
                >
                  <p className="text-[#FFFFFF] font-poppins sm:text-[14px] text-[8px]">
                    Write a review
                  </p>
                </Link>
              </div>
            </div>
          </div>
          <div className="w-full sm:w-[80%] overflow-x-hidden">
            <Slider
              className="your-slider-styles "
              {...settings}
              ref={(slider) => setSlider(slider)}
            >
              {/* 1st */}
              <div
                className={`mx-2 xl:mx-0 ${
                  currentSlide === 1
                    ? "transform sm:scale-125 transition-transform duration-500"
                    : ""
                }`}
              >
                <div
                  className="flex flex-col mt-20 sm:mt-32  mb-12 sm:mb-10 justify-center items-center gap-2 pb-3 bg-[#FFF] rounded-[20px] shadow-md w-[350px] sm:w-[250px]"
                  // style={{
                  //   boxShadow: "4px 4px 53.9px -11px rgba(0, 0, 0, 0.25)",
                  // }}
                >
                  <img
                    src={Sumit}
                    alt=""
                    srcset=""
                    className="-mt-11  sm:-mt-16 sm:h-[95px] sm:w-[95px] w-[80px] h-[80px]"
                  />
                  <p className="text-[#000] font-poppins sm:text-md text-sm font-bold -mt-1">
                    Sumit Raj
                  </p>
                  <div className="flex flex-row gap-2 justify-center items-center">
                    <div className="underline-offset-8 w-[17vh] sm:w-[14vh] h-[1px] bg-[#0000004d] border-[1px] color-[#0000002E] center-alignment"></div>
                    <p className="sm:text-4xl text-3xl -mt-6 text-[#00B4D8] font-poppins font-semibold">
                      ,,
                    </p>
                    <div className="underline-offset-8  w-[17vh] sm:w-[14vh] h-[1px] bg-[#0000004d] border-[1px] color-[#0000002E] center-alignment"></div>
                  </div>
                  <p className="text-[#666161] font-poppins font-medium text-[12px] w-[90%]  sm:text-[11px]">
                    Easy to use this application we buy medicine at cheap price
                    compare to others platform I love this app.
                  </p>
                  <img
                    src={Stars}
                    alt=""
                    srcset=""
                    className="mt-1 mb-1 w-[85px] h-[16px]"
                  />
                </div>
              </div>

              {/* 2nd */}
              <div
                className={`mx-2 xl:mx-0 ${
                  currentSlide === 2
                    ? "transform sm:scale-125 transition-transform duration-500"
                    : ""
                }`}
              >
                <div
                  className="flex flex-col mt-20 sm:mt-32  mb-12 sm:mb-10  justify-center items-center gap-2 pb-3 bg-[#FFF] rounded-[20px] shadow-md w-[350px] sm:w-[250px]"
                  // style={{
                  //   boxShadow: "4px 4px 53.9px -11px rgba(0, 0, 0, 0.25)",
                  // }}
                >
                  <img
                    src={Amlesh}
                    alt=""
                    srcset=""
                    className="-mt-11 sm:-mt-16 sm:h-[95px] sm:w-[95px] w-[80px] h-[80px]"
                  />
                  <p className="text-[#000] font-poppins sm:text-md text-sm font-bold -mt-1">
                    Amlesh Kumar
                  </p>
                  <div className="flex flex-row gap-2 justify-center items-center">
                    <div className="underline-offset-8 w-[17vh] sm:w-[14vh] h-[1px] bg-[#0000004d] border-[1px] color-[#0000002E] center-alignment"></div>
                    <p className="sm:text-4xl text-3xl -mt-6 text-[#00B4D8] font-poppins font-semibold">
                      ,,
                    </p>
                    <div className="underline-offset-8 w-[17vh] sm:w-[14vh] h-[1px] bg-[#0000004d] border-[1px] color-[#0000002E] center-alignment"></div>
                  </div>
                  <p className=" text-[#666161] font-poppins font-medium text-[12px] w-[90%] sm:text-[11px]">
                    Very useful app to get all medical services at one place.
                    Loved it
                  </p>
                  <img
                    src={Stars}
                    alt=""
                    srcset=""
                    className="mt-1 mb-1 w-[85px] h-[16px]"
                  />
                </div>
              </div>
              {/* 3rd */}
              <div
                className={`mx-2 xl:mx-0 ${
                  currentSlide === 3
                    ? "transform sm:scale-125 transition-transform duration-500"
                    : ""
                }`}
              >
                <div
                  className="flex flex-col mt-20 sm:mt-32  mb-12 sm:mb-10  justify-center items-center gap-2 pb-3 bg-[#FFF] rounded-[20px] shadow-md w-[350px] sm:w-[250px]"
                  // style={{
                  //   boxShadow: "4px 4px 53.9px -11px rgba(0, 0, 0, 0.25)",
                  // }}
                >
                  <img
                    src={Pragati}
                    alt=""
                    srcset=""
                    className="-mt-11 sm:-mt-16 sm:h-[95px] sm:w-[95px] w-[80px] h-[80px]"
                  />
                  <p className="text-[#000] font-poppins sm:text-md text-sm font-bold -mt-1">
                    Pragati Ranjan
                  </p>
                  <div className="flex flex-row gap-2 justify-center items-center">
                    <div className="underline-offset-8 w-[17vh] sm:w-[14vh] h-[1px] bg-[#0000004d] border-[1px] color-[#0000002E] center-alignment"></div>
                    <p className="sm:text-4xl text-3xl -mt-6 text-[#00B4D8] font-poppins font-semibold ">
                      ,,
                    </p>
                    <div className="underline-offset-8 w-[17vh] sm:w-[14vh] h-[1px] bg-[#0000004d] border-[1px] color-[#0000002E] center-alignment"></div>
                  </div>
                  <p className="text-[#666161] font-poppins w-[90%] font-medium text-[12px] sm:text-[11px]">
                    HealthCRAD provides all the healthcare facilities at one
                    platform in less time and and affordable price. Very fast
                    services in village areas.
                  </p>
                  <img
                    src={Stars}
                    alt=""
                    srcset=""
                    className="mt-1 mb-1 w-[85px] h-[16px]"
                  />
                </div>
              </div>

              {/* 4th */}
              <div
                className={`mx-2 xl:mx-0 ${
                  currentSlide === 4
                    ? "transform sm:scale-125 transition-transform duration-500"
                    : ""
                }`}
              >
                <div
                  className="flex flex-col mt-20 sm:mt-32  mb-12 sm:mb-10  justify-center items-center gap-2 pb-3 bg-[#FFF] rounded-[20px] shadow-md w-[350px] sm:w-[250px]"
                  // style={{
                  //   boxShadow: "4px 4px 53.9px -11px rgba(0, 0, 0, 0.25)",
                  // }}
                >
                  <img
                    src={Suman}
                    alt=""
                    srcset=""
                    className="-mt-11 sm:-mt-16 sm:h-[95px] sm:w-[95px] w-[80px] h-[80px]"
                  />
                  <p className="text-[#000] font-poppins sm:text-md text-sm font-bold -mt-1">
                    Suman Shekhar
                  </p>
                  <div className="flex flex-row gap-2 justify-center items-center">
                    <div className="underline-offset-8 w-[17vh] sm:w-[14vh] h-[1px] bg-[#0000004d] border-[1px] color-[#0000002E] center-alignment"></div>
                    <p className="sm:text-4xl text-3xl -mt-6 text-[#00B4D8] font-poppins font-semibold">
                      ,,
                    </p>
                    <div className="underline-offset-8  w-[17vh] sm:w-[14vh] h-[1px] bg-[#0000004d] border-[1px] color-[#0000002E] center-alignment"></div>
                  </div>
                  <p className="text-[#666161] font-poppins font-medium text-[12px] w-[90%] sm:text-[11px]">
                    Hope for this app people can easily buy medicines sitting at
                    home. So, this app is very helpful for everyone.
                  </p>
                  <img
                    src={Stars}
                    alt=""
                    srcset=""
                    className="mt-1 mb-1 w-[85px] h-[16px]"
                  />
                </div>
              </div>

              {/* 5th */}
              <div
                className={`mx-2 xl:mx-0 ${
                  currentSlide === 5
                    ? "transform sm:scale-125 transition-transform duration-500"
                    : ""
                }`}
              >
                <div
                  className="flex flex-col mt-20 sm:mt-32  mb-12 sm:mb-10  justify-center items-center gap-2 pb-3 bg-[#FFF] rounded-[20px] shadow-md w-[350px] sm:w-[250px]"
                  // style={{
                  //   boxShadow: "4px 4px 53.9px -11px rgba(0, 0, 0, 0.25)",
                  // }}
                >
                  <img
                    src={Abhi}
                    alt=""
                    srcset=""
                    className="-mt-11 sm:-mt-16 sm:h-[95px] sm:w-[95px] w-[80px] h-[80px]"
                  />
                  <p className="text-[#000] font-poppins sm:text-md text-sm font-bold -mt-1">
                    Abhishek Kumar
                  </p>
                  <div className="flex flex-row gap-2 justify-center items-center">
                    <div className="underline-offset-8 w-[17vh] sm:w-[14vh] h-[1px] bg-[#0000004d] border-[1px] color-[#0000002E] center-alignment"></div>
                    <p className="sm:text-4xl text-3xl -mt-6 text-[#00B4D8] font-poppins font-semibold">
                      ,,
                    </p>
                    <div className="underline-offset-8 w-[17vh] sm:w-[14vh] h-[1px] bg-[#0000004d] border-[1px] color-[#0000002E] center-alignment"></div>
                  </div>
                  <p className="text-[#666161] font-poppins font-medium text-[12px] w-[90%] sm:text-[11px]">
                    Best services. HealthCRAd gives best healthcare facilities
                  </p>
                  <img
                    src={Stars}
                    alt=""
                    srcset=""
                    className="mt-1 mb-1 w-[85px] h-[16px]"
                  />
                </div>
              </div>
              {/* 6th */}
              <div
                className={`mx-2 xl:mx-0 ${
                  currentSlide === 6
                    ? "transform sm:scale-125 transition-transform duration-500"
                    : ""
                }`}
              >
                <div
                  className="flex flex-col mt-20 sm:mt-32  mb-12 sm:mb-10  justify-center items-center gap-2 pb-3 bg-[#FFF] rounded-[20px] shadow-md w-[350px] sm:w-[250px]"
                  // style={{
                  //   boxShadow: "4px 4px 53.9px -11px rgba(0, 0, 0, 0.25)",
                  // }}
                >
                  <img
                    src={Mantu}
                    alt=""
                    srcset=""
                    className="-mt-11 sm:-mt-16 sm:h-[95px] sm:w-[95px] w-[80px] h-[80px]"
                  />
                  <p className="text-[#000] font-poppins sm:text-md text-sm font-bold -mt-1">
                    Mantu Singh
                  </p>
                  <div className="flex flex-row gap-2 justify-center items-center">
                    <div className="underline-offset-8 w-[17vh] sm:w-[14vh] h-[1px] bg-[#0000004d] border-[1px] color-[#0000002E] center-alignment"></div>
                    <p className="sm:text-4xl text-3xl -mt-6 text-[#00B4D8] font-poppins font-semibold">
                      ,,
                    </p>
                    <div className="underline-offset-8 w-[17vh] sm:w-[14vh] h-[1px] bg-[#0000004d] border-[1px] color-[#0000002E] center-alignment"></div>
                  </div>
                  <p className=" text-[#666161] font-poppins font-medium text-[12px] w-[90%] sm:text-[11px]">
                    HealthCRAD is one of the best app for booking appointment,
                    order medicine with less time at high discount. Book
                    ambulance at any time any where.
                  </p>
                  <img
                    src={Stars}
                    alt=""
                    srcset=""
                    className="mt-1 mb-1 w-[85px] h-[16px]"
                  />
                </div>
              </div>
              <div
                className={`mx-2 xl:mx-0 ${
                  currentSlide === 7
                    ? "transform sm:scale-125 transition-transform duration-500"
                    : ""
                }`}
              >
                <div
                  className="flex flex-col mt-20 sm:mt-32 mb-12 sm:mb-10 justify-center items-center gap-2 pb-3 bg-[#FFF] rounded-[20px] shadow-md w-[350px] sm:w-[250px]"
                  // style={{
                  //   boxShadow: "4px 4px 53.9px -11px rgba(0, 0, 0, 0.25)",
                  // }}
                >
                  <img
                    src={Saurav}
                    alt=""
                    srcset=""
                    className="-mt-11  sm:-mt-16 sm:h-[95px] sm:w-[95px] w-[80px] h-[80px]"
                  />
                  <p className="text-[#000] font-poppins sm:text-md text-sm font-bold -mt-1">
                    Saurabh Singh
                  </p>
                  <div className="flex flex-row gap-2 justify-center items-center">
                    <div className="underline-offset-8 w-[17vh] sm:w-[14vh] h-[1px] bg-[#0000004d] border-[1px] color-[#0000002E] center-alignment"></div>
                    <p className="sm:text-4xl text-3xl -mt-6 text-[#00B4D8] font-poppins font-semibold">
                      ,,
                    </p>
                    <div className="underline-offset-8 w-[17vh] sm:w-[14vh] h-[1px] bg-[#0000004d] border-[1px] color-[#0000002E] center-alignment"></div>
                  </div>
                  <p className="text-[#666161] font-poppins font-medium w-[90%] text-[12px] sm:text-[11px]">
                    This application is easy to use, all the features which are
                    available in this application in any other application, you
                    you can order medicine...
                  </p>
                  <img
                    src={Stars}
                    alt=""
                    srcset=""
                    className="mt-1 mb-1 w-[85px] h-[16px]"
                  />
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
};

export default Review;
