// import { SwipeableButton } from "react-swipeable-button";

// function App() {

//   const onSuccess = () => {
//     console.log("Successfully Swiped!");
//   };

//   return (
//     <div className="w-[270px] h-[50px] bg-white">
//        <SwipeableButton
//                   onSuccess={onSuccess} //callback function
//                   text="Swipe me!" //string 
//                   text_unlocked="yeee" //string
//                   color="#16362d" //css hex color
//                 />
//     </div>
//   );
// }

// export default App; 