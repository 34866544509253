import React, { useState, useEffect, useRef } from "react";
import Header from "../pages/HomePage/Header";
import { useLocation, useNavigate } from "react-router-dom";
import Dr from "../images/Login and Signup materials/Ultimate Healthcare2.png";
import Female_Dr from "../images/Login and Signup materials/10-109014_female-doctor-transparent-images-medical-assistant.png";
import Hlogo from "../images/Login and Signup materials/logo.png";
import siteCofig from "../urlFile";
import axios from "axios";
import Bg from "../images/Light-Blue-.png";
import PageLoader from "../Loader/PageLoader";

const OtpVerification = () => {
  const [loading, setLoading] = useState(false); // Add loading state
  const [loginSuccess, setLoginSuccess] = useState(false);
  const [timer, setTimer] = useState(30);
  const [verificationData, setVerificationData] = useState({});
  const [updatedOtp, setUpdatedOtp] = useState("");
  const [isOtpValid, setIsOtpValid] = useState(true);
  const [inputRefs, setInputRefs] = useState([
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
  ]);
  const navigate = useNavigate();
  const location = useLocation();
  const [otpResent, setOtpResent] = useState(false);

  const handleRoute = (path) => {
    setLoading(true);
    setTimeout(() => {
      navigate(path);
    }, 1000);
  };

  // Resend OTP API
  const resendOtp = async (mobileNumber) => {
    try {
      const response = await fetch(
        `${siteCofig.BASE_URL}${siteCofig.RESEND_OTP}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ phone: mobileNumber }),
        }
      );

      if (response.ok) {
        setOtpResent(true);
        setTimer(30);
        setTimeout(() => {
          setOtpResent(false);
        }, 3000);
      } else {
        console.error("Failed to resend OTP:", response.statusText);
      }
    } catch (error) {
      console.error("Failed to resend OTP:", error.message);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (timer > 0) {
        setTimer(timer - 1);
      }
    }, 1000);

    // Clear interval when component unmounts
    return () => clearInterval(interval);
  }, [timer]);

  useEffect(() => {
    const { userData, mobileNumber } = location.state || {};
    setVerificationData(userData);
    console.log(mobileNumber, "kkkkkkkkk");
  }, [location.state]);

  const handleInputChange = (index, value) => {
    const concatenatedValues = inputRefs.reduce((acc, obj) => {
      return acc + obj.current.value;
    }, "");
    setUpdatedOtp(concatenatedValues);
    if (value && index < inputRefs.length - 1) {
      inputRefs[index + 1].current.focus();
    }
  };

  const handleKeyDown = (index, event) => {
    if (event.key === "Backspace" && index > 0 && !event.target.value) {
      inputRefs[index - 1].current.focus();
    }
  };

  const handleInput = (e) => {
    const { value } = e.target;
    const cleanedValue = value.replace(/\D/g, "");
    e.target.value = cleanedValue;
  };

  // ========VerifyOTP======
  const verifyOtp = async () => {
    setLoading(true); // Start loading
    if (verificationData.otp === updatedOtp) {
      console.log(verificationData, "vvvvvvvvvvvv");
      if (verificationData.msg === "Successfully Login") {
        localStorage.setItem("mobileNumber", String(verificationData.phone));
        localStorage.setItem("userId", String(verificationData.id));

        // GET Username through API & set in local storage
        await getUserData();
        setLoginSuccess(true);
        console.log("Login success state:", loginSuccess);
      } else if (verificationData.msg === "Your mobile number Not register") {
        localStorage.setItem("mobileNumber", String(verificationData.mobile));
        handleRoute("/register");
      }
    } else {
      setIsOtpValid(false);
      setTimeout(() => {
        setIsOtpValid(true);
      }, 10000);
    }
    setLoading(false); // Stop loading
  };

  // Verify OTP
  const getOtp = async () => {
    setLoading(true); // Start loading
    let reqBody = {
      mobile: verificationData.phone,
    };
    try {
      const response = await axios.post(
        siteCofig.BASE_URL + siteCofig.USER_LOGIN,
        reqBody
      );
      const userData = response.data;
      if (
        userData.msg === "Successfully Login" ||
        userData.msg === "Your mobile number Not register"
      ) {
        setVerificationData(userData);
        setTimer(30);
      }
      console.log(userData);
    } catch (error) {
      console.error("Error fetching OTP:", error);
    }
    setLoading(false); // Stop loading
  };

  //GET User Name through Local Storage
  const getUserData = async () => {
    setLoading(true); // Start loading
    let reqBody = {
      id: localStorage.getItem("userId"),
    };
    try {
      const response = await axios.post(
        siteCofig.BASE_URL + siteCofig.VIEW_PROFILE,
        reqBody
      );
      const userData = response.data.data;
      console.log(userData, "mmmmmmmmm");
      localStorage.setItem("userName", userData.username);
      navigate("/", {
        state: { userName: userData.username },
      });
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
    setLoading(false); // Stop loading
  };

  // const handleRoute = (path) => {
  //   navigate(path);
  // };


  useEffect(() => {
    console.log("Login success state:", loginSuccess);
  }, [loginSuccess]);

  return (
    <>
      <Header />
      {loading && <PageLoader />}
      {otpResent && (
        <div className="bg-[green]  text-white p-2 absolute top-1/2 z-50 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded">
          <p className="w-52 sm:w-72 text-center font-lato ">
            OTP resent successfully !
          </p>
        </div>
      )}
      {loginSuccess && (
        <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white bg-green-500 px-4 py-2 rounded-lg shadow-lg">
          Login success!
        </div>
      )}
      <div
        className="pt-[170px] pb-40"
        style={{
          backgroundImage: `url(${Bg})`,
          height: "100vh",
        }}
      >
        <div className="flex justify-center items-center">
          <div
            className="flex flex-row bg-[#FFF] rounded-[10px]"
            style={{ boxShadow: "4px 4px 18.9px 6px rgba(0, 0, 0, 0.20)" }}
          >
            <div className="flex flex-col items-center gap-2">
              <div className=" ">
                <img
                  src={Hlogo}
                  alt="Logo"
                  className="w-64 h-16 mt-[56px] mb-[26px] "
                />
              </div>
              <div className="flex sm:w-[70%] -mt-6 mb-4">
                <p className="text-center text-sm font-semibold text-[#000]">
                  We have sent an OTP verification code
                  <br />
                  on your given mobile number :{" "}
                  <span>
                    +91{verificationData.phone || verificationData.mobile}
                  </span>
                </p>
              </div>
              <div className="flex sm:w-[68%]">
                <p className="text-md font-bold text-[#7e7a79] font-poppins">
                  Verify OTP
                </p>
              </div>
              <div>
                <form action="#" method="post" className=" ">
                  <div className="flex justify-center items-center mb-1">
                    {[...Array(6)].map((_, index) => (
                      <input
                        className="font-bold focus:outline-none focus:outline-[#10CAF2]"
                        key={index}
                        ref={inputRefs[index]}
                        type="text"
                        inputMode="numeric"
                        maxLength={1}
                        autoComplete="one-time-code"
                        onChange={(e) =>
                          handleInputChange(index, e.target.value)
                        }
                        onInput={(e) => handleInput(e)}
                        onKeyDown={(e) => handleKeyDown(index, e)}
                        style={{
                          marginRight: "8px",
                          width: "40px",
                          height: "40px",
                          background: "beige",
                          textAlign: "center",
                        }}
                      />
                    ))}
                  </div>
                  <button
                    type="submit"
                    className="w-full pt-6 bg-[#10CAF2] text-white rounded-md py-2 px-4 transition duration-300 mt-4 font-bold font-poppins -ml-1 text-xl"
                    onClick={(e) => {
                      e.preventDefault();
                      setIsOtpValid(true); // Reset the validation state before attempting to verify again
                      verifyOtp();
                    }}
                  >
                    Verify
                  </button>
                  {/* ========VerifyOTP====== */}
                  {!isOtpValid && (
                    <div className="text-red-500 text-sm">
                      Invalid OTP. Please enter the correct OTP.
                    </div>
                  )}
                  {/* ========VerifyOTP====== */}
                  <p className="font-poppins">
                    {timer === 0 ? (
                      <button
                        className="text-[#10CAF2]"
                        onClick={(e) => {
                          e.preventDefault();
                          resendOtp(
                            verificationData.phone || verificationData.mobile
                          );
                        }}
                      >
                        <p className="pt-2">Re-send Otp</p>
                      </button>
                    ) : (
                      <>Resend your OTP in {timer} sec.</>
                    )}
                  </p>
                </form>
              </div>
              <div className="flex justify-center items-center sm:w-[90%] w-full mt-3">
                <div className="text-xs flex justify-center p-2 items-center">
                  <p className="text-center font-poppins">
                    By continuing you agree to our{" "}
                    <a className="text-blue-500 hover:text-blue-700 cursor-pointer" >
                      <span onClick={() => handleRoute("/terms&condition")}>Terms and Conditions/</span>
                      <span onClick={() => handleRoute("/privacy&policy")}>Privacy and Policy</span>
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="relative hidden md:block">
              <img
                className="h-[450px] w-[293px] rounded-tr-[10px] rounded-br-[10px] style={{background: 'linear-gradient(89deg, #00B4D8 0.5%, #037CB3 45.18%, #081972 99.57%)'}}"
                src={Dr}
                alt=""
                style={{
                  background:
                    "linear-gradient(89deg, #00B4D8 0.5%, #037CB3 45.18%, #081972 99.57%)",
                }}
              />
              <img
                className="absolute top-[316px] w-[450px] h-[270px] -ml-10 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                src={Female_Dr}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OtpVerification;
