import React, { useState, useEffect } from "react";
import Header from "../Header";
import bg from "../../../images/Pathlab/medicine-removebg-preview.png";
import siteConfig from "../../../urlFile";
import axios from "axios";
const MyReport = () => {
  const [reportList, setReportList] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      let user_id = localStorage.getItem("userId");
      try {
        const response = await axios.get(
          `${siteConfig.BASE_URL}${siteConfig.SHOW_REPORT}${user_id}`
        );
        setReportList(response.data.data);
        console.log("Fetched Medical Specialties:", response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  function handleDownload(reportFileName) {
    const downloadUrl = `https://app.healthcrad.com/api/uploads/pathlab_report/${reportFileName}`;
    fetch(downloadUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", reportFileName);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((error) => {
        console.error("Error downloading report:", error);
      });
  }
  return (
    <>
      <Header />
      <div className="flex flex-col items-center gap-6 justify-center pt-28 pb-36">
        <div
          className="absolute left-1/3.8 -translate-x-1/2 top-[500px] hidden sm:block transform -translate-y-1/2 -z-50 w-[400px] h-[400px] lg:w-[500px] lg:h-[500px] xl:w-[680px] xl:h-[650px] bg-cover rotate-12 bg-no-repeat"
          style={{ backgroundImage: `url(${bg})` }}
        ></div>

        <div className="flex justify-center items-center mb-2">
          <div className="select-none cursor-default sm:w-40 sm:h-12 w-32 h-10 flex justify-center items-center rounded-[10px] border-[1px] border-[#10CAF2] bg-[#FFF]">
            <p className="text-[#10CAF2] text-lg sm:text-2xl font-semibold">
              My Report
            </p>
          </div>
        </div>

        <div className="grid grid-cols-3 justify-between w-full items-center p-1">
          {/* 1st */}

          <div className="select-none cursor-default flex flex-col justify-center items-center gap-10">
            <p className="font-poppins font-semibold text-xl sm:text-2xl text-[#00B4D8]">
              Pathlab
            </p>
            {reportList.map((report) => (
              <div
                key={report.id}
                className="flex justify-center p-2 items-center w-[110px] sm:w-[180px] h-[85px] border-[1px] border-[#00000033] rounded-[10px] bg-[#FFF]"
                style={{ boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" }}
              >
                <p className="text-center font-poppins text-md sm:text-xl text-[#4A4141]">
                  {report.lab_name}
                </p>
              </div>
              // <div
              //   className="flex justify-center p-2 items-center w-[110px] sm:w-[180px] h-[85px] border-[1px] border-[#00000033] rounded-[10px] bg-[#FFF]"
              //   style={{ boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" }}
              // >
              //   <p className="font-poppins text-md sm:text-xl text-center text-[#4A4141]">
              //     Kamre
              //     <br />
              //     Pathlab
              //   </p>
              // </div>
              // <div
              //   className="flex justify-center p-2 items-center  w-[110px] sm:w-[180px] h-[85px] border-[1px] border-[#00000033] rounded-[10px] bg-[#FFF]"
              //   style={{ boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" }}
              // >
              //   <p className="font-poppins text-md sm:text-xl text-center text-[#4A4141]">
              //     Naveen
              //     <br />
              //     Pathlab
              //   </p>
              // </div>
              // <div
              //   className="flex justify-center p-2 items-center  w-[110px] sm:w-[180px] h-[85px] border-[1px] border-[#00000033] rounded-[10px] bg-[#FFF]"
              //   style={{ boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" }}
              // >
              //   <p className="font-poppins text-md sm:text-xl text-center text-[#4A4141]">
              //     Sharma
              //     <br />
              //     Pathlab
              //   </p>
              // </div>
            ))}
          </div>

          {/* 2nd */}
          <div className="select-none cursor-default flex flex-col justify-center items-center gap-10">
            <p className="font-poppins font-semibold text-xl sm:text-2xl text-[#00B4D8]">
              Date
            </p>
            {reportList.map((report) => (
              <div
                key={report.id}
                className="flex justify-center p-2 items-center  w-[110px] sm:w-[180px] h-[85px] border-[1px] border-[#00000033] rounded-[10px] bg-[#FFF]"
                style={{ boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" }}
              >
                <p className="font-poppins text-md sm:text-xl text-center text-[#4A4141]">
                  {/* 20/12/2023 */}
                  {report.test_date}
                  {}
                </p>
              </div>
              // <div
              //   className="flex justify-center p-2 items-center  w-[110px] sm:w-[180px] h-[85px] border-[1px] border-[#00000033] rounded-[10px] bg-[#FFF]"
              //   style={{ boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" }}
              // >
              //   <p className="font-poppins text-md sm:text-xl text-center text-[#4A4141]">
              //     20/12/2023
              //   </p>
              // </div>
              // <div
              //   className="flex justify-center p-2 items-center w-[110px] sm:w-[180px] h-[85px] border-[1px] border-[#00000033] rounded-[10px] bg-[#FFF]"
              //   style={{ boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" }}
              // >
              //   <p className="font-poppins text-md sm:text-xl text-center text-[#4A4141]">
              //     20/12/2023
              //   </p>
              // </div>
              // <div
              //   className="flex justify-center p-2 items-center  w-[110px] sm:w-[180px] h-[85px] border-[1px] border-[#00000033] rounded-[10px] bg-[#FFF]"
              //   style={{ boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" }}
              // >
              //   <p className="font-poppins text-md sm:text-xl text-center text-[#4A4141]">
              //     20/12/2023
              //   </p>
              // </div>
            ))}
          </div>
          {/* 3rd */}
          <div className="select-none flex flex-col justify-center items-center gap-10">
            <p className="font-poppins cursor-default font-semibold text-xl sm:text-2xl text-[#00B4D8]">
              Report
            </p>
            {/* {reportList.map((report) => (
            <div
              className="cursor-pointer flex justify-center p-2 items-center  w-[110px] sm:w-[180px] h-[85px] border-[1px] border-[#00000033] rounded-[10px] bg-[#FFF]"
              style={{ boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" }}
            >
              <button className="font-poppins text-md sm:text-xl text-center text-[#07A50D]">
                Download
                <br />
                Report
              </button>
            </div>
          
            ))} */}
            {reportList.map((report) => (
              <div
                key={report.id} // Make sure to add a unique key when rendering a list
                className="cursor-pointer flex justify-center p-2 items-center  w-[110px] sm:w-[180px] h-[85px] border-[1px] border-[#00000033] rounded-[10px] bg-[#FFF]"
                style={{ boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" }}
              >
                {report.report !== null ? (
                  // <a href={report.report} download>
                  <a
                    href={`https://app.healthcrad.com/api/uploads/pathlab_report/${report.report}`}
                    download
                  >
                    <button className="font-poppins text-md sm:text-xl text-center text-[#07A50D]">
                      Download
                      <br />
                      Report
                    </button>
                  </a>
                ) : (
                  <button
                    className="font-poppins text-md sm:text-xl text-center text-[#808080] cursor-not-allowed"
                    disabled
                  >
                    Wait for
                    <br /> Report
                  </button>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default MyReport;
// <div
//   className="cursor-pointer flex justify-center p-2 items-center  w-[110px] sm:w-[180px] h-[85px] border-[1px] border-[#00000033] rounded-[10px] bg-[#FFF]"
//   style={{ boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" }}
// >
//   <button className="font-poppins text-md sm:text-xl text-center text-[#808080]">
//     Wait For
//     <br />
//     Report
//   </button>
// </div>
// <div
//   className="cursor-pointer flex justify-center p-2 items-center  w-[110px] sm:w-[180px] h-[85px] border-[1px] border-[#00000033] rounded-[10px] bg-[#FFF]"
//   style={{ boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" }}
// >
//   <button className="font-poppins text-md sm:text-xl text-center text-[#07A50D]">
//     Download
//     <br />
//     Report
//   </button>
// </div>
// <div
//   className="cursor-pointer flex justify-center p-2 items-center  w-[110px] sm:w-[180px] h-[85px] border-[1px] border-[#00000033] rounded-[10px] bg-[#FFF]"
//   style={{ boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" }}
// >
//   <button className="font-poppins text-md sm:text-xl text-center text-[#07A50D]">
//     Download
//     <br />
//     Report
//   </button>
// </div>
