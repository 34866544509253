import React, { useState } from "react";
import cart from "../../../images/frontend UI materials/Open box.png";
import PageLoader from "../../../Loader/PageLoader";
import { useNavigate } from "react-router";

const EmptyCart = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleRoute = (path) => {
    setLoading(true);
    setTimeout(() => {
      navigate(path);
    }, 1000);
  };
  return (
    <div className="-mt-4">
      {loading && <PageLoader />}
      <div className=" flex flex-col justify-center items-center gap-3 ">
        <div className="p-2 sm:ml-5  self-start">
          <span className=" font-semibold  text-[#000] font-poppins text-[30px]">
            My Cart
          </span>
        </div>
        <div className="flex justify-center items-center">
          <img src={cart} alt="" className="  w-56 h-56 sm:w-full sm:h-full " />
        </div>
        <div className=" flex justify-center items-center">
          <p className="text-3xl  font-semibold  text-[23px] text-[#000] font-poppins">
            Your Cart is Empty
          </p>
        </div>
        <div className="flex flex-col justify-center items-center">
          <p className="text-[#666161] self-center text-md sm:text-xl font-poppins">
            You have no items added in the cart.
          </p>
          <p className="text-[#666161] self-center text-md sm:text-xl font-poppins">
            Explore and add products you like!
          </p>
        </div>
        <div className=" flex justify-center items-center">
          <div className=" flex justify-center items-center w-[214px] h-10 bg-[#10CAF2] rounded-[5px] ">
            <button
              className="     text-[#FFF] font-poppins font-semibold "
              onClick={() => handleRoute("/allmedicines")}
            >
              ADD PRODUCTS
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmptyCart;
