import React from "react";
// import ComingSoonImg from "../../../images/frontend UI materials/U"
import Pathlogy from "../../../images/frontend UI materials/Diagnostic/Pathology.png";
import JustAt from "../../../images/frontend UI materials/Diagnostic/JustAtHome.jpg";
import Rural from "../../../images/frontend UI materials/Diagnostic/outreach-asha-training.png";
import SheduleAppointment from "../../../images/frontend UI materials/Diagnostic/online-appointment-scheduling-screenshot.png";
import SimpleCollection from "../../../images/frontend UI materials/Diagnostic/new-campaign-choose-metro 1.png";
import FastReliable from "../../../images/frontend UI materials/Diagnostic/lab-tests.png";
import Access from "../../../images/frontend UI materials/Diagnostic/mobile-patient-results.png";
import Convience from "../../../images/frontend UI materials/Diagnostic/marketing-mix-convenience.png";
import Acessability from "../../../images/frontend UI materials/Diagnostic/access.png";
import Assurance from "../../../images/frontend UI materials/Diagnostic/assurance.png";
import { Link } from "react-router-dom";
import Impact from "../../../images/frontend UI materials/Diagnostic/impact.png";
import Header from "../Header";

const Diagnostics = () => {
  return (
    <>
      <Header />
      <div className="pt-16">
        <div className=" relative flex sm:items-center justify-center w-full h-full ">
          <img
            src={Pathlogy}
            alt="pharmacy img"
            className="w-full  sm:h-[450px]"
          />
          <div className="absolute inset-0 flex items-center justify-center text-white text-center font-lato font-semibold">
            <h2 className="text-lg sm:text-5xl ">
              Glimpse to our Diagnostic and <br />
              Path Lab Feature!
            </h2>
          </div>
        </div>

        <div className="text-center text-[#4A4141] text-[11px] sm:text-[20px] font-lato font-normal pt-4 pb-4 mx-2">
          <p>
            At HealthCRAD, we are dedicated to breaking down barriers to
            healthcare access, ensuring that everyone has the opportunity to
            receive high-quality diagnostic services. We are excited to announce
            that in addition to our state-of-the-art laboratory, we will soon be
            offering doorstep blood sample collection, even in rural areas. 
          </p>
        </div>
        <div className="flex justify-center text-[#000] text-[16px] sm:text-[24px] font-poppins font-semibold pb-2">
          <h2>Hello Diagnostic & Path Lab Centers </h2>
        </div>
        <div className="flex justify-center text-[#0924B0] text-[11px] sm:text-[15px] font-poppins font-semibold pb-4">
          <h2>join us HealthCRAD to serve the mass user base ! </h2>
        </div>
        <Link to="https://wa.me/message/532QZXEFUYFWK1" target="/_blank">
          <div className="bg-[#00B4D8] w-[150px] sm:w-[230px] border-[1px] border-[#1A30A14D] rounded-[5px] mx-auto p-2 mb-5">
            <h2 className="text-[16px] sm:text-[20px] text-[#fff] font-poppins font-semibold  text-center">
              Connect now
            </h2>
          </div>
        </Link>

        <div className="underline-offset-8 w-auto border-[1px] color-[#0000002E] center-alignment mt-2 mb-4 xs:mx-2 sm:mx-16"></div>

        {/* ======================Expanding Access to Healthcare============================= */}
        <div className="mb-5 ">
          <h2 className="text-[#10CAF2] text-center font-poppins  font-bold text-[16px] sm:text-[24px]">
            Expanding Access to Healthcare
          </h2>
          {/* <p className="border-b-2 border-[#10CAF2] flex justify-center items-center w-[43px] ml-[35px] sm:w-[55px]  mb-3"></p> */}
          <div className="flex justify-center">
            <div className=" w-[62px] sm:w-[124px]  border-[1.5px] border-[#10CAF2] my-2"></div>
          </div>
        </div>

        {/* DoorStep */}
        <div className="flex justify-around mx-2 mb-5">
          <div className="flex justify-center items-center">
            <div className="">
              <h2 className="text-[#000000] font-poppins font-bold text-[13px] sm:text-[24px] ">
                Doorstep Blood Sample Collection
              </h2>
              <p className="border-b-2 border-[#000] w-[43px] ml-[188px] sm:w-[55px] sm:ml-[370px] mb-3"></p>
              <h3 className="text-[#666161] text-[11px] sm:text-xl w-[230px] xs:w-[260px] sm:w-[660px] leading-4">
                We understand that traveling to a lab for blood tests can be
                challenging, especially for those living in rural areas. That's
                why we're bringing our services directly to your doorstep. Our
                trained professionals will visit your home at a time that suits
                you, making it convenient and hassle-free to get the tests you
                need.
              </h3>
            </div>
          </div>
          <div className="flex items-center justify-center mt-6 sm:mt-[1px]">
            <img
              src={JustAt}
              alt=""
              className="h-[100px] sm:h-[290px] w-[100px] sm:w-[432px] rounded-[10px] "
            />
          </div>
        </div>

        <div className="flex justify-center items-center xs:gap-3 sm:gap-10 mb-5 mx-2 mt-5 ">
          <div className="flex sm:justify-center items-center">
            <img
              src={Rural}
              alt=""
              className="h-[95px] sm:h-[229px] w-[155px] sm:w-[458px] mt-2 sm:mt-[1px] "
            />
          </div>
          <div className="">
            <div className="">
              <h2 className="text-[#000000] font-poppins font-bold text-[13px] sm:text-[24px] ">
                Rural Outreach Program
              </h2>
              <p className="border-b-2 border-[#000] w-[43px] ml-[130px] sm:w-[55px] sm:ml-[250px] mb-3"></p>
              <h3 className="text-[#666161] text-[11px] sm:text-xl w-[230px] xs:w-[260px] sm:w-[660px] leading-4 ">
                We are committed to reaching underserved communities in rural
                areas. Through our rural outreach program, we will partner with
                local healthcare providers and community organizations to ensure
                that everyone has access to essential diagnostic services,
                regardless of their location.
              </h3>
            </div>
          </div>
        </div>
        <div className="underline-offset-8 w-auto border-[1px] color-[#0000002E] center-alignment mt-2 mb-4 xs:mx-2 sm:mx-16"></div>

        {/* =================================== How It Works================================== */}
        <div className="mb-2 ">
          <h2 className="text-[#10CAF2] text-center font-poppins  font-bold text-[16px] sm:text-[24px]">
            How It Works
          </h2>
          {/* <p className="border-b-2 border-[#10CAF2] flex justify-center items-center w-[43px] ml-[35px] sm:w-[55px]  mb-3"></p> */}
          <div className="flex justify-center">
            <div className=" w-[25px] sm:w-[50px]  border-[1.5px] border-[#10CAF2] my-2"></div>
          </div>
        </div>

        {/* card1*/}
        <div className="flex justify-around mx-2 mb-5">
          <div className="flex justify-center items-center">
            <div className="">
              <h2 className="text-[#000000] font-poppins font-bold text-[13px] sm:text-[24px] ">
                Schedule Your Appointment
              </h2>
              <p className="border-b-2 border-[#000] w-[43px] ml-[155px] sm:w-[55px] sm:ml-[300px] mb-3"></p>
              <h3 className="text-[#666161] text-[11px]  sm:text-xl w-[230px] xs:w-[260px] sm:w-[660px] leading-4">
                Simply call our dedicated hotline or use our online booking
                platform to schedule a convenient time for your blood sample
                collection.
              </h3>
            </div>
          </div>
          <div className="flex items-center justify-center">
            <img
              src={SheduleAppointment}
              alt=""
              className="h-[108px] sm:h-[290px] w-[168px] sm:w-[432px] "
            />
          </div>
        </div>
        {/* card2*/}
        <div className="flex justify-center items-center  xs:gap-2 sm:gap-10 mb-5 mx-2 mt-5 ">
          <div className="flex justify-center items-center">
            <img
              src={SimpleCollection}
              alt=""
              className="h-[100px] sm:h-[229px] w-[155px] sm:w-[398px] "
            />
          </div>
          <div className="">
            <div className="">
              <h2 className="text-[#000000] font-poppins font-bold text-[13px] sm:text-[24px] mb-0.5 ">
                Sample Collection at Your Doorstep
              </h2>
              <p className="border-b-2 border-[#000] w-[43px] ml-[205px]  sm:w-[55px] sm:ml-[390px] mb-3"></p>
              <h3 className="text-[#666161] text-[11px]  sm:text-xl w-[230px] xs:w-[260px] sm:w-[660px] leading-4 ">
                Our skilled phlebotomists will visit your home at the scheduled
                time to collect your blood sample. They will follow strict
                safety and hygiene protocols to ensure a safe and comfortable
                experience.
              </h3>
            </div>
          </div>
        </div>

        {/* card3 */}
        <div className="flex justify-around mx-2 mb-5">
          <div className="flex justify-center items-center">
            <div className="">
              <h2 className="text-[#000000] font-poppins font-bold text-[13px] sm:text-[24px] mb-0.5">
                Fast and Reliable Testing
              </h2>
              <p className="border-b-2 border-[#000] w-[43px] ml-[138px] sm:w-[55px] sm:ml-[265px] mb-3"></p>
              <h3 className="text-[#666161] text-[11px]  sm:text-xl w-[230px] xs:w-[260px] sm:w-[660px] leading-4">
                Once collected, your blood sample will be transported to our
                state-of-the-art laboratory for analysis. With our advanced
                technology and expert team, you can trust that your results will
                be accurate and reliable.{" "}
              </h3>
            </div>
          </div>
          <div className="flex items-center justify-center">
            <img
              src={FastReliable}
              alt=""
              className="h-[90px] sm:h-[220px] w-[115px] sm:w-[432px] "
            />
          </div>
        </div>
        {/* card4*/}
        <div className="flex items-center justify-center xs:gap-2 sm:gap-10  mb-5 mx-2 mt-5 ">
          <div className="flex sm:justify-center items-center">
            <img
              src={Access}
              alt=""
              className="h-[90px] sm:h-[229px] w-[155px] sm:w-[398px] mt-[20px] sm:mt-[1px]"
            />
          </div>
          <div className="">
            <div className="">
              <h2 className="text-[#000000] font-poppins font-bold text-[13px] sm:text-[24px] ">
                Access Your Results
              </h2>
              <p className="border-b-2 border-[#000] w-[43px] ml-[100px] sm:w-[55px] sm:ml-[200px] mb-3"></p>
              <h3 className="text-[#666161] text-[11px]  sm:text-xl w-[230px] xs:w-[260px] sm:w-[660px] leading-4 ">
                Receive secure access to your test results through our online
                portal. Our team of healthcare professionals will be available
                to explain your results and provide guidance on next steps.
              </h3>
            </div>
          </div>
        </div>

        <div className="underline-offset-8 w-auto border-[1px] color-[#0000002E] center-alignment mt-2 mb-4 xs:mx-2 sm:mx-16"></div>
        {/* ==============================Why Choose Us====================================== */}
        <div className="mb-5 ">
          <h2 className="text-[#10CAF2] text-center font-poppins  font-bold text-[16px] sm:text-[24px]">
            Why Choose Us
          </h2>
          {/* <p className="border-b-2 border-[#10CAF2] flex justify-center items-center w-[43px] ml-[35px] sm:w-[55px]  mb-3"></p> */}
          <div className="flex justify-center">
            <div className=" w-[25px] sm:w-[50px]  border-[1.5px] border-[#10CAF2] my-2"></div>
          </div>
        </div>

        {/* card1 */}
        <div className="flex justify-around mx-2 mb-5">
          <div className="flex justify-center items-center">
            <div className="">
              <h2 className="text-[#000000] font-poppins font-bold text-[13px] sm:text-[24px] ">
                Convenience
              </h2>
              <p className="border-b-2 border-[#000] w-[43px] ml-[60px] sm:w-[55px] sm:ml-[125px] mb-3"></p>
              <h3 className="text-[#666161] text-[11px] sm:text-xl w-[230px] xs:w-[260px] sm:w-[660px] leading-4">
                Say goodbye to long waits and travel hassles. With our doorstep
                blood sample collection service, getting tested has never been
                easier.
              </h3>
            </div>
          </div>
          <div className="flex items-center justify-center">
            <img
              src={Convience}
              alt=""
              className="h-[65px] sm:h-[153px] w-[168px] sm:w-[432px] "
            />
          </div>
        </div>
        {/* Card2 */}
        <div className="flex justify-center items-center xs:gap-2 sm:gap-10 mb-5 mx-2 mt-5 ">
          <div className="flex sm:justify-center items-center">
            <img
              src={Acessability}
              alt=""
              className="h-[77px] sm:h-[229px] w-[120px] sm:w-[398px] mt-[24px] sm:mt-[1px] "
            />
          </div>
          <div className="flex justify-center items-center">
            <div className="">
              <h2 className="text-[#000000] font-poppins font-bold text-[13px] sm:text-[24px] mb-1 ">
                Accessibility
              </h2>
              <p className="border-b-2 border-[#000] w-[43px] ml-[55px] sm:w-[55px] sm:ml-[115px] mb-3"></p>
              <h3 className="text-[#666161] text-[11px]  sm:text-xl w-[230px] xs:w-[260px] sm:w-[660px] leading-4 ">
                Our skilled phlebotomists will visit your home at the scheduled
                time to collect your blood sample. They will follow strict
                safety and hygiene protocols to ensure a safe and comfortable
                experience.
              </h3>
            </div>
          </div>
        </div>

        {/* Card3 */}
        <div className="flex justify-center items-center mx-2 mb-5">
          <div className="flex justify-center items-center">
            <div className="">
              <h2 className="text-[#000000] font-poppins font-bold text-[13px] sm:text-[24px]">
                Quality Assurance
              </h2>
              <p className="border-b-2 border-[#000] w-[43px] ml-[90px] sm:w-[55px] sm:ml-[180px] mb-3"></p>
              <h3 className="text-[#666161] text-[11px]  sm:text-xl w-[230px] xs:w-[260px] sm:w-[660px] leading-4">
                Rest assured that your samples will be handled with care and
                analyzed using the latest technology. Your health is our top
                priority, and we strive to deliver accurate results every time.
              </h3>
            </div>
          </div>
          <div className="flex items-center justify-center">
            <img
              src={Assurance}
              alt=""
              className="h-[108px] sm:h-[220px] w-[168px] sm:w-[432px] mt-[20px] sm:mt-[1px]"
            />
          </div>
        </div>
        {/* Card4 */}
        <div className="flex justify-center items-center xs:gap-2 sm:gap-10  mb-5 mx-2 mt-5 ">
          <div className="flex sm:justify-center items-center">
            <img
              src={Impact}
              alt=""
              className="h-[100px] sm:h-[210px] w-[155px] sm:w-[400px]  "
            />
          </div>
          <div className="flex justify-center items-center">
            <div className="">
              <h2 className="text-[#000000] font-poppins font-bold text-[13px] sm:text-[24px] ">
                Access Your Results
              </h2>
              <p className="border-b-2 border-[#000] w-[43px] ml-[100px] sm:w-[55px] sm:ml-[200px] mb-3"></p>
              <h3 className="text-[#666161] text-[11px]  sm:text-xl w-[230px] xs:w-[260px] sm:w-[660px] leading-4 ">
                Receive secure access to your test results through our online
                portal. Our team of healthcare professionals will be available
                to explain your results and provide guidance on next steps.
              </h3>
            </div>
          </div>
        </div>
        <div className="underline-offset-8 w-auto border-[1px] color-[#0000002E] center-alignment mt-2 mb-4 xs:mx-2 sm:mx-16"></div>

        <div className="text-center text-[#0924B0] text-[13px] sm:text-[20px] font-lato font-normal pt-4 pb-4 mx-2">
          <p>
            Stay tuned for the launch of our doorstep blood sample collection
            service, coming soon to a neighborhood near you. Your journey to
            better health starts here at HealthCRAD.
          </p>
        </div>

        <div className="text-center text-[#000] text-[15px] sm:text-[20px] font-poppins font-semibold pb-4">
          <h2>
            For inquiries or to schedule an appointment, contact us today!
          </h2>
        </div>

        <a className="" href="tel:7779888289">
          <div className="bg-[#00B4D8] w-[115px]  sm:w-[130px] border-[1px] border-[#000] rounded-[5px] mx-auto p-2 mb-4">
            <h2 className="text-[16px] sm:text-[20px] text-[#fff] font-poppins font-semibold  text-center">
              Call Us
            </h2>
          </div>
        </a>
      </div>
    </>
  );
};

export default Diagnostics;
