import React, { useEffect, useState } from "react";
import galleryicon from "../../../images/OrderByPrescription/gallery.png";
import cameraicon from "../../../images/OrderByPrescription/camera.png";
import Header from "../Header";
import { useNavigate } from "react-router-dom";
import IcomTimes from "../../../images/frontend UI materials/icon _Times_.svg";
import CancelIcon from "../../../images/frontend UI materials/cancel_icon.png";
import siteCofig from "../../../urlFile";
import axios from "axios";
import PrescriptionOrderPlaced from "../../HomePage/OrderPlaced/PrescriptionOrderPlaced";

const OrderByPrescription = () => {
  const [userDataList, setUserDataList] = useState([]);
  const [SuccessMessage, setSuccessMessage] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const [sliderOpen1, setSliderOpen1] = useState(false);
  const [selectedAddressId, setSelectedAddressId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [orderPlaced, setOrderPlaced] = useState(false);
  const [fileUpload, setFileUpload] = useState(false);
  const [address, setAddress] = useState(false);

  const handleToggle = () => {
    setIsOpen1(!isOpen);
    setSliderOpen1(true);
  };

  const closeSlider1 = () => {
    setIsOpen1(false);
    setSliderOpen1(false);
  };
  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const navigate = useNavigate();
  const handleRoute = (path) => {
    navigate(path);
  };

  const submitPrescriptionOrder = async (file) => {
    try {
      const userId = localStorage.getItem("userId");
      const selectedAddress = userDataList.find(
        (address) => address.id === selectedAddressId
      );
      let comment = feedback;
      const formData = new FormData();
      formData.append("pincode", selectedAddress.pincode);
      formData.append("city", selectedAddress.city);
      formData.append("name", selectedAddress.name);
      formData.append("address", selectedAddress.address);
      formData.append("additional_comment", comment);
      formData.append("landmark", selectedAddress.landmark);
      formData.append("phone", selectedAddress.phone);
      formData.append("userid", userId);
      formData.append("images", file);

      for (let pair of formData.entries()) {
        console.log(pair[0] + ", " + pair[1]); // Log key-value pairs
      }

      const response = await fetch(
        `https://app.healthcrad.com/order_prescription.php`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit prescription order");
      }

      const data = await response.json();
      console.log("Prescription order submitted successfully:", data);

      // Clear captured image from local storage after successful submission
      localStorage.removeItem("cameraImage");
      localStorage.removeItem("prescription_order_img");
    } catch (error) {
      console.error("Error submitting prescription order:", error.message);
    }
  };

  const handleFileChange = (event) => {
    const fileInput = event.target;
    if (fileInput.files.length > 0) {
      const file = fileInput.files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        setSelectedFile({
          name: file.name,
          dataURL: reader.result,
        });

        // Save file object to local storage
        localStorage.setItem("selectedImageFile", JSON.stringify(file));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleClearFile = () => {
    setSelectedFile(null);
    localStorage.removeItem("selectedImageURL");
  };

  const handleUpload = () => {
    // Check if address is selected
    const selectedAddress = userDataList.find(
      (address) => address.id === selectedAddressId
    );
    if (!selectedAddress) {
      console.error("Selected address not found.");
      setAddress(true);
      setTimeout(() => {
        setAddress(false);
      }, 3000);
      return;
    }

    const fileInput = document.getElementById("prescription-file");
    if (fileInput.files.length > 0) {
      // If the user selected an image from the gallery
      const file = fileInput.files[0];
      submitPrescriptionOrder(file);
      setOrderPlaced(true);
      fileInput.value = "";
      localStorage.removeItem("prescription_order_img");
    } else {
      // If no file selected from the gallery, retrieve camera-captured image
      const cameraImage = localStorage.getItem("prescription_order_img");
      console.log(
        "Camera-captured image stored in local storage:",
        cameraImage
      );
      if (cameraImage) {
        const blob = dataURItoBlob(cameraImage);
        const file = new File([blob], "camera_image.png");
        submitPrescriptionOrder(file);
        setOrderPlaced(true);
        localStorage.removeItem("prescription_order_img");
      } else {
        setFileUpload(true);
        setTimeout(() => {
          setFileUpload(false);
        }, 4000);
      }
    }
  };

  // Function to convert base64 to Blob
  function dataURItoBlob(dataURI) {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], { type: mimeString });
    return blob;
  }

  const openFileSelection = () => {
    const fileInput = document.getElementById("prescription-file");
    fileInput.click();
  };

  const [orderImage, setOrderImge] = useState(
    localStorage.getItem("prescription_order_img")
  );

  useEffect(() => {
    setOrderImge(localStorage.getItem("prescription_order_img"));
  }, [orderImage]);

  const removeOrderImage = () => {
    localStorage.removeItem("prescription_order_img");
    setOrderImge();
  };

  // form validation
  const [formData, setFormData] = useState({
    pincode: "",
    city: "",
    name: "",
    address: "",
    landmark: "",
    mobilenumber: "",
  });

  const [errors, setErrors] = useState({
    pincode: "",
    city: "",
    name: "",
    address: "",
    landmark: "",
    mobilenumber: "",
  });

  const handleInputChange1 = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = { ...errors };

    if (formData.pincode.length !== 6) {
      newErrors.pincode = "Pincode must be 6 digits";
      isValid = false;
    }

    if (!/^\d{10}$/.test(formData.mobilenumber)) {
      newErrors.mobilenumber = "Mobile Number must be 10 digits";
      isValid = false;
    }

    if (!formData.city) {
      newErrors.city = "City is required";
      isValid = false;
    }

    if (!formData.name) {
      newErrors.name = "Name is required";
      isValid = false;
    }

    if (!formData.address) {
      newErrors.address = "Address is required";
      isValid = false;
    }

    if (!formData.landmark) {
      newErrors.landmark = "Landmark is required";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleButtonClick = async () => {
    if (validateForm()) {
      setFormData({
        pincode: "",
        city: "",
        name: "",
        address: "",
        landmark: "",
        mobilenumber: "",
      });
      console.log("Form is valid:", formData);
      try {
        await handlePostRequest();
        setSuccessMessage(true);
        setTimeout(() => {
          setSuccessMessage(false);
        }, 2000);
      } catch (error) {
        console.log("Error saving address");
      }
      await handleGetRequest();
      setShowModal(false);
    } else {
      console.log("Form is not valid");
    }
  };

  // View Profile GET API
  const [userData, setUserData] = useState({
    user_id: localStorage.getItem("userId"),
    pincode: "",
    city: "",
    name: "",
    address: "",
    landmark: "",
    phone: "",
  });

  useEffect(() => {
    if (localStorage.getItem("userId")) {
      handleGetRequest();
    }
  }, []);

  const handleGetRequest = async () => {
    let userId = localStorage.getItem("userId");
    try {
      const response = await axios.get(
        `${siteCofig.BASE_URL}${siteCofig.GET_ADDRESS}${userId}`
      );

      const userData = response.data.data;
      console.log(userData, "dddddddddd");

      const responseData = response.data;
      if (
        responseData.msg === "Sucsess" &&
        Array.isArray(responseData.data) &&
        responseData.data.length > 0
      ) {
        const firstAddress = responseData.data[0];
        {
          // Update userDataList state with fetched data
          setUserDataList(responseData.data);
        }
        setUserData({
          user_id: firstAddress.user_id,
          pincode: firstAddress.pincode,
          city: firstAddress.city,
          name: firstAddress.name,
          address: firstAddress.address,
          landmark: firstAddress.landmark,
          phone: firstAddress.phone,
        });
      }
    } catch (error) {}
  };
  console.log("name", userData.name);

  //POST API
  const handlePostRequest = async () => {
    let reqBody = {
      user_id: localStorage.getItem("userId"),
      pincode: formData.pincode,
      city: formData.city,
      name: formData.name,
      address: formData.address,
      landmark: formData.landmark,
      phone: formData.mobilenumber,
    };
    try {
      const response = await axios.post(
        siteCofig.BASE_URL + siteCofig.SAVE_ADDRESS,
        reqBody
      );
      const userFormData = response.data.data;
      // console.log(userFormData, "ffffffffff");
    } catch (error) {}
  };

  // selected address by user
  const handleAddressClick = (addressId) => {
    setSelectedAddressId(addressId);
    setIsOpen1(false);
    console.log(`Address ${addressId} selected.`);
  };

  useEffect(() => {
    // Set default selection to the ID of the first address
    if (userDataList.length > 0 && selectedAddressId === null) {
      setSelectedAddressId(userDataList[0].id);
    }
  }, [userDataList, selectedAddressId]);

  return (
    <>
      <Header />
      {!orderPlaced && (
        <div className="pt-24 pb-20 ">
          {SuccessMessage && (
            <div className="bg-green-500 text-white p-2 absolute top-1/3 z-50 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded">
              <p className="w-52 sm:w-80 text-center font-lato">
                Address saved successfully.
              </p>
            </div>
          )}
          {address && (
            <div className="bg-red-500 text-white p-2 absolute top-1/3 z-50 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded">
              <p className="w-52 sm:w-80 text-center font-lato">
                Please select/add delivery address!
              </p>
            </div>
          )}
          {fileUpload && (
            <div className="bg-[red]  text-white p-2 absolute top-1/3 z-50 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded">
              <p className="w-52 sm:w-80 text-center font-lato ">
                Please select a file or capture an image <br />
                before placing the order !
              </p>
            </div>
          )}
          <div className="flex flex-col  md:flex-row justify-around items-center gap-5 md:items-start ">
            {/* Left side - Upload Prescription, Open Camera, Additional Comment */}
            <div className="flex flex-col justify-center items-center gap-5 ">
              <p className="text-[#4A4141] font-poppins text-[15px] sm:text-[20px] font-semibold flex justify-center ">
                Do you have a prescription ?
              </p>
              <div className="flex flex-col gap-16 justify-center items-center ">
                <div
                  className={`flex flex-col ${
                    selectedFile ? "bg-white" : "bg-[#10CAF2]"
                  } w-[165px] justify-center items-center shadow-[0px_4px_4px_0px rgba(0, 0, 0, 0.12)] rounded-[5px]`}
                >
                  {selectedFile ? (
                    <>
                      <input
                        type="file"
                        id="prescription-file"
                        style={{ display: "none" }}
                        onChange={handleFileChange}
                      />
                      <button className="text-white px-3 py-2 rounded-lg">
                        <>
                          <button
                            onClick={handleClearFile}
                            className=" top-0 right-0  ml-36"
                          >
                            <img
                              src={CancelIcon}
                              alt="Cancel icon"
                              className="w-5 h-5 mt-[5px] -ml-[11px] absolute"
                            />
                          </button>
                          <img
                            src={selectedFile.dataURL}
                            alt=""
                            className="h-[150px] w-[180px]"
                          />
                        </>
                      </button>
                    </>
                  ) : (
                    <>
                      <input
                        type="file"
                        id="prescription-file"
                        style={{ display: "none" }}
                        onChange={handleFileChange}
                      />
                      <button
                        onClick={openFileSelection}
                        className="text-white px-3 py-2 rounded-lg"
                      >
                        <img
                          src={galleryicon}
                          alt=""
                          className="h-[70px] w-[90px]"
                        />
                      </button>
                      <p className="text-[#FFF] font-poppins text-[12px] mt-[-4px] pb-2">
                        Upload Prescription
                      </p>
                    </>
                  )}
                </div>

                {orderImage && (
                  <img
                    src={CancelIcon}
                    alt="Cancel icon"
                    className="ml-[164px] -mb-[74px] relative lg:hidden"
                    onClick={() => removeOrderImage()}
                  />
                )}
                <div className="block lg:hidden">
                  <div className="flex flex-col w-[165px] bg-[#10CAF2] justify-center items-center shadow-[0px_4px_4px_0px rgba(0, 0, 0, 0.12)] rounded-[5px]">
                    {!orderImage ? (
                      <div>
                        <button className="text-white px-3 py-2 rounded-lg h-[90px] w-[130px] lg:hidden">
                          <img
                            src={cameraicon}
                            alt=""
                            className="h-[70px] w-[90px] ml-1"
                            onClick={() => handleRoute("/capture-pic")}
                          />
                        </button>
                        <p className="text-[#FFF] text-center font-poppins text-[13px] mt-[-4px] pb-2">
                          Open Camera
                        </p>
                      </div>
                    ) : (
                      <img
                        src={orderImage}
                        alt=""
                        className="h-[150px] w-[180px]"
                        absolute
                      /> // cp pic
                    )}
                  </div>
                </div>
                <div
                  className="h-[90px] w-[300px] bg-[#FFF] rounded-[5px]"
                  style={{
                    border: "1px solid rgba(102, 97, 97, 0.20)",
                    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.12)",
                  }}
                >
                  <input
                    className="rounded-lg p-2 w-full resize-none font-poppins text-[rgba(0, 0, 0, 0.35) text-[14px] focus:outline-none"
                    placeholder="Add additional comment....."
                    rows="4"
                    onChange={(e) => setFeedback(e.target.value)}
                  ></input>
                </div>
              </div>
            </div>
            {/* Right side - Add New Address, Select Address, Place Order */}
            <div className="flex flex-col justify-center items-center ">
              <div className="flex flex-col justify-center items-center gap-16  ">
                <div
                  className="bg-[#FFF]  mb-16 sm:mb-0 rounded-[5px] h-[50px] w-[300px] sm:mr-7  justify-center flex cursor-pointer select-none"
                  style={{
                    border: "1px solid rgba(0, 0, 0, 0.20)",
                    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.12)",
                  }}
                  onClick={openModal}
                >
                  <button className="text-[#10CAF2] px-3 py-2 rounded-lg font-poppins font-semibold">
                    + Add new address
                  </button>
                </div>
                {/* Right side slider start
              {/* box model */}
                {showModal && (
                  <div className="fixed top-0 left-0 z-50 w-full h-full flex items-center justify-center">
                    <div className="absolute top-0 left-0 w-full h-full bg-gray-900 opacity-50"></div>
                    <div className=" w-full sm:w-[60%] md:w-[40%] gap-3   z-10 bg-white p-7 rounded-lg shadow-lg flex flex-col justify-center items-center  ">
                      <div className=" w-full flex justify-end items-end">
                        <button
                          className="text-gray-500 hover:text-gray-700 self-end "
                          onClick={closeModal}
                        >
                          <img src={IcomTimes} alt="CrossIcon" className="" />
                        </button>
                      </div>
                      <div className="  p-1 pb-6  bg-[#FFF] rounded-[5px] shadow-sm border border-solid border-opacity-5 font-poppins  flex flex-col justify-center items-start ">
                        <div className="flex flex-col pt-2">
                          <label
                            className="font-poppins font-bold pl-7 text-[#10CAF2]"
                            htmlFor="name"
                          >
                            PIN CODE
                          </label>
                          <input
                            className="text-[15px] font-medium pl-7 focus:outline-none"
                            type="tel"
                            name="pincode"
                            value={formData.pincode}
                            onChange={handleInputChange1}
                            maxLength={6}
                            onKeyDown={(e) => {
                              // Allow only numeric keys, backspace, and arrow keys
                              if (
                                !/^\d$/.test(e.key) &&
                                e.key !== "Backspace" &&
                                e.key !== "ArrowLeft" &&
                                e.key !== "ArrowRight"
                              ) {
                                e.preventDefault();
                              }
                            }}
                          />
                          <div>
                            {" "}
                            <p className="text-red-500 text-sm ml-7">
                              {errors.pincode}
                            </p>
                          </div>
                        </div>
                        <div
                          className="underline-offset-8 w-[295px] xl:w-[339px] ml-[30px] mb-2 border-[1px] 
                        color-[#0000002E] center-alignment"
                        ></div>
                        <div className="flex flex-col">
                          <label
                            className="font-poppins font-bold pl-7 text-[#10CAF2]"
                            htmlFor="name"
                          >
                            CITY/STATE
                          </label>
                          <input
                            type="text"
                            name="city"
                            value={formData.city}
                            onChange={handleInputChange1}
                            className="text-[15px] font-medium pl-7 focus:outline-none"
                          />
                          <div>
                            {" "}
                            <p className="text-red-500 text-sm ml-7">
                              {errors.city}
                            </p>
                          </div>
                        </div>
                        <div
                          className="underline-offset-8 w-[295px] xl:w-[339px] ml-[30px] mb-2 border-[1px] 
                        color-[#0000002E] center-alignment"
                        ></div>
                        <div className="flex flex-col">
                          <label
                            className="font-poppins font-bold pl-7 text-[#10CAF2]"
                            htmlFor="name"
                          >
                            NAME
                          </label>
                          <input
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleInputChange1}
                            className="text-[15px] font-medium pl-7 focus:outline-none"
                          />
                          <div>
                            {" "}
                            <p className="text-red-500 text-sm ml-7">
                              {errors.name}
                            </p>
                          </div>
                        </div>
                        <div
                          className="underline-offset-8 w-[295px] xl:w-[339px] ml-[30px] mb-2 border-[1px] 
                        color-[#0000002E] center-alignment"
                        ></div>
                        <div className="flex flex-col">
                          <label
                            className="font-poppins font-bold pl-7 text-[#10CAF2]"
                            htmlFor="name"
                          >
                            ADDRESS
                          </label>
                          <input
                            type="text"
                            name="address"
                            value={formData.address}
                            onChange={handleInputChange1}
                            className="text-[15px] font-medium pl-7 focus:outline-none"
                          />
                          <div>
                            {" "}
                            <p className="text-red-500 text-sm ml-7">
                              {errors.address}
                            </p>
                          </div>
                        </div>
                        <div
                          className="underline-offset-8 w-[295px] xl:w-[339px] ml-[30px] mb-2 border-[1px] 
                        color-[#0000002E] center-alignment"
                        ></div>
                        <div className=" flex flex-col">
                          <label
                            className="font-poppins font-bold pl-7 text-[#10CAF2]"
                            htmlFor="name"
                          >
                            LANDMARK
                          </label>
                          <input
                            type="text"
                            name="landmark"
                            value={formData.landmark}
                            onChange={handleInputChange1}
                            className="text-[15px] font-medium pl-7 focus:outline-none"
                          />
                          <div>
                            {" "}
                            <p className="text-red-500 text-sm ml-7">
                              {errors.landmark}
                            </p>
                          </div>
                        </div>
                        <div
                          className="underline-offset-8 w-[295px] xl:w-[339px] ml-[30px] mb-2 border-[1px] 
                        color-[#0000002E] center-alignment"
                        ></div>
                        <div className=" flex flex-col">
                          <label
                            className="font-poppins font-bold pl-7 text-[#10CAF2]"
                            htmlFor="name"
                          >
                            MOBILE NUMBER
                          </label>
                          <input
                            type="tel"
                            name="mobilenumber"
                            value={formData.mobilenumber}
                            onChange={handleInputChange1}
                            className="text-[15px] font-medium pl-7 focus:outline-none"
                            maxLength={10}
                            onKeyDown={(e) => {
                              // Allow only numeric keys, backspace, and arrow keys
                              if (
                                !/^\d$/.test(e.key) &&
                                e.key !== "Backspace" &&
                                e.key !== "ArrowLeft" &&
                                e.key !== "ArrowRight"
                              ) {
                                e.preventDefault();
                              }
                            }}
                          />
                          <div>
                            {" "}
                            <p className="text-red-500 text-sm ml-7">
                              {errors.mobilenumber}
                            </p>
                          </div>
                        </div>
                        <div
                          className="underline-offset-8 w-[295px] xl:w-[339px] ml-[30px] border-[1px] 
                        color-[#0000002E] center-alignment"
                        ></div>

                        <div className=" mt-2 mb-2 p-1 w-full flex justify-center items-center">
                          <button
                            className=" flex justify-center items-center bg-[#10CAF2] text-[18px] font-semibold text-[#ffffff]    focus:outline-none rounded-[5px]  w-[60%] h-11 self-center "
                            style={{
                              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.12)",
                            }}
                            type="submit"
                            onClick={handleButtonClick}
                          >
                            Save Address{" "}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {/* box model end */}
                <div className="flex flex-col justify-center">
                  {/* toggle for select address start */}
                  {isOpen1 && (
                    <div
                      className={`${
                        isOpen1 ? "translate-x-0" : "translate-x-full"
                      } justify-center z-50 items-center fixed top-0 right-0 h-screen w-[80%] xl:w-[28%] sm:w-[350px] lg:w-[430px] bg-[#e5f8fc] p-2 overflow-y-auto`}
                    >
                      {/* Address Slider Content */}
                      <div className="flex mb-[16px] cursor-pointer mt-1 ">
                        <img
                          src={IcomTimes}
                          alt="CrossIcon"
                          className="ml-auto w-[20px] sm:w-auto "
                          onClick={closeSlider1}
                        />
                      </div>
                      <div className="block mx-auto max-w-[600px]">
                        <div className="text-[#00B4D8] font-poppins font-medium text-[15px] lg:ml-9">
                          <button>SELECT ADDRESS</button>
                        </div>
                        <div className=" flex flex-col justify-center items-center ">
                          {userDataList.map((userAddress) => (
                            <div
                              key={userAddress.id}
                              className={`border-2 border-gray-300 bg-[#FFF] rounded-lg p-2 mb-2 cursor-pointer w-full sm:w-[330px] ${
                                selectedAddressId === userAddress.id
                                  ? "border-[#00B4D8]"
                                  : ""
                              }`}
                              onClick={() => handleAddressClick(userAddress.id)}
                            >
                              <p className="font-poppins text-[#000] font-bold">
                                {userAddress.name}
                              </p>
                              <p className="font-poppins text-[#000] text-[14px]">
                                {userAddress.address} <br />
                                {userAddress.landmark}, {userAddress.city} -{" "}
                                {userAddress.pincode}, Bihar.
                                <br />
                                +91 - {userAddress.phone}
                              </p>
                              <div className="justify-end items-end flex">
                                <button className="text-[18px] font-poppins font-normal text-[#00B4D8]">
                                  Modify
                                </button>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                  {/* toggle for select address end */}

                  {/* Render details of the selected address */}
                  <div className="hidden sm:block">
                    {selectedAddressId && (
                      <div className="border-2 border-[#00B4D8] rounded-lg p-2 w-full sm:w-[330px]">
                        {/* Use the selectedAddressId to find the selected address */}
                        <div className="text-[#00B4D8] font-poppins font-bold text-[15px]  flex flex-row justify-end items-center">
                          <button onClick={handleToggle}>SELECT ADDRESS</button>
                        </div>
                        {userDataList.map((selectedAddress) => {
                          if (selectedAddress.id === selectedAddressId) {
                            return (
                              <React.Fragment key={selectedAddress.id}>
                                <p className="font-poppins text-[#000] font-bold">
                                  {selectedAddress.name}
                                </p>
                                <p className="font-poppins text-[#000] text-[14px]">
                                  {selectedAddress.address} <br />
                                  {selectedAddress.landmark},{" "}
                                  {selectedAddress.city} -{" "}
                                  {selectedAddress.pincode}, Bihar.
                                  <br />
                                  +91 - {selectedAddress.phone}
                                </p>
                                <div className="justify-end items-end flex">
                                  <button className="text-[18px] font-poppins font-normal text-[#00B4D8]">
                                    Modify
                                  </button>
                                </div>
                              </React.Fragment>
                            );
                          }
                          return null;
                        })}
                      </div>
                    )}
                  </div>
                </div>
                <div className=" hidden sm:block">
                  <div
                    className="bg-[#10CAF2] sm:mr-7 rounded-[5px] h-[50px] w-[300px]  justify-center flex cursor-pointer select-none"
                    style={{
                      border: "1px solid rgba(0, 0, 0, 0.20)",
                      boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.12)",
                    }}
                  >
                    <button
                      className="text-[#FFF] px-3 py-2 rounded-lg font-poppins font-semibold"
                      onClick={handleUpload}
                    >
                      Place Order
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className=" sm:hidden fixed z-30 flex justify-around items-center     w-full bottom-8 ">
              {selectedAddressId && (
                <div className="border-[1px] border-[#00B4D8] bg-[#FFF]  p-3 w-full ">
                  <div className="flex justify-between pb-1">
                    <p className="text-[#00B4D8] font-medium text-[14px] sm:text-[16px] font-poppins ">
                      Delivery Address
                    </p>
                    <button
                      className="text-[#00B4D8] text-[14px] sm:text-[16px] font-poppins font-medium cursor-pointer"
                      onClick={handleToggle}
                    >
                      Change Address
                    </button>
                  </div>
                  {userDataList.map((selectedAddress) => {
                    if (selectedAddress.id === selectedAddressId) {
                      return (
                        <React.Fragment key={selectedAddress.id}>
                          <p className="font-poppins text-[#000] font-bold">
                            {selectedAddress.name}
                          </p>
                          <p className="font-poppins text-[#000] text-[12px] sm:text-[14px]">
                            {selectedAddress.address} <br />
                            {selectedAddress.landmark}, {selectedAddress.city} -{" "}
                            {selectedAddress.pincode}, Bihar.
                            <br />
                            +91 - {selectedAddress.phone}
                          </p>
                        </React.Fragment>
                      );
                    }
                    return null;
                  })}
                </div>
              )}
            </div>
            {/* mobile view button design start */}
            <div className=" sm:hidden fixed z-30 flex justify-around items-center bg-[#10CAF2]    w-full bottom-0 ">
              <div className="select-none rounded-[4px] shadow-md w-[170px]  h-11  flex items-center justify-center cursor-pointer">
                <button
                  className="w-40 h-10 self-end font-poppins text-[#FFF] text-md font-semibold"
                  onClick={handleUpload}
                >
                  Place Order
                </button>
              </div>
            </div>
            {/* mobile view button design end */}
          </div>
        </div>
      )}
      {orderPlaced && <PrescriptionOrderPlaced />}
    </>
  );
};

export default OrderByPrescription;
