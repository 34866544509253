import React from "react";
import EmptyOrderHistoryPic from "../../../../images/Empty_Pages_Images/History_order 1.png";

function EmptyOrderHistory() {
  return (
    <>
      <div className="flex justify-center items-center p-2 mt-10 mb-14">
        <div className="flex flex-col justify-center items-center gap-5">
          <div className="flex justify-center items-center">
            <p className="text-[#66616180] text-center font-poppins font-semibold text-md sm:text-lg">
              Nothing is here. Book your first order now !
            </p>
          </div>
          <div className="flex justify-center items-center">
            <img
              src={EmptyOrderHistoryPic}
              alt=""
              srcset=""
              className="w-[200px] h-[200px] sm:h-[300px] sm:w-[300px]"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default EmptyOrderHistory;
