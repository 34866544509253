import React, { useState } from "react";
import Header from "../Header";
import Viceversa from "../../../images/Ambulance Booking/viceversa-arrow.png";
import img2 from "../../../images/frontend UI materials/Request a call/user.png";
import img4 from "../../../images/frontend UI materials/Request a call/phone.png";
import { useNavigate } from "react-router-dom";

const AmbulanceBook = () => {
  const navigate = useNavigate();
  const handleRoute = (path) => {
    navigate(path);
  };
  const [selectedCard, setSelectedCard] = useState(null);
  const [selectedCardPosition, setSelectedCardPosition] = useState({
    top: 0,
    left: 0,
  });
  const [isModalOpen, setModalOpen] = useState(false);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [nameError, setNameError] = useState("");
  const [phoneError, setPhoneError] = useState("");

  const ambulanceInfo = [
    {
      type: "MORTUARY",
      features: "Mobile mortuary van for transportation of dead body",
      whenToUse:
        "Funeral vehicle to transport last remains to home. Funeral home. Cemetery or cremation ground.",
    },
    {
      type: "PATIENT TRANSPORT",
      features: "Driver, Stretcher",
      whenToUse:
        "Inability to travel in a normal seated position, Lack of independent movement.",
    },
    {
      type: "BASIC LIFE SUPPORT",
      features: "Driver, Stretcher, Oxygen, BP apparatus",
      whenToUse:
        "Non-life-threatening emergency room visits, injury, or illness.",
    },
    {
      type: "ADVANCE LIFE SUPPORT",
      features:
        "Paramedic, Stretcher, Oxygen, Ventilator, Patient monitoring equipment and defibrillator, BP apparatus",
      whenToUse:
        "Life-threatening situations: emergency room visit, severe injury, heart attack, or unconsciousness.",
    },
  ];

  const getCardStyle = (index) => {
    const isSelected = selectedCard === index;
    return {
      border: `1px solid ${isSelected ? "#00B4D8" : "#00000033"}`,
      borderRadius: isSelected ? "10px" : "10px",
    };
  };

  const handleCardClick = (index, event) => {
    setSelectedCard(index);
    const cardRect = event.target
      .closest(".card-container")
      .getBoundingClientRect();
    const windowHeight = window.innerHeight;

    if (window.innerWidth > 1280) {
      // XL screen size
      setSelectedCardPosition({
        top: cardRect.top - 200,
        left: cardRect.left - 55,
      });
    } else {
      // Less than XL screen size
      setSelectedCardPosition({
        top: windowHeight / 2 - 100, // Adjust as needed for vertical centering
        left: "50%", // Horizontally center the popup
        transform: "translateX(-50%)", // Centering trick in Tailwind CSS
      });
    }

    setModalOpen(true);
    document.body.style.overflow = "hidden";
  };

  const handleCardSelect = (index) => {
    setSelectedCard(index);
  };

  const closeModal = () => {
    setModalOpen(false);
    document.body.style.overflow = "";
  };

  const validateInputs = () => {
    let isValid = true;

    if (!name.trim()) {
      setNameError("Name is required");
      isValid = false;
    } else {
      setNameError("");
    }

    if (!phone.trim()) {
      setPhoneError("Phone number is required");
      isValid = false;
    } else {
      setPhoneError("");
    }

    return isValid;
  };

  const handleContinueClick = () => {
    if (validateInputs()) {
      // Continue with your logic
      console.log("Form submitted successfully");
    }
  };

  return (
    <>
      <Header />
      <div className="pt-20">
        <div className="mx-auto grid place-items-center w-[230px] h-[50px] items-center justify-center group bg-[#FFF] rounded-[10px]  border-[1px] border-[#00B4D8]">
          <p className="lg:text-[20px] md:text-[16px] xs:text-[15px] text-[12px] font-poppins text-[#00B4D8] px-2 py-2 rounded-md cursor-default">
            Ambulance Booking
          </p>
        </div>

        <div className="lg:mx-16 border-[1px] border-[#00000026]  mt-[28px] shadow-md bg-[#F5F2F2]">
          <div className="mx-auto w-[300px] xs:w-[360px] sm:w-auto lg:w-[764px] lg:h-[150px] rounded-[10px] border-[1px] border-solid border-[#CECBCB] bg-[#ffffff] shadow-md mt-[30px] mb-[48px]">
            <p
              className="flex items-center justify-center font-medium lg:text-[20px] md:text-[16px] xs:text-[15px] text-[12px] text-[#07A50D] pt-2"
              onClick={() => handleRoute("/ambulanceBook/addAddress")}
            >
              From
            </p>
            <div className="flex gap-1 mb-1">
              <p className=" underline-offset-8 w-full h-0 ml-4 pr-8 mt-[35px]  border-[1px] color-[#0000002E] center-alignment"></p>
              <img
                src={Viceversa}
                alt=""
                className="h-[50px] w-[50px]    mt-2 mr-2 ml-1"
              />
            </div>
            <p
              className="pb-2 sm:pt-2 flex items-center justify-center font-medium font-poppins lg:text-[20px] md:text-[16px] xs:text-[15px] text-[12px] text-[#FE0505]"
              onClick={() => handleRoute("/ambulanceBook/addAddress")}
            >
              To
            </p>
          </div>

          <p className="flex items-center justify-center mt-[36px] mb-[21px] lg:text-[24px] md:text-[20px] text-[16px] text-[#00B4D8]">
            Select Ambulance Type
          </p>

          {/* Four card */}
          <div className="grid grid-cols-2 md:grid-cols-4 gap-[18px] xs:gap-6 sm:gap-2 place-items-center cursor-pointer">
            {[1, 2, 3, 4].map((index) => (
              <div
                key={index}
                className="card-container mx-auto lg:w-[190px] lg:h-[100px] md:w-[170px] md:h-[80px] w-[150px] h-[80px] p-2 bg-[#fff] shadow-[0px_4px_4px_0px_rgba(0,0,0,0.20)]"
                style={getCardStyle(index)}
                onClick={() => handleCardSelect(index)}
              >
                <div className="flex items-end justify-start">
                  <button
                    className="ml-auto bg-[#00B4D8] text-[#fff] h-5 w-5 text-[15px] rounded-full cursor-pointer"
                    onClick={(e) => handleCardClick(index, e)}
                  >
                    i
                  </button>
                </div>
                <p
                  className={`text-center lg:text-[20px] md:text-[16px] text-[12px] ${
                    selectedCard === index
                      ? "text-[#00B4D8]"
                      : "text-[rgb(74,65,65)]"
                  }`}
                >
                  {index === 1 && "MORTUARY"}
                  {index === 2 && "PATIENT TRANSPORT"}
                  {index === 3 && "BASIC LIFE SUPPORT"}
                  {index === 4 && "ADVANCE LIFE SUPPORT"}
                </p>
              </div>
            ))}
          </div>

          <div className="flex  mx-auto  w-[300px] xs:w-[360px] sm:w-full px-[28px] mt-[42px] mb-[32px] bg-[#fff] place-items-center justify-between font-poppins font-medium lg:w-[764px] h-[50px]  border-[1px] border-[#00000026] shadow-[0px_4px_4px_0px_rgba(0,0,0,0.20)] rounded-[10px]">
            <p className="lg:text-[20px] md:text-[16px] xs:text-[15px] text-[12px] text-[#4A4141]">
              Total Distance:
            </p>
            <p className="lg:text-[20px] md:text-[16px] xs:text-[15px] text-[12px] sm:text-[12px] text-[#00B4D8]">
              00 KM
            </p>
          </div>
          {/* two input button */}
          <div className=" flex flex-wrap justify-center  sm:gap-0 gap-2 lg:justify-between md:justify-between sm:justify-between lg:mx-44">
            <div className="space-x-2 flex border-1 justify-center   items-center rounded-[10px] bg-[#fff] border-[1px] border-[#CECBCB] shadow-[0px_4px_4px_0px_rgba(0,0,0,0.20)]">
              <span className="ml-[2px] ">
                <img
                  className=" w-[20px] h-[20px] sm:w-[27px] sm:h-[27px] ml-2 "
                  src={img2}
                  alt="Error"
                />
              </span>
              <input
                className="py-3 bg-[#fff] rounded-[10px]  h-[50px] placeholder:text-[12px] md:placeholder:text-[12px] lg:placeholder:text-[20px] w-[265px] sm:w-auto font-poppins focus:outline-none text-[15px] sm:text-[20px] font-normal text-[#979797]  pl-2"
                type="text"
                placeholder="Sunil Kumar"
                name="name"
                required
              />
            </div>
            <div className="space-x-2 flex border-1 justify-center   items-center rounded-[10px] bg-[#fff] border-[1px] border-[#CECBCB] shadow-[0px_4px_4px_0px_rgba(0,0,0,0.20)]  mb-20 sm:mb-0">
              <span className="ml-[2px] ">
                <img
                  className=" w-[20px] h-[20px] sm:w-[27px] sm:h-[27px] ml-2 "
                  src={img4}
                  alt="Error"
                />
              </span>
              <input
                className="py-3  bg-[#fff] rounded-[10px] h-[50px] placeholder:text-[12px] md:placeholder:text-[12px] lg:placeholder:text-[20px] w-[265px] sm:w-auto font-poppins focus:outline-none text-[15px] sm:text-[20px] font-normal text-[#979797]  pl-2"
                type="text"
                placeholder="9973025486"
                name="Phone no."
                required
              />
            </div>
          </div>

          {/* Continue button */}
          <div className="hidden sm:block">
            <div
              className=" mx-auto grid place-items-center w-[230px] h-[50px] items-center justify-center group bg-[#00B4D8] rounded-[10px] hover:bg-[#00B4D8] border-[1px] border-[#00B4D8] mt-5 mb-1"
              onClick={() => handleRoute("/ambulanceBook/confirmation")}
            >
              <button className="lg:text-[20px] md:text-[16px] text-[15px] font-poppins text-[#fff] px-2 py-2 rounded-md group-hover:text-white">
                Continue
              </button>
            </div>
          </div>
          {/* mobile view button design start */}
          <div className=" sm:hidden fixed z-50 flex justify-around items-center bg-gray-100   w-full bottom-0  ">
            <div className=" flex flex-col pt-2.5 ">
              <p className="text-[13px] font-poppins font-medium  text-[#00B4D8] ">
                Total Dist.
              </p>
              <p className=" text-[18px] font-poppins font-semibold text-[#000000] ">
                55 Km
              </p>
            </div>
            <div
              className="select-none rounded-[4px] border-[1px] border-[#10CAF2] shadow-md bg-[#00B4D8] w-[170px]  h-11  flex items-center justify-center cursor-pointer"
              onClick={() => handleRoute("/ambulanceBook/confirmation")}
            >
              <button className=" text-white font-">Continue</button>
            </div>
          </div>
          {/* mobile view button design end */}
        </div>
      </div>
      {/* Modal */}
      {isModalOpen && selectedCard !== null && (
        <div
          className="fixed inset-0 z-50 w-full h-full bg-[rgba(0,0,0,0.5)] flex items-center justify-center overlay"
          onClick={closeModal}
        >
          <div
            className="  bg-white p-2 rounded-[10px] w-[317px] h-auto absolute"
            style={{
              top: `${selectedCardPosition.top}px`,
              left: `${selectedCardPosition.left}`,
              transform: `${selectedCardPosition.transform || ""}`,
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <p className="text-[16px] font-bold ">
              Ambulance Type: {ambulanceInfo[selectedCard - 1].type}
            </p>
            <p className="text-[12px]">
              <strong>Features:</strong>{" "}
              {ambulanceInfo[selectedCard - 1].features}
            </p>
            <p className="text-[12px]">
              <strong>When to Use:</strong>{" "}
              {ambulanceInfo[selectedCard - 1].whenToUse}
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default AmbulanceBook;
