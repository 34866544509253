import React, { useEffect, useState } from "react";
import Header from "../../Header";
import { useNavigate } from "react-router";
import siteConfig from "../../../../urlFile";
import axios from "axios";
import EmptyAppointment from "./EmptyAppointment";
import warningPic from "../../../../images/png-transparent-removebg-preview.png";
import { Modal } from "@mui/material";
import PageLoader from "../../../../Loader/PageLoader";

const MyAppointment = () => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const navigate = useNavigate();

  const handleOpen = (appointment) => {
    setSelectedAppointment(appointment);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Cancellation API
  const handleConfirm = async () => {
    console.log("information", selectedAppointment);
    try {
      const obj = {
        userid: localStorage.getItem("userId"),
        appointment_id: selectedAppointment.id,
      };
      const response = await axios.post(
        siteConfig.BASE_URL + siteConfig.CANCEL_APPOINTMENT,
        obj
      );
      console.log("Cancellation API Response:", response);
      if (response.data.error == "200") {
        handleGetRequest();
        handleClose();
      } else {
        console.error("Cancellation unsuccessful:", response.data);
      }
    } catch (error) {
      console.error("Error canceling appointment:", error);
    }
    handleClose();
  };

  /*
      Status code
      0 - waiting for approval
      3 - Appointment Accepted
    */
  // const handleRoute = (path) => {
  //   navigate(path);
  // };
  const handleRoute = (path, bookDetails) => {
    setLoading(true);
    setTimeout(() => {
      navigate(path, { state: bookDetails });
    }, 1000);
  };

  const [appointmentDataList, setAppointmentDataList] = useState([]);
  console.log("list", appointmentDataList);
  useEffect(() => {
    if (localStorage.getItem("userId")) {
      handleGetRequest();
    }
  }, []);

  // Appointment GET API
  const handleGetRequest = async () => {
    let userId = localStorage.getItem("userId");
    try {
      const response = await axios.get(
        `${siteConfig.BASE_URL}${siteConfig.GET_UPCOMING_APPOINTMENT}${userId}`
      );
      const responseData = response.data;
      if (responseData.msg === "No record found") {
        setAppointmentDataList([]);
      }
      if (
        responseData.msg === "success" &&
        Array.isArray(responseData.country) &&
        responseData.country.length > 0
      ) {
        // Update List state with fetched data
        setAppointmentDataList(responseData.country);
      }
    } catch (error) {
      console.error("Error fetching appointments:", error);
    }
  };

  const getDoctorImageUrl = (imageName) =>
    `https://app.healthcrad.com/upload/${imageName}`;

  return (
    <>
      <Header />
      {loading && <PageLoader />}
      {/* buttons start */}
      <div className="flex sm:gap-3 md:gap-3 justify-between lg:mx-8 pt-[80px] font-poppins text-[16px]">
        <div className="m-1.5 cursor-pointer group bg-[#FFF] rounded-[10px] flex justify-center items-center hover:bg-[#00B4D8] border-[1px] border-[#10CAF2] w-[150px] sm:w-[260px] h-[55px] mb-4">
          <button className="font-poppins text-[#10CAF2] px-2 py-2 rounded-md group-hover:text-white">
            Upcoming Appointments
          </button>
        </div>
        <div
          className="m-1.5 group cursor-pointer bg-[#FFF] rounded-[10px] flex justify-center items-center hover:bg-[#00B4D8] border-[1px] border-[#979797] w-[150px] sm:w-[260px] h-[55px]"
          onClick={() => handleRoute("/myAppointmentHistory")}
        >
          <button className="font-poppins text-[#979797] px-2 py-2 rounded-md group-hover:text-white">
            History
          </button>
        </div>
      </div>
      {/* buttons end */}
      <div className="mb-12 flex flex-col sm:gap-0.5">
        {appointmentDataList.length > 0 ? (
          appointmentDataList.map((bookDetails) => (
            <div
              key={bookDetails.id}
              className="m-[6px] sm:m-[10px] md:m-[10px] h-auto w-auto shadow-md border-[1px] border-[rgba(0, 0, 0, 0.20)] sm:p-3 bg-[#FFF] rounded-[10px] lg:mx-16 font-poppins mt-3"
            >
              <div className="flex justify-between">
                <div className="flex font-poppins sm:gap-3">
                  <img
                    className="sm:h-[80px] sm:w-[80px] h-[55px] w-[55px] mx-[4px] sm:mr-3 rounded-full mt-[11px]"
                    src={getDoctorImageUrl(bookDetails.dimg_url)}
                    alt=""
                  />
                  <p className="text-[#666161] font-poppins font-normal mt-1">
                    <span className="text-[#000] font-poppins font-semibold text-[11px] xs:text-[13px] sm:text-[13px] md:text-[16px] lg:text-[20px]">
                      Doctor:{" "}
                    </span>
                    <span className="text-[11px] xs:text-[13px] sm:text-[13px] md:text-[16px] lg:text-[20px]">
                      {bookDetails.dname}
                    </span>
                    <span className="text-[#07A50D] font-poppins font-normal text-[9px] xs:text-[11px] sm:text-[13px] md:text-[16px] lg:text-[20px]">
                      {" "}
                      ({bookDetails.dprofile})
                    </span>{" "}
                    <br />
                    <span className="text-[#000]  font-poppins font-semibold text-[11px] xs:text-[13px] sm:text-[13px] md:text-[16px] lg:text-[20px]">
                      Patient:
                    </span>{" "}
                    <span className="text-[11px] xs:text-[13px] sm:text-[13px] md:text-[16px] lg:text-[20px]">
                      {bookDetails.name}
                    </span>
                    <br />
                    <span className="text-[11px] xs:text-[13px] sm:text-[13px] md:text-[16px] lg:text-[20px]">
                      {bookDetails.week_day} | {bookDetails.time_slot} |{" "}
                      {bookDetails.bookdate}
                    </span>
                  </p>
                </div>
                <div className="sm:mr-[12px] mt-2 hidden sm:block">
                  <p className="text-[#07A50D] xl:text-lg sm:text-sm text-[11px] md:text-lg font-poppins font-bold">
                    <span className="text-[#1A30A1] font-bold xl:text-lg sm:text-sm text-[11px] xs:text-[13px]  md:text-lg font-poppins">
                      Fees:
                    </span>{" "}
                    Rs. {bookDetails.amount} <br />{" "}
                    <span className="text-[#1A30A1] font-bold xl:text-sm sm:text-sm text-[11px] xs:text-[13px] md:text-sm font-poppins">
                      Payment:
                    </span>
                    <span className="xl:text-sm sm:text-sm text-[11px] xs:text-[13px] md:text-sm">
                      {" "}
                      {bookDetails.payment}
                    </span>
                  </p>
                </div>
              </div>

              <div className="flex flex-row ml-[62px] w-[220px] xs:w-[265px] justify-between sm:p-3 sm:hidden md:hidden lg:hidden xl:hidden mt-1.5">
                <p className="text-[#07A50D] xl:text-lg sm:text-sm text-[11px] xs:text-[13px] md:text-lg font-poppins font-bold">
                  <span className="text-[#1A30A1] font-bold xl:text-lg sm:text-sm text-[11px] xs:text-[13px] md:text-lg font-poppins">
                    Fees:
                  </span>{" "}
                  Rs. {bookDetails.amount}{" "}
                </p>
                <p className="text-[#07A50D] xl:text-lg sm:text-sm text-[11px] md:text-lg font-poppins font-bold">
                  <span className="text-[#1A30A1] font-bold xl:text-sm sm:text-sm text-[11px] xs:text-[13px] md:text-sm font-poppins sm:ml-2 ml-0">
                    Payment:
                  </span>
                  <span className="xl:text-sm sm:text-sm text-[11px] xs:text-[13px] md:text-sm">
                    {" "}
                    {bookDetails.payment}
                  </span>
                </p>
              </div>

              <div className="hidden sm:block underline-offset-8 lg:w-auto ml-4 -pr-8  mt-[10px] border-[1px] color-[#0000002E] center-alignment"></div>
              <div className="flex justify-between text-[11px] xs:text-[13px] sm:text-[15px] md:text-[20px] font-normal font-poppins sm:p-3 mt-3 p-[5px]">
                <button
                  className="sm:m-1.5 text-[#00B4D8] border-[1px] border-[#00B4D8] hover:bg-[#00B4D8] hover:text-[#FFF] rounded-md sm:w-[186px] sm:h-[45px] w-[72px] xs:w-[85px] h-[29px] mb-2"
                  onClick={() =>
                    handleRoute(`/myAppointment/getDetails`, bookDetails)
                  }
                >
                  Get Details
                </button>
                <button
                  className="sm:m-1.5 text-[#FE0505] hover:bg-[#FE0505] hover:text-[#FFF] border-[1px] border-[#FE0505] rounded-md sm:w-[186px] sm:h-[45px] w-[72px] xs:w-[85px] h-[29px] mb-2"
                  onClick={() => handleOpen(bookDetails)}
                >
                  Cancel
                </button>
                <button
                  className="sm:m-1.5 text-[#FF9432] border-[1px] border-[#FF9432] hover:bg-[#FF9432] hover:text-[#FFF] rounded-md sm:w-[186px] sm:h-[45px] w-[72px] xs:w-[85px] h-[29px] mb-2"
                  onClick={() =>
                    handleRoute(`/myAppointment/Reschedule`, bookDetails)
                  }
                >
                  Reschedule
                </button>
                <button
                  className="sm:m-1.5 text-sm:[#20CD27] hover:bg-[#20CD27] hover:text-[#FFF] border-[1px] border-[#20CD27] rounded-md] rounded-md sm:w-[186px] sm:h-[45px] text-[#20CD27] w-[72px] xs:w-[85px] h-[29px] mb-2"
                  onClick={() => (window.location.href = "tel:+91777988828")}
                >
                  Contact
                </button>
              </div>
              {/* modal box for Delete start */}
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="fixed z-10 inset-0 overflow-y-auto"
              >
                <div className="flex items-center justify-center min-h-screen">
                  <div className="bg-white w-[290px] sm:w-[390px] p-2 gap-2 rounded-lg text-center">
                    <div className=" ">
                      <div className="flex justify-center items-center p-2">
                        <img src={warningPic} className="h-16 w-16" alt="" />
                      </div>
                      <div className="pt-3 select-none mb-4 flex justify-center items-center">
                        <p className="text-center text-[#4A4141] font-poppins font-semibold  text-[12px] sm:text-[16px]">
                          Do you want to Cancel the selected
                          <br />
                          schedule ?{" "}
                        </p>
                      </div>
                    </div>
                    <div className="flex mb-3 justify-around items-center">
                      <div className="select-none flex justify-center items-center w-24 lg:w-32 h-12 bg-[#FE0505] rounded-[10px]">
                        <button
                          className=" text-[16px] font-poppins font-bold text-[#FFF]"
                          onClick={handleClose}
                        >
                          Cancel
                        </button>
                      </div>
                      <div className="select-none flex justify-center items-center w-24 lg:w-32 h-12 bg-[#10CAF2] rounded-[10px]">
                        <button
                          className="text-[16px] font-poppins font-bold text-[#FFF]"
                          onClick={handleConfirm}
                        >
                          Confirm
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
              {/* modal box for Delete end */}
              <p className="text-[11px] sm:text-[16px] text-[#20CD27] font-poppins font-normal pl-[6px] sm:pl-[16px] pb-2">
                {" "}
                <span className="text-[#4A4141] text-[12px] xs:text-[13px] sm:text-[20px] font-poppins font-semibold">
                  Status:
                </span>{" "}
                {bookDetails.status === "0" ? "Wait for approval" : "Approved"}
              </p>
            </div>
          ))
        ) : (
          // Render EmptyAppointment component
          <EmptyAppointment />
        )}
      </div>
    </>
  );
};

export default MyAppointment;
