import { useState, useEffect, useRef } from "react";
import Header from "../../Header";
import img1 from "../../../../images/frontend UI materials/Request a call/iconp.svg";
import img2 from "../../../../images/frontend UI materials/Request a call/phone.png";
import logo from "../../../../images/frontend UI materials/f1_hlogo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import siteCofig from "../../../../urlFile";
import EmptyAmbulancePage from "./EmptyAmbBooking";

const AmbulanceBooking = () => {
  const [ambulanceHistory, setAmbulanceHistory] = useState([]);
  const navigate = useNavigate();
  const handleRoute = (path) => {
    // setLoading(true);
    setTimeout(() => {
      navigate(path);
    }, 1000);
  };

  const user_id = localStorage.getItem("userId");
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${siteCofig.BASE_URL}${siteCofig.AMBULANCE_HISTORY}${user_id}`
        );
        // if (response.data.msg === "success" && response.data.error === "200") {
        setAmbulanceHistory(response.data.data);
        console.log(response.data.data);
        // } else {
        //   console.error("Error fetching orders:", response.data.msg);
        // }
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };

    if (user_id) {
      fetchData();
    }
  }, [user_id]);

  return (
    <>
      <Header />
      <div className="pt-24">
        <div className=" flex justify-center items-center">
          <div
            className="mb-3 cursor-default group bg-[#FFF] rounded-[10px] flex justify-center items-center  border-[1px]
         border-[#00B4D8] xl:w-[230px] xl:h-[50px] md:w-[230px] md:h-[50px] w-[200px] h-10  "
          >
            <p className="xl:text-lg sm:text-md md:text-lg text-[#00B4D8] font-poppins ">
              Ambulance Booking
            </p>
          </div>
        </div>
        <div className=" flex flex-col justify-center items-center ">
          {ambulanceHistory.length > 0 ? (
            ambulanceHistory.map((item) => (
              <div
                key={item.id}
                className="flex flex-col   mb-12  md:h-[90%]  sm:h-full  lg:w-[80%]   gap-6"
              >
                {/* {ambulanceHistory.length > 0 ? ( */}

                {/* first box left side content */}
                <div className="bg-[#FFF] border-[1px] border-[rgba(0, 0, 0, 0.20)] shadow-md  rounded-[10px] p-4 ">
                  <div className="flex justify-between  ">
                    <div className="flex gap-1 sm:gap-0 ">
                      <div>
                        <img
                          src={logo}
                          alt=""
                          className=" bg-[#FFF] rounded-[40px] border-[1px] border-[#FFF] w-[75px] h-[70px]  md:w-[115px] md:h-[115px] "
                        />
                      </div>
                      <div>
                        <p className=" font-poppins text-xs md:text-sm lg:text-md xl:text-xl text-[#666161] ">
                          <span className="text-[#000] text-sm sm:text-md xl:text-xl font-bold ">
                            Type:
                          </span>
                          {item.type}
                        </p>
                        <p className=" mt-1 font-poppins text-xs md:text-sm lg:text-md xl:text-xl text-[#666161] ">
                          <span className="text-[#000] text-sm sm:text-md xl:text-xl font-bold mt-4 ">
                            Patient:
                          </span>{" "}
                          {item.username}
                        </p>
                        <p className=" mt-2 font-poppins text-xs md:text-sm lg:text-md xl:text-xl text-[#666161] ">
                          <span className="text-[#07A50D]">From:</span>{" "}
                          {item.pickup_address} <br />
                          <span className=" text-[#FE0505] ">To:</span>{" "}
                          {item.drop_address}
                        </p>
                      </div>
                    </div>
                    {/* right side content */}
                    <div className="flex flex-col  pl-2   ">
                      <p className="font-poppins text-xs md:text-sm lg:text-md xl:text-xl text-[#4A4141] ">
                        <span className="text-[#1A30A1] ">Phone:</span>{" "}
                        {item.phone}
                        <br />
                        <span className="text-[#1A30A1] ">Order Id:</span>{" "}
                        {item.id}
                      </p>
                    </div>
                  </div>
                  {/* vertical line */}
                  <div className="underline-offset-8 w-[98%] ml-[7px] sm:ml-8 lg:ml-5 mb-3 mt-3  border-[1px]  color-[#0000002E] center-alignment"></div>
                  <div className=" flex flex-wrap font-poppins  justify-between mb-2">
                    <div className=" mb-2 group bg-[#FFF] rounded-[10px] flex justify-center items-center   xl:w-[230px] xl:h-[50px] md:w-[230px] md:h-[50px] w-[100px] h-12">
                      <p className="font-poppins text-xs md:text-sm lg:text-md xl:text-xl text-[#07A50D] px-2 py-2 rounded-md ">
                        {/* {item.driver_name}Searching for Driver */}
                        {item.driver_name
                          ? item.driver_name
                          : "Searching for Driver"}
                      </p>
                    </div>
                    <div className=" mb-2 group bg-[#FFF] rounded-[10px] flex justify-center items-center   xl:w-[230px] xl:h-[50px] md:w-[230px] md:h-[50px] w-[100px] h-12">
                      <p className="font-poppins text-xs md:text-sm lg:text-md xl:text-xl text-[#666161] px-2 py-2 rounded-md ">
                        <span className="text-[#FF9432] ">Distance:</span>{" "}
                        {item.distance} km
                      </p>
                    </div>
                    <div className=" mb-2 group bg-[#FFF] rounded-[10px] flex justify-center items-center  border-[1px] border-[#979797] sm:w-[150px] sm:h-[45px] w-[110px] h-10">
                      {/* hover:bg-[#00B4D8] */}
                      <span>
                        <img
                          src={img1}
                          alt=""
                          className=" text-[#979797] sm:w-[30px] sm:h-[30px] w-[20px]  h-[20px]"
                        />
                      </span>
                      <button
                        disabled
                        className="font-poppins text-xs  md:text-sm lg:text-md xl:text-xl text-[#979797] px-2 py-2 rounded-md "
                      >
                        {/* group-hover:text-white */}
                        Driver
                      </button>
                    </div>
                  </div>
                  <div className="flex justify-between items-center  ">
                    <p className="font-poppins text-xs md:text-sm lg:text-md xl:text-xl text-[#000] px-2 py-2 rounded-md ">
                      <span className="text-[#07A50D] ">OTP:</span>{" "}
                      {item.verify_number}
                    </p>
                    <div className="group select-none flex justify-center items-center gap-2 w-[110px] sm:w-[150px] h-10 border-[1px] border-[#979797] rounded-[8px]">
                      <button
                        disabled
                        className="flex justify-center items-center gap-2 text-[#979797]  font-poppins text-md sm:text-xl "
                      >
                        {" "}
                        <FontAwesomeIcon
                          icon={faMapMarkerAlt}
                          className=" text-[18px] sm:text-[20px] text-[#979797] "
                        />
                        Track
                      </button>
                    </div>
                  </div>
                </div>

                {/* ) : (
           <EmptyInprocess />
        )} */}
                {/* second box */}
              </div>
              //  ))}
            ))
          ) : (
            <EmptyAmbulancePage />
          )}
        </div>
      </div>
    </>
  );
};

export default AmbulanceBooking;

// <div className="bg-[#FFF] border-[1px] border-[rgba(0, 0, 0, 0.20)] shadow-md  rounded-[10px] p-4">
// <div className="flex justify-between  ">
//   <div className="flex gap-1 sm:gap-0 ">
//     <div>
//       <img
//         src={logo}
//         alt=""
//         className=" bg-[#FFF] rounded-[40px] border-[1px] border-[#FFF] w-[75px] h-[70px]  md:w-[115px] md:h-[115px] "
//       />
//     </div>
//     <div>
//       <p className=" font-poppins text-xs md:text-sm lg:text-md xl:text-xl text-[#666161] ">
//         <span className="text-[#000] text-sm sm:text-md xl:text-xl font-bold ">
//           Type:
//         </span>{" "}
//         Advanced Life Support{" "}
//       </p>
//       <p className=" mt-1 font-poppins text-xs md:text-sm lg:text-md xl:text-xl text-[#666161] ">
//         <span className="text-[#000] text-sm sm:text-md xl:text-xl font-bold mt-4 ">
//           Patient:
//         </span>{" "}
//         Pankaj Kumar{" "}
//       </p>
//       <p className=" mt-2 font-poppins text-xs md:text-sm lg:text-md xl:text-xl text-[#666161] ">
//         <span className="text-[#07A50D]">From:</span> Sadar Bazar,
//         Purnea, Bihar <br />
//         <span className=" text-[#FE0505] ">To:</span> Max
//         Hospital, Patna, Bihar
//       </p>
//     </div>
//   </div>
//   {/* right side content */}
//   <div className="flex flex-col  pl-2   ">
//     <p className="font-poppins text-xs md:text-sm lg:text-md xl:text-xl text-[#4A4141] ">
//       <span className="text-[#1A30A1] ">Phone:</span> 9122471133{" "}
//       <br />
//       <span className="text-[#1A30A1] ">Order Id:</span> 139
//     </p>
//   </div>
// </div>
// {/* vertical line */}
// <div className="underline-offset-8 w-[98%] ml-[7px] sm:ml-5 mb-3 mt-3  border-[1px]  color-[#0000002E] center-alignment"></div>
// <div className=" flex flex-wrap font-poppins  justify-between mb-2">
//   <div className=" mb-2 group bg-[#FFF] rounded-[10px] flex justify-center items-center   xl:w-[230px] xl:h-[50px] md:w-[230px] md:h-[50px] w-[100px] h-12">
//     <p className="font-poppins text-xs md:text-sm lg:text-md xl:text-xl text-[#07A50D] px-2 py-2 rounded-md ">
//       Manoj Dube
//     </p>
//   </div>
//   <div className=" mb-2 group bg-[#FFF] rounded-[10px] flex justify-center items-center   xl:w-[230px] xl:h-[50px] md:w-[230px] md:h-[50px] w-[100px] h-12">
//     <p className="font-poppins text-xs md:text-sm lg:text-md xl:text-xl text-[#666161] px-2 py-2 rounded-md ">
//       <span className="text-[#FF9432] ">Distance:</span> 50.10 km
//     </p>
//   </div>
//   <div className=" mb-2 group bg-[#FFF] rounded-[10px] flex justify-center items-center hover:bg-[#00B4D8] border-[1px] border-[#00B4D8] sm:w-[150px] sm:h-[45px] w-[110px] h-10">
//     <span>
//       <img
//         src={img2}
//         alt=""
//         className=" text-[#979797]  sm:w-[30px] sm:h-[30px] w-[20px]  h-[20px]"
//       />
//     </span>
//     <button className="font-poppins text-xs md:text-sm lg:text-md xl:text-xl text-[#00B4D8] px-2 py-2 rounded-md group-hover:text-white">
//       Driver
//     </button>
//   </div>
// </div>
// <div className="flex justify-between items-center ">
//   <p className="font-poppins text-xs md:text-sm lg:text-md xl:text-xl text-[#000] px-2 py-2 rounded-md ">
//     {/* sm:ml-12 */}
//     <span className="text-[#07A50D] ">OTP:</span> 210764
//   </p>
//   <div className="group select-none flex justify-center items-center gap-2 w-[110px]  sm:w-[150px] h-10 border-[1px] border-[#00B4D8] rounded-[8px] ">
//     <button className="flex justify-center items-center gap-2 text-[#00B4D8]  font-poppins text-md sm:text-xl ">
//       {" "}
//       <FontAwesomeIcon
//         icon={faMapMarkerAlt}
//         className=" text-[18px] sm:text-[20px] text-[#00B4D8] "
//       />
//       Track
//     </button>
//   </div>
// </div>
// </div>
