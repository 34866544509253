import React, { useState } from "react";
import img1 from "../../images/frontend UI materials/Request a call/headset.png";
import img2 from "../../images/frontend UI materials/Request a call/user.png";
import img3 from "../../images/frontend UI materials/Request a call/request call.png";
import img4 from "../../images/frontend UI materials/Request a call/phone.png";
import img5 from "../../images/frontend UI materials/Request a call/edit.png";
import Swal from "sweetalert2";
import "./RequestCall.css";
import Header from "./Header";
import Bg from "../../images/Light-Blue-.png";
import axios from "axios";
import siteCofig from "../../urlFile";
import { useNavigate } from "react-router-dom";

const RequestCall = () => {
  const navigate = useNavigate();
  const myAlert = () => {
    Swal.fire({
      title: "Your request for a call has been received.",
      text: "We will be get back to you soon. Stay Healthy 😊",
      icon: "success",
      customClass: {
        confirmButton: "custom-ok-button-class",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        navigate("/");
      }
    });
  };

  const [formData, setFormData] = useState({
    user_id: localStorage.getItem("userId"),
    name: "",
    phone: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const [validationError, setValidationError] = useState("");

  const submitHandler = async (event) => {
    // event.preventDefault();

    // Validate form data
    if (
      !formData.name.trim() ||
      !formData.phone.trim() ||
      !formData.message.trim()
    ) {
      setValidationError(" Fill all details");

      setTimeout(() => {
        setValidationError("");
      }, 5000);
      return;
    }

    try {
      const response = await axios.post(
        siteCofig.BASE_URL + siteCofig.HELP,
        formData
      );
      if (response.data.msg === "Record save Successfully") {
        myAlert();
      }
      console.log(response.data);

      setFormData({
        user_id: "",
        name: "",
        phone: "",
        message: "",
      });
      setValidationError("");
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <Header />
      {/* <div
        className="pt-28  flex justify-center items-center bg-cover"
        style={{ backgroundImage: `url(${Bg})` }}
      > */}
      <div
        className="pt-28 pb-20 flex justify-center items-center bg-cover"
        style={{
          backgroundImage: `url(${Bg})`,
          height: "100vh", // Set height to 100vh for full viewport height
        }}
      >
        <div
          className="p-8 pb-16 rounded-[10px] flex flex-col justify-center items-center Shadow-[4px_4px_13.1px_3px rgba(0, 0, 0, 0.20)]"
          style={{
            background: "linear-gradient(180deg, #00B4D8 0%, #091E87 99.48%)",
          }}
        >
          <div className="flex justify-center items-center -mt-3 mb-4">
            <div className="cursor-default flex gap-2 bg-[#04920A] rounded-[10px] w-52 h-12 justify-center items-center">
              <span className="flex justify-center items-center">
                <img
                  src={img1}
                  alt=""
                  className="w-[25px] h-[25px] sm:w-[32px] sm:h-[32px]"
                />
              </span>
              <span className="text-[#FFF] font-poppins text-lg md:text-xl xl:text-xl flex justify-center items-center">
                Request a call
              </span>
            </div>
          </div>
          <div
            className="flex flex-row bg-[#FFF] shadow-[4px_4px_19.8px_9px rgba(0, 0, 0, 0.20)] rounded-[10px]"
            style={{ boxShadow: "4px 4px 19.8px 9px rgba(0, 0, 0, 0.20)" }}
          >
            <div className="space-y-8 p-4">
              <p className="text-center cursor-default mt-[14px] font-poppins text-[#666161] text-[28px] font-normal">
                How may we help you?
              </p>
              {/* Name box */}
              <div className="space-x-2 flex border-1 border-[#00000033] justify-center items-center bg-[#FFF] rounded-[10px] shadow-[0px_4px_4px_0px rgba(0, 0, 0, 0.08)]">
                <span className="ml-[2px]">
                  <img
                    className="w-[27px] h-[27px] ml-1"
                    src={img2}
                    alt="Error"
                  />
                </span>
                <input
                  className="py-3 pl-3 placeholder:text-lg sm:placeholder:text-xl rounded-[10px] h-[50px] w-full font-poppins focus:outline-none text-sm sm:text-md font-normal text-[rgba(0, 0, 0, 0.20)] text-extrabold"
                  type="text"
                  placeholder="Name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
              </div>
              {/* phone number box */}
              <div className="space-x-2 flex border-1 border-[#00000033] justify-center items-center rounded-[10px] border-[1px solid rgba(0, 0, 0, 0.20)] bg-[#FFF] shadow-[0px_4px_4px_0px rgba(0, 0, 0, 0.08)]">
                <span className="ml-[2px] ">
                  <img
                    className="w-[27px] h-[27px] ml-1"
                    src={img4}
                    alt="Error"
                  />
                </span>
                <input
                  className="py-3 pl-3 placeholder:text-lg sm:placeholder:text-xl rounded-[10px] w-full h-[50px] font-poppins text-[rgba(0, 0, 0, 0.20)] font-normal focus:outline-none text-sm sm:text-md text-extrabold"
                  type="text"
                  placeholder="phone Number"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                />
              </div>
              {/* TextArea */}
              <div className="space-x-2 flex border-1 border-[#00000033] rounded-[10px] bg-[#FFF] border-[1px solid rgba(0, 0, 0, 0.20)] shadow-[0px_4px_4px_0px rgba(0, 0, 0, 0.08)]">
                <span className="mt-[16px] ml-[2px]">
                  <img
                    className="w-[27px] h-[27px] ml-1"
                    src={img5}
                    alt="Error"
                  />
                  {/* w-10 h-10  */}
                </span>
                <textarea
                  className="w-full pl-3 placeholder:text-lg sm:placeholder:text-xl mt-[3px] rounded-[10px] ml-[6px] py-3 font-poppins focus:outline-none text-sm sm:text-md font-normal text-[rgba(0, 0, 0, 0.20)] text-bold"
                  type="text"
                  placeholder="Write your message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                />
              </div>
              {/* Validation error message */}
              {validationError && (
                <p className="text-red-500 mt-2">{validationError}</p>
              )}
              {/* Request button */}
              <div
                className="flex items-center justify-center h-[6vh] rounded-[10px] bg-[#10CAF2] border-[1px solid rgba(0, 0, 0, 0.20)] shadow-[0px_4px_4px_0px rgba(0, 0, 0, 0.08)] cursor-pointer select-none"
                onClick={() => submitHandler()}
              >
                <button
                  className="text-center font-poppins rounded-md bg-[#10CAF2] text-extrabold text-[24px] text-[#FFF] font-normal"
                  // onClick={() => {myAlert();submitHandler()}}
                  // onClick={submitHandler}
                >
                  Request
                </button>
              </div>
            </div>
            <div className="hidden lg:block pt-8">
              <img
                className="w-[270px] h-[400px]"
                src={img3}
                alt=""
                srcset=""
              />
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default RequestCall;
