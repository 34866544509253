import React from "react";
import MedicalOrdiancePoint from "../../../images/frontend UI materials/MedicalStore.jpeg";
import { Link } from "react-router-dom";
import Header from "../Header";

const OrdinancePoints = () => {
  return (
    <>
      <Header />
      <div className="w-full font-poppins sm:my-10 lg:px-[52px]   sm:p-0 mb-4">
        {/* Parent Container */}
        <div className="flex  flex-col-reverse xl:flex-row  xl:justify-around sm:pt-10">
          {/* Heading Buttom and Paragraph */}
          <div className="p-3">
            {/* Heading */}
            <div className="sm:pb-[10px] pb-2">
              <h1 className="text-[#000] sm:text-3xl text-[14px] xs:text-[18px] font-medium flex justify-center lg:justify-start ">
                HealthCRAD Ordinance Point in BIHAR
              </h1>
            </div>
            {/* Paragraph */}
            <div>
              <p className="text-[#666161] lg:text-xl md:text-[18px] sm:text-[16px] text-[13px] lg:w-[783px] lg:h-auto font-thin sm:mt-10 text-justify ">
                Join <span className="text-[#4A4141]">HealthCRAD</span>{" "}
                Ordinance Points Chain and become a dedicated partner in
                creating rural healthcare ecosystem <br /> <br />
                <h2 className="text-[#000000] text-[16px] sm:text-[24px] font-semibold font-poppins text-center xs:mb-4 sm:mb-5">
                  Services:{" "}
                </h2>
                <br />
                <div className="xs:leading-[30px] sm:leading-10">
                  <ul className="list-decimal ml-5">
                    <li>Digitize your medical store</li>
                    <li>Zero Setup & Maintenance fee</li>
                    <li>No hidden charges</li>
                    <li>Book Doctor Appointments</li>
                    <li>Order cities medicines for your patients</li>
                    <li>Book Ambulance for your patients</li>
                    <li>Path Lab support for your patients</li>
                    <li>E-clinic setups</li>
                    <li>Get 30%-60% discounts on ethical and generic</li>
                    <li>medicines for your store stock</li>
                  </ul>
                </div>
              </p>
            </div>
            {/* Button  */}
            <Link to="https://wa.me/message/532QZXEFUYFWK1" target="/_blank">
              <div className=" flex xl:justify-start mt-8 sm:mt-16 ">
                <button className="hidden sm:block text-[#fff] md:text-[20px] sm:text-[18px] text-[16px] bg-[#00B4D8F0] rounded-[5px] font-medium lg:text-[24px] lg:w-3/5 md:h-[70px] sm:h-[70px] h-[44px] shadow-[4px_4px_4px_0px_rgba(0,0,0,0.20)] px-[15px]">
                  Order Medicine as a Retailer
                </button>
              </div>
            </Link>
          </div>
          {/* image for larger screen */}
          <div className=" xs:mt-16 sm:mt-20 p-0">
            <img
              src={MedicalOrdiancePoint}
              alt=""
              className="w-full sm:w-[330px] h-[300px]  mb-[70px]"
            />
          </div>
        </div>
      </div>

      {/* mobile view button design start */}
      <div className=" sm:hidden fixed z-10 flex justify-center items-center bg-gray-100 h-[60px] py-1  w-full bottom-0 ">
        <div className="sm:hidden">
          <Link to="https://wa.me/message/532QZXEFUYFWK1" target="/_blank">
            <button className="bg-[#00B4D8F0] rounded-[5px] font-medium text-white shadow-md  h-[40px] w-full  text-[18px] px-2">
              Order Medicine as a Retailer
            </button>
          </Link>
        </div>
      </div>
      {/* mobile view button design end */}
    </>
  );
};

export default OrdinancePoints;
