import React, { useState, useEffect } from "react";
import PersonalItem from "../../images/ShopMedicineByCategory/personal-Care_Mobile-removebg-preview.png";
import WellnessItem from "../../images/ShopMedicineByCategory/WellnessItem.png";
import Babymothercare from "../../images/ShopMedicineByCategory/baby_and_mother_care-removebg-preview.png";
import DiabetesMedicines from "../../images/ShopMedicineByCategory/Diabetes Medicines.png";
import OTCMedicines from "../../images/ShopMedicineByCategory/OTC Medicines.png";
import PageLoader from "../../Loader/PageLoader";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

const ShopMedicineByCategory = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const location = useLocation();
  const [activeCategory, setActiveCategory] = useState(null);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const categoryParam = searchParams.get("category");
    if (categoryParam) {
      setActiveCategory(categoryParam);
    }
  }, [location.search]);

  // const handleRoute = (category) => {
  //   window.location.href = `/medicine?category=${category}`;
  // };
  const handleRoute = (category) => {
    window.location.href = `/medicine?category=${category}`;
    setLoading(true);
    setTimeout(() => {
      // navigate(path);
    }, 1000);
  };

  return (
    <div className=" w-full mt-5">
      {loading && <PageLoader />}
      <div className="sm:pt-10   font-poppins">
        {/* Heading and SubHeading */}
        <div className="">
          <h1 className="text-[#2F2D2D] pb-2.5 sm:pb-0 text-[18px] sm:text-[24px]  font-semibold font-lato pl-[7px] xs:pl-[13px] sm:pl-[28px] md:pl-[37px] lg:pl-[50px] xl:pl-[20px] 2xl:pl-[53px] ">
            Shop medicine by category
          </h1>
        </div>
        {/* Parent Div Card */}
        <div className="w-full grid grid-cols-3 xl:grid-cols-5  gap-6 sm:gap-2   sm:m-0 justify-items-center sm:p-0 p-[9px]  ">
          {/* Card-1 */}
          <div
            className={`rounded-tl-[15px] rounded-tr-[15px] rounded-bl-[35px] rounded-br-[35px] sm:rounded-tl-[20px] sm:rounded-tr-[20px] sm:rounded-bl-[60px] sm:rounded-br-[60px] sm:mt-[30px] sm:mb-20 w-[88px] xs:w-[109px] h-[72px] sm:h-[128px] sm:w-[208px] rounded-[30px] cursor-pointer shadow-[0px_4px_5px_0px_#AAFFAD] ${
              activeCategory === "PersonalCare" ? "bg-blue-500 text-white" : ""
            }`}
            onClick={() => handleRoute("4")}
          >
            <div className="bg-[#AAFFAD] rounded-tl-[15px] rounded-tr-[15px] rounded-bl-[35px] rounded-br-[35px] sm:rounded-tl-[20px] sm:rounded-tr-[20px] sm:rounded-bl-[60px] sm:rounded-br-[60px] w-[88px] xs:w-[109px] h-[72px] sm:h-[128px] sm:w-[208px]">
              <p className="   text-[#00A307] text-[11px] xs:text-[13px]   sm:text-[24px] font-normal pl-[13px] xs:pl-[26px] pr-[25px] pt-[6px]  sm:pl-[46px] sm:pr-[28px] sm:pt-[17px]">
                Personal Item
              </p>
              <img
                src={PersonalItem}
                alt=""
                className="   mx-auto w-[63px] h-[68px]  sm:w-[133px] sm:h-[144px] rounded-[10px]"
              />
            </div>
          </div>
          {/* Card-2*/}
          <div
            className={`rounded-tl-[15px] rounded-tr-[15px] rounded-bl-[35px] rounded-br-[35px] sm:rounded-tl-[20px] sm:rounded-tr-[20px] sm:rounded-bl-[60px] sm:rounded-br-[60px] sm:mt-[30px] sm:mb-20 w-[88px] xs:w-[109px] h-[72px] sm:h-[128px] sm:w-[208px] rounded-[30px] cursor-pointer shadow-[0px_4px_5px_0px_#D0D4E4] ${
              activeCategory === "WellnessItems" ? "bg-blue-500 text-white" : ""
            }`}
            onClick={() => handleRoute("5")}
          >
            <div className="bg-[#D0D4E4] rounded-tl-[15px] rounded-tr-[15px] rounded-bl-[35px] rounded-br-[35px] sm:rounded-tl-[20px] sm:rounded-tr-[20px] sm:rounded-bl-[60px] sm:rounded-br-[60px]  w-[88px] xs:w-[109px] h-[72px] sm:h-[128px] sm:w-[208px]">
              <p className="   text-[#142979] text-[11px] xs:text-[13px]   sm:text-[24px] font-normal pl-[13px] xs:pl-[26px] pr-[25px] pt-[6px]  sm:pl-[46px] sm:pr-[28px] sm:pt-[17px] pb-[7px]">
                Wellness Item
              </p>
              <img
                src={WellnessItem}
                alt=""
                className="   mx-auto w-[45px] h-[64px]  sm:w-[89px] sm:h-[126px] rounded-[10px] "
              />
            </div>
          </div>
          {/* Card-3 */}
          <div
            className={`rounded-tl-[15px] rounded-tr-[15px] rounded-bl-[35px] rounded-br-[35px] sm:rounded-tl-[20px] sm:rounded-tr-[20px] sm:rounded-bl-[60px] sm:rounded-br-[60px] sm:mt-[30px] sm:mb-20 w-[88px] xs:w-[109px] h-[72px] sm:h-[128px] sm:w-[208px] rounded-[30px] cursor-pointer shadow-[0px_4px_5px_0px_#EFD3D3] ${
              activeCategory === "BabyCare" ? "bg-blue-500 text-white" : ""
            }`}
            onClick={() => handleRoute("3")}
          >
            <div className="bg-[#EFD3D3] rounded-tl-[15px] rounded-tr-[15px] rounded-bl-[35px] rounded-br-[35px] sm:rounded-tl-[20px] sm:rounded-tr-[20px] sm:rounded-bl-[60px] sm:rounded-br-[60px]  w-[88px] xs:w-[109px] h-[72px] sm:h-[128px] sm:w-[208px]">
              <p className="text-[#B12626] text-[11px] xs:text-[13px] sm:text-[24px] font-normal sm:pl-[26px] sm:pr-[28px] sm:pt-[17px] pl-[13px] xs:pl-[18px] pt-[6px]">
                Baby & mother care
              </p>
              <img
                src={Babymothercare}
                alt=""
                className="   mx-auto w-[58px] h-[78px]  sm:w-[121px] sm:h-[151px] rounded-[10px]"
              />
            </div>
          </div>
          {/* Card-4 */}
          <div
            className={`hidden xl:block rounded-tl-[15px] rounded-tr-[15px] rounded-bl-[35px] rounded-br-[35px] sm:rounded-tl-[20px] sm:rounded-tr-[20px] sm:rounded-bl-[60px] sm:rounded-br-[60px] sm:mt-[30px] mt-[60px] sm:mb-20 w-[88px] xs:w-[109px] h-[72px] sm:w-[208px] rounded-[30px] cursor-pointer shadow-[0px_4px_5px_0px_#D3EEEF] ${
              activeCategory === "Diabetes" ? "bg-blue-500 text-white" : ""
            }`}
            onClick={() => handleRoute("2")}
          >
            <div className="bg-[#D3EEEF] rounded-tl-[15px] rounded-tr-[15px] rounded-bl-[35px] rounded-br-[35px] sm:rounded-tl-[20px] sm:rounded-tr-[20px] sm:rounded-bl-[60px] sm:rounded-br-[60px] w-[88px] xs:w-[109px] h-[72px] sm:h-[128px] sm:w-[208px]">
              <p className="   text-[#38B1B9] text-[11px] xs:text-[13px]  sm:text-[24px] font-normal pl-[13px] xs:pl-[26px] pr-[25px] pt-[6px]  sm:pl-[46px] sm:pr-[28px] sm:pt-[17px]">
                Diabetes Medicines
              </p>
              <img
                src={DiabetesMedicines}
                alt=""
                className="   mx-auto w-[81px] h-[61px]  sm:w-[144px] sm:h-[118px] rounded-[10px]"
              />
            </div>
          </div>
          {/* Card-5 */}
          <div
            className={`hidden xl:block rounded-tl-[15px] rounded-tr-[15px] rounded-bl-[35px] rounded-br-[35px] sm:rounded-tl-[20px] sm:rounded-tr-[20px] sm:rounded-bl-[60px] sm:rounded-br-[60px] sm:mt-[30px] mt-[60px] sm:mb-20 w-[88px] xs:w-[109px] h-[72px] sm:h-[128px] sm:w-[208px] rounded-[30px] cursor-pointer shadow-[0px_4px_5px_0px_#D8EDF2] ${
              activeCategory === "OTC" ? "bg-blue-500 text-white" : ""
            }`}
            onClick={() => handleRoute("1")}
          >
            <div className="bg-[#D8EDF2] rounded-tl-[15px] rounded-tr-[15px] rounded-bl-[35px] rounded-br-[35px] sm:rounded-tl-[20px] sm:rounded-tr-[20px] sm:rounded-bl-[60px] sm:rounded-br-[60px]  w-[88px] xs:w-[109px] h-[72px] sm:h-[128px] sm:w-[208px]">
              <p className="   text-[#5BB5CA] text-[11px] xs:text-[13px]   sm:text-[24px] font-normal pl-[13px] xs:pl-[26px] pr-[25px] pt-[6px]  sm:pl-[46px] sm:pr-[28px] sm:pt-[17px]">
                OTC Medicines
              </p>
              <img
                src={OTCMedicines}
                alt=""
                className="mx-auto w-[65px] h-[68px]  sm:w-[126px] sm:h-[132px] rounded-[10px]"
              />
            </div>
          </div>
        </div>

        {/* small screen show this card */}
        <div className="flex justify-around mx-[36px] mt-[10px] xs:mt-[36px] mb-[95px] ">
          {/* Card-4 */}
          <div
            className={` xl:hidden rounded-tl-[15px] rounded-tr-[15px] rounded-bl-[35px] rounded-br-[35px] sm:rounded-tl-[20px] sm:rounded-tr-[20px] sm:rounded-bl-[60px] sm:rounded-br-[60px] sm:mt-[30px] mt-[60px] sm:mb-20 w-[88px] xs:w-[109px] h-[72px] sm:w-[208px]  rounded-[30px] cursor-pointer shadow-[0px_4px_5px_0px_#D3EEEF] ${
              activeCategory === "Diabetes" ? "bg-blue-500 text-white" : ""
            }`}
            onClick={() => handleRoute("2")}
          >
            <div className="bg-[#D3EEEF] rounded-tl-[15px] rounded-tr-[15px] rounded-bl-[35px] rounded-br-[35px] sm:rounded-tl-[20px] sm:rounded-tr-[20px] sm:rounded-bl-[60px] sm:rounded-br-[60px] w-[88px] xs:w-[109px] h-[72px] sm:h-[128px] sm:w-[208px]">
              <p className="   text-[#38B1B9] text-[11px] xs:text-[13px]   sm:text-[24px] font-normal pl-[13px] xs:pl-[26px] pr-[25px] pt-[6px]  sm:pl-[46px] sm:pr-[28px] sm:pt-[17px]">
                Diabetes Medicines
              </p>
              <img
                src={DiabetesMedicines}
                alt=""
                className="   mx-auto w-[81px] h-[61px]  sm:w-[144px] sm:h-[118px] rounded-[10px]"
              />
            </div>
          </div>
          {/* Card-5 */}
          <div
            className={` xl:hidden  rounded-tl-[15px] rounded-tr-[15px] rounded-bl-[35px] rounded-br-[35px] sm:rounded-tl-[20px] sm:rounded-tr-[20px] sm:rounded-bl-[60px] sm:rounded-br-[60px] sm:mt-[30px] mt-[60px] sm:mb-20 w-[88px] xs:w-[109px] h-[72px] sm:h-[128px] sm:w-[208px]  rounded-[30px] cursor-pointer shadow-[0px_4px_5px_0px_#D8EDF2] ${
              activeCategory === "OTC" ? "bg-blue-500 text-white" : ""
            }`}
            onClick={() => handleRoute("1")}
          >
            <div className="bg-[#D8EDF2] rounded-tl-[15px] rounded-tr-[15px] rounded-bl-[35px] rounded-br-[35px] sm:rounded-tl-[20px] sm:rounded-tr-[20px] sm:rounded-bl-[60px] sm:rounded-br-[60px]  w-[88px] xs:w-[109px] h-[72px] sm:h-[128px] sm:w-[208px]">
              <p className="   text-[#5BB5CA] text-[11px] xs:text-[13px]   sm:text-[24px] font-normal pl-[13px] xs:pl-[26px] pr-[25px] pt-[6px]  sm:pl-[46px] sm:pr-[28px] sm:pt-[17px]">
                OTC Medicines
              </p>
              <img
                src={OTCMedicines}
                alt=""
                className="   mx-auto w-[65px] h-[68px]  sm:w-[126px] sm:h-[132px] rounded-[10px]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopMedicineByCategory;
