import React from "react";
import Header from "../../Header";

const TermsAndCond = () => {
  return (
    <>
      <Header />
      <div className="pt-28 mb-20 gap-10 cursor-default flex flex-col justify-center items-center">
        <div className="flex justify-center items-center ">
          <p className="font-poppins text-[#00B4D8]  font-normal  lg:text-3xl text-2xl">
            Terms and Conditions
          </p>
        </div>
        <div className="flex justify-center items-center ">
          <div className="bg-[#FFF] sm:w-[90%] w-[100%] h-[80%]  rounded-[10px] border-[1px] shadow-md sm:p-0 p-2 sm:ml-0 ml-1 ">
            <p className="font-poppins text-sm p-4 sm:p-0 text-align">
              <p className="font-poppins text-xl sm:text-2xl">
                * Terms & Condition
              </p>
              <br />
              HealthCRAD is Visionary brand of Bhavah Healthcare Private Limited
              BHPL. We at HealthCRAD BHPL we us provide services to all
              individuals accessing or using our HealthCRAD platform BHPL
              Platform for any reason you yours User subject to the notices
              terms and conditions set forth in these terms and conditions Terms
              and Conditions Agreement read with the Privacy Policy available
              here. The HealthCRAD Platform is owned and operated by BHPL Bhavah
              Healthcare Private Limited a company duly incorporated under the
              provisions of the Companies Act 2013. By accessing or browsing of
              the BHPL Platform and using the Services as defined below you
              indicate your agreement to all the terms and conditions in this
              Agreement. If you disagree with any part of the Terms and
              Conditions then you may discontinue access or use of the BHPL
              Platform. <br /> &nbsp; &nbsp;
              <p className="font-poppins text-2xl">1. ELIGIBILITY </p>
              <br />
              When you use the BHPL Platform you represent that you meet the
              following primary eligibility criteria:
              <br />
              a. You are at least 18 years old or accessing the BHPL Platform
              under the supervision of a parent or guardian who in such a case
              will be deemed as the recipient / end-user of the Services as
              defined in these Terms and Conditions for the purpose of these
              Terms and Conditions. <br /> b. If your age is below that of 18
              years your parents or legal guardians can transact on behalf of
              you if they are registered users. You are prohibited from
              purchasing any material the sale or purchase of which to/by minors
              is prohibited and which is for consumption by adults only. <br />
              c. You are legally competent to contract, and otherwise competent
              to receive the Services as defined in these Terms and Conditions.
              Persons who are incompetent to contract within the meaning of the
              Indian Contract Act, 1872 including undischarged insolvents etc.
              are not eligible to use the BHPL Platform.
              <br />
              d. You have not been previously suspended or removed by HealthCRAD
              or any other BHPL entity or disqualified for any other reason from
              availing the Service
              <br />
              e. BHPL reserves the right to terminate your membership if any
              and/or refuse to provide you with access to the BHPL Platform if
              BHPL discovers that you are under the age of 18 years.
              <br />
              f. These Terms and Conditions are published in compliance of and
              is governed by the provisions of Indian laws as may be amended
              from time to time including but limited to: <br />
              i. The Indian Contract Act 1872. <br />
              ii. The Indian Information Technology Act, 2000 and the rules,
              regulations guidelines and clarifications framed thereunder
              including the Indian Information Technology Reasonable Security
              Practices and Procedures and Sensitive Personal Information Rules
              2011 and the Indian Information Technology Intermediaries
              Guidelines Rules 2011
              <br />
              iii. The Drugs and Cosmetic Act 1940 read with the Drugs and
              Cosmetics Rules 1945
              <br />
              iv. The Drugs and Magic Remedies Objectionable Advertisements Act
              1954
              <br />
              v. The Indian Medical Council Act 1956 read with the Indian
              Medical Council Rules 1957
              <br />
              vi. Pharmacy Act 1948 and
              <br />
              vii. The Consumer Protection Act 2019 and Consumer Protection
              E-Commerce Rules 2020.
              <br />
              <br />
              <p className="font-poppins text-2xl">
                1. YOUR USE OF THE BHPL PLATFORM
              </p>
              <br />
              3.1. By using the BHPL Platform you expressly agree to be bound by
              the Terms and Conditions. If you do not agree with any of the
              Terms and Conditions, please do not use the BHPL Platform. Any
              accessing browsing or otherwise using the BHPL Platform indicates
              your agreement to these Terms and Conditions and any other
              policies or guidelines that may be applicable on the BHPL Platform
              at the time of your access and usage of the BHPL Platform and
              which may be updated from time to time.
              <br />
              3.2. These Terms and Conditions document is an electronic record
              in terms of the Information Technology Act 2000 and rules made
              thereunder as may be applicable and the amended provisions
              pertaining to electronic records in various statutes as amended by
              the Information Technology Act 2000. This electronic record is
              generated by a computer system and does not require any physical
              or digital signatures.
              <br />
              3.3. As an end-user and recipient of Services when you use the
              BHPL Platform you agree to the following conditions of use:
              <br />
              <br />
              <p className="font-poppins text-2xl">
                3.3.1. Due diligence conditions:
              </p>
              <br />
              a. You are solely responsible for the medical health and personal
              information you provide on the BHPL Platform and you are requested
              to use your discretion in providing such information
              <br />
              b. You will provide accurate and complete information everywhere
              on the BHPL Platform based on which you will receive the Services.
              <br />
              c. You will be solely responsible for all access to and use of
              this BHPL Platform by anyone using the password and identification
              originally assigned to you whether or not such access to and use
              of this BHPL Platform is actually authorized by you, including
              without limitation all communications and transmissions and all
              incurred through such access or use. You are solely responsible
              for protecting the security and confidentiality of the password
              and identification assigned to you.
              <br />
              d. The information provided by you may be used by us for the
              purpose of Services including analysis research training and
              disclosure where required to our affiliates group companies
              vendors agents and government authorities etc. as stated in our
              Privacy Policy.
              <br />
              e. We reserve the right to refuse service or terminate accounts at
              our discretion, if we believe that you have violated or are likely
              to violate applicable law or these Terms and Conditions.
              <br />
              <br />
              <p className="font-poppins text-2xl">3.3.2. Scope of Services</p>
              <br />
              a. BHPL provides top verified doctors of various categories on the
              BHPL platform for the users to book their appointments according
              to their needs and rate them eventually.
              <br />
              b. BHPL sells products in various categories such as medicines
              medical devices vitamins and supplements baby food, personal care
              health foods and over the counter drugs to end customers. BHPL
              does not sell any drugs listed in Schedule X of the Drugs and
              Cosmetics Act 1940 on the BHPL Platform.
              <br />
              c. BHPL provides access to various types of ambulances such as
              Advanced Life Support Basic Life Support Patient Transport and
              mortuary for the users to book it for medical purposes.
              <br />
              d. BHPL provides users to get digital Path Lab Reports by
              uploading the receipts.
              <br />
              <br />
              <p className="font-poppins text-2xl">3.3.3. Prohibitions</p>
              <br />
              a. You may view and access the content available on the BHPL
              Platform solely for the purposes of availing the Services and only
              as per these Terms and Conditions. You may not reproduce
              distribute display sell lease transmit create derivative works
              from translate modify reverse-engineer disassemble decompile or
              otherwise exploit the BHPL Platform or any portion of it unless
              expressly permitted by BHPL in writing.
              <br />
              b. You may not assign transfer or sub-contract any of your rights
              or obligations under these Terms or any related order for Products
              to any third party unless agreed upon in writing by BHPL.
              <br />
              c. You may not make any commercial use of any of the information
              provided on the BHPL Platform
              <br />
              d. You may not impersonate any person or entity or falsely state
              or otherwise misrepresent your identity age or affiliation with
              any person or entity.
              <br />
              e. You may not upload any content prohibited under applicable law
              and or designated as "Prohibited Content under Section 5 below.
              <br />
              f. You may not resell any Products purchased from BHPL stores or
              the BHPL Platform.
              <br />
              <br />
              <p className="font-poppins text-2xl">
                3.3.4. Delivery and Charges Policy:
              </p>
              <br />
              <p className="font-poppins text-2xl">
                3.3.4.1. Doctor Appointments:
              </p>
              <br />
              <p className="font-poppins text-2xl">Booking an Appointment:</p>
              <br />
              BHPL allows the Users to seek medical consultation by booking an
              appointment with its registered practitioners on the platform.
              User can book an appointment with the practitioners of their
              choice according to their date time ratings experience and fees.
              The User can search for the practitioner according to their need
              through the search option made available on the platform after
              finding the best suitable practitioner the user can book
              appointment according to their time and date and after filling the
              required details the user can make payment or opt for payment on
              clinic visit after which the user can visit the practitioner on
              that day.User is solely responsible for selecting the consultant
              and to visit them the time on which the appointment is booked and
              pay or not pay at visit according to their preferred method of
              payment.
              <br />
              <br />
              <p className="font-poppins text-2xl">
                Cancellation of Appointment:
              </p>
              <br />
              If due to any reason the user is unable to turn up to the
              practitioner the user can cancel the appointment the user should
              cancel the appointment at least one hour before the time at which
              the appointment is booked by providing the valid reason for
              cancellation of the appointment.If the user does not provide the
              valid reason or has not turned up on the time of appointment
              without cancelling the user will not be eligible for any refunds
              if paid already or the user may be subjected to the legal action
              by the BHPL if repeats the same mistake again causing any type of
              loss to the company or its service providers.
              <br />
              <br />
              <p className="font-poppins text-2xl">Payments and Refunds:</p>
              <br />
              The user can pay the fees as per their convenience if they choose
              to pay online for a visit can pay through their banking apps or
              they may opt to pay on visit.If due to any reason the user cancels
              the appointment or their visit is not successful if practitioner
              refuses the appointment the user will get refund within 7 working
              days.If it is shown that the mistake has been done by the user
              side or if a valid reason is not provided by the user or if the
              user has done anything unlawful to get the money from BHPL he/she
              may not get the refund and may be subjected to law.
              <br />
              <br />
              <p className="font-poppins text-2xl">3.3.4.2. Orders:</p>
              <br />
              <p className="font-poppins text-2xl">Placing an Order:</p>
              <br />
              BHPL allows the users to purchase their medicines and essentials
              through its platform by providing proper prescription and all the
              other necessary details the user can place the order for the
              listed products on the platform or may purchase their needs just
              through a valid prescription. If prescription do not match the
              eligibility criteria or contains some restricted products the
              company may contact the user to confirm the validity of the
              prescription after confirming the validity of the prescription and
              getting other necessary details the order will be confirmed.BHPL
              suggests its users to recheck the products which are being ordered
              and must attach a valid prescription if needed with any particular
              products.
              <br />
              <br />
              <p className="font-poppins text-2xl">Medicine Delivery Policy:</p>
              <br />
              Valid prescription from a doctor is mandatory for all medicine
              orders apart from when the law permits dispatch of medicine
              without a prescription. You may be requested to upload a scanned
              copy of the prescription on the BHPL Platform You agree that the
              prescription uploaded by you will be examined by a registered
              pharmacist where applicable or necessary under law, and your order
              shall be considered confirmed only after the prescription is
              accepted by the Pharmacist You agree and consent to the
              particulars of your prescription will be recorded by BHPL in
              accordance with its obligations under applicable laws You may be
              required to display the prescription whether offline or online for
              the purpose of record keeping by BHPL at the time of picking up
              medicines or accepting delivery of medicines through an online
              order and BHPL will have a right to reject either fully or
              partially your Order in case it is found that the prescription
              shared by you is not as per the relevant applicable statutory
              requirements and same shall be informed to you through various
              communication channels.
              <br />
              <br />
              <p className="font-poppins text-2xl">Shipping:</p>
              <br />
              BHPL endeavours to carry out deliveries during and within such
              time period as communicated on our BHPL Platform and various
              communication channels. Our services are offered on a best effort
              basis and as such we do not provide any guarantee of the exact
              delivery time which despite all our efforts may vary from order to
              order due to certain factors including but not limited to
              operational disruptions owing to shortage of products and manpower
              strikes lockouts official control measures inevitable delays by
              our suppliers rider unavailability public holidays product
              unavailability/shortage date of delivery time of delivery area of
              delivery distance from the hub customer demand traffic sudden
              breakdown of delivery vehicle weather conditions force majeure
              events specified below and/or any other unforeseen reasons beyond
              our control BHPL shall endeavour to deliver the order at the
              delivery address as provided to us while placing the order on our
              platform. However any interruption or disruption caused in
              delivery due to any incorrect details provided to us on the BHPL
              Platform shall not be our responsibility and any resultant
              losses/damages caused shall be your sole responsibility and will
              not be attributable to us in any manner whatsoever. Not with
              standing your request to leave your order at your doorstep or
              elsewhere, such request cannot be complied with and BHPL shall not
              be responsible to deliver your order if no one is available to
              receive the same at the delivery address in as much as it could
              lead to theft tampering spoilage contamination etc. BHPL shall not
              be liable for non-delivery or any losses/damages arising from the
              same in the given case scenario and any losses/damages caused by
              such circumstances shall be solely borne by you.
              <br />
              You shall undertake to provide accurate directions information and
              authorisations to accept delivery of your order. Due to your
              failure to comply with the same if the order stands undelivered
              the order shall be deemed to have been delivered to you and all
              the risks and responsibilities in relation thereto shall pass on
              to you. As part of BHPLs best endeavour to deliver all orders
              within the promised delivery timeline our rider may connect with
              you via mobile or internet call to identify the exact location of
              delivery. However in the event you do not pick up the call or the
              call on the given number does not get connected at the first
              instance for any reason whatsoever the promised delivery timeline
              shall stand no longer applicable without prejudice to the fact
              that the promised time is only an approximate measure of delivery
              time .The delivery charges associated with each of your purchases
              will be displayed on the checkout page once you place an order on
              the BHPL Platform. Any other applicable charges will also be
              displayed at the time of purchase and will be payable accordingly.
              <br />
              <br />
              <p className="font-poppins text-2xl">Cash on Delivery COD</p>
              <br />
              is a payment method by which you can pay for your ordered items in
              cash when BHPL delivers the orders to you at your delivery
              address.
              <br />
              If you do not own a credit or debit card or do not wish to pay
              online rather than making any advance online payment BHPL COD
              option gives you the flexibility to pay the complete order amount
              on delivery through a point-of-service terminal or through cash as
              the case may be In the event you choose to pay for your order via
              COD mode the riders shall have the right to refuse delivery of
              your order if you fail to make the complete payment at the time of
              delivery. In that case, BHPL shall not be liable for any losses or
              damage arising from non-delivery of the order and the same shall
              be your sole responsibility.
              <br />
              You will be informed once the order is confirmed and when the
              items are shipped with an update on the approximate delivery time
              BHPL makes its best efforts to ship each item in your order within
              the approximate delivery time intimated to you at the time of
              placing the order. However in some cases it may take longer to
              ship the order as we may have to procure it from some other stores
              or our suppliers. In the unlikely event that BHPL is not able to
              ship your order completely within 36 hours of the order we shall
              cancel the remaining unshipped part of the order and send you a
              communication informing you about the same. In such cases your
              payment against the unshipped part of the order shall be refunded
              in the manner you have made the payment in accordance with the
              BHPL Payment Returns and Refund Policy.
              <br />
              Delivery time periods specified on our platform shall always be
              non-binding under all circumstances in as much as delivery is a
              sum total of multiple factors that can assume uncertainty at any
              moment for unforeseen reasons beyond our control. To put forth its
              best efforts to deliver the order effectively and within the
              promised timeframe both internal and third-party entities are
              engaged by BHPL to carry out deliveries with strict Standard
              Operating Procedures. Please note that third party services will
              be governed by their own terms of service and other policies
              applicable to the corresponding third party and we have no control
              or connection to it whats oever and we make no representation or
              endorsement regarding the accuracy relevancy copyright compliance
              legality completeness timeliness or quality of any product
              services advertisements and other content appearing in or linked
              to the services of the third party and BHPL does not make any
              inter-state deliveries of medicine. Inter-state delivery of
              medicines is fulfilled through external delivery partners. BHPL
              may also engage external delivery partners for certain inter-city
              and local deliveries. Any delay loss harm theft or damage arising
              from the shipping of products by the external delivery partner is
              the sole liability of the external delivery partner. BHPL shall
              not be held liable for the actions of the external delivery
              partner in any way whatsoever.
              <br />
              <br />
              <p className="font-poppins text-2xl">Return & Replacement:</p>
              <br />
              Return of Order: <br />
              The user can return the product If no longer needed or receives
              the damaged or different product from the ordered product within
              the provided time and conditions.
              <br />
              Only those products which are eligible to return can be returned
              the user can provide the valid reason to return the order and make
              sure to return the product without damaging or doing any harm to
              the product. The user should make sure to provide with proper
              evidence to return the product and should return the product
              within 3 days after the delivery.The return will not be accepted
              if the product is damaged or any part of the product is missing or
              damaged by the user
              <br />
              <p className="font-poppins text-2xl">Replacement of Order:</p>
              <br />
              The user may replace the delivered products which are eligible for
              replacement the user can replace the items which are delivered to
              them if user gets the different product from what they have
              ordered or the delivered products are defective or damaged.
              <br />
              The product which is being replaced should be in the same
              condition in which it was received all the packing and bills
              should be preserved for successful replacement of the order.The
              user should check the products on the time of delivery or
              immediately after delivery to ensure the delivery of the correct
              and original products and should also immediately inform the
              company with proof to get the replacement of the products.
              <br />
              <br />
              <p className="font-poppins text-2xl">Payments & Refunds:</p>
              <br />
              If user cancel the order or if returns the order after the
              successful pickup the user will get the full refund and the refund
              will be credited in their provided account details. If the user
              had paid online for the order by any digital method the refund
              will be made in the same account. If the user has opted for the
              cash on delivery COD then they have to provide the bank details or
              the preferred UPI details to get the eligible refund. If due to
              any valid reason the return fails or the details of the preferred
              way of refund provided by user is wrong and company credits the
              amount in that account in both cases the company should not held
              responsible.
              <br />
              <br />
              <p className="font-poppins text-2xl">Additional Information:</p>
              <br />
              International delivery is not available currently. You can access
              the website to order items while being located anywhere in the
              world but the shipping address has to be in India.
              <br />
              he user may cancel the order anytime by providing the valid reason
              but once the order is cancelled can't be undone. If user wants to
              continue the order the user can place new order again.
              <br />
              BHPL shall have the right at our sole discretion to refuse or
              cancel any orders placed for that product unless the product has
              already been dispatched. You will compensate BHPL for any extra
              cost incurred for redelivery in the event of a non-delivery in the
              first instance on account of a mistake by you i.e. wrong name or
              address or any other wrong information.
              <br />
              <br />
              <p className="font-poppins text-2xl">
                3.3.4.3. Ambulance Bookings:
              </p>
              <br />
              <p className="font-poppins text-2xl">Booking of an Ambulance:</p>
              <br />
              BHPL allows users to book ambulance for any emergency without much
              hustle user can book various types of ambulance through the
              platform. User can select and book the desired category of
              ambulance by providing the necessary details and after filling all
              the details can proceed for the payments. Once all the necessary
              details have been fulfilled the user will be provided with their
              selected category of ambulance if available in nearby areas of the
              User. BHPL works with various ambulances agencies ambulance
              providers & relevant vendors to provide Ambulances to you. BHPL
              isn't liable if the booking isn't fulfilled due to the various
              circumstances such as unavailability of ambulances to your nearest
              achievable points strikes curfews etc. Although BHPL tries its
              best to provide the above-mentioned services.
              <br />
              <br />
              <p className="font-poppins text-2xl">Charges & Pricings:</p>
              <br />
              After filling the necessary details User can proceed to complete
              the booking and will be asked to select the preferred payment
              method the User may opt to pay online or by cash.
              <br />
              The pricing may vary based on distance and types of ambulance the
              User may proceed after selecting the preferred payment method.
              <br />
              <br />
              <p className="font-poppins text-2xl">Cancellation of Booking:</p>
              <br />
              User can cancel the booking anytime for various reasons but terms
              may be applied to the cancelation based on the timing of
              cancelation. If the User cancel’s the booking within 5 minutes
              after booking or within 5 minutes after the booking has been
              accepted by any ambulance service provider the user will not have
              to pay any cancellation charges. If the User does not cancel the
              booking within the mentioned time the User may have to pay penalty
              amount of the total booking amount depending upon the distance
              covered by the ambulance before cancellation for the loss of the
              ambulance service provider as that ambulance could have been used
              for someone else in need.
              <br />
              <br />
              <p className="font-poppins text-2xl">Payments & Refunds:</p>
              <br />
              If the User selects to pay with online method they do not need to
              pay anything for the ride but may have to pay extra if any extra
              equipment has been needed or the free waiting time has been
              exceeded.
              <br />
              the User choose to pay with cash then they may have to pay all the
              charges by cash. If the User cancels the booking after doing
              payment through online method the refund will be credited into the
              User’s account within 7 business days.
              <br /> &nbsp; &nbsp;
              <p className="font-poppins text-2xl">
                3.3.4.4. Path Lab Reports:
              </p>
              <br /> &nbsp;Requesting a Report:
              <br /> &nbsp;BHPL allows users to request to get their reports
              digitally or physically if he has made his check up from any of
              our service points the user may request to get the report by
              providing the details about them and their Path lab in which the
              test was conducted.The user should provide the correct details and
              valid receipt to company to get their digital report from our
              service point areas.If the user has not paid or has paid partially
              for the test and the remaining amount is due then the company
              might ask them to pay the remaining fee after which a payment link
              will be send to the user and after successful payment the digital
              report will be uploaded to the Path lab section of the HealthCRAD
              mobile application.
              <br />
              If the user has already paid the Path lab do not need to pay again
              the user will get their report digitally in their HealthCRAD
              account.The company should not be held responsible for any of the
              irregularities or the error present in the Path lab report since
              we are only the medium to deliver the rest results online to the
              user on their request.
              <br />
              The user should always check and confirm the serviceable areas of
              the company before placing the request to Path lab reports.
              <br /> &nbsp; &nbsp;
              <p className="font-poppins text-2xl">
                Cancellation of the request:
              </p>
              <br />
              The user can request the company to cancel the report request when
              company calls back the user to confirm the request.If the
              requested report is from the Path lab which is out of our
              serviceable areas or if the user denies to pay the due amount of
              the Path lab then the company may cancel the request of the user.
              <br /> &nbsp; &nbsp;
              <p className="font-poppins text-2xl">Payments & Refunds:</p>{" "}
              <br />
              If the user has paid on the time of requesting the report and the
              request gets cancelled due to any reason the refund will be
              initiated soon and their account will be credited with the
              eligible refund amount.
              <br /> &nbsp; &nbsp;
              <p className="font-poppins text-2xl">3.3.4.5. Emergency SOS:</p>
              <br />
              BHPL provides the SOS support for its users to be safe in
              emergency situations it allows users to send the SOS message with
              their live location to their near and dear ones. The user agrees
              to allow the access which are required to get help them in any
              emergency no personal data will be stored in our server and we do
              not have access to user’s personal information so it is the user’s
              responsibility to make their emergency support account with
              appropriate details.
              <br />
              BHPL shall not held responsible for any kind of damage occurred to
              the user in any emergency if they have used our platform and were
              unable to get help.
              <br /> &nbsp; &nbsp;
              <p className="font-poppins text-2xl">4. PROHIBITED CONTENT</p>
              <br />
              a. You shall not upload to distribute or otherwise publish through
              the BHPL Platform the following Prohibited Content which includes
              any content information or other material that
              <br />
              b. belongs to another person and which you do not own the rights
              to
              <br /> c. is harmful harassing blasphemous defamatory obscene
              pornographic paedophilic invasive of anothers privacy including
              bodily privacy insulting or harassing based on gender libellous
              racially or ethnically objectionable or otherwise inconsistent
              with or contrary to the laws in force.
              <br />
              d. is hateful racially or ethnically objectionable disparaging of
              any person.
              <br />
              e. relates to or seems to encourage money laundering or gambling.
              <br />
              f. harm minors in any way; infringes any patent trademark
              copyright or other proprietary rights.
              <br />
              g.violates any law in India for the time being in force.
              <br />
              h. deceives or misleads the addressee about the origin of your
              message and intentionally communicates any information which is
              patently false or misleading in nature but may reasonably be
              perceived as a fact.
              <br />
              i. communicates any information which is grossly offensive or
              menacing in nature.
              <br />
              j. impersonates another person
              <br />
              k. threatens the unity, integrity defence security or sovereignty
              of India friendly relations with foreign states or public order.
              <br />
              l. is patently false and untrue, and is written or published in
              any form with the intent to mislead or harass a person entity or
              agency for financial gain or to cause any injury to any person.
              <br />
              m. incites any offence or prevents investigation of any offence or
              insults any other nation.
              <br />
              n. is abusive or inappropriate to the Doctor conducting your
              medical consultation or any employees consultants or technicians
              of BHPL or affiliate who you may interact with for availing
              Services.
              <br />
              o. is not relating to the medical consultation or relating to any
              of the Services you avail from us.You also understand and
              acknowledge that if you fail to adhere to the above we have the
              right to remove such information and or immediately terminate your
              access to the Services and or to the BHPL Platform.
              <br /> &nbsp; &nbsp;
              <p className="font-poppins text-2xl">5. INDEMNITY </p>
              <br />
              a. By using the BHPL Platform and/or the Service you agree to the
              fullest extent permitted by law to indemnify and keep indemnified
              and hold BHPL and its affiliates and its directors officers
              employees affiliates agents contractors and licensors harmless
              with respect to any claims losses costs charges and expenses
              including reasonable attorney fees that the concerned indemnified
              persons may suffer on account of your breach of these Terms.
              <br />
              b. your use of the BHPL Platform and or the Service.
              <br />
              c. incorrect or inaccurate credit debit card details provided by
              you.
              <br />
              d. you using a credit debit card which is not lawfully owned by
              you.
              <br />
              e. you permitting a third party to use your password or other
              means to access your account.
              <br />
              f. your non-compliance with applicable law or regulations in the
              jurisdiction in which you are accessing the BHPL Platform and or
              the Service.
              <br />
              g. you providing a false forged unauthorized or manipulated
              prescription.
              <br />
              h. any action taken by BHPL as part of its investigation of a
              suspected violation of these Terms or as a result of its finding
              or decision that a violation of these Terms has occurred.
              <br /> &nbsp; &nbsp;
              <p className="font-poppins text-2xl">
                6. LIMITATION OF LIABILITY
              </p>
              <br />
              By using our Services you confirm that you understand and agree to
              the following: <br /> a. To the extent permitted by applicable law
              BHPL or its affiliates or any BHPL group companies will not be
              liable to you for any special, indirect, incidental, consequential
              punitive reliance or exemplary damages arising out of or relating
              to.
              <br />
              i. these Terms and Conditions and Privacy Policy
              <br />
              ii. your use or inability to use the BHPL Platform
              <br />
              iii. your use of any third party services you contacted through
              the BHPL Platform.
              <br />
              b. BHPL does not warrant that the BHPL Platform or any of the
              Services or Products available through it will be uninterrupted or
              free from errors. There may be delay omissions interruption and or
              inaccuracies in materials or Service available through the BHPL
              Platform.
              <br />
              c. Although BHPL takes reasonable steps to prevent the
              introduction of viruses worms or other malicious code to the BHPL
              Platform BHPL does not represent or warrant that the BHPL Platform
              or the Service or Products or materials that may be made available
              through the BHPL Platform are free from such destructive features.
              BHPL is not liable for any damages or harm attributable to such
              features or arising directly or indirectly from such features.{" "}
              <br />
              d. To the extent permitted by law BHPL shall not be liable to you
              or to any third party for any direct incidental indirect special
              or consequential losses damages whatsoever including but not
              limited to lost profits revenue contracts anticipated savings
              goodwill or wasted expenditure business interruption loss of
              programs or other data on your information system or any other
              indirect or consequential loss even if BHPL has been advised knew
              or should have known of the possibility of such damages arising
              out of or related to.
              <br />
              i. your use of or reliance on the BHPL Platform any information
              hyperlinks or content contained therein or Services included on or
              otherwise made available to you through the BHPL Platform.
              <br />
              ii. your provision of information personal or otherwise, to BHPL
              <br />
              iii. the provision of Services by BHPL
              <br />
              iv. acts or negligence on the part of BHPL its agents or
              employees.
              <br />
              v. Errors delays or deficiencies in services or theft or damage of
              Products attributable to third parties who assist BHPL such as
              delivery partners.
              <br />
              e. To the fullest extent permitted by law BHPL disclaims and
              excludes all warranties and representations express implied or
              statutory with respect to the BHPL platform or BHPLs services or
              with respect to the accuracy currency or completeness of the
              information provided by BHPL including the implied warranties of
              merchantability or fitness for a particular purpose and
              non-infringement of a patent trademark or other intellectual
              property right. The BHPL platform including without limitation all
              content information and links contained therein, is provided as is
              without any warranty that it will be uninterrupted or error free.
              You expressly agree that your use of this BHPL platform is at your
              sole risk.
              <br />
              f. Notwithstanding anything herein to the contrary BHPLs aggregate
              liability whether in contract tort or otherwise for any loss or
              damage that you may incur on any account whatsoever arising out of
              your use of the BHPL Platform shall be limited to a sum equal to
              the amount paid or payable by you for the Products or Services in
              respect of one incident or series of incidents attributable to the
              same cause.
              <br />
              g. BHPL reserves the right to refuse Service terminate accounts
              remove or edit content or cancel orders anytime at their sole
              discretion. <br />
              h. No claims or action arising out of or related to the use of the
              BHPL Platform or these terms and conditions may be brought by you
              more than one 1 year after the cause of action relating to such
              claim or action arose. <br /> i. We have selected the Products on
              the basis that they will be used for personal use only
              <br />
              If you are planning to use them for business purposes you are
              advised to make sure that you are covered by a proper insurance
              plan. Where you decide to use the Products in the course of a
              business we exclude to the fullest extent permitted by law those
              warranties and conditions relating to fitness for a particular
              purpose.
              <br />
              Our maximum liability to business users arising out of or in
              connection with the Products shall be limited to the replacement
              value of the Product in question. In relation to business users we
              do not accept liability for the fitness of Products for business
              purposes nor do we accept liability for loss of use of the Product
              nor any loss over and above the cost of the Products in the event
              of a claim for breach of warranty or condition. This section shall
              survive the termination of this Agreement and the termination of
              your use of our Services or the BHPL Platform.
              <br /> &nbsp; &nbsp;
              <p className="font-poppins text-2xl">
                7. DATA & INFORMATION POLICY
              </p>
              <br />
              We respect your right to privacy in respect of any personal
              information provided to us for the purposes of availing our
              Services. To see how we collect and use your personal information,
              please see our Privacy Policy.
              <br /> &nbsp; &nbsp;
              <p className="font-poppins text-2xl">
                8. INTELLECTUAL PROPERTY AND OWNERSHIP
              </p>
              <br />
              You recognize and agree that all copyright registered trademarks
              and other intellectual property rights on all materials or
              contents provided as part of the BHPL Platform belong to us at all
              times or to those who grant us the license for their use. You are
              permitted to use this material and content only as expressly
              authorized by our licensors or us. No use of these may be made
              without the prior written authorization of BHPL.
              <br />
              All Product names trade names service names tag lines or logotype
              distinguished in form text or otherwise from surrounding text e.g.
              all capital letters collectively Marks are trademarks owned by or
              licensed to BHPL unless otherwise noted. The Marks on the BHPL
              Platform are variously protected by the laws of India. Use of any
              of these Marks may not be made without the prior written consent
              of BHPL except for the sole purpose of identifying the Products or
              Services originating from BHPL.
              <br />
              You acknowledge and agree that the material and content shown at
              the BHPL Platform is made available for your personal
              non-commercial use only and that you may download such material
              and content only for the purpose of using this BHPL Platform.
              <br />
              You warrant that any such User Content submitted by you to the
              BHPL Platform is original and does not infringe the copyright of
              others and you hereby grant BHPL a perpetual irrevocable
              non-exclusive royalty free license to use such User Content so
              submitted without any further recourse to you and you hereby waive
              all rights in such User Content. BHPL has the right but not the
              obligation to monitor and edit or remove any activity or User
              Content and takes no responsibility and assumes no liability for
              any User Content posted by you or content posted by any third
              party.
              <br /> &nbsp; &nbsp;
              <p className="font-poppins text-2xl">9. OTHER CONDITIONS</p>
              <br />
              Please note that your payments are processed in accordance with
              applicable law and you may refer to our Payments and Refund Policy
              for details. By using our Services you further confirm that you
              understand and agree to the following
              <br />
              a. That you will use the services provided by BHPL its affiliates
              consultants and contracted companies for lawful purposes only and
              comply with all applicable laws and regulations while using the
              BHPL Platform and transacting on the BHPL Platform.
              <br />
              b. You will provide true accurate complete and current information
              in all instances where such information is requested of you. BHPL
              reserves the right to confirm and validate the information and
              other details provided by you at any point of time. If upon
              confirmation your details are found not to be true wholly or
              partly BHPL has the right in its sole discretion to reject the
              registration and debar you from using the Services of BHPL and
              other affiliated websites without prior intimation whatsoever.
              <br />
              c. That you using your best and prudent judgment before entering
              into any transaction through this BHPL Platform doing so at your
              sole risk. BHPL reserves the right to transfer assign or
              sub-contract the benefit of the whole or part of any of its rights
              or obligations under these Terms or any related contract to any
              third party. If any part of these Terms is held by any competent
              authority to be invalid or unenforceable in whole or in part the
              validity or enforceability of the other Terms shall not be
              affected. These Terms do not create or confer any rights or
              benefits enforceable by any person that is not a party. No delay
              or failure by BHPL to exercise any powers rights or remedies under
              these Terms will operate as a waiver of them nor will any single
              or partial exercise of any such powers rights or remedies preclude
              any other or further exercise of them. Any waiver to be effective
              must be in writing and signed by an authorized representative of
              BHPL. These Terms including the documents or other sources
              referred to in these Terms supersede all prior representations
              understandings and agreements between you and BHPL relating to the
              use of the BHPL Platform including the use of Services and order
              of Products and sets forth the entire agreement and understanding
              between you and BHPL for your use of the BHPL Platform
              <br />
              When you visit the BHPL Platform or send e-mail to us you are
              communicating with us electronically. You consent to receive
              communication from us electronically. We may communicate with you
              by e-mail or by posting notices on the BHPL Platform. You agree
              that all agreements notices disclosures and other communication
              that we provide to you electronically satisfy any legal
              requirement that such communication be in writing.
              <br /> &nbsp; &nbsp;
              <p className="font-poppins text-2xl">
                10. THIRD PARTY LINKS AND RESOURCES
              </p>
              <br />
              Where the BHPL Platform contain links to other sites and resources
              provided by third parties including where our social media sharing
              plugins include links to third party sites these links are
              provided for your information only merely as a convenience. We
              have no control over the contents of those websites or resources
              and accept no responsibility for them or for any loss or damage
              that may arise from your use of them. Any links to such linked
              Internet sites should no way be construed as an endorsement
              representation or promotion by BHPL as to the content
              representation accuracy products or services found or otherwise
              described in such linked Internet sites. Use of those linked
              Internet sites links is done at your own risk and cost. You will
              be bound by the privacy policy and terms of use of the respective
              website when you navigate away from the BHPL Platform to any such
              website.
              <br /> &nbsp; &nbsp;
              <p className="font-poppins text-2xl">11. AMENDMENTS</p>
              <br />
              We may from time-to-time update or revise these Terms and
              Conditions. Every time you wish to use the BHPL Platform please
              check the relevant Terms and Conditions and Privacy Policy to
              ensure you understand the terms that apply at that time.
              <br /> &nbsp; &nbsp;
              <p className="font-poppins text-2xl">
                12. EVENTS BEYOND OUR CONTROL
              </p>
              <br />
              We will not be liable for any non-compliance or delay in
              compliance with any of the obligations we assume under any
              contract including any delay or failure to deliver Products when
              caused by events that are beyond our reasonable control Force
              Majeure.
              <br />
              Force Majeure shall include any act event failure to exercise
              omission or accident that is beyond our reasonable control
              including among others the following.
              <br />
              a. Strike lockout or other forms of protest.
              <br />
              b. Civil unrest revolt invasion terrorist attack or terrorist
              threat war declared or not or threat or preparation for war
              <br />
              c. Fire explosion storm flood earthquake collapse epidemic or any
              other natural disaster.
              <br />
              d. Inability to use public or private transportation and
              telecommunication systems
              <br />
              e. Acts decrees legislation regulations or restrictions of any
              government or public authority including any judicial
              determination. Our obligations deriving from any contracts should
              be considered suspended during the period in which Force Majeure
              remains in effect and we will be given an extension of the period
              in which to fulfil these obligations by an amount of time we shall
              communicate to you, not being less than the time that the
              situation of Force Majeure lasted.
              <br /> &nbsp; &nbsp;
              <p className="font-poppins text-2xl">13. TERMINATION</p>
              <br />
              a. We reserve the right to refuse the use of Services immediately
              in case your conduct is deemed by us to be in contravention of
              applicable acts laws rules and regulations or these Terms and
              Conditions.
              <br /> b. For change in law specifically we reserve our rights to
              suspend our obligations under any contract indefinitely or provide
              Services under revised Terms and Conditions.
              <br /> &nbsp; &nbsp;
              <p className="font-poppins text-2xl">
                14. APPLICABLE LEGISLATION AND JURISDICTION
              </p>
              <br />
              The use of our BHPL Platform and the contract between you and BHPL
              shall be governed by the laws applicable in India without regard
              to the conflict of law rules. Any dispute relating to the use of
              our Services will be only resolved exclusively in the Courts at
              Patna India but not in any other Court.
              <br /> &nbsp; &nbsp;
              <p className="font-poppins text-2xl">15. CONTACT US </p>
              <br />
              If you have any comments query or grievances regarding the
              Services Terms and Conditions and Privacy Policy you may contact
              <p className="font-poppins sm:text-xl text-normal text-blue-800">
                mailto:support@healthcrad.com
              </p>
              Please note that upon lodging of a complaint a ticket number shall
              be given to you by which you may track the status of your
              complaint.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsAndCond;
