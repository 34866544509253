import React, { useEffect, useState } from "react";
import Header from "../Header";
import axios from "axios";
import siteCofig from "../../../urlFile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import bg from "../../../images/Light-Blue-.png";
import { useNavigate } from "react-router-dom";
import PageLoader from "../../../Loader/PageLoader";

const YourAccount = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleRoute = (path) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      navigate("/edit-account", { state: { userData: userData } });
    }, 1000);
  };

  const [userData, setUserData] = useState({
    username: "",
    phone: "",
    email: "",
    image: "",
    emergency_mobile_no_1: "",
    emergency_mobile_no_2: "",
    emergency_mobile_no_3: "",
  });

  useEffect(() => {
    if (localStorage.getItem("userId")) {
      handleGetRequest();
    }
  }, []);

  // View Profile GET API
  const handleGetRequest = async () => {
    let reqBody = {
      id: localStorage.getItem("userId"),
    };
    try {
      const response = await axios.post(
        siteCofig.BASE_URL + siteCofig.VIEW_PROFILE,
        reqBody
      );
      const userData = response.data.data;
      console.log(userData, "kkkkkkkkkkkkk");
      setUserData((prev) => ({
        ...prev,
        username: userData.username,
        email: userData.email,
        phone: userData.phone,
        image: userData.image,
        emergency_mobile_no_1: userData.emergency_mobile_no_1,
        emergency_mobile_no_2: userData.emergency_mobile_no_2,
        emergency_mobile_no_3: userData.emergency_mobile_no_3,
      }));
    } catch (error) {}
  };

  const getUserImageUrl = (imageName) =>
    `https://app.healthcrad.com/api/uploads/${imageName}`;

  return (
    <>
      <Header />
      {loading && <PageLoader />}
      <div
        className="mb-10 pb-24 pt-28 px-4 sm:px-0 bg-lightgray bg-cover bg-no-repeat bg-center"
        style={{ backgroundImage: `url(${bg})` }}
      >
        <div className="flex flex-col justify-center items-center gap-3">
          <div className="flex flex-col lg:flex-row w-full md:w-[80%] lg:w-[99%] xl:w-[65%]">
            <p className="font-poppins text-[#FFF] text-2xl font-semibold self-start">
              Your Account
            </p>
          </div>
          <div className="flex flex-col lg:flex-row w-full md:w-[80%] lg:w-[99%] xl:w-[65%] rounded-[20px]">
            <div
              className="flex flex-col justify-center items-center rounded-tl-[10px] lg:rounded-bl-[10px] rounded-tr-[10px] lg:rounded-tr-[0px] w-full lg:w-[35%] p-4 pb-4"
              style={{
                background:
                  "linear-gradient(92deg, #22B6D6 1.22%, #1A2A7E 98.62%)",
              }}
            >
              <div className="relative">
                <div
                  className="flex items-center justify-center rounded-full overflow-hidden w-28 h-28 lg:w-32 lg:h-32 bg-gray-300 relative
                 border-b-[3px] border-[#184978] "
                >
                  {/* <FontAwesomeIcon
                    icon={faUser}
                    className="text-gray-600 w-16 h-16 text-4xl"
                  /> */}
                  <img
                    src={getUserImageUrl(userData.image)}
                    alt=""
                    srcset=""
                    className="w-full h-full"
                  />
                </div>
              </div>
              <input
                type="text"
                className="mt-3 text-xl w-[80%] sm:w-[50%] lg:w-[90%] font-medium font-poppins text-[#FFF] text-center rounded-lg p-2"
                value={userData.username}
                style={{
                  background:
                    "linear-gradient(92deg, #22B6D6 1.22%, #1A2A7E 98.62%)",
                }}
                disabled
              />
            </div>
            <div className="flex flex-col gap-6 w-full lg:w-[65%] border-[1px] lg:rounded-tr-[10px] rounded-br-[10px] rounded-bl-[10px] lg:rounded-bl-[0px] border-[#00000033] bg-white shadow-lg p-4 pb-4">
              <div className="flex flex-col ml-4">
                <h3 className="text-lg text-[#00B4D8] font-semibold font-poppins">
                  User Details
                </h3>
                <div className="w-auto  mt-2 border-[1px] border-[#0000002E]"></div>
              </div>
              <div className="flex flex-col lg:flex-row gap-4 ml-4 mt-1">
                <div className="flex flex-col">
                  <label
                    className="text-[#666161] font-poppins font-bold"
                    htmlFor="name"
                  >
                    Phone
                  </label>
                  <input
                    className="w-full sm:w-[250px] h-[37px] placeholder:text-[18px] pl-[8px] focus:outline-none rounded-md border border-gray-300 mb-2"
                    type="tel"
                    name=""
                    required
                    disabled
                    value={userData.phone}
                  />
                </div>
                <div className="flex flex-col">
                  <label
                    className="text-[#666161] font-poppins font-bold"
                    htmlFor="email"
                  >
                    Email
                  </label>
                  <input
                    className="w-full sm:w-[250px] h-[37px] placeholder:text-[18px] focus:outline-none rounded-md border border-gray-300 pl-[8px] mb-2"
                    type="email"
                    name="email"
                    required
                    disabled
                    value={userData.email}
                  />
                </div>
              </div>
              <div className="flex flex-col ml-4 mt-1">
                <div className="w-full border-[1px] border-[#0000002E]"></div>
                <h3 className="mt-3 text-lg text-[#00B4D8] font-semibold font-poppins">
                  Emergency contact numbers
                </h3>
              </div>
              <div className="grid lg:grid-cols-2 grid-cols-1 gap-4 ml-4">
                <div className="flex flex-col">
                  <label
                    className="text-[#666161] font-poppins font-bold"
                    htmlFor="emergencyNo1"
                  >
                    Emergency No.1
                  </label>
                  <input
                    className="w-full sm:w-[250px] h-[37px] focus:outline-none rounded-md border border-gray-300 pl-[8px]"
                    type="text"
                    id="emergencyNo1"
                    name="emergencyNo1"
                    disabled
                    value={userData.emergency_mobile_no_1}
                  />
                </div>
                <div className="flex flex-col">
                  <label
                    className="text-[#666161] font-poppins font-bold"
                    htmlFor="emergencyNo2"
                  >
                    Emergency No.2
                  </label>
                  <input
                    className="w-full sm:w-[250px] h-[37px] focus:outline-none rounded-md border border-gray-300 pl-[8px]"
                    type="text"
                    id="emergencyNo2"
                    name="emergencyNo2"
                    disabled
                    value={userData.emergency_mobile_no_2}
                  />
                </div>
                <div className="flex flex-col">
                  <label
                    className="text-[#666161] font-poppins font-bold"
                    htmlFor="emergencyNo3"
                  >
                    Emergency No.3
                  </label>
                  <input
                    className="w-full sm:w-[250px] h-[37px] focus:outline-none rounded-md border border-gray-300 pl-[8px]"
                    type="text"
                    id="emergencyNo3"
                    name="emergencyNo3"
                    disabled
                    value={userData.emergency_mobile_no_3}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className=" hidden sm:block justify-end  w-full ">
            <div className="  flex mt-3 w-full justify-end md:w-[80%] lg:w-[90%] xl:w-[80%]">
              <div
                className="  rounded-[10px] flex justify-end self-end"
                style={{
                  background:
                    "linear-gradient(180deg, #1A30A1 0%, #16A9CA 100%)",
                }}
              >
                <button
                  className="w-40 h-10 self-end font-poppins text-[#FFF] text-md font-semibold"
                  onClick={() => handleRoute()}
                >
                  {" "}
                  <FontAwesomeIcon icon={faEdit} className="mr-2" />
                  Edit
                </button>
              </div>
            </div>
          </div>

          {/* mobile view button design start */}
          <div className=" sm:hidden fixed z-50 flex justify-around items-center bg-[#00B4D8]   w-full bottom-0 ">
            <div
              className="select-none rounded-[4px] shadow-md bg-[#00B4D8] w-[170px]  h-11  flex items-center justify-center cursor-pointer"
              onClick={() => handleRoute()}
            >
              <button className="w-40 h-10 self-end font-poppins text-[#FFF] text-md font-semibold">
                {" "}
                <FontAwesomeIcon icon={faEdit} className="mr-2" />
                Edit
              </button>
            </div>
          </div>
          {/* mobile view button design end */}
        </div>
      </div>
    </>
  );
};

export default YourAccount;
