import React from "react";
import Pharmacy from "../../../images/frontend UI materials/pharmacy-interior-design.png";
import MedicalStore from "../../../images/frontend UI materials/medicine_store.jpg";
import Benefits from "../../../images/frontend UI materials/BenefitsImg.png";
import Support from "../../../images/frontend UI materials/support-hand-icon-removebg-preview.png";
import Marketing from "../../../images/frontend UI materials/marketing 1.png";
import DedicateImg from "../../../images/frontend UI materials/Dedicated Digital Outlets.png";
import OnlineOrderImg from "../../../images/frontend UI materials/online Orders.png";
import OrderdiancePointImg from "../../../images/frontend UI materials/Ordinance Point Chain Link.png";
import HighMargin from "../../../images/frontend UI materials/High Margin.png";
import Quality from "../../../images/frontend UI materials/Quality Assurance.png";
import Logo from "../../../images/frontend UI materials/logo.png";
import HealthLogoName from "../../../images/frontend UI materials/HealthCRAD-Name.png";
import { Link } from "react-router-dom";
import Header from "../Header";

const PharmacyOutlets = () => {
  return (
    <>
      <Header />

      <div className="pt-16 relative flex items-center justify-center w-full h-full ">
        <img
          src={Pharmacy}
          alt="pharmacy img"
          className="w-full  sm:h-[400px] "
        />
        <div className="absolute inset-0 flex items-center justify-center text-white text-center font-lato font-semibold">
          <h2 className="text-lg sm:text-5xl mt-[60px] ">
            HealthCRAD Pharmacy Outlets In Bihar
          </h2>
        </div>
      </div>

      <div className="text-center text-[#666161] text-[11px] sm:text-[20px] font-lato font-normal pt-4 pb-4 mx-2">
        <p>
          If you are interested in opening your own pharmacy or medical store.
          Connect with us today. 
        </p>
      </div>
      <div className="flex justify-center text-[#666161] text-[16px] sm:text-[30px] font-poppins font-semibold pb-4">
        <h2>FRANCHISE ENQUIRIES</h2>
      </div>
      <Link to="https://wa.me/message/532QZXEFUYFWK1" target="/_blank">
        <div className="bg-[#00B4D8] w-[150px] sm:w-[230px] border-[1px] border-[#1A30A14D] rounded-[5px] mx-auto p-2 mb-5">
          <h2 className="text-[16px] sm:text-[20px] text-[#fff] font-poppins font-semibold  text-center">
            Enquiry
          </h2>
        </div>
      </Link>

      <div className="underline-offset-8 w-auto border-[1px] color-[#0000002E] center-alignment mt-2 mb-10 xs:mx-2 sm:mx-16"></div>

      <div className="text-center text-[#666161] font-semibold font-poppins text-[13px] sm:text-[24px]  mb-5 mx-2">
        <h2>
          Become <span className="text-[#1A30A1]">Health</span>
          <span className="text-[#10CAF2]">CRAD</span> dedicated Pharmacy outlet
          Partner and increase your sales.
        </h2>
      </div>
      {/* Categories */}
      <div className="flex justify-around mx-2 mb-5">
        <div className="flex justify-center items-center">
          <div className="">
            <h2 className="text-[#000000] font-poppins font-bold text-[16px] sm:text-[24px] ">
              Categories
            </h2>
            <p className="border-b-2 border-[#000] w-[43px] ml-[55px] sm:w-[55px] sm:ml-[85px] mb-3"></p>
            <h3 className="text-[11px]  sm:text-xl">
              Mega Franchise & Mini Franchise
            </h3>
          </div>
        </div>
        <div>
          <img
            src={MedicalStore}
            alt=""
            className="h-[108px] sm:h-[286px] w-[168px] sm:w-[428px] rounded-[10px] "
          />
        </div>
      </div>
      <div className="underline-offset-8 w-auto border-[1px] color-[#0000002E] center-alignment mt-2 mb-4 xs:mx-2 sm:mx-16"></div>
      {/* Benefits */}
      <div className="flex justify-center items-center gap-4  mb-5 mx-2 mt-5 ">
        <div className="flex sm:justify-center items-center">
          <img
            src={Benefits}
            alt=""
            className="h-[90px] sm:h-[200px] w-[137px] sm:w-[528px] mt-6 sm:mt-1 "
          />
        </div>
        <div className=" flex justify-center items-center">
          <div className="">
            <h2 className="text-[#000000] font-poppins font-bold text-[16px] sm:text-[24px] ">
              Benefits
            </h2>
            <p className="border-b-2 border-[#000] w-[43px] ml-[35px] sm:w-[55px] sm:ml-[55px] mb-3"></p>
            <h3 className="text-[11px] sm:text-xl w-[230px] xs:w-[260px] sm:w-[660px] leading-4">
              zero setup & maintenance cost, Dedicated Digital Outlets, Online
              Orders, 10000+ Ordinance Points Chain Link & Support, Genuine and
              Ethical Products at High Margins, 100% Quality Assurance.
            </h3>
          </div>
        </div>
      </div>
      <div className="underline-offset-8 w-auto border-[1px] color-[#0000002E] center-alignment mt-2 mb-4 xs:mx-2 sm:mx-16"></div>

      {/* Support */}
      <div className="flex justify-center items-center mx-2 mb-5 gap-4 ">
        <div className="flex justify-center items-center">
          <div className="">
            <h2 className="text-[#000000] font-poppins font-bold text-[16px] sm:text-[24px] ">
              Hand Holding Support
            </h2>
            <p className="border-b-2 border-[#000] w-[43px] ml-[150px] sm:w-[55px] sm:ml-[225px] mb-3"></p>
            <h3 className="text-[11px]  sm:text-xl w-[230px] xs:w-[250px]  leading-4 sm:w-[500px]">
              Every franchisee will get full support and cooperation from the
              company which will help them in maximizing the reach of their
              business in rural and urban areas.
            </h3>
          </div>
        </div>
        <div>
          <img
            src={Support}
            alt=""
            className="h-[90px] sm:h-[245px]  sm:w-[340px] mt-[20px] sm:mt-[1px] "
          />
        </div>
      </div>
      <div className="underline-offset-8 w-auto border-[1px] color-[#0000002E] center-alignment mt-2 mb-4 xs:mx-2 sm:mx-16"></div>

      {/* Marketing*/}
      <div className="flex justify-center items-center gap-4  mb-5 mx-2 mt-5 ">
        <div className="flex sm:justify-center items-center">
          <img
            src={Marketing}
            alt=""
            className="h-[90px] sm:h-[244px] w-[137px] sm:w-[528px] mt-[32px] sm:mt-[1px] "
          />
        </div>
        <div className="flex justify-center items-center">
          <div className="">
            <h2 className="text-[#000000] font-poppins font-bold text-[16px] sm:text-[24px] mb-1">
              Marketing
            </h2>
            <p className="border-b-2 border-[#000] w-[43px] ml-[50px] sm:w-[55px] sm:ml-[80px] mb-3"></p>
            <h3 className="text-[11px]  sm:text-xl w-[230px] xs:w-[260px] sm:w-[660px] leading-4 ">
              We will provide a complete set of branding and marketing material
              for your offline promotion and ads. Apart from this we will be
              running online campaigns to make people aware of your store.
            </h3>
          </div>
        </div>
      </div>
      <div className="underline-offset-8 w-auto border-[1px] color-[#0000002E] center-alignment mt-2 mb-5 xs:mx-2 sm:mx-16"></div>

      {/* Key Advantage */}
      <div className="mb-5">
        <div className="mb-5 ">
          <h2 className="text-[#10CAF2] text-center font-poppins  font-bold text-[16px] sm:text-[24px]">
            Key Advantages
          </h2>
          {/* <p className="border-b-2 border-[#10CAF2] flex justify-center items-center w-[43px] ml-[35px] sm:w-[55px]  mb-3"></p> */}
          <div className="flex justify-center">
            <div className=" w-[25px] sm:w-[50px]  border-[1.5px] border-[#10CAF2] my-2"></div>
          </div>
        </div>
        <div className=" grid grid-cols-3 xl:grid-cols-5 mx-2  gap-9 font-poppins font-bold text-[11px] sm:text-[20px]">
          <div className="grid place-items-center gap-3">
            <img
              src={DedicateImg}
              alt=""
              className="w-[100px] h-[100px] sm:w-[201px] sm:h-[144px] "
            />
            <h2 className="text-center ">
              Dedicated Digital <br />
              Outlets
            </h2>
          </div>

          <div className="grid place-items-center -mt-[22px]  ">
            <img
              src={OnlineOrderImg}
              alt=""
              className="w-[90px] h-[91px] sm:w-[154px] sm:h-[155px]  "
            />
            <h2 className="text-center">online Orders</h2>
          </div>

          <div className="grid place-items-center sm:mb-[10px] gap-3">
            <img
              src={OrderdiancePointImg}
              alt=""
              className="w-[90px] h-[91px] sm:w-[155px] sm:h-[155px] sm:mb-[10px] "
            />
            <h2 className="text-center">
              Ordinance Point <br /> Chain Link
            </h2>
          </div>

          <div className="grid place-items-center gap-3 ">
            <img
              src={HighMargin}
              alt=""
              className="w-[90px] h-[91px] sm:w-[196px] sm:h-[197px] sm:-mt-[52px]"
            />
            <h2 className="text-center sm:-mt-[52px] ml-[10px] sm:ml-[30px]">
              High Margin
            </h2>
          </div>

          <div className="grid place-items-center gap-2 sm:gap-3 ">
            <img
              src={Quality}
              alt=""
              className=" w-[100px] h-[91px] sm:w-[197px] sm:h-[163px] "
            />
            <h2 className="text-center">
              100% Quality <br />
              Assurance
            </h2>
          </div>
        </div>

        <div className="mt-5">
          <div className="flex items-center justify-center">
            <img
              src={Logo}
              alt=""
              className=" w-[120px] h-[97px] sm:w-[338px] sm:h-[288px] "
            />
          </div>
          <div className="flex items-center justify-center">
            <img
              src={HealthLogoName}
              alt=""
              className="sm:w-[538px] sm:h-[149px] w-[180px] h-[50px] xs:-mt-[28px] sm:-mt-[80px]"
            />
          </div>
          <div className=" text-[11px] sm:text-[20px] font-lato font-light text-center sm:mx-[40px]">
            <p className="mx-2">
              "Explore boundless opportunities with HealthCRAD's franchising
              expansion, reshaping the pharmacy sector nationwide. Join us on
              the journey to establish your pharmacy franchise, where
              comprehensive support and lucrative benefits await. Elevate your
              income potential and play a pivotal role in driving India's rural
              healthcare evolution. Connect with us today and be a force for
              positive change."
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default PharmacyOutlets;
