import React, { useEffect, useState } from "react";
import Header from "../../Header";
import { useNavigate } from "react-router";
import siteCofig from "../../../../urlFile";
import axios from "axios";
import EmptyHistory from "./EmptyHistory";
import { Modal } from "@mui/material";
import CrossIcon from "../../../../images/frontend UI materials/cancel_icon.png";
import PageLoader from "../../../../Loader/PageLoader";

const MyAppointmentHistory = () => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [feedback, setFeedback] = useState("");
  const [appointmentId, setAppointmentId] = useState("");
  const [doctorId, setDoctorId] = useState("");
  const [feedbackSuccess, setFeedbackSuccess] = useState("");
  const [error, setError] = useState("");
  // const handleRoute = (path) => {
  //   navigate(path);
  // };
  // const handleRoute = (path) => {
  //   setLoading(true);
  //   setTimeout(() => {
  //     navigate(path);
  //   }, 1000);
  // }
  const handleRoute = (path, bookDetails) => {
    setLoading(true);
    setTimeout(() => {
      navigate(path, { state: bookDetails });
    }, 1000);
  };
  // Get Api
  const [appointmentDataList, setAppointmentDataList] = useState([]);
  const [appointmentData, setAppointmentData] = useState({
    user_id: localStorage.getItem("userId"),
    name: "",
    uname: "",
    dname: "",
    dprofile: "",
    dimg_url: "",
    start_time: "",
    end_time: "",
    day: "",
    bookdate: "",
    payment: "",
    status: "",
    date_time: "",
    cancel_time: "",
  });

  useEffect(() => {
    if (localStorage.getItem("userId")) {
      handleGetRequest();
    }
  }, []);

  const handleGetRequest = async () => {
    let userId = localStorage.getItem("userId");
    try {
      const response = await axios.get(
        `${siteCofig.BASE_URL}${siteCofig.GET_PAST_APPOINTMENT}${userId}`
      );
      const responseData = response.data;
      if (
        responseData.msg === "success" &&
        Array.isArray(responseData.country) &&
        responseData.country.length > 0
      ) {
        // Update List state with fetched data
        setAppointmentDataList(responseData.country);

        const firstAppointment = responseData.country[0];
        console.log("firstAppointment", firstAppointment); // Check the structure

        setAppointmentData({
          user_id: firstAppointment.user_id,
          name: firstAppointment.name,
          uname: firstAppointment.uname,
          dname: firstAppointment.dname,
          dprofile: firstAppointment.dprofile,
          dimg_url: firstAppointment.dimg_url,
          start_time: firstAppointment.start_time,
          end_time: firstAppointment.end_time,
          day: firstAppointment.day,
          bookdate: firstAppointment.bookdate,
          payment: firstAppointment.payment,
          status: firstAppointment.status,
          date_time: firstAppointment.date_time,
          cancel_time: firstAppointment.cancel_time,
        });

        console.log("Doctor", firstAppointment.dname);
        console.log("status", firstAppointment.status);
      }
    } catch (error) {}
  };

  // Feedback API
  const handleOpen = (id, doctorId) => {
    setOpen(true);
    setAppointmentId(id);
    setDoctorId(doctorId);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit1 = async () => {
    let userId = localStorage.getItem("userId");
    let comment = feedback;
    try {
      const requestData = {
        userid: userId,
        appointment_id: appointmentId,
        comment: comment,
        doterid: doctorId,
      };

      const response = await axios.post(
        `${siteCofig.BASE_URL}${siteCofig.DOCTOR_FEEDBACK}`,
        requestData
      );

      if (response.status === 200) {
        console.log("Feedback submitted successfully");
        setTimeout(() => {
          setFeedbackSuccess(false);
        }, 3000);
        handleClose();
      } else {
        console.error("Feedback submission failed");
      }
    } catch (error) {
      console.error("Error submitting feedback:", error);
    }
  };

  const handleSubmit = () => {
    if (feedback.trim() === "") {
      setError("Please provide your feedback !");
      return;
    }
    console.log("Submitting feedback:", feedback);
    setFeedback("");
    setError(" ");
    console.log("Submitted");
    setFeedbackSuccess(true);
    handleSubmit1();
  };

  const getDoctorImageUrl = (imageName) =>
    `https://app.healthcrad.com/upload/${imageName}`;

  return (
    <>
      <Header />
      {loading && <PageLoader />}
      <div className="flex sm:gap-3 md:gap-3 justify-between lg:mx-8 pt-[80px] font-poppins text-[16px]">
        {feedbackSuccess && (
          <div className="bg-[green]  text-white p-2 absolute top-1/2  left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded">
            <p className="w-52 sm:w-80 text-center font-lato ">
              Feedback sent successfully!
            </p>
          </div>
        )}
        <div
          className="m-1.5 cursor-pointer group bg-[#FFF] rounded-[10px] flex justify-center items-center hover:bg-[#00B4D8] border-[1px] border-[#979797] w-[150px] sm:w-[260px] h-[55px] mb-3"
          onClick={() => handleRoute("/myAppointment")}
        >
          <button className="font-poppins text-[#979797] px-2 py-2 rounded-md  group-hover:text-white">
            Upcoming Appointments
          </button>
        </div>

        <div className="group m-1.5 cursor-pointer bg-[#FFF] rounded-[10px] flex justify-center items-center hover:bg-[#00B4D8] border-[1px] border-[#00B4D8] w-[150px] sm:w-[260px] h-[55px] mb-3">
          <button className="font-poppins text-[#10CAF2] px-2 py-2 rounded-md group-hover:text-white">
            History
          </button>
        </div>
      </div>
      <div className="mb-12">
        {appointmentDataList.length > 0 ? (
          appointmentDataList.map((bookDetails) => (
            <div
              key={bookDetails.id}
              className="m-[6px] sm:m-[10px] md:m-[10px] h-auto w-auto shadow-md border-[1px] border-[rgba(0, 0, 0, 0.20)] sm:p-3 bg-[#FFF] rounded-[10px] lg:mx-16 font-poppins mt-4 "
            >
              <div className="flex justify-between">
                <div className="flex font-poppins sm:gap-3">
                  <img
                    className="sm:h-[80px] sm:w-[80px] h-[55px] w-[55px] mx-[4px] rounded-full mt-[11px]"
                    src={getDoctorImageUrl(bookDetails.dimg_url)}
                    alt=""
                  />

                  <p className="text-[#666161] font-poppins font-normal mt-1">
                    <span className="text-[#000] font-poppins font-semibold text-[11px] xs:text-[13px] sm:text-[13px] md:text-[16px] lg:text-[20px]">
                      Doctor:{" "}
                    </span>
                    <span className="text-[11px] xs:text-[13px] sm:text-[13px] md:text-[16px] lg:text-[20px]">
                      {bookDetails.dname}
                    </span>
                    <span className="text-[#07A50D] font-poppins font-normal text-[9px] xs:text-[11px] sm:text-[13px] md:text-[16px] lg:text-[20px]">
                      ({bookDetails.dprofile})
                    </span>{" "}
                    <br />
                    <span className="text-[#000] font-poppins font-semibold text-[11px] xs:text-[13px] sm:text-[13px] md:text-[16px] lg:text-[20px]">
                      Patient:
                    </span>{" "}
                    <span className="text-[11px] xs:text-[13px] sm:text-[13px] md:text-[16px] lg:text-[20px]">
                      {bookDetails.name}
                    </span>
                    <br />
                    <span className="text-[11px] xs:text-[13px] sm:text-[13px] md:text-[16px] lg:text-[20px]">
                      {" "}
                      {bookDetails.day} | {bookDetails.start_time} to{" "}
                      {bookDetails.end_time} | {bookDetails.bookdate}
                    </span>
                  </p>
                </div>
                <div className="sm:mr-[12px] mt-2 hidden sm:block">
                  <p className="text-[#07A50D]  xl:text-lg sm:text-sm text-[11px] xs:text-[13px] md:text-lg font-poppins font-bold">
                    <span className="text-[#1A30A1] font-bold xl:text-lg sm:text-sm text-[11px] xs:text-[13px] md:text-lg font-poppins">
                      Fees:
                    </span>{" "}
                    Rs. {bookDetails.amount} <br />{" "}
                    <span className="text-[#1A30A1] font-bold xl:text-sm sm:text-sm text-[11px] xs:text-[13px] md:text-sm font-poppins">
                      Payment:
                    </span>
                    <span className="xl:text-sm sm:text-sm text-[11px] xs:text-[13px] md:text-sm">
                      {" "}
                      {bookDetails.payment}
                    </span>
                  </p>
                </div>
              </div>

              <div className="flex flex-row ml-[62px] w-[220px] xs:w-[271px] justify-between sm:p-3  sm:hidden md:hidden lg:hidden xl:hidden mt-1.5">
                <p className="text-[#07A50D] xl:text-lg sm:text-sm text-[11px] xs:text-[13px] md:text-lg font-poppins font-bold">
                  <span className="text-[#1A30A1] font-bold xl:text-lg sm:text-sm text-[11px] xs:text-[13px] md:text-lg font-poppins">
                    Fees:
                  </span>{" "}
                  Rs. {bookDetails.amount}{" "}
                </p>
                <p className="text-[#07A50D] xl:text-lg sm:text-sm text-[11px] xs:text-[13px] md:text-lg font-poppins font-bold">
                  <span className="text-[#1A30A1] font-bold xl:text-sm sm:text-sm text-[11px] xs:text-[13px]  md:text-sm font-poppins sm:ml-2 ml-0">
                    Payment:
                  </span>
                  <span className="xl:text-sm sm:text-sm text-[11px] xs:text-[13px] md:text-sm">
                    {" "}
                    {bookDetails.payment}
                  </span>
                </p>
              </div>

              <div className="hidden sm:block underline-offset-8 lg:w-auto ml-4 -pr-8 mt-[10px] border-[1px] color-[#0000002E] center-alignment"></div>

              <div className="flex flex-row justify-between text-[11px] xs:text-[13px] sm:text-[15px] md:text-[20px] font-normal font-poppins sm:p-3 p-[5px]">
                <button
                  className="m-1.5 text-[#00B4D8] border-[1px] border-[#00B4D8] hover:bg-[#00B4D8] hover:text-[#FFF] rounded-md w-[186px] h-[29px] sm:h-[45px] mb-2"
                  onClick={() =>
                    handleRoute(`/myAppointment/getDetails`, bookDetails)
                  }
                >
                  Get Details
                </button>

                <button
                  className="m-1.5 text-[#20CD27] hover:bg-[#20CD27] hover:text-[#FFF] border-[1px] border-[#20CD27] rounded-md] rounded-md w-[186px] h-[29px] sm:h-[45px] mb-2"
                  onClick={() => (window.location.href = "tel:+91777988828")}
                >
                  Contact
                </button>

                <button
                  className="m-1.5 text-[#FF9432] hover:bg-[#FF9432] hover:text-[#FFF] border-[1px] border-[#FF9432] rounded-md w-[186px] h-[29px] sm:h-[45px] mb-2"
                  // onClick={handleOpen}
                  onClick={() => handleOpen(bookDetails.id, bookDetails.doctor)}
                >
                  Add Feedback
                </button>
              </div>

              <p className="text-[11px] xs:text-[13px] sm:text-[16px] text-[#20CD27] font-poppins font-normal pl-[10px] sm:pl-[20px] pb-2">
                {" "}
                <span className="text-[#4A4141] text-[12px] xs:text-[14px] sm:text-[20px] font-poppins font-semibold">
                  Status:
                </span>{" "}
                <span
                  className={
                    bookDetails.status === "4"
                      ? "text-green-500"
                      : "text-red-500"
                  }
                >
                  {bookDetails.status === "4" ? "Attended" : "Cancelled"}
                </span>
              </p>
            </div>
          ))
        ) : (
          // Render EmptyHistoryPage
          <EmptyHistory />
        )}
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="fixed z-10 inset-0 overflow-y-auto"
      >
        <div className="flex items-center justify-center min-h-screen">
          <div className="bg-white w-full sm:w-[420px] p-2 rounded-lg text-center">
            <div className=" ">
              <div className="select-none flex justify-end items-end p-3">
                <img
                  src={CrossIcon}
                  onClick={handleClose}
                  className="sm:h-8 sm:w-8 h-7 w-7 -mr-3 -mt-2 cursor-pointer"
                  alt=""
                />
              </div>
              <div className="-mt-4 select-none mb-4 flex justify-start items-center">
                <textarea
                  name=""
                  id=""
                  placeholder="Add your valuable feedback.."
                  onChange={(e) => setFeedback(e.target.value)}
                  className=" focus:outline-none placeholder:text-[#666161] font-inter p-3 w-full"
                />
              </div>
              {error && <p className="p-2 text-red-500">{error}</p>}
            </div>
            <div className="flex mb-3 justify-center items-center">
              <div className="select-none flex justify-center items-center w-20 lg:w-24 h-10 bg-[#00B4D8] rounded-[10px]">
                <button
                  className="text-[15px] font-poppins font-bold text-[#FFF]"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default MyAppointmentHistory;
