import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate, useLocation } from "react-router";
import yt from "../../images/frontend UI materials/Social/yt.png";
import twitter from "../../images/frontend UI materials/Social/twitter.png";
import ln from "../../images/frontend UI materials/Social/linkedin.png";
import ins from "../../images/frontend UI materials/Social/insta.png";
import wp from "../../images/frontend UI materials/Social/wp.png";
import fb from "../../images/frontend UI materials/Social/fb.png";
import gp from "../../images/frontend UI materials/Social/gp.png";
import Phonepelogoicon from "../../images/frontend UI materials/Phonepelogoicon.png";
import wpIcon from "../../images/frontend UI materials/Social/wp-icon.png";
import mailicon from "../../images/frontend UI materials/Social/mailicon.png";
import galleryicon from "../../images/gallery_icon.png";
import PageLoader from "../../Loader/PageLoader";

function Footer() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = useLocation();
  // const handleRoute = (path) => {
  //   navigate(path);
  // };
  const handleRoute = (path) => {
    setLoading(true);
    setTimeout(() => {
      navigate(path);
    }, 1000);
  };

  return (
    <>
      <div className="bg-[#ECE7E759] font-lato  p-2.5 ">
        {loading && <PageLoader />}
        <div className=" mb-4 pt-4">
          <h1 className="text-center text-[#9C9898]  text-[20px] sm:text-4xl font-bold sm:font-extrabold">
            To know more about{" "}
            <span className="text-[#1A30A1] font-extrabold">Health</span>
            <span className="text-[#00B4D8] font-extrabold">CRAD</span>
          </h1>
        </div>

        <div className="  grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-3  place-items-center p-1.5">
          {/* div1 */}
          <div className="hidden sm:block flex flex-col gap-4 self-start ">
            <p className="text-xl font-semibold mb-2">Social</p>
            <div className="grid grid-cols-6 place-items-center gap-1">
              <Link
                to="https://www.linkedin.com/company/healthcrad/ "
                target="/_blank"
              >
                <img
                  src={ln}
                  alt=""
                  className=" w-[26px] h-[26px] sm:w-[40px] sm:h-[40px] "
                />
              </Link>
              <Link
                to="https://instagram.com/healthcrad?igshid=MzMyNGUyNmU2YQ%3D%3D"
                target="/_blank"
              >
                <img
                  src={ins}
                  alt=""
                  className=" w-[26px] h-[26px] sm:w-[40px] sm:h-[40px] "
                />
              </Link>{" "}
              <Link
                to="https://www.facebook.com/HealthCRAD?mibextid=9R9pXO "
                target="/_blank"
              >
                <img
                  src={fb}
                  alt=""
                  className=" w-[26px] h-[26px] sm:w-[40px] sm:h-[40px] "
                />
              </Link>{" "}
              <Link to="https://wa.me/message/532QZXEFUYFWK1" target="/_blank">
                <img
                  src={wp}
                  className=" w-[26px] h-[26px] sm:w-[40px] sm:h-[40px] "
                  alt=""
                  to="https://wa.me/message/532QZXEFUYFWK1"
                />
              </Link>
              <Link
                to="https://x.com/Healthcrad?t=nHnkD4_s4V5p4jcjWhtymg&s=09"
                target="/_blank"
              >
                <img
                  src={twitter}
                  alt=""
                  className=" w-[26px] h-[26px] sm:w-[40px] sm:h-[40px] "
                />
              </Link>
              <Link
                to="https://youtube.com/@HealthCRAD?si=TRYTIjxOhTOeJL4N"
                target="/_blank"
              >
                <img
                  src="yt.png"
                  alt=""
                  className=" w-[26px] h-[26px] sm:w-[40px] sm:h-[40px] "
                />
              </Link>
            </div>
            {/* <div className="flex space-x-3">
             
            </div> */}
            <h4 className="text-xl font-semibold  mb-2 mt-2">Legal</h4>
            <p
              className="text-[#00B4D8] cursor-pointer underline  mt-1"
              onClick={() => handleRoute("/terms&condition")}
              style={{ fontStyle: "italic" }}
            >
              Terms and Conditions
            </p>
            <p
              className=" text-[#00B4D8] cursor-pointer underline  mt-2.5"
              onClick={() => handleRoute("/privacy&policy")}
              style={{ fontStyle: "italic" }}
            >
              Privacy Policy
            </p>
          </div>
          <div className=" sm:hidden md:hidden lg:hidden xl:hidden flex flex-col gap-3  ">
            <p className="text-md font-semibold ">Social</p>
            <div className="grid grid-cols-5  gap-1">
              <Link
                to="https://www.linkedin.com/company/healthcrad/ "
                target="/_blank"
              >
                <img src={ln} alt="" className="  w-[30px] h-[30px] " />
              </Link>
              <Link
                to="https://instagram.com/healthcrad?igshid=MzMyNGUyNmU2YQ%3D%3D"
                target="/_blank"
              >
                <img src={ins} alt="" className="  w-[30px] h-[30px] " />
              </Link>{" "}
              <Link
                to="https://www.facebook.com/HealthCRAD?mibextid=9R9pXO "
                target="/_blank"
              >
                <img src={fb} alt="" className="  w-[30px] h-[30px] " />
              </Link>{" "}
              <Link to="https://wa.me/message/532QZXEFUYFWK1" target="/_blank">
                <img
                  src={wp}
                  className="  w-[30px] h-[30px] "
                  alt=""
                  to="https://wa.me/message/532QZXEFUYFWK1"
                />
              </Link>
              <Link
                to="https://x.com/Healthcrad?t=nHnkD4_s4V5p4jcjWhtymg&s=09"
                target="/_blank"
              >
                <img src={twitter} alt="" className="  w-[30px] h-[30px]  " />
              </Link>
              <Link
                to="https://youtube.com/@HealthCRAD?si=TRYTIjxOhTOeJL4N"
                target="/_blank"
              >
                <img src="yt.png" alt="" className=" w-[30px] h-[30px]  " />
              </Link>
            </div>
            <p className="text-md font-semibold">Legal</p>
            <div
              className=" flex flex-col -mt-2"
              style={{ fontStyle: "italic" }}
            >
              <p
                className="text-[#00B4D8] text-[15px] cursor-pointer underline"
                onClick={() => handleRoute("/terms&condition")}
              >
                Terms and Conditions
              </p>
              <p
                className=" text-[15px] text-[#00B4D8] cursor-pointer underline"
                onClick={() => handleRoute("/privacy&policy")}
              >
                Privacy Policy
              </p>
            </div>
            <div className=" -mt-1">
              <h4 className="text-md text-[#000000]">
                <span className=" font-semibold">Download HealthCRAD</span>{" "}
                {/* <span className=" font-semibold">Health</span>
              <span className=" font-semibold">CRAD</span> */}
              </h4>
            </div>
            <div className=" -mt-1">
              {" "}
              <p className="text-[13px] ">
                Manage your health with ease <br /> Download{" "}
                <span className="text-[#1A30A1]">Health</span>
                <span className="text-[#00B4D8]">CRAD</span> today!
              </p>
            </div>
            <Link
              className=" -mt-5"
              to="https://play.google.com/store/apps/details?id=com.foundercode.healthcrad_user"
              target="/_blank"
            >
              <img src={gp} alt="" className="  " />
            </Link>
          </div>
          {/* div2 */}
          <div className=" hidden sm:block flex flex-col gap-3  self-start ">
            <h4 className="text-xl">
              <span className="text-[#000000] font-semibold">Downlod</span>{" "}
              <span className="text-[rgb(26,48,161)] font-semibold">
                Health
              </span>
              <span className="text-[#00B4D8] font-semibold">CRAD</span>
            </h4>
            <p className="text-sm mt-3 mb-3">
              Manage your health with ease <br /> Download{" "}
              <span className="text-[#1A30A1]">Health</span>
              <span className="text-[#00B4D8]">CRAD</span> today!
            </p>
            <p className="text-sm text-[#000000AD] mb-3">
              Ultimate solution to all your <br /> Healthcare & Emergency
              needs.We <br /> are here to help and support you.
            </p>
            <Link
              to="https://play.google.com/store/apps/details?id=com.foundercode.healthcrad_user"
              target="/_blank"
            >
              <img src={gp} alt="" />
            </Link>
          </div>
          {/* div3 */}
          <div className=" flex flex-col gap-3 self-start ">
            <h4 className=" text-md sm:text-xl text-[#000000] font-semibold">
              Contact Us
            </h4>
            <p className=" text-[12px] sm:text-sm text-[#000000]">
              Our customer representative <br />
              team is available <span className="font-bold">24/7</span>.
            </p>
            <Link to="mailto:healthcrad@gmail.com">
              <p className=" text-[13px] xs:text-sm flex text-[#0924B0]">
                {" "}
                <img className="w-5 mr-1" src={mailicon} alt="" />{" "}
                healthcrad@gmail.com
              </p>
            </Link>

            <Link to="https://wa.me/message/532QZXEFUYFWK1" target="/_blank">
              <span className="text-[#0924B0D9] flex text-xl">
                <img className="pr-2" src={wpIcon} alt="" /> 7779888289
              </span>
            </Link>

            {/* <div
              className="justify-center cursor-pointer select-none flex rounded-[10px] items-center w-28 h-10 bg-[#00B4D8]"
              onClick={() => handleRoute("/helpAndSupport")}>
              <button className="text-center font-poppins text-[#FFF] sm:text-xl text-lg">
                Support
              </button>
            </div> */}
            <div
              className=" flex gap-3 justify-start items-center cursor-pointer"
              onClick={() => handleRoute("/gallery")}
            >
              <img
                src={galleryicon}
                alt=""
                srcset=""
                className=" w-10 h-10 sm:w-12 sm:h-12 "
              />
              <p className="text-[#00B4D8] font-lato text-lg sm:text-xl font-bold underline">
                Gallery
              </p>
            </div>
            <h2 className=" text-md sm:text-xl text-[#000000] font-semibold">
              Head Office
            </h2>
            <div className="flex">
              {/* <img src={Phonepelogoicon} alt="" className="h-[40px] w-[40px]" />
              <h2 className="text-[#5F259F] flex items-center text-xl font-semibold">
                PhonePe
              </h2> */}
              <p className="text-[13px] font-poppins text-[#000000] -mt-4 ">First Floor, House No : 236, Sharda Nagar, <br /> Purnea, Bihar - 854301</p>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-[#1A30A1] grid grid-cols-3 place-items-center sm:rounded-tl-[20px] sm:rounded-tr-[20px]  text-[#fff] p-2 sm: ">
        <div className=" flex flex-col">
          <span className="font-[500] text-[8px] sm:text-xl self-center">
            HealthCRAD
          </span>
          <p className="font-[300] text-[8px] sm:text-sm self-center">
            save more serve more
          </p>
        </div>
        <div className=" ">
          <p className="font-[500] text-[8px] sm:text-sm self-center text-center">
            ©2024 HealthCRAD. All Rights Reserved
          </p>
        </div>
        <div className=" flex flex-col ">
          <p className="font-[600] text-[8px] sm:text-sm self-end ">
            Powered By
          </p>
          <p className="font-[300] text-[8px] sm:text-sm self-center">
            Bhavah Healthcare Pvt. Ltd.
          </p>
        </div>
      </div>
    </>
  );
}

export default Footer;
