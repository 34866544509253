import React, { useState, useEffect } from "react";
import Header from "../Header";
import { useLocation } from "react-router-dom";
import PageLoader from "../../../Loader/PageLoader";
import { useNavigate } from "react-router-dom";
import siteConfig from "../../../urlFile";
import axios from "axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import group1 from "../../../images/Medicine_Description/Group_209.png";
import group2 from "../../../images/Medicine_Description/Group_210.png";
import group3 from "../../../images/Medicine_Description/Group_211.png";
import van from "../../../images/Medicine_Description/Vector.png";
import star from "../../../images/Medicine_Description/star.png";
import cashback from "../../../images/Medicine_Description/cashback.png";
import infinite from "../../../images/Medicine_Description/infinite.png";

const ImageModal = ({ images, onClose }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const goToPreviousImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const goToNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <div className="fixed inset-0 z-50 flex justify-center items-center bg-black bg-opacity-75">
      <div className="max-w-full max-h-full overflow-auto">
        <img
          src={images[currentImageIndex]}
          alt="Enlarged"
          className="max-h-screen max-w-screen"
        />
        <button
          onClick={onClose}
          className="absolute top-16 xl:top-2 right-4 xl:right-48 text-black text-6xl font-extrabold cursor-pointer focus:outline-none"
        >
          &times;
        </button>
        <button
          onClick={goToPreviousImage}
          className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-black text-white rounded-full p-2 focus:outline-none"
        >
          &lt;
        </button>
        <button
          onClick={goToNextImage}
          className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-black text-white rounded-full p-2 focus:outline-none"
        >
          &gt;
        </button>
      </div>
    </div>
  );
};

const MedicineDescription = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const medicineDetails = location.state.medicineDetails;
  const [counter, setCounter] = useState(0);
  const [existItem, setExistItem] = useState(false);
  const [sliderIndex, setSliderIndex] = useState(0);

  const openModal = (imageUrl) => {
    setSelectedImage(imageUrl);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (index) => setSliderIndex(index),
  };

  const sliderContainerStyle = {
    maxWidth: "370px", // Adjust this value as needed
    maxHeight: "170px", // Adjust this value as needed
    width: "100%",
  };

  const addToCart = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${siteConfig.BASE_URL}${siteConfig.ADD_TO_CART}`,
        {
          id: localStorage.getItem("userId"),
          productid: medicineDetails.id,
          name: medicineDetails.name,
          discount: medicineDetails.discount,
          price: medicineDetails.discounted_amount,
          discountPrice: medicineDetails.discounted_amount,
          quantity: counter,
        }
      );
      console.log("Item added to cart:", response.data);
      navigate("/cart");
    } catch (error) {
      console.error("Error adding item to cart:", error);
      // Check if the error response contains the specific error message
      if (
        error.response &&
        error.response.data &&
        error.response.data.msg === "Product is already exist"
      ) {
        alert("Product is already in the cart.");
        setExistItem(true); // Set the state to indicate that item exists in the cart
        setTimeout(() => {
          setExistItem(false); // Reset the state after 3 seconds
        }, 3000);
      } else {
        alert(
          "An error occurred while adding the item to the cart. Please try again later."
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const incrementCounter = () => {
    if (counter < 20) {
      setCounter(counter + 1);
    }
  };

  const decrementCounter = () => {
    if (counter > 1) {
      setCounter(counter - 1);
    }
  };

  const getMedicineImageUrl = (imageName) =>
    `https://app.healthcrad.com/api/uploads/medicine/${imageName}`;
  const [currentSlide, setCurrentSlide] = useState(1);
  // var setting = {
  //   infinite: true,
  //   dots: false,
  //   speed: 500,
  //   slidesToShow: 1,
  //   autoplay: true,
  //   speed: 1800,
  //   autoplaySpeed: 1000,
  // };
  const setting = {
    infinite: true,
    dots: false,
    speed: 2000,
    nextDelay: 8000,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    beforeChange: (currentSlide, nextSlide) => {
      setCurrentSlide(nextSlide + 1);
    },
  };

  return (
    <>
      <Header />
      {loading && <PageLoader />}
      <div className="pt-20 pb-16 font-poppins ">
        {/* {existItem && (
          <div className="bg-green-500  text-white p-2 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded">
            <p className="w-52 sm:w-60 text-center">Item is already added in the cart!</p>
          </div>
        )} */}
        <div className="flex justify-center items-center p-2">
          <div className="flex flex-col lg:flex-row p-2 justify-center items-start  lg:gap-16 xl:gap-40">
            {/* 1st */}
            <div className="w-full mb-6 lg:mb-0 flex flex-col justify-center items-center gap-6 lg:gap-10   ">
              <div className="w-full flex justify-center items-center sm:w-[410px] p-4 rounded-[10px] border-[1px] border-[#00000033] bg-[#FFF] shadow-sm relative">
                {medicineDetails.prescription === "1" && (
                  <p className=" right-1  top-1 z-10 font-bold absolute text-[15px] rounded-[5px] w-7 text-center border-[1px] border-[#10CAF2] text-[#10CAF2]">
                    Rx
                  </p>
                )}

                <div className="slider-container">
                  <Slider {...settings}>
                    {medicineDetails.image.map((image, index) => (
                      <img
                        key={index}
                        src={getMedicineImageUrl(image)}
                        alt=""
                        srcSet=""
                        // className="w-full h-full object-cover"
                        className="w-[300px] h-[160px] sm:w-[380px] sm:h-[190px]  cursor-pointer "
                        // onClick={() => openModal(getMedicineImageUrl(medicineDetails.image[0]))}
                        onClick={() =>
                          openModal(
                            getMedicineImageUrl(medicineDetails.image[0])
                          )
                        }
                      />
                    ))}
                    {/* {isModalOpen && <ImageModal imageUrl={selectedImage} onClose={closeModal} />} */}
                  </Slider>
                </div>
                <style jsx>{`
                  @media (max-width: 640px) {
                    .slider-container {
                      max-width: 250px; // Adjust this value for smaller screens
                      max-height: 150px; // Adjust this value for smaller screens
                    }
                  }
                  @media (min-width: 640px) and (max-width: 768px) {
                    .slider-container {
                      max-width: 300px; // Adjust this value for medium screens
                      max-height: 200px; // Adjust this value for medium screens
                    }
                  }
                  @media (min-width: 768px) {
                    .slider-container {
                      max-width: 370px; // Adjust this value for larger screens
                      max-height: 250px; // Adjust this value for larger screens
                    }
                  }
                `}</style>
              </div>
              <div className="w-full sm:w-[390px] p-2 flex flex-row justify-between items-center rounded-[10px] border-[1px] border-[#00000026] bg-[#ffffffc2] shadow-sm ">
                <p>
                  <span className="text-[#4A4141] text-md sm:text-xl font-bold">
                    {medicineDetails.name}
                  </span>
                  <br />
                  <span className="text-[#0000008a] text-md ">Rs.</span>
                  <span className="line-through text-[#0000008a] text-md">
                    {" "}
                    {medicineDetails.price}
                  </span>
                  <span className="text-[#07A50D] text-md sm:text-lg ml-4 font-bold">
                    off {medicineDetails.discount}%
                  </span>
                  <br /> <span className="text-[#000] text-md">Rs.</span>
                  <span className="text-[#000] font-bold sm:text-lg text-md">
                    {" "}
                    {medicineDetails.discounted_amount}
                  </span>
                </p>
              </div>
              <div className="w-full sm:w-[390px] flex flex-row justify-between items-center rounded-[10px] border-[1px] border-[#00000033] bg-[#FFF] text-[#4A4141] sm:text-lg text-md p-3 shadow-sm">
                <p>Quantity: {counter}</p>
                <p>Price: Rs. {medicineDetails.discounted_amount * counter}</p>
              </div>
              <div
                className={`${
                  counter === 0 ? "bg-[#10CAF2]" : "bg-[#FFF]"
                } cursor-pointer w-full sm:w-[390px] bg-[#10CAF2] rounded-[10px] flex justify-center items-center h-12 shadow-sm`}
              >
                <div
                  className={`w-full ${
                    counter === 0 ? "bg-[#10CAF2]" : ""
                  } h-12  rounded-[6px] cursor-pointer flex justify-center items-center`}
                >
                  {counter > 0 && (
                    <button
                      className="bg-[#e0f2f6] font-bold h-[25px] w-[25px] sm:w-[34px] sm:h-[35px] text-[19px] sm:text-[24px] rounded-3xl text-[#869194]"
                      onClick={decrementCounter}
                    >
                      -
                    </button>
                  )}
                  {counter > 0 && (
                    <button
                      className="w-[40px] h-[16px] -mt-2 sm:-mt-3 text-[#000] font-bold text-xl"
                      style={{ fontFamily: "revert", fontSize: "16px" }}
                    >
                      {counter}
                    </button>
                  )}
                  {counter === 0 && (
                    <button
                      className="text-md sm:text-lg text-white font-bold"
                      //  onClick={handleOnClick}
                      onClick={incrementCounter}
                    >
                      Add to cart
                    </button>
                  )}
                  {counter > 0 && (
                    <button
                      className="bg-[#e0f2f6] h-[25px] w-[25px] sm:w-[34px] sm:h-[35px] text-[19px] sm:text-[24px] font-bold ml-[9px] rounded-3xl text-[#869194]"
                      onClick={incrementCounter}
                      disabled={counter >= 20}
                    >
                      +
                    </button>
                  )}
                </div>
              </div>
              {counter > 0 && (
                <div className=" hidden sm:block ">
                  <div
                    className="cursor-pointer w-full sm:w-[390px] bg-[#10CAF2] rounded-[10px] flex justify-center items-center h-12"
                    style={{ boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.15)" }}
                    // onClick={() => navigate("/cart")}
                    onClick={addToCart}
                  >
                    <button
                      type="button"
                      className="font-medium  text-[#FFF] sm:text-xl text-lg"
                      // onClick={addToCart}
                    >
                      Go to cart
                    </button>
                  </div>
                </div>
              )}
              <div className="w-full sm:w-[400px] flex flex-row justify-between items-center rounded-[10px] border-[1px] border-[#00000033] bg-[#FFF]  p-2 shadow-sm">
                <div className="flex justify-between items-center w-full ">
                  <div className="flex flex-col justify-center items-center w-full gap-1.5">
                    <img
                      src={group1}
                      alt=""
                      srcset=""
                      className=" h-10 w-10 "
                    />
                    <p className="text-[#000000] text-[8px] sm:text-[10px] font-semibold text-center w-full sm:w-36 flex justify-center items-center">
                      10K+ Happy Customers
                    </p>
                  </div>
                  <div className="flex flex-col justify-center items-center w-full gap-1.5">
                    <img
                      src={group2}
                      alt=""
                      srcset=""
                      className=" h-10 w-14 "
                    />
                    <p className="text-[#000000] text-[8px] sm:text-[10px]  font-semibold ">
                      100% Authentic
                    </p>
                  </div>
                  <div className="flex flex-col justify-center items-center w-full gap-1.5">
                    <img
                      src={group3}
                      alt=""
                      srcset=""
                      className=" h-10 w-12 "
                    />
                    <p className="text-[#000000] text-[8px] sm:text-[10px] font-semibold text-center w-full sm:w-36 flex justify-center items-center ">
                      Quality Checked Products
                    </p>
                  </div>
                </div>
              </div>

              <div className="w-full sm:w-[400px] flex flex-col justify-between items-center rounded-[10px] border-[1px] border-[#FF006E] bg-[#FFF]  shadow-sm relative">
                <p className=" absolute top-1.5 text-[11px] sm:text-[13px] font-medium font-lato text-[#000000] right-2.5 ">
                  {currentSlide}/3
                </p>
                <div className="w-full slider-container2 ">
                  <Slider {...setting}>
                    <div className="flex">
                      {/* 1st Slide */}
                      <div className="flex flex-row justify-center items-center w-full p-2.5 gap-4">
                        <img
                          src={van}
                          alt=""
                          className="h-9 w-9 sm:h-12 sm:w-12"
                        />
                        <div className="flex flex-col gap-1">
                          <p className="text-[#000000] text-[12px] sm:text-[17px] font-bold text-center self-start">
                            Superfast Delivery
                          </p>
                          <p className="text-[#666161] text-[10px] sm:text-[13px] text-center font-medium">
                            High priority delivery for all orders
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="flex">
                      {/* 2nd Slide */}
                      <div className="flex justify-center items-center w-full p-2.5 gap-4">
                        <img
                          src={cashback}
                          alt=""
                          className=" h-9 w-9 sm:h-12 sm:w-12"
                        />
                        <div className="flex flex-col gap-1">
                          <p className="text-[#000000] text-[12px] sm:text-[17px] font-bold text-center self-start">
                            Unlimited Free Delivery
                          </p>
                          <p className="text-[#666161] text-[10px] sm:text-[13px] self-start text-center font-medium">
                            Free delivery on orders above Rs. 1099
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="flex">
                      {/* 3rd Slide */}
                      <div className="flex justify-center items-center w-full p-2.5 gap-2">
                        <img
                          src={infinite}
                          alt=""
                          className="h-12 w-12 sm:h-16 sm:w-14"
                        />
                        <div className="flex flex-col gap-1">
                          <p className="text-[#000000] text-[12px] sm:text-[17px] font-bold text-center self-start">
                            Additional Cashback, Rewards...
                          </p>
                          <p className="text-[#666161] text-[9px] sm:text-[13px] text-center self-start font-medium">
                            HealthCRAD exclusive Help 2 Earn initiative
                          </p>
                        </div>
                      </div>
                    </div>
                  </Slider>
                </div>
                <style jsx>{`
                  @media (max-width: 640px) {
                    .slider-container2 {
                      max-width: 270px; // Adjust this value for smaller screens
                      max-height: 150px;
                    }
                  }
                `}</style>
                <div className="flex bg-[#FF006E] p-2  w-full rounded-bl-[10px] rounded-br-[10px] justify-center items-center gap-2 ">
                  <img src={star} alt="" srcset="" />
                  <p className="text-[#FFF] text-[12px] sm:text-[14px] font-medium self-center">
                    Our priority is safe & secure delivery
                  </p>
                </div>
              </div>
            </div>
            <div className=" flex flex-col gap-3">
              <div
                className="pt-1 pb-1  flex flex-col justify-center items-center gap-3 p-2 rounded-[10px] bg-[#FFF] border-[1px] border-[#00000026]"
                style={{ boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.15)" }}
              >
                <p className="self-center text-[#000] font-bold text-md sm:text-xl">
                  Description
                </p>
                <div className="w-full sm:w-[650px] md:w-[500px] xl:w-[700px] flex flex-col justify-center gap-3.5 pl-2">
                  <p className="self-start text-[17px] text-[#10CAF2] font-bold underline">
                    Product Introduction
                  </p>
                  {medicineDetails.discription
                    .split("\r\n\r\n")
                    .map((segment, index) => {
                      const [topic, ...contentLines] = segment.split("\r\n");
                      const content = contentLines
                        .join("\r\n")
                        .split("*")
                        .map((line, idx) => (
                          <React.Fragment key={idx}>
                            {idx > 0 && "*"}{" "}
                            {/* Include '*' character except for the first line */}
                            {line}
                            <br />
                          </React.Fragment>
                        ));
                      return (
                        <React.Fragment key={index}>
                          <p className="text-[#000] sm:text-lg text-md">
                            {index === 0 ? (
                              <span className="text-[#666161] sm:text-md text-sm">
                                {topic}
                              </span>
                            ) : (
                              <span className="text-[#666161] sm:text-md text-sm">
                                {topic}
                              </span>
                            )}
                            <span className="text-[#666161] sm:text-md text-sm">
                              {content}
                            </span>
                          </p>
                        </React.Fragment>
                      );
                    })}
                </div>
              </div>
              <div
                className="pt-1 pb-1  w-full sm:w-[665px] md:w-[515px] xl:w-[715px] flex flex-col justify-center items-center gap-2 p-3 rounded-[10px] bg-[#FFF] border-[1px] border-[#00000026]"
                style={{ boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.15)" }}
              >
                <p className="text-[#10CAF2] sm:text-md text-sm  self-start mt-2 font-semibold font-lato ">
                  DISCLAIMER
                </p>
                <p
                  className="text-[#979797] sm:text-md text-sm pb-1  "
                  style={{ lineHeight: "1.8" }}
                >
                  The contents of this website are for informational purposes
                  only and not intended to be a substitute for professional
                  medical advice, diagnosis, or treatment. Please seek the
                  advice of a physician or other qualified health provider with
                  any questions you may have regarding a medical condition. Do
                  not disregard professional medical advice or delay in seeking
                  it because of something you have read on this website.
                </p>
              </div>
            </div>
          </div>
          <div className="sm:hidden fixed z-50 flex justify-around items-center bg-[#FAF1FF] w-full bottom-0">
            {counter > 0 && (
              <div className="flex flex-col pt-2.5">
                <p className="text-[16px] font-lato text-[#000000]">
                  Total price:
                </p>
                <p className="text-[20px] font-lato font-semibold text-[#000000]">
                  Rs.{medicineDetails.discounted_amount * counter}
                </p>
              </div>
            )}
            {counter > 0 && (
              <div
                className="select-none rounded-[4px] border-[1px] border-[#10CAF2] shadow-md bg-[#00B4D8] w-[170px] h-11 flex items-center justify-center cursor-pointer"
                //  onClick={() => navigate("/cart")}
                onClick={addToCart}
              >
                <button className="text-white font-poppins">Go to cart</button>
              </div>
            )}
          </div>
        </div>
      </div>
      {isModalOpen && (
        <ImageModal
          images={medicineDetails.image.map(getMedicineImageUrl)}
          onClose={closeModal}
        />
      )}
    </>
  );
};

export default MedicineDescription;
