import React, { useEffect, useState, useRef } from "react";
import left from "../images/herosection/left_pictures.png";
import SearchIcon from "../images/NavbarProfileIcon/search.png";
import Typed from "typed.js";
import axios from "axios";
import siteConfig from "../urlFile";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

const HeroSectionSmall = () => {
  const [typedInstance, setTypedInstance] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const navigate = useNavigate();
  const [isSearchFocused, setIsSearchFocused] = useState(false);

  useEffect(() => {
    if (searchTerm === "") {
      setSuggestions([]);
      setShowSuggestions(false);
      return;
    }

    const fetchSuggestions = async () => {
      try {
        const responseMedicine = await axios.get(
          `${siteConfig.BASE_URL}${siteConfig.GET_ALL_MEDICINE}`
        );
        const medicines = responseMedicine.data.data.map(
          (medicine) => medicine.name
        );

        const responseDoctor = await axios.get(
          `${siteConfig.BASE_URL}${siteConfig.GET_DOCTOR}`
        );
        const doctors = responseDoctor.data.data.map((doctor) => doctor.dname);
        const doctorsAdd = responseDoctor.data.data.map(
          (doctor) => doctor.city
        );
        const doctorsdept = responseDoctor.data.data.map((doctor) => {
          const departments = doctor.department.split(",");
          const department = departments.slice(1).join(",").trim();
          return department;
        });

        const allSuggestions = [
          ...medicines,
          ...doctors,
          ...doctorsAdd,
          ...doctorsdept,
        ];
        const filteredSuggestions = allSuggestions
          .filter((suggestion) =>
            suggestion.toLowerCase().includes(searchTerm.toLowerCase())
          )
          .map((suggestion) => suggestion.split(" ").slice(0, 3).join(" "))
          .slice(0, 10); // Limit suggestions to a maximum of 10 and show only first two words

        setSuggestions(filteredSuggestions);
        setShowSuggestions(filteredSuggestions.length > 0);
      } catch (error) {
        console.error("Error fetching suggestions:", error);
      }
    };

    fetchSuggestions();
  }, [searchTerm]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
  };

  const handleSuggestionClick = (suggestion) => {
    setSearchTerm(suggestion);
    setShowSuggestions(false);
    // navigate(`/allitems?search=${encodeURIComponent(suggestion)}`);
    if (searchTerm.trim() !== "") {
      navigate(`/allitems?search=${encodeURIComponent(searchTerm)}`);
    }
  };

  useEffect(() => {
    const placeholderTyped = new Typed("#searchInput", {
      strings: [
        "Medicine Search",
        "Doctor Search",
        "Medical condition research",
        "Healthcare provider discovery",
        "Prescription medication details",
      ],
      typeSpeed: 100,
      backSpeed: 100,
      backDelay: 1000,
      loop: true,
      onStringTyped: function (arrayPos, self) {
        if (arrayPos === self.strings.length - 1) {
          setTimeout(function () {
            self.reset();
          }, 2000);
        }
      },
    });

    setTypedInstance(placeholderTyped);

    return () => {
      placeholderTyped.destroy();
    };
  }, []);

  const onFocusSearchBox = () => {
    if (typedInstance) {
      typedInstance.destroy();
    }
    setSearchTerm("");
    setIsSearchFocused(true);
  };

  const onBlurSearchBox = () => {
    if (searchTerm.trim() === "") {
      // Restart the placeholder animation if the search term is empty
      const placeholderTyped = new Typed("#searchInput", {
        strings: [
          "Medicine Search",
          "Doctor Search",
          "Medical condition research",
          "Healthcare provider discovery",
          "Prescription medication details",
        ],
        typeSpeed: 100,
        backSpeed: 100,
        backDelay: 1000,
        loop: true,
        onStringTyped: function (arrayPos, self) {
          if (arrayPos === self.strings.length - 1) {
            setTimeout(function () {
              self.reset();
            }, 2000);
          }
        },
      });
      setTypedInstance(placeholderTyped);
    } else {
      const placeholderText = document.getElementById("placeholderTyped");
      if (placeholderText) {
        placeholderText.style.visibility = "hidden";
      }
    }
    setIsSearchFocused(false);
  };

  return (
    <>
      <div className=" pt-14 ">
        <div className="overflow-hidden  bg-[#77129A] mt-2 flex gap-5 justify-start items-center">
          <span
            className=" text-[#FFF]   flex  p-2.5   sm:gap-3 "
            style={{ animation: "marquee 25s linear infinite" }}
          >
            <li className="text-[14px] font-lato sm:text-[16px] w-52 ">
              Welcome to HealthCRAD
            </li>
            <li className="text-[14px] font-lato sm:text-[16px] w-[310px]">
              Hassel free doctor appointment booking
            </li>
            <li className="text-[14px] font-lato sm:text-[16px] w-64">
              Ease/Instant ambulance booking
            </li>
            <li className="text-[14px] font-lato sm:text-[16px] w-36">
              Affordable price
            </li>
            <li className="text-[14px] font-lato sm:text-[16px] w-52">
              Quick medicine delivery
            </li>
            <li className="text-[14px] font-lato sm:text-[16px] w-32">
              24/7 Support
            </li>
            <li className="text-[14px] font-lato sm:text-[16px] w-56">
              Currently COD available
            </li>
            <li className="text-[14px] font-lato sm:text-[16px] w-56">
              We wish you a good health
            </li>
          </span>
          <style>
            {`
                @keyframes marquee {
                    0% { transform: translateX(40%); }
                    100% { transform: translateX(-100%); }
                }
                `}
          </style>
        </div>
        {/* search Box    */}
        <div className="relative flex justify-center items-start h-[18vh] sm:h-[26vh] w-full bg-gradient-to-b from-[#FDFDFF] via-transparent to-[#C9BCFF] overflow-hidden ">
          <img
            src={left}
            className="absolute top-1/2 left-0 transform -translate-x-[25px] -translate-y-1/2 w-28 h-28 sm:w-44 sm:h-44"
            alt="Left Image"
          />
          <div className="relative flex mt-[30px] z-10 justify-center border-[1px] border-[#e7dfdf] rounded-[5px] items-center ">
            <input
              id="searchInput"
              className="pl-4 pr-10 shadow-lg w-[304px] xs:w-[370px] h-[44px]  md:w-[620px] rounded-lg  text-[#00000087] focus:outline-none"
              type="text"
              onFocus={onFocusSearchBox}
              onBlur={onBlurSearchBox}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />

            <span className="absolute right-3 top-0 sm:top-0 h-full flex items-center ">
              <img src={SearchIcon} alt="Search Icon" className="h-5 w-5  " />
            </span>
          </div>
          <img
            src={left}
            className="absolute top-1/2 right-0 transform translate-x-[25px] -translate-y-1/2 scale-x-[-1] w-28 h-28 sm:w-44 sm:h-44"
            alt="Right Image"
          />
        </div>
        <div className=" flex justify-center">
          {searchTerm && showSuggestions && (
            <div className="absolute  top-[177px] sm:top-[182px] z-50 bg-white border border-gray-300 rounded p-2   w-[304px] xs:w-[370px] md:w-[620px] ">
              {suggestions.map((suggestion, index) => (
                <div
                  key={index}
                  className="py-2 px-4 cursor-pointer hover:bg-gray-100"
                  onClick={() => handleSuggestionClick(suggestion)}
                >
                  <div className="flex gap-4 items-center">
                    <FontAwesomeIcon icon={faSearch} className="h-5 w-5" />
                    <p className="overflow-hidden line-clamp-1 font-lato font-medium ">
                      {suggestion}
                    </p>
                  </div>
                  {/* <hr className="ml-10" /> */}
                </div>
              ))}
            </div>
          )}
          {!showSuggestions && searchTerm && (
            <div className="absolute top-[177px] sm:top-[182px] w-[304px] xs:w-[370px] md:w-[620px]  z-10 bg-white border border-gray-300 rounded items-center p-2  flex justify-center ">
              <p className="text-[#FF0000]  text-center font-lato xs:text-[13px] sm:text-[16px] font-medium ">
                Your Product is not listed, kindly{" "}
                <span className=" text-[#0924B0D9] cursor-pointer">
                  {" "}
                  <a
                    className="text-[#0924B0D9] underline"
                    href="tel:+917779888289"
                  >
                    Call us
                  </a>
                </span>{" "}
                or
                <span className=" text-[#3db53d] cursor-pointer mr-1">
                  {" "}
                  <a
                    className="text-[#3db53d] underline "
                    target="_blank"
                    href="https://api.whatsapp.com/message/532QZXEFUYFWK1?autoload=1&app_absent=0"
                  >
                    Whatsapp
                  </a>
                </span>
                directly.
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default HeroSectionSmall;
