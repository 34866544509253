// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.swal2-popup .swal2-confirm.custom-ok-button-class {
  background-color: #10caf2 !important;
  color: #ffffff !important;
  border-color: transparent !important;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/HomePage/RequestCall.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,yBAAyB;EACzB,oCAAoC;AACtC","sourcesContent":[".swal2-popup .swal2-confirm.custom-ok-button-class {\n  background-color: #10caf2 !important;\n  color: #ffffff !important;\n  border-color: transparent !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
