import React from "react";
import Doctor from "../../../images/frontend UI materials/merchant_doctor.jpg";
import { Link } from "react-router-dom";
import Header from "../Header";

const DoctorsSection = () => {
  return (
    <>
      <Header />
      <div className="w-full font-poppins my-10 lg:px-[52px] sm:p-3  mb-4">
        {/* Parent Container */}
        <div className="flex   flex-col-reverse xl:flex-row  xl:justify-around pt-10">
          {/* Heading Buttom and Paragraph */}
          <div>
            {/* Heading */}
            <div className="p-3 sm:pb-[10px] pb-2">
              <h1 className="text-[#000] sm:text-3xl text-[16px] font-medium flex justify-center xl:justify-start ">
                <span className="text-blue-900">Health</span>{" "}
                <span className="text-[#00B4D8]">CRAD</span>{" "}
                <span className="text-[#EE8829] pl-[4px]"> Doctors</span>
              </h1>
            </div>
            {/* Paragraph */}
            <div>
              <p className="m-3 sm:m-0 lg:text-xl md:text-[18px] sm:text-[16px] text-[11px] xs:text-[13px] lg:w-[799px] font-normal text-[#666161] mt-3 mb-2 text-justify ">
                <span className="text-[#4A4141] font-semibold font-poppins">
                  DOCTORS{" "}
                </span>
                being the forefront stakeholders of the healthcare ecosystem,
                HealthCRAD is pleased to offer you an opportunity to be a part
                of India's FIRST rural centric Healthcare company with
                <span className="text-[#19C915] font-semibold font-poppins">
                  {" "}
                  10,000+
                </span>{" "}
                Ordinance Points chain in entire Bihar. <br /> <br />
                We truly need your partnership and support in creating{" "}
                <span className="text-[#000000] font-semibold font-poppins">
                  RURAL HEALTHCARE REVOLUTION.{" "}
                </span>{" "}
                <br /> <br />
                <div className="text-[#000000] text-[16px] sm:text-[24px] font-semibold font-poppins text-center xs:mb-4 sm:mb-5">
                  Key service Offerings are:
                </div>
                <div className="xs:leading-[30px] sm:leading-10">
                  <ul className="list-decimal ml-5">
                    <li>Dedicated mobile application/website for Doctors</li>
                    <li>Zero Setup & Maintenance fee</li>
                    <li>No hidden charges</li>
                    <li>
                      Comprehensive platform for daily appointments management
                    </li>
                    <li>
                      Mark your digital presence to HealthCRAD’s mass user base
                    </li>
                    <li>Increase your reach to Rural Patients</li>
                    <li>
                      Pay us commission only on successful appointment through
                      us
                    </li>
                    <li>
                      <span className="font-semibold text-[#FE0505]">24x7</span>{" "}
                      Support
                    </li>
                    <li>Quick & Easy Onboarding process</li>
                    <li>
                      Get patients directly from our Ordinance Points fleet
                    </li>
                  </ul>
                </div>
              </p>
            </div>
            {/* Button  */}
            <div className=" p-3 flex w-3/6 gap-[60px] sm:gap-[90px] text-center mt-10 mb-5 ">
              <div className="hidden sm:block">
                <Link
                  to="https://play.google.com/store/apps/details?id=com.foundercode.health_crad_driver"
                  target="_blank"
                >
                  <button className="bg-[#00B4D8F0] rounded-[5px] sm:rounded-lg font-medium  text-white shadow-md lg:h-[40px] lg:w-[200px] md:h-[40px] md:w-[200px] sm:h-[40px] sm:w-[200px] h-[40px] w-[145px] lg:text-[16px] md:text-[16px] sm:text-[16px] text-[13px]">
                    Download & Register
                  </button>
                </Link>
              </div>
              <div className="hidden sm:block">
                <Link
                  to="https://youtube.com/@HealthCRAD?si=HaCTDpC-vg-KdR8P"
                  target="_blank"
                >
                  <button
                    className="bg-[#00B4D8F0] rounded-[5px] sm:rounded-lg font-medium text-white shadow-[0px_4px_4px_0px_rgba(0,0,0,0.20)] 
                     lg:h-[40px] lg:w-[200px] md:h-[40px] md:w-[200px] sm:h-[40px] sm:w-[200px] h-[40px] w-[147px] lg:text-[16px] md:text-[16px] sm:text-[16px] text-[13px]"
                  >
                    More Information
                  </button>
                </Link>
              </div>
            </div>
          </div>
          {/* image  */}
          <div className="sm:mt-20">
            <img
              src={Doctor}
              alt=""
              className=" w-full sm:w-[280px] h-[300px] sm:rounded-md "
            />
          </div>
        </div>
      </div>

      {/* mobile view button design start */}
      <div className="sm:hidden fixed z-10 flex justify-around  items-center bg-gray-100 h-[60px] py-1  w-full bottom-0 ">
        <div className=" sm:hidden p-1">
          <Link
            to="https://play.google.com/store/apps/details?id=com.foundercode.health_crad_driver"
            target="_blank"
          >
            <button className="bg-[#00B4D8F0] rounded-[5px] sm:rounded-lg font-medium  text-white shadow-md lg:h-[40px] lg:w-[200px] md:h-[40px] md:w-[200px] sm:h-[40px] sm:w-[200px] h-[40px] w-[145px] lg:text-[16px] md:text-[16px] sm:text-[16px] text-[13px]">
              Download & Register
            </button>
          </Link>
        </div>
        <div className="sm:hidden p-1">
          <Link
            to="https://youtube.com/@HealthCRAD?si=HaCTDpC-vg-KdR8P"
            target="_blank"
          >
            <button
              className="bg-[#00B4D8F0] rounded-[5px] sm:rounded-lg font-medium text-white shadow-[0px_4px_4px_0px_rgba(0,0,0,0.20)] 
                     lg:h-[40px] lg:w-[200px] md:h-[40px] md:w-[200px] sm:h-[40px] sm:w-[200px] h-[40px] w-[147px] lg:text-[16px] md:text-[16px] sm:text-[16px] text-[13px]"
            >
              More Information
            </button>
          </Link>
        </div>
      </div>
      {/* mobile view button design end */}
    </>
  );
};

export default DoctorsSection;
