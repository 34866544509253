import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import seach from "../../images/herosection/seach.png";
import man from "../../images/herosection/Docto-hero-section.png";
import med from "../../images/herosection/med.png";
import ui from "../../images/herosection/ui.png";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import siteCofig from "../../urlFile";
import { Link } from "react-router-dom";
import PhoneIcon from "./../../images/herosection/faPhoneVolume.svg";
import PageLoader from "../../Loader/PageLoader";

const PreviousBtn = (props) => {
  const { className, onClick } = props;
  return (
    <div className="hidden xl:block">
      <div className={` ${className} `} onClick={onClick}>
        <ArrowBackIosNewIcon className="text-[blue] text-3xl hidden " />
      </div>
    </div>
  );
};

const NextBtn = (props) => {
  const { className, onClick } = props;
  return (
    <div className="hidden xl:block">
      <div className={` ${className} mx-1 hidden xl:block`} onClick={onClick}>
        <ArrowForwardIosIcon className="text-[blue] text-3xl hidden xl:block" />
      </div>
    </div>
  );
};

const HeroSection = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleRoute = (path) => {
    setLoading(true);
    setTimeout(() => {
      navigate(path);
    }, 1000);
  };

  var settings = {
    infinite: true,
    dots: true,
    autoplay: true,
    autoplaySpeed: 4000, // Time between slides
    speed: 500, // Transition speed
    backDelay: 10000, // Time to wait before transitioning to the next slide
    slidesToShow: 2,
    slidesToScroll: 1,
    prevArrow: <PreviousBtn />,
    nextArrow: <NextBtn />,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [images, setImages] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          siteCofig.BASE_URL + siteCofig.GET_BANNER
        );
        console.log(response.data);
        setImages(response.data?.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  const burl = "https://app.healthcrad.com/api/uploads/advertisement/";

  return (
    <>
      <div className="pt-[1px]  bg-[#ADE8F452]  ">
        {loading && <PageLoader />}
        <div className="relative  h-auto pb-[65px] bg-no-repeat">
          {/* slider part */}
          <div className="mt-3 sm:mb-24">
            <div className="w-full xl:w-[93%] mx-auto">
              <Slider {...settings}>
                {images.map((image, index) => (
                  <div key={index}>
                    <img
                      src={`${burl}${image.image}`}
                      alt={`Image ${index}`}
                      className={`h-[165px] w-full sm:h-[220px] sm:w-[550px] sm:rounded-[10px] sm:shadow-[0px_4px_22.3px_0px_rgba(0,0,0,0.12)] ${
                        image.url ? "cursor-pointer" : ""
                      }`}
                      onClick={() => {
                        if (image.url && image.url.trim() !== "") {
                          window.open(image.url.trim(), "_blank");
                        }
                      }}
                    />
                  </div>
                ))}
              </Slider>
            </div>
          </div>

          {/* image upload part */}
          <div className="flex justify-evenly  lg:mx-12 md:mx-12 sm:mx-12 mx-6  mt-12 flex-wrap gap-4">
            <div className="bg-[#90E0EF] lg:w-[550px] lg:h-[150px] md:w-[550px] md:h-[150px] sm:w-[550px] sm:h-[150px] w-[400px] h-[100px] flex justify-between rounded-[10px] shadow-[0px_4px_22.3px_0px_rgba(0,0,0,0.12)]">
              <div className="flex items-center">
                <img
                  className=" pt-[5px] ml-[10px] sm:mt-[-30px] lg:ml-4 md:ml-4  sm:ml-4  flex flex-col items-center justify-center lg:h-[180px] lg:w-[200px] md:h-[180px] md:w-[200px] sm:h-[180px] sm:w-[200px] h-[100px] w-[120px] "
                  src={man}
                  alt=""
                />
              </div>
              <div className="text-[#fff] font-poppins font-medium px-1 flex flex-col items-center justify-center mr-[35px] sm:mr-4 ">
                <a
                  href="https://wa.me/message/532QZXEFUYFWK1"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#1A30A1] font-semibold bg-[#ffffff] border-[1px] border-solid border-[#00B4D8] rounded-[5px] lg:text-[20px] md:text-[20px] sm:text-[20px] text-[13px] lg:w-[227px] lg:h-[50px] md:w-[227px] md:h-[50px] sm:w-[227px] sm:h-[50px] w-[120px] h-[30px] flex items-center justify-center mb-1.5"
                >
                  Call & Whatsapp
                </a>
                <h1 className="text-[#fff] font-poppins font-medium lg:text-[20px] md:text-[20px] sm:text-[20px] text-[13px] mb-1.5">
                  for Order{" "}
                  <span className="text-[#FE0505] font-bold">24/7</span>
                </h1>
                <a
                  href="https://wa.me/message/532QZXEFUYFWK1"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center gap-1.5"
                >
                  <img
                    src={PhoneIcon}
                    alt="PhoneIcon"
                    className="w-[15px] h-[15x]"
                  />
                  <span className="text-[#fff] font-poppins font-medium lg:text-[20px] md:text-[20px] sm:text-[20px] text-[13px]">
                    7779888289
                  </span>
                </a>
              </div>
            </div>

            <div className="bg-[#90E0EF] lg:w-[550px] lg:h-[150px] md:w-[550px] md:h-[150px] sm:w-[550px] sm:h-[150px] w-[400px] h-[100px] flex justify-between rounded-[10px] shadow-[0px_4px_22.3px_0px_rgba(0,0,0,0.12)]">
              <div className="flex flex-col justify-center">
                <img
                  className="sm:mt-[-40px] flex flex-col  items-center justify-center "
                  src={med}
                  alt=""
                />
              </div>
              <div className="sm:text-[18px] px-3 font-[500] text-center flex flex-col gap-1 items-center justify-center">
                <h1 className="lg:text-[18px] md:text-[18px] sm:text-[18px] text-[13px] text-[#fff]">
                  Order medicines with prescription
                </h1>
                <div
                  className="flex items-center"
                  onClick={() => handleRoute("/order-by-prescription")}
                >
                  <label
                    htmlFor="fileInput"
                    className="cursor-pointer mt-2 bg-[#FFFFFF] flex items-center  text-white font-semibold py-2 px-4 rounded-md shadow-md  transition duration-300"
                  >
                    <img className="w-4 sm:w-5" src={ui} alt="" />
                    <button
                      className="ml-1 text-[#0924B0] lg:text-[18px] md:text-[18px] sm:text-[18px] text-[13px]"
                      onClick={() => handleRoute("/order-by-prescription")}
                    >
                      Upload
                    </button>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroSection;
