import React, { useEffect, useState, useRef } from "react";
import Yourcart from "../../../images/frontend UI materials/icon _Add to Shopping Cart_.svg";
import Arrow from "../../../images/frontend UI materials/Arrow 1.svg";
import Eyeicon from "../../../images/frontend UI materials/icon _Eye_.svg";
import IcomTimes from "../../../images/frontend UI materials/icon _Times_.svg";
import VerifyIcon from "../../../images/frontend UI materials/VerifyIcon.svg";
import Header from "../Header";
import PaymentMethodImg from "../../../images/frontend UI materials/PaymentMethod.png";
import ProceedIcon from "../../../images/frontend UI materials/ArrowForward.png";
import CartPayment from "../../../images/frontend UI materials/Cart.png";
import { useLocation } from "react-router-dom";
import siteCofig from "../../../urlFile";
import axios from "axios";
import PageLoader from "../../../Loader/PageLoader";
import CartOrderPlaced from "../../HomePage/OrderPlaced/CartOrderPlaced";
import { useNavigate } from "react-router-dom";

const AddressSlider = ({ onClose }) => {
  // Add logic for address saving or selecting here
  return (
    <div>
      <button onClick={onClose}></button>
    </div>
  );
};

const OrderReview = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [showSelectAddress, setShowSelectAddress] = useState(true);
  const [showAddNewAddress, setShowAddNewAddress] = useState(false);
  const [newAddress, setNewAddress] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [validationError, setValidationError] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [sliderOpen, setSliderOpen] = useState(false);
  const [userDataList, setUserDataList] = useState([]);
  const [SuccessMessage, setSuccessMessage] = useState("");
  const [selectedAddressId, setSelectedAddressId] = useState(null);
  const [totalAmount, setTotalAmount] = useState(0);
  const [deliveryCharge, setDeliveryCharge] = useState(0);
  const [totalPayableAmount, setTotalPayableAmount] = useState(0);
  const [selectedOption1, setSelectedOption1] = useState(null);
  const [selectedPaymentMode, setSelectedPaymentMode] = useState("cod");
  const [showModal, setShowModal] = useState(false);
  const [address, setAddress] = useState(false);
  const [orderPlaced, setOrderPlaced] = useState(false);
  const [successMessageDelete, setSuccessMessageDelete] = useState(false);

  const location = useLocation();
  const cartItems = location.state;
  console.log("cartItems are", cartItems);

  const handleOptionChange = (event) => {
    setSelectedOption1(event.target.value);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  const [selectedPayment, setSelectedPayment] = useState(""); // State to keep track of selected payment method

  const handlePaymentSelection = (paymentMethod) => {
    setSelectedPayment(paymentMethod);
  };

  const handleToggle = () => {
    setToggle((prev) => !prev);
    setShowSelectAddress(true);
    setShowAddNewAddress(false);
    setNewAddress("");
    setToggle(true);
  };
  const closeSlider1 = () => {
    setToggle(false);
    setToggle(false);
  };

  // form validation
  const [formData, setFormData] = useState({
    pincode: "",
    city: "",
    name: "",
    address: "",
    landmark: "",
    mobilenumber: "",
  });

  const [errors, setErrors] = useState({
    pincode: "",
    city: "",
    name: "",
    address: "",
    landmark: "",
    mobilenumber: "",
  });

  const handleInputChange1 = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = { ...errors };

    if (formData.pincode.length !== 6) {
      newErrors.pincode = "Pincode must be 6 digits";
      isValid = false;
    }

    if (!/^\d{10}$/.test(formData.mobilenumber)) {
      newErrors.mobilenumber = "Mobile Number must be 10 digits";
      isValid = false;
    }

    if (!formData.city) {
      newErrors.city = "City is required";
      isValid = false;
    }

    if (!formData.name) {
      newErrors.name = "Name is required";
      isValid = false;
    }

    if (!formData.address) {
      newErrors.address = "Address is required";
      isValid = false;
    }

    if (!formData.landmark) {
      newErrors.landmark = "Landmark is required";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleButtonClick = async () => {
    if (validateForm()) {
      setFormData({
        pincode: "",
        city: "",
        name: "",
        address: "",
        landmark: "",
        mobilenumber: "",
      });
      console.log("Form is valid:", formData);
      try {
        await handlePostRequest();
        setSuccessMessage(true);
        setTimeout(() => {
          setSuccessMessage(false);
        }, 2000);
      } catch (error) {
        console.log("Error saving address");
      }
      await handleGetRequest();
      setShowModal(false);
    } else {
      console.log("Form is not valid");
    }
  };

  // View Profile GET API
  const [userData, setUserData] = useState({
    user_id: localStorage.getItem("userId"),
    pincode: "",
    city: "",
    name: "",
    address: "",
    landmark: "",
    phone: "",
  });

  useEffect(() => {
    if (localStorage.getItem("userId")) {
      handleGetRequest();
    }
  }, []);

  const handleGetRequest = async () => {
    let userId = localStorage.getItem("userId");
    try {
      const response = await axios.get(
        `${siteCofig.BASE_URL}${siteCofig.GET_ADDRESS}${userId}`
      );

      const userData = response.data.data;
      console.log(userData, "dddddddddd");

      const responseData = response.data;
      if (
        responseData.msg === "Sucsess" &&
        Array.isArray(responseData.data) &&
        responseData.data.length > 0
      ) {
        const firstAddress = responseData.data[0];
        {
          // Update userDataList state with fetched data
          setUserDataList(responseData.data);
        }
        setUserData({
          user_id: firstAddress.user_id,
          pincode: firstAddress.pincode,
          city: firstAddress.city,
          name: firstAddress.name,
          address: firstAddress.address,
          landmark: firstAddress.landmark,
          phone: firstAddress.phone,
        });
      }
    } catch (error) {}
  };
  console.log("name", userData.name);

  //POST API
  const handlePostRequest = async () => {
    let reqBody = {
      user_id: localStorage.getItem("userId"),
      pincode: formData.pincode,
      city: formData.city,
      name: formData.name,
      address: formData.address,
      landmark: formData.landmark,
      phone: formData.mobilenumber,
    };
    try {
      const response = await axios.post(
        siteCofig.BASE_URL + siteCofig.SAVE_ADDRESS,
        reqBody
      );
      const userFormData = response.data.data;
      console.log(userFormData, "ffffffffff");
    } catch (error) {}
  };

  // selected address by user
  const handleAddressClick = (addressId) => {
    setSelectedAddressId(addressId);
    setToggle(false);
    console.log(`Address ${addressId} selected.`);
  };

  useEffect(() => {
    // Set default selection to the ID of the first address
    if (userDataList.length > 0 && selectedAddressId === null) {
      setSelectedAddressId(userDataList[0].id);
    }
  }, [userDataList, selectedAddressId]);

  const handleDelete = async (addressId) => {
    try {
      // Update the local state to remove the address immediately
      const updatedUserList = userDataList.filter(
        (address) => address.id !== addressId
      );
      setUserDataList(updatedUserList);

      // Send the delete request to the server
      await axios.delete(
        `${siteCofig.BASE_URL}${siteCofig.DELETE_ADDRESS}${addressId}`
      );

      setSuccessMessageDelete(true);
      setTimeout(() => {
        setSuccessMessageDelete(false);
      }, 2000);

      // Fetch the updated list of addresses after deletion
      await handleGetRequest();
    } catch (error) {
      console.error("Error deleting address:", error.message);
    }
  };

  useEffect(() => {
    let total = 0;
    for (const item of cartItems) {
      total += parseFloat(item.discountedPrice * item.quantity);
    }
    setTotalAmount(total);
  }, [cartItems]);

  useEffect(() => {
    const deliveryCharge = totalAmount >= 1099 ? 0 : 80;
    const totalPayableAmount = (totalAmount + deliveryCharge).toFixed(2);

    // Update the states
    setDeliveryCharge(deliveryCharge);
    setTotalPayableAmount(totalPayableAmount);
  }, [totalAmount]);

  const placeOrder = async () => {
    try {
      const userId = localStorage.getItem("userId");
      const paymentMode = selectedPaymentMode;
      const amount = totalPayableAmount;
      const description = "please call me back once my order is confirm";
      const transactionId = selectedPaymentMode;
      const selectedAddress = userDataList.find(
        (address) => address.id === selectedAddressId
      );

      if (!selectedAddress) {
        console.error("Selected address not found.");
        setAddress(true);
        setTimeout(() => {
          setAddress(false);
        }, 2000);

        return;
      }

      if (!paymentMode) {
        console.error("Please select a payment method.");

        return;
      }

      const requestData = {
        userid: userId,
        address: `${selectedAddress.address}, ${selectedAddress.landmark}, ${selectedAddress.city} - ${selectedAddress.pincode}, Bihar, ${selectedAddress.phone}`,
        paymod: paymentMode,
        amount: amount,
        description: description,
        transaction_id: transactionId,
      };

      const response = await axios.post(
        `${siteCofig.ORDER_CHECKOUT}`,
        requestData
      );
      console.log("Order placed successfully:", response.data);
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        setOrderPlaced(true);
        window.history.replaceState(null, "", "/cart");
      }, 2000);
    } catch (error) {
      console.error("Error placing order:", error);
    }
  };

  const handlePaymentModeChange = (mode) => {
    setSelectedPaymentMode(mode);
  };

  const getMedicineImageUrl = (imageName) =>
    `https://app.healthcrad.com/api/uploads/medicine/${imageName}`;

  //  OutsideClick PopUp Close Code
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const popupRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setIsPopupOpen(false);
      }
    };

    if (isPopupOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isPopupOpen]);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  return (
    <>
      <Header />
      {loading && <PageLoader />}
      {!orderPlaced && (
        <div className=" pt-20 pb-[100px] sm:pb-20 xs:mx-2 xl:mx-20 container mx-auto">
          {successMessageDelete && (
            <div className="bg-green-500 text-white p-2 absolute top-1/3 z-50 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded">
              <p className="w-52 sm:w-60 text-center">
                Address deleted successfully!
              </p>
            </div>
          )}
          {address && (
            <div className="bg-red-500 text-white p-2 absolute top-1/3 z-50 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded">
              <p className="w-52 sm:w-80 text-center font-lato">
                Please select/add delivery address!
              </p>
            </div>
          )}
          {SuccessMessage && (
            <div className="bg-green-500 text-white p-2 absolute top-1/3 z-50 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded">
              <p className="w-52 sm:w-80 text-center font-lato">
                Address saved successfully.
              </p>
            </div>
          )}
          {/* order review */}
          <div className=" flex  justify-between bg-[#10CAF2] rounded-[5px]  shadow-[0px_4px_4px_0px_rgba(0,0,0,0.12)] xl:mb-6 xl:ml-12 xl:mr-16 2xl:ml-28 2xl:mr-32 ">
            <div className="flex items-center justify-start">
              <h1 className=" text-center  font-semibold text-[17px] xl:text-[20px] text-[#ffffff] font-lato pl-2 ">
                Order Review
              </h1>
            </div>
            <div className="flex  ml-[32px] ">
              <div>
                <img
                  src={Yourcart}
                  alt="YourCart Icon"
                  className="sm:w-[55px] sm:h-[28px] w-[46px] h-[30px] pt-[7.47px]"
                />
                <p className="text-[#ffffff] font-medium sm:text-[12px] text-[11px] font-poppins">
                  Your Cart
                </p>
              </div>

              <div className="flex items-center justify-center lg:w-[133px] ">
                <img
                  src={Arrow}
                  alt="Arrow-image"
                  className=" w-[65px] sm:w-auto mb-[10px] sm:mb-[9px] pr-[10px]"
                />
              </div>

              <div className=" sm:-mr-[10px]">
                <img
                  src={Eyeicon}
                  alt=""
                  className=" pl-[5px] lg:pr-[40px] w-[44px] h-[20px]  sm:w-[91px] mt-2"
                />
                <p className="sm:text-[13px] text-[11px] text-[#ffffff] font-poppins font-medium   pr-[11px] pl-[5px] ">
                  review
                </p>
              </div>
            </div>
          </div>
          {/* Left Side content */}

          <div className="flex xl:flex-row flex-col-reverse justify-center   xl:justify-around items-center  w-full">
            <div className="flex flex-col xl:self-start pb-28 xl:pb-0  justify-around ">
              <p className="xl:hidden  p-2 text-[18px] text-[#000000] font-lato font-semibold  self-start">
                Get it
              </p>
              {cartItems.map((item) => (
                <div key={item.id} className=" relative  flex  justify-center">
                  <div className="  sm:w-[520px] sm:h-[auto] w-[300px] xs:w-[360px] bg-[#ffffff] rounded-lg sm:px-4 py-2 mb-3.5  border-[1px] border-solid border-[#00000033]">
                    <h2 className="text-[#000000] font-medium text-[16px] sm:text-[20px] pl-2.5 ">
                      Products
                    </h2>
                    {item.prescription === "1" && (
                      <p className=" right-1  top-1 z-10 font-bold absolute text-[15px] rounded-[5px] w-7 text-center border-[1px] border-[#10CAF2] text-[#10CAF2]">
                        Rx
                      </p>
                    )}
                    <div className="flex p-1  ">
                      <div className=" flex items-center  justify-center w-[54px] sm:w-[100px] mb-[32px] ">
                        <img src={getMedicineImageUrl(item.image[0])} alt="" />
                      </div>
                      <div className="font-poppins sm:w-2/3 sm:pl-3 ">
                        <p className="text-[#4A4141]  text-[13px] sm:text-[16px] leading-6 sm:mb-[18px] mt-[5px]">
                          {item.name}
                        </p>
                        <p className=" font-normal text-[#0000008A] sm:text-[16px] text-[12px]">
                          Rs.{" "}
                          <span className="line-through">{item.amount}</span>{" "}
                          <span className="text-[#07A50D] sm:text-[16px] text-[13px] font-poppins font-medium">
                            off
                          </span>{" "}
                          <span className="text-[#07A50D] sm:text-[20px] text-[14px] font-poppins font-bold">
                            {item.discount}%
                          </span>
                        </p>
                        <p className="font-semibold sm:text-[20px] text-[14px] text-[#000000]">
                          Rs. {item.discountedPrice}
                        </p>
                        <div className="flex justify-between gap-[10px] xs:gap-[30px]">
                          <p className="sm:text-[12px] text-[9px] font-light mt-[11px] text-[#979797]">
                            Delivery between 2-3 working days.
                          </p>
                          <p className="text-[#00B4D8] sm:text-[14px] text-[12px]  font-lato w-20 font-medium  self-end p-1.5 rounded-[5px] ">
                            QTY : {item.quantity}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {/* Right Side Content */}
            <div className="w-full sm:w-auto mb-3 mt-3 xl:mt-0   xl:self-start  ">
              {/* Prescription Uploaded */}
              {cartItems.some((item) => item.prescription === "1") && (
                <div className="flex flex-col  gap-6 w-full mb-3 sm:w-[550px] xl:w-[500px] sm:h-[40px] h-[47px]">
                  {cartItems.find((item) => item.prescription === "1") && (
                    <div
                      key={
                        cartItems.find((item) => item.prescription === "1").id
                      }
                      className="mb-2 sm:mb-6 flex rounded-[5px] border-[2px] border-solid border-[#20CD27] justify-between sm:w-[550px] xl:w-[500px] sm:h-[70px] h-[47px] w-auto px-3 sm:px-0">
                      <p className="flex items-center justify-start text-[14px] sm:text-[18px] text-[#20CD27]">
                        Prescription Uploaded
                      </p>
                      <img
                        src={VerifyIcon}
                        alt=""
                        className="sm:h-[40px] sm:w-[30px] my-[10px] sm:my-[5px] w-[25px] h-[25px]  sm:mr-[20px] mr-[11px]"
                      />
                    </div>
                  )}
                </div>
              )}

              {/* + Add New Address */}
              <div className="flex justify-between">
                <div
                  className="mt-[20px] sm:mt-[30px] mb-[20px] sm:mb-0"
                  onClick={openModal}>
                  <button class=" border-1 border-solid bg-white border-[#10CAF2] shadow-0px_4px_4px_0px_rgba(0, 0, 0, 0.12) cursor-pointer rounded-[5px] w-[135px] h-[32px] text-[#00B4D8]  font-poppins font-normal text-[14px]">
                    + Add Address
                  </button>
                </div>
                <div className="relative  mt-[30px]">
                  <button
                    className="ml-auto bg-[#00B4D8] text-[#fff] h-5 w-5 text-[15px] rounded-full cursor-pointer"
                    onClick={togglePopup}>
                    i
                  </button>
                  {isPopupOpen && (
                    <div
                      ref={popupRef}
                      className="fixed inset-0 flex items-center justify-center z-50">
                      <div
                        className="absolute inset-0 bg-gray-800 opacity-50"
                        onClick={togglePopup}></div>
                      <div className="relative bg-white shadow-md p-4 rounded-md w-[333px]">
                        <h2 className="text-lg font-semibold mb-2">
                          How to cancel the order?
                        </h2>
                        <p>
                          To cancel the order, please go to the Help section,
                          write your query and phone number, and request a
                          callback. We will assist you in canceling the order.{" "}
                          <br />
                          <span className="cursor-pointer">
                            Or call on{" "}
                            <a
                              className="text-[#0924B0D9] underline"
                              href="tel:+917779888289">
                              7779888289
                            </a>
                          </span>
                        </p>
                        <p className="text-red-500">
                          Note: Order must be cancelled within 24 hours of
                          placement, otherwise the order will not be cancelled
                          after pickup.
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {/* ========= Delivery Address======== */}
              <div
                className={`fixed bottom-0 left-0 right-0 z-10 sm:mb-[20px] mb-[42px] ${
                  !toggle ? "sm:static" : " "
                }`}>
                <div className="  bg-white rounded-t-[10px] sm:rounded-[10px]  h-auto w-full mt-[7px] mb-[7px] justify-between   ">
                  {selectedAddressId && (
                    <div className="border-[1px] border-[#00B4D8] rounded-t-[10px] sm:rounded-lg p-3 w-full ">
                      <div className="flex justify-between pb-1">
                        <p className="text-[#00B4D8] font-medium text-[14px] sm:text-[16px] font-poppins ">
                          Delivery Address
                        </p>
                        <button
                          className="text-[#00B4D8] text-[14px] sm:text-[16px] font-poppins font-medium cursor-pointer"
                          onClick={handleToggle}>
                          Change Address
                        </button>
                      </div>
                      {userDataList.map((selectedAddress) => {
                        if (selectedAddress.id === selectedAddressId) {
                          return (
                            <React.Fragment key={selectedAddress.id}>
                              <p className="font-poppins text-[#000] font-bold">
                                {selectedAddress.name}
                              </p>
                              <p className="font-poppins text-[#000] text-[12px] sm:text-[14px]">
                                {selectedAddress.address} <br />
                                {selectedAddress.landmark},{" "}
                                {selectedAddress.city} -{" "}
                                {selectedAddress.pincode}, Bihar.
                                <br />
                                +91 - {selectedAddress.phone}
                              </p>
                            </React.Fragment>
                          );
                        }
                        return null;
                      })}
                    </div>
                  )}
                  {/* box model */}
                  {showModal && (
                    <div className="fixed top-0 left-0 z-50 w-full h-full flex items-center justify-center">
                      <div className="absolute top-0 left-0 w-full h-full bg-gray-900 opacity-50"></div>
                      <div className=" w-full sm:w-[60%] md:w-[40%] gap-3   z-10 bg-white p-7 rounded-lg shadow-lg flex flex-col justify-center items-center  ">
                        <div className=" w-full flex justify-end items-end">
                          <button
                            className="text-gray-500 hover:text-gray-700 self-end "
                            onClick={closeModal}>
                            <img src={IcomTimes} alt="CrossIcon" className="" />
                          </button>
                        </div>
                        <div className="  p-1 pb-6  bg-[#FFF] rounded-[5px] shadow-sm border border-solid border-opacity-5 font-poppins  flex flex-col justify-center items-start ">
                          <div className="flex flex-col pt-2">
                            <label
                              className="font-poppins font-bold pl-7 text-[#10CAF2]"
                              htmlFor="name">
                              PIN CODE
                            </label>
                            <input
                              className="text-[15px] font-medium pl-7 focus:outline-none"
                              type="tel"
                              name="pincode"
                              value={formData.pincode}
                              onChange={handleInputChange1}
                              maxLength={6}
                              onKeyDown={(e) => {
                                // Allow only numeric keys, backspace, and arrow keys
                                if (
                                  !/^\d$/.test(e.key) &&
                                  e.key !== "Backspace" &&
                                  e.key !== "ArrowLeft" &&
                                  e.key !== "ArrowRight"
                                ) {
                                  e.preventDefault();
                                }
                              }}
                            />
                            <div>
                              {" "}
                              <p className="text-red-500 text-sm ml-7">
                                {errors.pincode}
                              </p>
                            </div>
                          </div>
                          <div
                            className="underline-offset-8 w-[295px] xl:w-[339px] ml-[30px] mb-2 border-[1px] 
                        color-[#0000002E] center-alignment"></div>
                          <div className="flex flex-col">
                            <label
                              className="font-poppins font-bold pl-7 text-[#10CAF2]"
                              htmlFor="name">
                              CITY/STATE
                            </label>
                            <input
                              type="text"
                              name="city"
                              value={formData.city}
                              onChange={handleInputChange1}
                              className="text-[15px] font-medium pl-7 focus:outline-none"
                            />
                            <div>
                              {" "}
                              <p className="text-red-500 text-sm ml-7">
                                {errors.city}
                              </p>
                            </div>
                          </div>
                          <div
                            className="underline-offset-8 w-[295px] xl:w-[339px] ml-[30px] mb-2 border-[1px] 
                        color-[#0000002E] center-alignment"></div>
                          <div className="flex flex-col">
                            <label
                              className="font-poppins font-bold pl-7 text-[#10CAF2]"
                              htmlFor="name">
                              NAME
                            </label>
                            <input
                              type="text"
                              name="name"
                              value={formData.name}
                              onChange={handleInputChange1}
                              className="text-[15px] font-medium pl-7 focus:outline-none"
                            />
                            <div>
                              {" "}
                              <p className="text-red-500 text-sm ml-7">
                                {errors.name}
                              </p>
                            </div>
                          </div>
                          <div
                            className="underline-offset-8 w-[295px] xl:w-[339px] ml-[30px] mb-2 border-[1px] 
                        color-[#0000002E] center-alignment"></div>
                          <div className="flex flex-col">
                            <label
                              className="font-poppins font-bold pl-7 text-[#10CAF2]"
                              htmlFor="name">
                              ADDRESS
                            </label>
                            <input
                              type="text"
                              name="address"
                              value={formData.address}
                              onChange={handleInputChange1}
                              className="text-[15px] font-medium pl-7 focus:outline-none"
                            />
                            <div>
                              {" "}
                              <p className="text-red-500 text-sm ml-7">
                                {errors.address}
                              </p>
                            </div>
                          </div>
                          <div
                            className="underline-offset-8 w-[295px] xl:w-[339px] ml-[30px] mb-2 border-[1px] 
                        color-[#0000002E] center-alignment"></div>
                          <div className=" flex flex-col">
                            <label
                              className="font-poppins font-bold pl-7 text-[#10CAF2]"
                              htmlFor="name">
                              LANDMARK
                            </label>
                            <input
                              type="text"
                              name="landmark"
                              value={formData.landmark}
                              onChange={handleInputChange1}
                              className="text-[15px] font-medium pl-7 focus:outline-none"
                            />
                            <div>
                              {" "}
                              <p className="text-red-500 text-sm ml-7">
                                {errors.landmark}
                              </p>
                            </div>
                          </div>
                          <div
                            className="underline-offset-8 w-[295px] xl:w-[339px] ml-[30px] mb-2 border-[1px] 
                        color-[#0000002E] center-alignment"></div>
                          <div className=" flex flex-col">
                            <label
                              className="font-poppins font-bold pl-7 text-[#10CAF2]"
                              htmlFor="name">
                              MOBILE NUMBER
                            </label>
                            <input
                              type="tel"
                              name="mobilenumber"
                              value={formData.mobilenumber}
                              onChange={handleInputChange1}
                              className="text-[15px] font-medium pl-7 focus:outline-none"
                              maxLength={10}
                              onKeyDown={(e) => {
                                // Allow only numeric keys, backspace, and arrow keys
                                if (
                                  !/^\d$/.test(e.key) &&
                                  e.key !== "Backspace" &&
                                  e.key !== "ArrowLeft" &&
                                  e.key !== "ArrowRight"
                                ) {
                                  e.preventDefault();
                                }
                              }}
                            />
                            <div>
                              {" "}
                              <p className="text-red-500 text-sm ml-7">
                                {errors.mobilenumber}
                              </p>
                            </div>
                          </div>
                          <div
                            className="underline-offset-8 w-[295px] xl:w-[339px] ml-[30px] border-[1px] 
                        color-[#0000002E] center-alignment"></div>

                          <div className=" mt-2 mb-2 p-1 w-full flex justify-center items-center">
                            <button
                              className=" flex justify-center items-center bg-[#10CAF2] text-[18px] font-semibold text-[#ffffff]    focus:outline-none rounded-[5px]  w-[60%] h-11 self-center "
                              style={{
                                boxShadow:
                                  "0px 4px 4px 0px rgba(0, 0, 0, 0.12)",
                              }}
                              type="submit"
                              onClick={handleButtonClick}>
                              Save Address{" "}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* box model end */}
                  <div className="">
                    {toggle && (
                      <div className="fixed inset-0 bg-gray-800 p-0 bg-opacity-50 flex flex-col items-center justify-center overlay">
                        <aside className="lg:w-[208px] h-screen fixed top-0 right-0 bottom-0">
                          <div
                            className="flex cursor-pointer absolute left-4 top-4 "
                            onClick={handleToggle}></div>
                          <div className="fixed top-0 right-0 h-screen w-[80%] xl:w-[28%] sm:w-[350px] lg:w-[430px] bg-[#e5f8fc] p-2 overflow-y-auto ">
                            {/* Address Slider Content */}
                            <div className="flex mb-[22px] cursor-pointer mt-20 ">
                              <img
                                src={IcomTimes}
                                alt="CrossIcon"
                                className="ml-auto w-[20px] sm:w-auto "
                                onClick={closeSlider1}
                              />
                            </div>
                            <div className="block mx-auto max-w-[600px]">
                              <div className="text-[#00B4D8] font-poppins font-medium text-[15px] lg:ml-9">
                                <button>SELECT ADDRESS</button>
                              </div>
                              {showSelectAddress && (
                                <div className=" flex flex-col justify-center items-center ">
                                  {userDataList.map((userAddress) => (
                                    <div
                                      key={userAddress.id}
                                      className={`border-2 border-gray-300 bg-[#FFF] rounded-lg p-2 cursor-pointer mb-2 w-full sm:w-[330px] ${
                                        selectedAddressId === userAddress.id
                                          ? "border-[#00B4D8]"
                                          : ""
                                      }`}
                                      onClick={() =>
                                        handleAddressClick(userAddress.id)
                                      }>
                                      <p className="font-poppins text-[#000] font-bold">
                                        {userAddress.name}
                                      </p>
                                      <p className="font-poppins text-[#000] text-[14px]">
                                        {userAddress.address} <br />
                                        {userAddress.landmark},{" "}
                                        {userAddress.city} -{" "}
                                        {userAddress.pincode}, Bihar.
                                        <br />
                                        +91 - {userAddress.phone}
                                      </p>
                                      <div
                                        className="justify-end items-end flex"
                                        onClick={() =>
                                          handleDelete(userAddress.id)
                                        }>
                                        <button className="text-[18px] font-poppins font-normal text-[#00B4D8]">
                                          Delete
                                        </button>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              )}
                            </div>
                          </div>
                        </aside>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* Payment mode and payment details and Proceed Button */}
              <div className="rounded-[5px]  flex sm:flex-col justify-center shadow-[0px_4px_20.799999237060547px_0px_#0000001A] p-3 w-full ">
                <div className="flex flex-col  sm:flex-row gap-[30px] sm:gap-3   w-[103%] ">
                  {/* ==========Payments Cart========== sm:hidden */}
                  <div className="w-full h-auto bg-[#ffffff] rounded-[5px] ">
                    <h2 className="text-center text-[16px] text-[#000000] font-poppins font-semibold  ">
                      PAYMENT DETAILS
                    </h2>
                    <div className=" mt-2.5 text-[#666161] text-[16px] font-poppins  ">
                      <div className=" flex flex-col justify-center gap-3  ">
                        <div className=" text-[14px] flex justify-between items-center">
                          <p>Item Cost</p>
                          <span>Rs. {totalAmount}</span>
                        </div>
                        <div className="text-[14px]  flex justify-between items-center ">
                          <p className=" ">Delivery Charge</p>
                          <span className="">Rs. {deliveryCharge}</span>
                        </div>
                        <div className="p-[3px] flex items-center  text-[15px] justify-between  text-[#0FAD15] bg-[#f2f7f2] rounded-[5px] h-[35px]">
                          <p className="">Total Cost</p>
                          <span className="">Rs. {totalPayableAmount} </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Payment mode */}
                  <div className="flex sm:flex-row flex-col w-full h-auto">
                    <div className="  w-full rounded-[5px] bg-[#fff]">
                      <div className="flex gap-2 mb-2">
                        <img
                          src={PaymentMethodImg}
                          alt=""
                          className="w-[25px] h-[18px]"
                        />
                        <h1 className="text-[16px] text-[#000000] font-poppins font-semibold">
                          Payment Mode
                        </h1>
                      </div>
                      <div className="mt-4">
                        {/* <div
                        className={`flex items-center cursor-pointer ps-4 group-hover:border-[#00B4D8] border-[1px] ${
                          selectedPaymentMode === "online"
                            ? "border-[#00B4D8]"
                            : "border-[#979797]"
                        } group bg-[#FFF] rounded-[5px]`}
                        onClick={() => handlePaymentModeChange("online")}>
                        <input
                          id="bordered-radio-1"
                          type="radio"
                          value="online"
                          name="bordered-radio"
                          className="w-4 h-4 text-[#00B4D8] border-none focus:ring-[#00B4D8]"
                          onChange={() => handlePaymentModeChange("online")}
                          checked={selectedPaymentMode === "cod"}
                        />
                        <label
                          htmlFor="bordered-radio-1"
                          className="w-full h-[42px] py-2.5 ms-2 cursor-pointer text-sm font-normal">
                          Online Payment{" "}
                          <span className="text-[9px] text-[#07A50D]">
                            ( Avail. Soon )
                          </span>
                        </label>
                      </div> */}
                      </div>
                      <div className="mt-3">
                        <div
                          className={`flex items-center w-full sm:w-[98%] cursor-pointer  ps-3 group-hover:border-[#00B4D8] border-[1px] ${
                            selectedPaymentMode === "cod"
                              ? "border-[#00B4D8]"
                              : "border-[#979797]"
                          } group bg-[#FFF] rounded-[5px]`}
                          onClick={() => handlePaymentModeChange("cod")}>
                          <input
                            id="bordered-radio-2"
                            type="radio"
                            value="cod"
                            name="bordered-radio"
                            className="w-4 h-4 text-[#00B4D8] border-none focus:ring-[#00B4D8]"
                            onChange={() => handlePaymentModeChange("cod")}
                            checked={selectedPaymentMode === "cod"}
                          />
                          <label
                            htmlFor="bordered-radio-2"
                            className="w-full h-[42px] py-2.5 ms-2 cursor-pointer text-sm font-normal">
                            Cash On Delivery
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Desktop view button design start */}
                <div className="hidden sm:block w-full mt-4 ">
                  <div className="flex justify-between items-center leading-6 bg-[#FFFFFF] px-12 h-[64px] w-full rounded-[5px] border-[0.5px] border-[#d8d4d4]">
                    <div className="flex ">
                      <div className="flex flex-col">
                      <p className="text-[14px] font-lato font-medium text-[#10CAF2] ">
                      Total Payable
                    </p>
                    <p className="text-[20px] font-lato font-bold text-[#000] ml-[3px] ">
                      Rs. {(totalAmount + deliveryCharge).toFixed(2)}
                    </p>
                      </div>
                    </div>
                    <div
                  className="select-none rounded-[4px] h-[40px] w-[130px] bg-[#10CAF2]  flex items-center justify-center cursor-pointer"
                  onClick={placeOrder}>
                      {/* onClick={() => handleRoute(`/order-review`, cartItems)} */}
                      <button className="text-white font-lato font-medium text-[20px]">
                        Place Order
                      </button>
                    </div>
                  </div>
                </div>
                {/* Destop view button design end */}
              </div>
            </div>
            {/* mobile view button design start */}
            <div className=" sm:hidden fixed z-40 flex  w-full bottom-0 border-t-[1px] border-[#10CAF2]  ">
              <div className="flex justify-between items-center bg-[#F5F5F5] px-3 w-full py-2">
                <div className="flex ">
                  <div className="flex flex-col  ml-[15px]">
                    <p className="text-[12px] font-lato font-medium text-[#00B4D8] ">
                      Total Payable
                    </p>
                    <p className="text-[16px] font-lato font-bold text-[#000000]  ">
                    Rs. {(totalAmount + deliveryCharge).toFixed(2)}
                    </p>
                  </div>
                </div>
                <div
                  className="select-none rounded-[4px]  h-[40px] w-[142px] bg-[#10CAF2]  flex items-center justify-center cursor-pointer mr-[15px]"
                  onClick={placeOrder}>
                  <button className="text-[#ffffff] font-lato font-medium text-[16px]">
                    Place Order
                  </button>
                </div>
              </div>
            </div>
            {/* mobile view button design end */}
          </div>
        </div>
      )}
      {orderPlaced && <CartOrderPlaced />}
    </>
  );
};

export default OrderReview;
