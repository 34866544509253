import React from "react";
import Navigation from "../../Navigation/Navigation";

function Header() {
  return (
    <>
      <Navigation />
    </>
  );
}

export default Header;
