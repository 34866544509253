import React from "react";
import EmptyPrescriptionPic from "../../../../images/Empty_Pages_Images/prescription_Empty_removebg.png";

function EmptyPrescription() {
  return (
    <>
      <div className="p-3 mt-10 mb-14">
        <div className="flex flex-col justify-center items-center gap-5">
          <div className="flex justify-start self-start ml-0 sm:ml-10 lg:ml-28 xl:ml-72 items-start">
            <p className="text-[#66616180] text-center font-poppins font-semibold text-lg sm:text-xl">
              Nothing is here. Book your order by prescription now !
            </p>
          </div>
          <div className=" flex justify-center items-center">
            <img
              src={EmptyPrescriptionPic}
              alt=""
              srcset=""
              className="w-[150px] h-[150px] sm:h-[250px] sm:w-[250px]"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default EmptyPrescription;
