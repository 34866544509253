import React, { useRef, useState } from "react";
import Webcam from "react-webcam";
import Header from "../Header";
import { useNavigate } from "react-router-dom";

const videoConstraints = {
  width: 540,
  facingMode: "environment",
};

const OpenCamera = () => {
  const [devices, setDevices] = React.useState([]);
  const handleDevices = React.useCallback(
    (mediaDevices) => {
      setDevices(mediaDevices.filter(({ kind }) => kind === "videoinput"));
    },
    [setDevices]
  );

  React.useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then(handleDevices);
  }, [handleDevices]);

  const webcamRef = useRef(null);
  const [liveImageUrl, setLiveImageUrl] = useState(null);
  const [isWebcamOn, setIsWebcamOn] = useState(true);
  const [uploadedImage, setUploadedImage] = useState(null);
  const navigate = useNavigate();

  // After capturing the photo
  const capturePhoto = async () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setLiveImageUrl(imageSrc);
    setIsWebcamOn(false);
  };

  const onUserMedia = (e) => {
    console.log(e);
  };

  const resetCapture = () => {
    setLiveImageUrl(null);
    setIsWebcamOn(true);
  };

  const handleImageUpload = (e) => {
    console.log("value of e", e);
    //console.log("prescription_order_img", prescription_order_img);

    localStorage.setItem("prescription_order_img", e);
    navigate("/order-by-prescription");
  };

  // console.log(liveImageUrl, "oooooooooo");
  return (
    <>
      <Header />
      <div className="flex items-center justify-center  pt-32 ">
        {isWebcamOn && (
          <Webcam
            ref={webcamRef}
            audio={false}
            screenshotFormat="image/png"
            videoConstraints={videoConstraints}
            onUserMedia={onUserMedia}
            mirrored={true}
          />
        )}
      </div>
      {!liveImageUrl && (
        <div className="flex justify-center items-center">
          <button
            onClick={capturePhoto}
            className="bg-[#0db8da] hover:bg-blue-500 text-white font-bold py-2 px-8 rounded m-4"
          >
            Capture
          </button>
        </div>
      )}
      {liveImageUrl && (
        <>
          <div className="flex items-center justify-center">
            <img src={liveImageUrl} alt="Live Screenshot" />
          </div>
          <div className="flex items-center justify-center">
            <button
              onClick={resetCapture}
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-8 rounded m-4"
            >
              Refresh
            </button>
            <button
              onClick={() => handleImageUpload(liveImageUrl)}
              className="h-[39px] w-[120px] bg-green-500 hover:bg-green-700 text-white font-bold py-2 rounded m-4"
            >
              Upload
            </button>
          </div>
        </>
      )}
      {uploadedImage && (
        <div className="flex items-center justify-center">
          <img src={uploadedImage} alt="Uploaded Image" />
        </div>
      )}
    </>
  );
};

export default OpenCamera;
