import React, { useState } from "react";
import Doctors from "../../images/Our_Offering/doctor-removebg-preview.png";
import Medicines from "../../images/Our_Offering/Medicines.png";
import Ambulance from "../../images/Our_Offering/Ambulance.png";
import Pathlab from "../../images/Our_Offering/pathlab-removebg-preview.png";
import { useNavigate } from "react-router-dom";
import PageLoader from "../../Loader/PageLoader";

const OurOfferings = () => {
  const [loading, setLoading] = useState(false);
  const [showPathlabMessage, setShowPathlabMessage] = useState(false);
  const navigate = useNavigate();
  const handleRoute = (path) => {
    setLoading(true);
    setTimeout(() => {
      navigate(path);
    }, 1000);
  };

  return (
    <div className=" w-full ">
      {loading && <PageLoader />}
      <div className="pt-10  font-poppins">
        {showPathlabMessage && (
          <div className="bg-red-500 text-white p-2 absolute top-3/2 z-50 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded">
            <p className="w-52 sm:w-80 text-center font-lato">
              Work in progress available soon... !<br />
              Currently available on HealthCRAD application.
            </p>
          </div>
        )}
        {/* Heading and SubHeading */}
        <div className="">
          <h1 className="text-[#2F2D2D] pb-2.5 sm:pb-0 text-[18px] sm:text-[24px] font-semibold font-lato pl-[7px] xs:pl-[13px] sm:pl-[28px] md:pl-[37px] lg:pl-[50px] xl:pl-[20px] 2xl:pl-[53px] ">
            Our Offerings
          </h1>
        </div>
        {/* Parent Div Card */}
        <div className="w-full grid grid-cols-2 xl:grid-cols-4 gap-1 xs:gap-6 sm:gap-2   sm:m-0 justify-items-center">
          {/* Card-1 */}
          <div
            className=" sm:mt-[30px] w-[145px] h-[175px]  xs:w-[164px] xs:h-[200px] sm:w-[269px] sm:h-[325px]  shadow-[0px_4px_5px_0px_#AFCFED] rounded-[30px] cursor-pointer transform hover:scale-105 transition-transform duration-300 ease-in-out mb-3.5 sm:mb-0"
            onClick={() => handleRoute("/doctors")}
          >
            <div className="bg-[#AFCFED] w-[145px] h-[89px] xs:h-[106px] xs:w-[165px] sm:h-[180px] sm:w-[269px] rounded-t-[30px]">
              <img
                src={Doctors}
                alt=""
                className=" pt-[5px]  mx-auto w-[97px] h-[90px] xs:h-[106px]  sm:w-[172px] sm:h-[180px] rounded-[10px]"
              />
            </div>
            <h2 className=" text-left ml-[9px] xs:ml-[20px] xs:mr-[9px] mt-[8px] xs:mt-[11px] pb-[2px] xs:pb-[8px] sm:mx-[33px] sm:mt-[33px] font-medium font-lato text-[20px]  sm:text-[24px] text-[#000] ">
              Doctors
            </h2>
            <p className=" text-left ml-[9px] xs:ml-[20px] mr-[9px] text-[12px] xs:text-[13px] sm:mx-[33px] mb-[33px] text-[#979797] sm:text-[15px] font-normal pb-[4px]">
              Book appointment in just 30 sec.
            </p>
          </div>
          {/* Card-2*/}
          <div
            className=" sm:mt-[30px] w-[145px] h-[175px]  xs:w-[164px] xs:h-[200px] sm:w-[269px] sm:h-[325px] shadow-[0px_4px_5px_0px_#98CBD6] rounded-[30px] cursor-pointer transform hover:scale-105 transition-transform duration-300 ease-in-out mb-3.5 sm:mb-0"
            onClick={() => handleRoute("/allmedicines")}
          >
            <div className="bg-[#98CBD6] w-[145px] h-[89px] xs:h-[106px] xs:w-[165px] sm:h-[180px] sm:w-[269px] rounded-t-[30px]">
              <img
                src={Medicines}
                alt=""
                className="pb-[5px]  mx-auto w-[97px] h-[90px] xs:h-[106px] sm:w-[172px] sm:h-[180px] rounded-[10px]"
              />
            </div>
            <h2 className=" text-left ml-[9px] xs:ml-[20px] xs:mr-[9px] mt-[8px] xs:mt-[11px] pb-[2px] xs:pb-[8px] sm:mx-[33px] sm:mt-[33px] font-medium font-lato text-[20px] sm:text-[24px] text-[#000] ">
              Medicines
            </h2>
            <p className=" text-left ml-[9px] xs:ml-[20px] mr-[9px] text-[12px] xs:text-[13px] sm:ml-[33px] sm:mr-[20px] mb-[33px] text-[#979797]  sm:text-[15px] font-normal pb-[4px]">
              Quick doorstep medicine delivery
            </p>
          </div>
          {/* Card-3 */}
          <div
            className="sm:mt-[30px] w-[145px] h-[175px]  xs:w-[164px] xs:h-[200px] sm:w-[269px] sm:h-[325px] shadow-[0px_4px_5px_0px_#CCD0DB] rounded-[30px] cursor-pointer transform hover:scale-105 transition-transform duration-300 ease-in-out mb-3.5 sm:mb-0"
            // onClick={() => handleRoute("/ambulanceBook")}
            onClick={() => {
              setShowPathlabMessage(true);
              setLoading(false);
              setTimeout(() => {
                setShowPathlabMessage(false);
              }, 4000);
            }}
          >
            <div className="bg-[#CCD0DB] w-[145px] h-[89px] xs:h-[106px] xs:w-[165px] sm:h-[180px] sm:w-[269px] rounded-t-[30px]">
              <img
                src={Ambulance}
                alt=""
                className=" mx-auto w-[110px] h-[90px] xs:h-[106px] sm:w-[172px] sm:h-[180px] rounded-[10px]"
              />
            </div>
            <h2 className="text-left ml-[9px] xs:ml-[20px] xs:mr-[9px] mt-[8px] xs:mt-[11px] pb-[2px] xs:pb-[8px] sm:ml-[33px] sm:mt-[33px] font-medium font-lato text-[20px] sm:text-[24px] text-[#000]">
              Ambulance
            </h2>
            <p className=" hidden sm:block ml-[20px] mr-[9px] sm:mr-[9px] sm:ml-[33px] mb-[33px] text-[#979797] text-[13px] sm:text-[15px] font-normal pb-[4px]">
              Instant Ambulance Bookings at the time of need
            </p>
            <p className=" sm:hidden text-left ml-[9px] xs:ml-[20px] mr-[9px] text-[12px] xs:text-[13px] text-[#979797] sm:text-[15px] font-normal pb-[4px]">
              Instant Ambulance Bookings
            </p>
          </div>
          {/* Card-4 */}
          <div
            className="sm:mt-[30px] w-[145px] h-[175px]  xs:w-[164px] xs:h-[200px] sm:w-[269px] sm:h-[325px] shadow-[0px_4px_5px_0px_#D5D8FC] rounded-[30px] cursor-pointer transform hover:scale-105 transition-transform duration-300 ease-in-out mb-3.5 sm:mb-0"
            onClick={() => handleRoute("/pathlab")}
            // onClick={() => {
            //   setShowPathlabMessage(true);
            //   setLoading(false);
            //   setTimeout(() => {
            //     setShowPathlabMessage(false);
            //   }, 4000);
            // }}
          >
            <div className="bg-[#D5D8FC] w-[145px] h-[89px] xs:h-[106px] xs:w-[165px] sm:h-[180px] sm:w-[269px] rounded-t-[30px]">
              <img
                src={Pathlab}
                alt=""
                className="  w-[130px] h-[90px] xs:h-[106px] sm:w-[200px] sm:h-[180px] rounded-[10px]"
              />
            </div>
            <h2 className=" text-left ml-[9px] xs:ml-[20px] xs:mr-[9px] mt-[8px] xs:mt-[11px] pb-[2px] xs:pb-[8px] sm:mx-[33px] sm:mt-[33px] font-medium font-lato text-[20px] sm:text-[24px] text-[#000] ">
              Pathlab
            </h2>
            <p className="hidden sm:block ml-[20px] mr-[9px] sm:mx-[33px] mb-[33px] text-[#979797] text-[13px] sm:text-[15px] font-normal pb-[4px]">
              Digital / Physical pathlab reports delivery
            </p>
            <p className=" sm:hidden text-left ml-[9px] xs:ml-[20px] mr-[9px] text-[12px] xs:text-[13px] mb-[33px] text-[#979797]  sm:text-[15px] font-normal pb-[4px]">
              Digital / Physical pathlab reports del..
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurOfferings;
