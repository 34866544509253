import React, { useEffect, useState } from "react";
import bg from "../../images/Rectangle_102.png";
import Header from "../HomePage/Header";
import { useNavigate } from "react-router";
import siteConfig from "../../urlFile";
import axios from "axios";
import PageLoader from "../../Loader/PageLoader";

const MedicineAll = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [medicineData, setMedicineData] = useState([]);
  const [counter, setCounter] = useState({});
  const [existItem, setExistItem] = useState({});
  const [addedToCartItems, setAddedToCartItems] = useState([]);
  const [showAddedToCartMessage, setShowAddedToCartMessage] = useState(false);

  const handleRoute = (path, medicineDetails) => {
    setLoading(true);
    setTimeout(() => {
      navigate(path, { state: { medicineDetails } });
    }, 1000);
  };

  // API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${siteConfig.BASE_URL}${siteConfig.GET_ALL_MEDICINE}`
        );
        setMedicineData(response.data.data);
        // initializeCounter(response.data.data);
        console.log("Fetched Medical Specialties:", response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const getMedicineImageUrl = (imageName) =>
    `https://app.healthcrad.com/api/uploads/medicine/${imageName}`;

  const handleAddToCart = async (medicineId) => {
    setLoading(true);

    try {
      const response = await axios.post(
        `${siteConfig.BASE_URL}${siteConfig.ADD_TO_CART}`,
        {
          id: localStorage.getItem("userId"),
          productid: medicineId,
          quantity: 1,
        }
      );

      if (response.data.msg === "Product is already exist") {
        setExistItem((prevState) => ({ ...prevState, [medicineId]: true }));
        setTimeout(() => {
          setExistItem((prevState) => ({ ...prevState, [medicineId]: false }));
        }, 2000);
      } else {
        setAddedToCartItems((prevItems) => [...prevItems, medicineId]);
        setShowAddedToCartMessage((prevState) => ({
          ...prevState,
          [medicineId]: true,
        }));
        setTimeout(() => {
          setShowAddedToCartMessage((prevState) => ({
            ...prevState,
            [medicineId]: false,
          }));
        }, 2000);
      }
    } catch (error) {
      console.error("Error adding item to cart:", error);
      alert(
        "An error occurred while adding the item to the cart. Please try again later."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Header />
      {loading && <PageLoader />}
      <div className="pt-20 pb-10 font-poppins">
        <div className="flex justify-center items-center ">
          <p className=" font-lato text-[#00B4D8] text-2xl sm:text-3xl font-semibold ">
            Medicines
          </p>
        </div>
        <div
          className=" w-full overflow-x-hidden  grid lg:grid-cols-4 xl:grid-cols-5 md:grid-cols-3 grid-cols-2 bg-repeat-x shadow-md p-3 pt-4 mt-6 xl:gap-6 gap-3"
          style={{ backgroundImage: `url(${bg})` }}
        >
          {medicineData.map((medicineDetails) => (
            <div
              key={medicineDetails.id}
              className="flex justify-center relative"
            >
              {showAddedToCartMessage[medicineDetails.id] && (
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50">
                  <div className="bg-green-500 text-white p-2 rounded">
                    <p className=" w-44 sm:w-60 text-center">
                      Item added to the cart!
                    </p>
                  </div>
                </div>
              )}
              {existItem[medicineDetails.id] && (
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50">
                  <div className="bg-green-500 text-white p-2 rounded">
                    <p className=" w-44 sm:w-60 text-center">
                      Item is already added in the cart!
                    </p>
                  </div>
                </div>
              )}
              <div
                className=" sm:w-[230px] sm:h-[302px] mb-4 p-2 w-[205px] h-[245px] rounded-[10px] border-[1px] bg-[#FFF]"
                style={{
                  boxShadow: "0 4px 8px rgba(0,0,0,.12)",
                }}
              >
                <div
                  className="cursor-pointer flex justify-end"
                  onClick={() =>
                    handleRoute(
                      "/medicine/medicineDescription",
                      medicineDetails
                    )
                  }
                >
                  <div className="bg-[#008000] z-10 p-1 rounded-bl-[8px] rounded-br-[8px] mr-[4px] sm:mr-[8px] -mt-[9px] flex justify-center items-center w-auto sm:w-14">
                    <p className="text-[#FFF]  font-bold sm:text-lg text-sm font-poppins text-center">
                      {" "}
                      {medicineDetails.discount}%
                      <br />
                      OFF{" "}
                    </p>
                  </div>
                </div>
                <div
                  className="cursor-pointer flex flex-col gap-1 p-2 -mt-6 justify-center items-center"
                  onClick={() =>
                    handleRoute(
                      "/medicine/medicineDescription",
                      medicineDetails
                    )
                  }
                >
                  <img
                    className="sm:w-[105px] sm:h-[105px] w-[80px] h-[80px]"
                    src={getMedicineImageUrl(medicineDetails.image[0])}
                    alt=""
                  />
                  <p className="text-xs sm:text-sm font-poppins text-[#000] font-medium overflow-hidden line-clamp-1 ">
                    {medicineDetails.name}
                  </p>
                </div>
                <div className="underline-offset-8 w-full border-[1px] color-[#0000002E] center-alignment"></div>
                <div className="  pt-2 text-[#0000008A] text-sm sm:text-[14px] flex font-poppins">
                  {" "}
                  <span className="font-bold mr-[2px]">Rs.</span>
                  <del className="">{medicineDetails.price}</del>
                </div>

                {medicineDetails.prescription === "1" && (
                  <div className="absolute ml-[93px]  sm:ml-[188px] -mt-3 ">
                    <p className="text-[15px] font-bold rounded-[5px] w-7 text-center border-[1px] border-[#10CAF2] text-[#10CAF2]">
                      Rx
                    </p>
                  </div>
                )}
                <div className=" pt-0.5 text-[#000] font-bold text-sm sm:text-[17px] font-poppins">
                  <p className="">
                    {" "}
                    <span className="mr-[2px]">Rs.</span>
                    {medicineDetails.discounted_amount}
                  </p>
                </div>
                <div className="flex justify-center items-center mt-1.5 sm:mt-0 sm:pt-2.5 pb-1">
                  <div
                    className={`w-full relative ${
                      addedToCartItems.includes(medicineDetails.id)
                        ? "bg-[#008000]"
                        : "bg-[#10CAF2]"
                    }
                    h-8 sm:h-10 rounded-[6px] cursor-pointer flex justify-center items-center`}
                  >
                    {addedToCartItems.includes(medicineDetails.id) ? (
                      <button
                        className="text-sm sm:text-lg text-white font-bold"
                        onClick={() => navigate("/cart")} // Go to cart
                      >
                        Go to cart
                      </button>
                    ) : (
                      <button
                        className="text-sm sm:text-lg text-white font-bold"
                        onClick={() => handleAddToCart(medicineDetails.id)}
                      >
                        Add to cart
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default MedicineAll;
