import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BhavahGroup from "../../images/Supported by/Bhavah Group.png";
import GovInd from "../../images/Supported by/gov ind.png";
import GovtOfBihar from "../../images/Supported by/Govt. of bihar.png";
import LetsInspireBihar from "../../images/Supported by/Lets Inspire Bihar.png";
import Microsoft from "../../images/Supported by/Microsoft.png";
import NASSCOM from "../../images/Supported by/NASSCOM.png";
import PCE from "../../images/Supported by/PCE.png";
import StartupBihar from "../../images/Supported by/Startup bihar.png";
import StartupIndia from "../../images/Supported by/Startup india.png";
import StartupZone from "../../images/Supported by/Startup Zone.png";
import Wadhwani from "../../images/Supported by/Wadhwani.png";
import Xartup from "../../images/Supported by/xartup.png";
import Aws from "../../images/Supported by/Aws.png";

import { Link } from "react-router-dom";

// const PreviousBtn = (props) => {
//   // console.log(props);
//   const { className, onClick, currentSlide } = props;
//   return (
//     <>
//       <div className={className} onClick={onClick}>
//         <ArrowBackIosNewIcon style={{ color: "blue", fontSize: "30px" }} />
//       </div>
//     </>
//   );
// };

// const NextBtn = (props) => {
//   const { className, onClick, slideCount, currentSlide } = props;
//   // console.log(props);
//   return (
//     <>
//       <div className={className} onClick={onClick}>
//         <ArrowForwardIosIcon style={{ color: "blue", fontSize: "30px" }} />
//       </div>
//     </>
//   );
// };

const SimpleSlider = () => {
  var settings = {
    infinite: true,
    dots: false,
    autoplay: true,
    autoplaySpeed: 4000, // Time between slides
    speed: 500, // Transition speed
    backDelay: 10000, // Time to wait before transitioning to the next slide
    slidesToShow: 4,
    slidesToScroll: 1,
    // prevArrow: <PreviousBtn />,
    // nextArrow: <NextBtn />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="mt-4 sm:mt-8 mb-8">
      <div className="h-auto bg-gradient-to-t from-[#FFFFFF] to-[#C1CBFF1A] flex flex-col justify-center rounded-tl-2xl rounded-tr-2xl">
        <h1 className=" mt-[18px] mb-4 text-[18px] sm:text-[24px] font-semibold font-lato pl-[13px] sm:pl-[28px] md:pl-[37px] lg:pl-[50px] xl:pl-[28px] 2xl:pl-[53px]">
          Supported By
        </h1>
        <div className="overflow-x-hidden">
          <Slider {...settings}>
            <Link to="https://startup.bihar.gov.in/" target="_blank">
              <img src={StartupBihar} alt="" className=" h-[89px]   " />
            </Link>

            <Link to="https://www.startupindia.gov.in/" target="_blank">
              <img
                src={StartupIndia}
                alt=""
                className=" h-[50px] sm:h-[89px] sm:w-[224px] w-[100px] mt-[27px]"
              />
            </Link>

            <Link
              to="https://chat.whatsapp.com/LvGZcSzhRsGLOBpEXsfYpC"
              target="_blank"
            >
              <img
                src={StartupZone}
                alt=""
                className=" h-[89px] sm:h-[100px] sm:w-[224px] w-[100px]"
              />
            </Link>

            <img
              src={GovInd}
              alt=""
              className=" h-[72px] sm:h-[89px] sm:w-[224px] w-[100px]"
            />

            <Link to="https://www.pcepurnia.org/" target="_blank">
              <img src={PCE} alt="" className=" h-[95px] sm:h-[120px] " />
            </Link>

            <Link to="https://www.wfglobal.org/" target="_blank">
              <img
                src={Wadhwani}
                alt=""
                className="h-[64px] sm:h-[89px] sm:w-auto w-[100px]"
              />
            </Link>

            <Link
              to="https://nasscom.in/deeptech/deeptechclub/our-startup.php"
              target="_blank"
            >
              <img
                src={NASSCOM}
                alt=""
                className=" sm:h-[89px] sm:w-auto w-[100px] mt-[26px] sm:mt-0"
              />
            </Link>

            <Link to=" " target="">
              <img
                src={BhavahGroup}
                alt=""
                className=" h-[89px] sm:h-[120px] w-[224px] "
              />
            </Link>

            <Link to="https://www.microsoft.com/en-us/startups" target="_blank">
              <img
                src={Microsoft}
                alt=""
                className="h-[47px] mt-[23px] sm:mt-0 sm:h-[89px]  sm:w-[224px] w-[100px] "
              />
            </Link>

            <Link to="https://www.letsinspirebihar.org/" target="_blank">
              <img
                src={LetsInspireBihar}
                alt=""
                className=" h-[79px] sm:h-[89px]  w-[224px] "
              />
            </Link>

            <Link to="https://www.xartup.com/" target="_blank">
              <img src={Xartup} alt="" className=" h-[89px]    " />
            </Link>

            <Link to="https://www.xartup.com/" target="_blank">
              <img src={GovtOfBihar} alt="" className=" h-[89px]    " />
            </Link>

            <Link to="https://www.xartup.com/" target="_blank">
              <img src={Aws} alt="" className=" h-[124px]    " />
            </Link>
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default SimpleSlider;
