import { useEffect, useState } from "react";
import Header from "../Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import bg from "../../../images/Light-Blue-.png";
import { useLocation, useNavigate } from "react-router-dom";
import siteCofig from "../../../urlFile";
import axios from "axios";
import PageLoader from "../../../Loader/PageLoader";

const EditAccount = () => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [usernameError, setUsernameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [emergencyMobileNo1Error, setEmergencyMobileNo1Error] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [profileMsg, setProfileMsg] = useState(false);
  const [isInputFocused, setIsInputFocused] = useState(false);

  const [userInfo, setUserInfo] = useState({
    username: "",
    phone: "",
    email: "",
    image: null,
    emergency_mobile_no_1: "",
    emergency_mobile_no_2: "",
    emergency_mobile_no_3: "",
  });

  const getUserImageUrl = (imageName) =>
    `https://app.healthcrad.com/api/uploads/${imageName}`;

  useEffect(() => {
    const { userData } = location.state || {};

    setUserInfo((prev) => ({
      ...prev,
      username: userData.username,
      email: userData.email,
      phone: userData.phone,
      image: userData.image,
      emergency_mobile_no_1: userData.emergency_mobile_no_1,
      emergency_mobile_no_2: userData.emergency_mobile_no_2,
      emergency_mobile_no_3: userData.emergency_mobile_no_3,
    }));

    console.log(userData, "kkkkkkkkk");
  }, [location.state]);

  const handleImageChange = (event) => {
    const selectedFile = event.target.files[0];
    console.log("Selected File:", selectedFile);
    const reader = new FileReader();
    reader.onloadend = () => {
      const img = new Image();
      img.src = reader.result;
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const MAX_WIDTH = 500;
        const MAX_HEIGHT = 500;
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }

        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);
        canvas.toBlob(
          (blob) => {
            const file = new File([blob], "profile_image.jpg", {
              type: "image/jpeg",
            });
            setUserInfo((prev) => ({ ...prev, image: file }));
          },
          "image/jpeg",
          0.7
        );
      };
    };
    if (selectedFile) {
      reader.readAsDataURL(selectedFile);
    }
  };

  const handleCameraClick = () => {
    if (!userInfo.image) {
      const fileInput = document.getElementById("upload-image");
      if (fileInput) {
        fileInput.click();
      }
    }
  };

  const handleSave = () => {
    if (
      userInfo.username.trim() &&
      userInfo.email.trim() &&
      userInfo.emergency_mobile_no_1.trim() &&
      /^\d{10}$/.test(userInfo.emergency_mobile_no_1.trim())
    ) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(userInfo.email.trim())) {
        setShowErrorMessage(true);
        setTimeout(() => {
          setShowErrorMessage(false);
        }, 4000);
        return;
      }

      setShowMessage(true);
      setTimeout(() => {
        setShowMessage(false);

        setUserInfo((prev) => ({
          ...prev,
          username: "",
          email: "",
          phone: "",
          image: "",
          emergency_mobile_no_1: "",
          emergency_mobile_no_2: "",
          emergency_mobile_no_3: "",
        }));
      }, 3000);
    } else {
      setShowErrorMessage(true);
      setTimeout(() => {
        setShowErrorMessage(false);
      }, 4000);
    }
  };

  const handlePostRequest = async () => {
    const reqBody = new FormData();
    reqBody.append("id", localStorage.getItem("userId"));
    reqBody.append("username", userInfo.username);
    reqBody.append("phone", userInfo.phone);
    reqBody.append("email", userInfo.email);
    reqBody.append("emergency_mobile_no_1", userInfo.emergency_mobile_no_1);
    reqBody.append("emergency_mobile_no_2", userInfo.emergency_mobile_no_2);
    reqBody.append("emergency_mobile_no_3", userInfo.emergency_mobile_no_3);
    reqBody.append("image", userInfo.image);
    console.log(reqBody, "bbbbbbbbbbb");

    if (!userInfo.username.trim()) {
      setUsernameError("Name is required");
      return; // Prevent further execution if validation fails
    } else {
      setUsernameError("");
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(userInfo.email)) {
      setEmailError("Please enter a valid email address");
      return;
    } else {
      setEmailError("");
    }

    if (
      !userInfo.emergency_mobile_no_1.trim() ||
      !/^\d{10}$/.test(userInfo.emergency_mobile_no_1.trim())
    ) {
      setEmergencyMobileNo1Error("Emergency No.1 is required");
      return;
    } else {
      setEmergencyMobileNo1Error("");
    }
    try {
      const response = await axios.post(
        siteCofig.BASE_URL + siteCofig.UPDATE_PROFILE,
        reqBody
      );
      console.log("API Response:", response.data);
      if (response.data.msg === "Sucsess" || response.data.error == 200) {
        localStorage.setItem("userName", userInfo.username);
        setLoading(true);
        setProfileMsg(true);
        setTimeout(() => {
          setLoading(false);
          setProfileMsg(false);
          navigate("/your-account");
        }, 1000);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <Header />
      {loading && <PageLoader />}
      <div
        className="mb-10 pb-24 pt-28 px-4 sm:px-0 bg-lightgray bg-cover bg-no-repeat bg-center"
        style={{ backgroundImage: `url(${bg})` }}
      >
        {profileMsg && (
          <div className="bg-green-500 text-white p-2 absolute top-1/3 z-50 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded">
            <p className="w-56 sm:w-80 text-center font-lato">
              Profile details updated successfully.
            </p>
          </div>
        )}
        <div className="flex flex-col justify-center items-center gap-3">
          <div className="flex flex-col lg:flex-row w-full   md:w-[80%] lg:w-[99%] xl:w-[65%]">
            <p className="font-poppins text-[#FFF] text-2xl font-semibold self-start">
              Edit Account
            </p>
          </div>
          <div className="flex flex-col lg:flex-row w-full md:w-[80%] lg:w-[99%] xl:w-[65%] rounded-[20px]">
            <div
              className="flex flex-col justify-center items-center w-full lg:w-[35%] p-4 pb-4 rounded-tl-[10px] lg:rounded-bl-[10px] rounded-tr-[10px] lg:rounded-tr-[0px]"
              style={{
                background:
                  "linear-gradient(92deg, #22B6D6 1.22%, #1A2A7E 98.62%)",
              }}
            >
              <div className="relative">
                <div className="flex items-center justify-center rounded-full overflow-hidden w-20 h-20 lg:w-32 lg:h-32 bg-gray-300 relative">
                  {userInfo.image instanceof File ? (
                    <img
                      src={URL.createObjectURL(userInfo.image)}
                      alt="User"
                      className="w-full h-full object-cover"
                    />
                  ) : (
                    <img
                      src={getUserImageUrl(userInfo.image)}
                      alt=""
                      className="w-full h-full object-cover"
                    />
                  )}
                </div>
                <label
                  htmlFor="upload-image"
                  className="absolute bottom-0 right-0"
                >
                  <FontAwesomeIcon
                    icon={faCamera}
                    className="text-gray-600 text-xl cursor-pointer bg-white rounded-full p-1"
                    onClick={handleCameraClick}
                  />
                  <input
                    type="file"
                    id="upload-image"
                    accept="image/*"
                    className="hidden"
                    onChange={handleImageChange}
                  />
                </label>
              </div>
              <input
                type="text"
                className={` mt-3 text-xl w-[80%] sm:w-[50%] lg:w-[90%] font-medium font-poppins text-[#FFF] text-center bg-brown-500 rounded-lg p-2 focus:outline-none border ${
                  isInputFocused ? "border-black" : "border-transparent"
                }`}
                value={userInfo.username}
                onFocus={() => setIsInputFocused(true)}
                onBlur={() => setIsInputFocused(false)}
                onChange={(e) =>
                  setUserInfo((prev) => ({ ...prev, username: e.target.value }))
                }
                style={{
                  background:
                    "linear-gradient(92deg, #22B6D6 1.22%, #1A2A7E 98.62%)",
                }}
              />
            </div>
            <div className="flex flex-col lg:rounded-tr-[10px] rounded-br-[10px] rounded-bl-[10px] lg:rounded-bl-[0px] gap-6 w-full lg:w-[65%] border-[1px] border-[#00000033] bg-white shadow-lg p-4 pb-4">
              <div className="flex flex-col ml-4">
                <h3 className="text-lg text-[#00B4D8] font-semibold font-poppins">
                  User Details
                </h3>
                <div className="w-auto  mt-2 border-[1px] border-[#0000002E]"></div>
              </div>

              <div className="flex flex-col lg:flex-row gap-4 ml-4 mt-3">
                <div className="flex flex-col">
                  <label
                    className="text-[#666161] font-poppins font-bold"
                    htmlFor="name"
                  >
                    Phone
                  </label>
                  <input
                    className="w-full sm:w-[250px] h-[37px] placeholder:text-[18px] pl-[8px] focus:outline-none rounded-md border border-gray-300 mb-2"
                    type="tel"
                    name="name"
                    required
                    value={userInfo.phone}
                    disabled
                  />
                </div>
                <div className="flex flex-col">
                  <label
                    className="text-[#666161] font-poppins font-bold"
                    htmlFor="email"
                  >
                    Email
                  </label>
                  <input
                    className="w-full sm:w-[250px] h-[37px] placeholder:text-[18px] focus:outline-none rounded-md border border-gray-300 pl-[8px] mb-2"
                    type="email"
                    name="email"
                    required
                    value={userInfo.email}
                    onChange={(e) =>
                      setUserInfo((prev) => ({
                        ...prev,
                        email: e.target.value,
                      }))
                    }
                  />
                </div>
              </div>

              <div className="flex flex-col ml-4 mt-1">
                <div className="w-full border-[1px] border-[#0000002E]"></div>
                <h3 className="mt-3 text-lg text-[#00B4D8] font-semibold font-poppins">
                  Emergency contact numbers
                </h3>
              </div>

              <div className="grid lg:grid-cols-2 grid-cols-1 gap-4 ml-4">
                <div className="flex flex-col">
                  <label
                    className="text-[#666161] font-poppins font-bold"
                    htmlFor="emergencyNo1"
                  >
                    Emergency No.1
                  </label>
                  <input
                    className="w-full sm:w-[250px] h-[37px] focus:outline-none rounded-md border border-gray-300 pl-[8px]"
                    type="text"
                    id="emergencyNo1"
                    name="emergencyNo1"
                    value={userInfo.emergency_mobile_no_1}
                    onChange={(e) =>
                      setUserInfo((prev) => ({
                        ...prev,
                        emergency_mobile_no_1: e.target.value,
                      }))
                    }
                  />
                </div>
                <div className="flex flex-col">
                  <label
                    className="text-[#666161] font-poppins font-bold"
                    htmlFor="emergencyNo2"
                  >
                    Emergency No.2
                  </label>
                  <input
                    className="w-full sm:w-[250px] h-[37px] focus:outline-none rounded-md border border-gray-300 pl-[8px]"
                    type="text"
                    id="emergencyNo2"
                    name="emergencyNo2"
                    value={userInfo.emergency_mobile_no_2}
                    onChange={(e) =>
                      setUserInfo((prev) => ({
                        ...prev,
                        emergency_mobile_no_2: e.target.value,
                      }))
                    }
                  />
                </div>
                <div className="flex flex-col">
                  <label
                    className="text-[#666161] font-poppins font-bold"
                    htmlFor="emergencyNo3"
                  >
                    Emergency No.3
                  </label>
                  <input
                    className="w-full sm:w-[250px] h-[37px] focus:outline-none rounded-md border border-gray-300 pl-[8px]"
                    type="text"
                    id="emergencyNo3"
                    name="emergencyNo3"
                    value={userInfo.emergency_mobile_no_3}
                    onChange={(e) =>
                      setUserInfo((prev) => ({
                        ...prev,
                        emergency_mobile_no_3: e.target.value,
                      }))
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="hidden sm:block justify-end w-full">
            <div className=" flex mt-2 w-full justify-end md:w-[80%] lg:w-[95%] xl:w-[80%]">
              <div
                className="rounded-[10px] flex justify-end self-end"
                style={{
                  background:
                    "linear-gradient(180deg, #1A30A1 0%, #16A9CA 100%)",
                }}
              >
                <button
                  className="w-48 h-10 self-end  font-poppins text-[#FFF] text-md font-semibold"
                  onClick={() => handlePostRequest()}
                >
                  Save & Continue
                </button>
              </div>
            </div>
          </div>
          {/* mobile view button design start */}
          <div
            className=" sm:hidden fixed z-50 flex justify-around items-center    w-full bottom-0 "
            style={{
              background: "linear-gradient(180deg, #1A30A1 0%, #16A9CA 100%)",
            }}
          >
            <div
              className="select-none rounded-[4px] shadow-md w-[170px]  h-11  flex items-center justify-center cursor-pointer"
              onClick={() => handlePostRequest()}
            >
              <button className="w-40 h-10 self-end font-poppins text-[#FFF] text-md font-semibold">
                Save & Continue
              </button>
            </div>
          </div>
          {/* mobile view button design end */}
        </div>
      </div>
    </>
  );
};

export default EditAccount;
