import React, { useState } from "react";
import orderPlacedPic from "../../../images/placed.gif";
import { useNavigate } from "react-router-dom";
import PageLoader from "../../../Loader/PageLoader";

const CartOrderPlaced = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const handleRoute = (path) => {
    setLoading(true);
    setTimeout(() => {
      navigate(path);
    }, 1000);
  };

  return (
    <>
      <div className=" pt-14  pb-10 ">
        {loading && <PageLoader />}
        <div className=" flex flex-col justify-center items-center gap-10 ">
          <div className="  flex justify-center items-center">
            <img
              src={orderPlacedPic}
              alt=""
              srcset=""
              className="  h-[350px] w-full sm:h-[450px] "
            />
          </div>
          <div className="-mt-4 flex justify-center items-center">
            <p className="self-center text-[#000000] font-lato font-semibold text-xl sm:text-2xl text-center ">
              Your order has been successfully
              <br /> placed
            </p>
          </div>
          <div className=" flex flex-col gap-3  justify-center items-center ">
            <p className=" text-[#000000] font-lato font-semibold text-md sm:text-lg  ">
              Go to
              <span
                className="cursor-pointer text-[#20CD27]"
                onClick={() => handleRoute("/myorders")}
              >
                {" "}
                My Order{" "}
              </span>
              section
            </p>
            <button
              onClick={() => handleRoute("/")}
              className="bg-[#20CD27] curso p-1  w-14 h-11 self-center rounded-[5px] text-[#FFF] "
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CartOrderPlaced;
