import React from "react";
import EmptyAmb from "../../../../images/Empty_Pages_Images/advance_life_support_ambulance- 1.png";

const EmptyAmbBooking = () => {
  return (
    <>
      <div className=" flex justify-center items-center p-2 mt-20">
        <div className="flex flex-col justify-center items-center gap-5">
          <div className=" flex justify-center items-center">
            <p className="text-[#66616180] text-center font-poppins font-semibold text-lg sm:text-xl ">
              No any ambulance booking !
            </p>
          </div>
          <div className=" flex justify-center items-center">
            <img
              src={EmptyAmb}
              alt=""
              srcset=""
              className="w-[300px] h-[200px] sm:h-[330px] sm:w-[430px]  "
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default EmptyAmbBooking;
