import React from "react";
import Header from "../Header";
import Viceversa from "../../../images/Ambulance Booking/viceversa-arrow.png";
import img2 from "../../../images/frontend UI materials/Request a call/user.png";
import img4 from "../../../images/frontend UI materials/Request a call/phone.png";
import Swal from "sweetalert2";

const AmbulanceBookConfirmation = () => {
  const myAlert = () => {
    Swal.fire({
      title: "Ambulance Booking Successfully",
      text: "Go to the Ambulance Booking Section",
      icon: "success",
      customClass: {
        confirmButton: "custom-ok-button-class",
      },
    });
  };

  return (
    <>
      <Header />
      <div className="pt-20 ">
        <div className=" cursor-pointer mx-auto mb-3 grid place-items-center rounded-[10px] border-[1px] border-[#10CAF2] bg-[#FFF]  sm:w-[120px] sm:h-[45px] w-[100px] h-10  lg:ml-[2px] ml-1">
          <p className="xl:text-lg sm:text-md md:text-lg text-[#00B4D8] font-poppins">
            Review
          </p>
        </div>

        <div className="lg:mx-16 border-[1px] border-[#00000026]  mt-[28px] shadow-md bg-[#F5F2F2]">
          {/* find Distance */}
          <div className="mx-auto w-[300px] xs:w-[360px] sm:w-auto lg:w-[764px] lg:h-[150px] rounded-[10px] border-[1px] border-solid border-[#CECBCB] bg-[#ffffff] shadow-md mt-[30px] mb-[48px]">
            <p className="flex items-center justify-center font-medium lg:text-[20px] md:text-[16px] xs:text-[15px] text-[12px] text-[#07A50D] pt-2">
              Line bazar, Purnea, Bihar
            </p>
            <div className="flex gap-1">
              <p className=" underline-offset-8 w-full h-0 ml-4 pr-8 mt-[35px] border-[1px] color-[#0000002E] center-alignment"></p>
              <img
                src={Viceversa}
                alt=""
                className="h-[50px] w-[50px] mt-2 mr-2 ml-1"
              />
            </div>
            <p className="flex items-center justify-center font-medium font-poppins lg:text-[20px] md:text-[16px] xs:text-[15px] text-[12px] text-[#FE0505] pb-2 sm:pt-2.5">
              Max Hospital, Patna, Bihar
            </p>
          </div>

          {/* total distance button */}
          <div className="flex  mx-auto  w-[300px] xs:w-[360px] sm:w-full px-[28px] mt-[42px] mb-[32px] bg-[#fff] place-items-center justify-between font-poppins font-medium lg:w-[764px] h-[50px]  border-[1px] border-[#00000026] shadow-[0px_4px_4px_0px_rgba(0,0,0,0.20)] rounded-[10px]">
            <p className="lg:text-[20px] md:text-[16px] xs:text-[15px] text-[12px] text-[#4A4141]">
              Ambulance Type:
            </p>
            <p className="lg:text-[20px] md:text-[16px] xs:text-[15px] text-[12px] text-[#00B4D8]">
              Patient Transport
            </p>
          </div>

          <div className="flex  mx-auto  w-[300px] xs:w-[360px] sm:w-full px-[28px] mt-[42px] mb-[32px] bg-[#fff] place-items-center justify-between font-poppins font-medium lg:w-[764px] h-[50px]  border-[1px] border-[#00000026] shadow-[0px_4px_4px_0px_rgba(0,0,0,0.20)] rounded-[10px]">
            <p className="lg:text-[20px] md:text-[16px] xs:text-[15px] text-[12px] text-[#4A4141]">
              Total Distance:
            </p>
            <p className="lg:text-[20px] md:text-[16px] xs:text-[15px] text-[12px] sm:text-[12px] text-[#00B4D8]">
              00 KM
            </p>
          </div>
          {/* two input button */}
          <div className=" flex flex-wrap justify-center  sm:gap-0 gap-2 lg:justify-between md:justify-between sm:justify-between lg:mx-44">
            <div className="space-x-2 flex border-1 justify-center   items-center rounded-[10px] bg-[#fff] border-[1px] border-[#CECBCB] shadow-[0px_4px_4px_0px_rgba(0,0,0,0.20)]">
              <span className="ml-[2px] ">
                <img
                  className=" w-[20px] h-[20px] sm:w-[27px] sm:h-[27px] ml-2 "
                  src={img2}
                  alt="Error"
                />
              </span>
              <input
                className="py-3 bg-[#fff] rounded-[10px]  h-[50px] placeholder:text-[12px] md:placeholder:text-[12px] lg:placeholder:text-[20px] w-[265px] sm:w-auto font-poppins focus:outline-none text-[15px] sm:text-[20px] font-normal text-[#979797]  pl-2"
                type="text"
                placeholder="Sunil Kumar"
                name="name"
                required
              />
            </div>
            <div className="space-x-2 flex border-1 justify-center   items-center rounded-[10px] bg-[#fff] border-[1px] border-[#CECBCB] shadow-[0px_4px_4px_0px_rgba(0,0,0,0.20)]  mb-20 sm:mb-0">
              <span className="ml-[2px] ">
                <img
                  className=" w-[20px] h-[20px] sm:w-[27px] sm:h-[27px] ml-2 "
                  src={img4}
                  alt="Error"
                />
              </span>
              <input
                className="py-3  bg-[#fff] rounded-[10px] h-[50px] placeholder:text-[12px] md:placeholder:text-[12px] lg:placeholder:text-[20px] w-[265px] sm:w-auto font-poppins focus:outline-none text-[15px] sm:text-[20px] font-normal text-[#979797]  pl-2"
                type="text"
                placeholder="9973025486"
                name="Phone no."
                required
              />
            </div>
          </div>
          {/* Continue button */}
          <div className=" hidden sm:block">
            <div
              className="mx-auto grid place-items-center w-[230px] h-[50px] items-center justify-center group bg-[#00B4D8] rounded-[10px] hover:bg-[#00B4D8] border-[1px] border-[#00B4D8] mt-5 mb-1"
              onClick={() => myAlert()}
            >
              <button className="lg:text-[20px] md:text-[16px] text-[15px] font-poppins text-[#fff] px-2 py-2 rounded-md group-hover:text-white">
                Confirm Booking
              </button>
            </div>
          </div>
          {/* mobile view button design start */}
          <div className=" sm:hidden fixed z-50 flex justify-around  items-center bg-gray-100   w-full bottom-0 ">
            <div className=" flex flex-col pt-2.5 ">
              <p className="text-[13px] font-poppins font-medium  text-[#00B4D8] ">
                Total Dist.
              </p>
              <p className=" text-[18px] font-poppins font-semibold text-[#000000] ">
                55 Km
              </p>
            </div>
            <div
              className="select-none rounded-[4px] border-[1px] border-[#10CAF2] shadow-md bg-[#00B4D8] w-[170px]  h-11  flex items-center justify-center cursor-pointer"
              onClick={() => myAlert()}
            >
              <button className=" text-white font-lato text-[16px]">
                Confirm Booking
              </button>
            </div>
          </div>
          {/* mobile view button design end */}
        </div>
      </div>
    </>
  );
};

export default AmbulanceBookConfirmation;
