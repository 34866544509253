import React, { useState, useEffect } from "react";
import Header from "../Header";
import bg from "../../../images/Rectangle_102.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import Slider from "react-slick";
import { useLocation } from "react-router";
import siteConfig from "../../../urlFile";
import axios from "axios";
import PageLoader from "../../../Loader/PageLoader";
import { useNavigate } from "react-router";

const Medicine = () => {
  const [slider, setSlider] = useState(null);
  const [activeCategory, setActiveCategory] = useState(null);
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [category, setCategory] = useState([]);
  const [counter, setCounter] = useState({});
  const [selectedCategory, setSelectedCategory] = useState("");
  const [medicines, setMedicines] = useState([]);
  const [existItem, setExistItem] = useState({});
  const [addedToCartItems, setAddedToCartItems] = useState([]);
  const [showAddedToCartMessage, setShowAddedToCartMessage] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const categoryParam = searchParams.get("category");
    if (categoryParam) {
      setSelectedCategory(categoryParam);
      setActiveCategory(categoryParam);
    }
  }, [location.search]);

  const getCategoryClassName = (categoryId) => {
    return `transition-transform duration-300 ${
      activeCategory === categoryId ? "transform scale-110" : ""
    }`;
  };
  const handleRoute = (path, medicineDetails) => {
    setLoading(true);
    setTimeout(() => {
      navigate(path, { state: { medicineDetails } });
    }, 1000);
  };

  const settings = {
    infinite: false,
    dots: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 4,
    autoplay: false,
    speed: 1800,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 850,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 760,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const getcategoryImageUrl = (imageName) =>
    `https://app.healthcrad.com/api/uploads/${imageName}`;
  const getMedicineImageUrl = (imageName) =>
    `https://app.healthcrad.com/api/uploads/medicine/${imageName}`;

  const goToPrev = () => {
    slider && slider.slickGoTo(slider.innerSlider.state.currentSlide - 1);
  };

  const goToNext = () => {
    slider && slider.slickGoTo(slider.innerSlider.state.currentSlide + 1);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${siteConfig.BASE_URL}${siteConfig.MEDICINE_CATEGORY}`
        );
        setCategory(response.data.data);
        console.log("Fetched category Specialties:", response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (selectedCategory) {
      fetch(`${siteConfig.BASE_URL}${siteConfig.GET_MEDICINE_BY_CATEGORY}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ category: selectedCategory }),
      })
        .then((response) => response.json())
        .then((data) => {
          setMedicines(data.data);
          // initializeCounter(data.data);
        })
        .catch((error) => {
          console.error("Error fetching medicines:", error);
        });
    }
  }, [selectedCategory]);

  const handleCategoryClick = (categoryId) => {
    setSelectedCategory(categoryId);
    setActiveCategory(categoryId);
  };

  const handleAddToCart = async (medicineId) => {
    setLoading(true);

    try {
      const response = await axios.post(
        `${siteConfig.BASE_URL}${siteConfig.ADD_TO_CART}`,
        {
          id: localStorage.getItem("userId"),
          productid: medicineId,
          quantity: 1,
        }
      );

      if (response.data.msg === "Product is already exist") {
        setExistItem((prevState) => ({ ...prevState, [medicineId]: true }));
        setTimeout(() => {
          setExistItem((prevState) => ({ ...prevState, [medicineId]: false }));
        }, 2000);
      } else {
        setAddedToCartItems((prevItems) => [...prevItems, medicineId]);
        setShowAddedToCartMessage((prevState) => ({
          ...prevState,
          [medicineId]: true,
        }));
        setTimeout(() => {
          setShowAddedToCartMessage((prevState) => ({
            ...prevState,
            [medicineId]: false,
          }));
        }, 2000);
      }
    } catch (error) {
      console.error("Error adding item to cart:", error);
      alert(
        "An error occurred while adding the item to the cart. Please try again later."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Header />
      {loading && <PageLoader />}
      <div className="pt-20 pb-10 font-poppins">
        <div className="flex flex-col justify-center items-center gap-2">
          <p className="self-center text-center lg:text-2xl sm:text-lg font-bold font-lato text-lg md:text-xl text-[#00B4D8] ">
            Medicine by category
          </p>
          {category.length > 0 && (
            <div className="w-[95%] overflow-x-hidden">
              <Slider
                className="your-slider-styles"
                {...settings}
                ref={(slider) => setSlider(slider)}
              >
                {category.map((category) => (
                  <div key={category.id} className="px-2 mb-11 mt-4">
                    <div
                      className={`  rounded-[10px] shadow-md bg-[#9AD3F3] relative cursor-pointer ${getCategoryClassName(
                        `${category.id}`
                      )}`}
                      onClick={() => handleCategoryClick(`${category.id}`)}
                    >
                      <img
                        src={getcategoryImageUrl(category.image)}
                        alt=""
                        className="flex-shrink-0 h-[125px] w-[105px] "
                      />
                      {activeCategory === `${category.id}` && (
                        <div className="absolute -bottom-1 -left-2 w-[130%] h-20 bg-gradient-to-t from-[#FFFFFF] to-transparent blur-[2px]"></div>
                      )}
                      {activeCategory === `${category.id}` && (
                        <FontAwesomeIcon
                          icon={faChevronDown}
                          className="w-7 h-7 absolute -bottom-9 transform -translate-x-1/2 left-1/2  mr-1 text-[#666161]"
                        />
                      )}
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          )}
          <div
            className="lg:hidden xl:hidden absolute top-52 -translate-y-1/2 right-1 sm:right-4 cursor-pointer"
            onClick={goToNext}
          >
            <FontAwesomeIcon
              icon={faChevronRight}
              className="text-[#10CAF2] h-5 w-5"
            />
          </div>
          <div
            className="lg:hidden xl:hidden absolute top-52 -translate-y-1/2 left-1 sm:left-4 cursor-pointer"
            onClick={goToPrev}
          >
            <FontAwesomeIcon
              icon={faChevronLeft}
              className="text-[#10CAF2] w-5 h-5"
            />
          </div>
        </div>
        <div
          className="w-full overflow-x-hidden grid lg:grid-cols-4 xl:grid-cols-5 md:grid-cols-3 grid-cols-2 bg-repeat-x shadow-md p-3 pt-4 mt-8 xl:gap-6 gap-3"
          style={{ backgroundImage: `url(${bg})` }}
        >
          {medicines.map((medicineDetails) => (
            <div
              key={medicineDetails.id}
              className="flex justify-center relative"
            >
              {showAddedToCartMessage[medicineDetails.id] && (
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50">
                  <div className="bg-green-500 text-white p-2 rounded">
                    <p className=" w-44 sm:w-60 text-center">
                      Item added to the cart!
                    </p>
                  </div>
                </div>
              )}
              {existItem[medicineDetails.id] && (
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50">
                  <div className="bg-green-500 text-white p-2 rounded">
                    <p className=" w-44 sm:w-60 text-center">
                      Item is already added in the cart!
                    </p>
                  </div>
                </div>
              )}
              <div
                className=" sm:w-[230px] sm:h-[302px] mb-4 p-2 w-[205px] h-[245px] rounded-[10px] border-[1px] bg-[#FFF]"
                style={{
                  boxShadow: "0 4px 8px rgba(0,0,0,.12)",
                }}
              >
                <div
                  className="cursor-pointer flex justify-end"
                  onClick={() =>
                    handleRoute(
                      "/medicine/medicineDescription",
                      medicineDetails
                    )
                  }
                >
                  <div className="bg-[#008000] z-10 p-1 rounded-bl-[8px] rounded-br-[8px] mr-[4px] sm:mr-[8px] -mt-[9px] flex justify-center items-center w-auto sm:w-14">
                    <p className="text-[#FFF]  font-bold sm:text-lg text-sm font-poppins text-center">
                      {" "}
                      {medicineDetails.discount}%
                      <br />
                      OFF{" "}
                    </p>
                  </div>
                </div>
                <div
                  className="cursor-pointer flex flex-col gap-1 p-2 -mt-6 justify-center items-center"
                  onClick={() =>
                    handleRoute(
                      "/medicine/medicineDescription",
                      medicineDetails
                    )
                  }
                >
                  <img
                    className="sm:w-[105px] sm:h-[105px] w-[80px] h-[80px]"
                    src={getMedicineImageUrl(medicineDetails.image[0])}
                    alt=""
                  />
                  <p className="text-xs sm:text-sm font-poppins text-[#000] font-medium overflow-hidden line-clamp-1 ">
                    {medicineDetails.name}
                  </p>
                </div>
                <div className="underline-offset-8 w-full border-[1px] color-[#0000002E] center-alignment"></div>
                <div className="  pt-2 text-[#0000008A] text-sm sm:text-[14px] flex font-poppins">
                  {" "}
                  <span className="font-bold mr-[2px]">Rs.</span>
                  <del className="">{medicineDetails.price}</del>
                </div>

                {medicineDetails.prescription === "1" && (
                  <div className="absolute ml-[93px]  sm:ml-[188px] -mt-3 ">
                    <p className="text-[15px] font-bold rounded-[5px] w-7 text-center border-[1px] border-[#10CAF2] text-[#10CAF2]">
                      Rx
                    </p>
                  </div>
                )}
                <div className=" pt-0.5 text-[#000] font-bold text-sm sm:text-[17px] font-poppins">
                  <p className="">
                    {" "}
                    <span className="mr-[2px]">Rs.</span>
                    {medicineDetails.discounted_amount}
                  </p>
                </div>
                <div className="flex justify-center items-center mt-1.5 sm:mt-0 sm:pt-2.5 pb-1">
                  <div
                    className={`w-full relative ${
                      addedToCartItems.includes(medicineDetails.id)
                        ? "bg-[#008000]"
                        : "bg-[#10CAF2]"
                    } h-8 sm:h-10 rounded-[6px] cursor-pointer flex justify-center items-center`}
                  >
                    {addedToCartItems.includes(medicineDetails.id) ? (
                      <button
                        className="text-sm sm:text-lg text-white font-bold"
                        onClick={() => navigate("/cart")} // Go to cart
                      >
                        Go to cart
                      </button>
                    ) : (
                      <button
                        className="text-sm sm:text-lg text-white font-bold"
                        onClick={() => handleAddToCart(medicineDetails.id)}
                      >
                        Add to cart
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Medicine;
