import React from "react";
import { createBrowserRouter, useLocation, Navigate } from "react-router-dom";
import HomePage from "../pages/HomePage/HomePage";
import Error from "../pages/HomePage/Error";
import LoginForm from "../Auth/LoginForm";
import OtpVerification from "../Auth/otp-verify-page";
import RequestCall from "../pages/HomePage/RequestCall";
import Cart from "../pages/HomePage/Cart/Cart";
import OrderReview from "../pages/HomePage/Cart/OrderReview";
import EmptyCart from "../pages/HomePage/Cart/EmptyCart";
import OrderByPrescription from "../pages/HomePage/Order/OrderByPrescription";
import OpenCamera from "../pages/HomePage/Order/OpenCamera";
import GetDetails from "../pages/HomePage/Profile/My Orders/GetDetails";
import PrescriptionOrder from "../pages/HomePage/Profile/My Orders/PrescriptionOrder";
import PrescriptionOrderGetDetails from "../pages/HomePage/Profile/My Orders/Prescription Order/PrescriptionOrderGetDetails";
import OrderHistory from "../pages/HomePage/Profile/My Orders/OrderHistory";
import MyOrders from "../pages/HomePage/Profile/My Orders/MyOrders";
import ManageAddress from "../pages/HomePage/Profile/Manage Address/ManageAddress";
import MyAppointment from "../pages/HomePage/Profile/My Appointment/MyAppointment";
import MyAppointmentHistory from "../pages/HomePage/Profile/My Appointment/MyAppointmentHistory";
import AmbulanceBooking from "../pages/HomePage/Profile/Ambulance Booking/AmbulanceBooking";
import DarkMode from "../Navigation/DarkMode";
import MyAppointmentGetDetails from "../pages/HomePage/Profile/My Appointment/MyAppointmentGetDetails";
import Faqs from "../pages/HomePage/Profile/FAQS/Faqs";
import Reschedule from "../pages/HomePage/Profile/My Appointment/Reschedule";
import Pathlab from "../pages/HomePage/Pathlab/Pathlab";
import Register from "../Auth/Register";
import RescheduleFillDetails from "../pages/HomePage/Profile/My Appointment/RescheduleFillDetails";
import AmbulanceBook from "../pages/HomePage/Ambulance/AmbulanceBook";
import AmbulanceBookConfirmation from "../pages/HomePage/Ambulance/AmbulanceBookConfirmation";
import Doctors from "../pages/HomePage/Doctor/Doctors";
import AppointmentBook from "../pages/HomePage/Doctor/AppointmentBook";
import AppointmentSchedule from "../pages/HomePage/Doctor/AppointmentSchedule";
import ViewProfile from "../pages/HomePage/Doctor/ViewProfile";
import HelpAndSupport from "../pages/HomePage/Profile/Help & Support/HelpAndSupport";
import AddAddress from "../pages/HomePage/Ambulance/AddAddress";
import AppointmentFillDetails from "../pages/HomePage/Doctor/AppointmentFillDetails";
import YourAccount from "../pages/HomePage/Profile/YourAccount";
import RequestReport from "../pages/HomePage/Pathlab/RequestReport";
import Medicine from "../pages/HomePage/Medicine/Medicine";
import MyReport from "../pages/HomePage/Pathlab/MyReport";
import EditAccount from "../pages/HomePage/Profile/EditAccount";
import MedicineDescription from "../pages/HomePage/Medicine/MedicineDescription";
import TermsAndCond from "../pages/HomePage/Profile/Terms & Conditions/TermsAndCond";
import PrivacyPolicy from "../pages/HomePage/Profile/Privacy & Policy/PrivacyPolicy";
import GalleryPage from "../pages/HomePage/Gallery/GalleryPage";
import DoctorsSection from "../pages/HomePage/Merchant Section/DoctorsSection";
import AmbulanceProvider from "../pages/HomePage/Merchant Section/AmbulanceProvider";
import MedicineSellers from "../pages/HomePage/Merchant Section/MedicineSellers";
import PharmacyOutlets from "../pages/HomePage/Store Section/PharmacyOutlets";
import OrdinancePoints from "../pages/HomePage/Store Section/OrdinancePoints";
import Diagnostics from "../pages/HomePage/Merchant Section/Diagnostics";
import MedicineAll from "../pages/AllMedicine/MedicineAll";
import Eclinic from "../pages/HomePage/Upcomming Services/Eclinic";
import BloodBank from "../pages/HomePage/Upcomming Services/BloodBank";
import CMS from "../pages/HomePage/Upcomming Services/CMS";
import Emergency from "../pages/HomePage/Upcomming Services/Emergency";
import AllItems from "../GlobalSearch/AllItems";

// Function to check if the user is logged in
const isLoggedIn = () => {
  const user = localStorage.getItem("userId");
  return !!user;
};

// PrivateRoute component to protect routes
const PrivateRoute = ({ element, path }) => {
  const location = useLocation();

  if (!isLoggedIn() && path !== "/login") {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return element;
};

export const appRouter = createBrowserRouter([
  // Home Page
  {
    path: "/",
    element: <HomePage />,
  },

  // Auth
  {
    path: "/login",
    element: <LoginForm />,
  },

  {
    path: "/register",
    element: <Register />,
  },

  {
    path: "/otp-verify",
    element: <OtpVerification />,
  },

  {
    path: "/faqs",
    element: <Faqs />,
  },

  {
    path: "/terms&condition",
    element: <TermsAndCond />,
  },

  {
    path: "/privacy&policy",
    element: <PrivacyPolicy />,
  },

  // Private routes
  {
    path: "/your-account",
    element: <PrivateRoute element={<YourAccount />} />,
  },

  {
    path: "/edit-account",
    element: <PrivateRoute element={<EditAccount />} />,
  },

  {
    path: "/allmedicines",
    element: <PrivateRoute element={<MedicineAll />} />,
  },

  // Order
  {
    path: "/myorders",
    element: <PrivateRoute element={<MyOrders />} />,
  },

  {
    path: "/cart",
    element: <PrivateRoute element={<Cart />} />,
  },

  {
    path: "/order-review",
    element: <PrivateRoute element={<OrderReview />} />,
  },

  {
    path: "/order-by-prescription",
    element: <PrivateRoute element={<OrderByPrescription />} />
  },

  {
    path: "/capture-pic",
    element: <PrivateRoute element={<OpenCamera />} />
  },

  {
    path: "/myorders/history",
    element: <PrivateRoute element={<OrderHistory />} />
  },

  {
    path: "/myorders/getDetails",
    element: <PrivateRoute element={<GetDetails />} />
  },

  {
    path: "/myorders/prescriptionOrder",
    element: <PrivateRoute element={<PrescriptionOrder />} />
  },

  {
    path: "/myorders/prescriptionOrder/getDetails",
    element: <PrivateRoute element={<PrescriptionOrderGetDetails />} />
  },

  // Doctor
  {
    path: "/myAppointment",
    element: <PrivateRoute element={<MyAppointment />} />
  },

  {
    path: "/myAppointmentHistory",
    element: <PrivateRoute element={<MyAppointmentHistory />} />
  },

  {
    path: "/myAppointment/getDetails",
    element: <PrivateRoute element={<MyAppointmentGetDetails />} />
  },

  {
    path: "/myAppointment/reschedule",
    element: <PrivateRoute element={<Reschedule />} />
  },

  {
    path: "/myAppointment/reschedule/fillDetails",
    element: <PrivateRoute element={<RescheduleFillDetails />} />
  },

  {
    path: "/doctors",
    element: <PrivateRoute element={<Doctors />} />
  },

  {
    path: "/doctors/appointmentBook",
    element: <PrivateRoute element={<AppointmentBook />} />
  },

  {
    path: "/doctors/viewProfile",
    element: <PrivateRoute element={<ViewProfile />} />
  },

  {
    path: "/doctors/appointmentSchedule",
    element: <PrivateRoute element={<AppointmentSchedule />} />
  },

  {
    path: "/doctors/appointmentFillDetails",
    element: <PrivateRoute element={<AppointmentFillDetails />} />
  },

  // Ambulance
  {
    path: "/ambulanceBooking",
    element: <PrivateRoute element={<AmbulanceBooking />} />
  },

  {
    path: "/ambulanceBook",
    element: <PrivateRoute element={<AmbulanceBook />} />
  },

  {
    path: "/ambulanceBook/addAddress",
    element: <PrivateRoute element={<AddAddress />} />
  },

  {
    path: "/ambulanceBook/confirmation",
    element: <PrivateRoute element={<AmbulanceBookConfirmation />} />
  },

  // Medicine
  {
    path: "/medicine",
    element: <PrivateRoute element={<Medicine />} />
  },

  {
    path: "/medicine/medicineDescription",
    element: <PrivateRoute element={<MedicineDescription />} />
  },

  // Pathlab
  {
    path: "/pathlab",
    element: <PrivateRoute element={<Pathlab />} />
  },

  {
    path: "/pathlab/requestReport",
    element: <PrivateRoute element={<RequestReport />} />
  },

  {
    path: "/pathlab/myReport",
    element: <PrivateRoute element={<MyReport />} />
  },

  // Address
  {
    path: "/manage-address",
    element: <PrivateRoute element={<ManageAddress />} />
  },

  {
    path: "/request-call",
    element: <PrivateRoute element={<RequestCall />} />
  },

  {
    path: "/helpAndSupport",
    element: <HelpAndSupport />,
  },

  // Merchant
  {
    path: "/doctors-section",
    element: <DoctorsSection />,
  },

  {
    path: "/ambulance-provider",
    element: <AmbulanceProvider />,
  },

  {
    path: "/medicine-sellers",
    element: <MedicineSellers />,
  },

  {
    path: "/diagnostics",
    element: <Diagnostics />,
  },

  // Store
  {
    path: "/pharmacy-outlets",
    element: <PharmacyOutlets />,
  },

  {
    path: "/ordinance-points",
    element: <OrdinancePoints />
  },

  // Upcomming Services
  {
    path: "/e-clinic",
    element: <PrivateRoute element={<Eclinic />} />
  },

  {
    path: "/blood-bank",
    element: <PrivateRoute element={<BloodBank />} />
  },

  {
    path: "/cms",
    element: <PrivateRoute element={<CMS />} />
  },

  {
    path: "/emergency",
    element: <PrivateRoute element={<Emergency />} />
  },

  // Gallery
  {
    path: "/gallery",
    element: <PrivateRoute element={<GalleryPage />} />
  },

  // Dark Mode
  // {
  //   path: "DarkMode",
  //   element: <DarkMode />,
  // },

  // Error
  {
    path: "*",
    element: <Error />,
  },
  //search component
  {
    path: "/allitems",
    element: <PrivateRoute element={<AllItems />} />
  },

]);