import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import Gallary from "./Gallary";
import MedicineCard from "./MedicineCard";
import OurOfferings from "./OurOfferings";
import SupportBy from "./SupportBy";
import HeroSection from "./HeroSection";
import OfferSlider from "./OfferSlider";
import ServicesData from "./ServicesData";
import Review from "./Review";
import Testimonial from "./Testimonial";
import Merchant from "./Merchant Section/Merchant";
import Store from "./Store Section/Store";
import OurDoctors from "./OurDoctors";
import ShopMedicineByCategory from "./ShopMedicineByCategory";
import DoctorsCategory from "./DoctorsCategory";
import UpcommingService from "./Upcomming Services/UpcommingService";
import Emergency from "./Emergency";
import HeroSectionSmall from "../../GlobalSearch/HeroSectionSmall";

const HomePage = () => {
  return (
    <div className="font-poppins">
      <Header />
      <>
        <HeroSectionSmall />
        <HeroSection />
        <OurOfferings />
        <ShopMedicineByCategory />
        <MedicineCard />
        <DoctorsCategory />
        <OurDoctors />
        <OfferSlider />
        <Merchant />
        <Store />
        <UpcommingService />
        <Review />
        <SupportBy />
        <Testimonial />
        <ServicesData />
        <Emergency />
        <Footer />
      </>
    </div>
  );
};

export default HomePage;
