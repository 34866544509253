import { useState, useEffect, useRef } from "react";
import Header from "../../Header";
import Medicine1 from "../../../../images/frontend UI materials/medicine_1.png";
import { useNavigate } from "react-router-dom";
import PageLoader from "../../../../Loader/PageLoader";
import axios from "axios";
import siteCofig from "../../../../urlFile";
import EmptyPrescription from "./EmptyPrescription";

const PrescriptionOrder = () => {
  const [loading, setLoading] = useState(false);
  const [medicineOrder, setMedicineOrder] = useState([]);
  const navigate = useNavigate();
  // const handleRoute = (path) => {
  //   navigate(path);
  // };
  const handleRoute = (path) => {
    setLoading(true);
    setTimeout(() => {
      navigate(path);
    }, 1000);
  };

  const user_id = localStorage.getItem("userId");
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${siteCofig.CHECKOUT_URL}${siteCofig.GET_PRESCRIPTION_ORDER}${user_id}`
        );
        if (response.data.msg === "success" && response.data.error === "200") {
          setMedicineOrder(response.data.data);
          console.log(response.data);
        } else {
          console.error("Error fetching orders:", response.data.msg);
        }
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };

    if (user_id) {
      fetchData();
    }
  }, [user_id]);
  console.log(medicineOrder, "nnnn");

  // const getMedicineImageUrl = (imageName) => `https://app.healthcrad.com/api/uploads/medicine/${imageName}`;

  //  OutsideClick PopUp Close Code
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const popupRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setIsPopupOpen(false);
      }
    };

    if (isPopupOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isPopupOpen]);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  return (
    <>
      <Header />
      {loading && <PageLoader />}
      <div className="flex flex-wrap font-poppins pt-20 lg:mx-8 justify-between mb-5">
        <div
          className="ml-2 mb-2 group bg-[#FFF] rounded-[10px] flex justify-center items-center hover:bg-[#00B4D8] border-[1px] border-[#979797] xl:w-[230px] xl:h-[50px] md:w-[230px] md:h-[50px] w-[100px] h-35"
          onClick={() => handleRoute("/myorders")}
        >
          <button className="xl:text-lg sm:text-md md:text-lg font-poppins text-[#979797] px-2 py-2 rounded-md group-hover:text-white">
            In Process Orders
          </button>
        </div>
        <div className=" mb-2 group bg-[#FFF] rounded-[10px] flex justify-center items-center hover:bg-[#00B4D8] border-[1px] border-[#00B4D8] xl:w-[230px] xl:h-[50px] md:w-[230px] md:h-[50px] w-[100px] h-35">
          <button className="xl:text-lg sm:text-md md:text-lg font-poppins text-[#00B4D8] px-2 py-2 rounded-md group-hover:text-white">
            Prescription Orders
          </button>
        </div>
        <div
          className="mr-2 mb-2 group bg-[#FFF] rounded-[10px] flex justify-center items-center hover:bg-[#00B4D8] border-[1px] border-[#979797] xl:w-[230px] xl:h-[50px] md:w-[230px] md:h-[50px]  w-20 h-35"
          onClick={() => handleRoute("/myorders/history")}
        >
          <button className="xl:text-lg sm:text-md md:text-lg font-poppins text-[#979797] px-2 py-2 rounded-md group-hover:text-white">
            History
          </button>
        </div>
      </div>

      <div className="flex flex-col gap-[14px] lg:mx-16 p-2 -mt-[40px]">
        <div className="relative  mt-[2px] flex justify-end">
          <button
            className="ml-auto bg-[#00B4D8] text-[#fff] h-5 w-5 text-[15px] rounded-full cursor-pointer font-semibold font-lato"
            onClick={togglePopup}
          >
            i
          </button>
          {isPopupOpen && (
            <div
              ref={popupRef}
              className="fixed inset-0 flex items-center justify-center z-50"
            >
              <div
                className="absolute inset-0 bg-gray-800 opacity-50"
                onClick={togglePopup}
              ></div>
              <div className="relative bg-white shadow-md p-4 rounded-md w-[333px]">
                <h2 className="text-lg font-semibold mb-2">
                  How to cancel the order?
                </h2>
                <p>
                  To cancel the order, please go to the Help section, write your
                  query and phone number, and request a callback. We will assist
                  you in canceling the order. <br />
                  <span className="cursor-pointer">
                    Or call on{" "}
                    <a className="text-[#0924B0D9]" href="tel:+917779888289">
                      7779888289
                    </a>
                  </span>
                </p>
                <p className="text-red-500">
                  Note: Order must be canceled within 24 hours of placement,
                  otherwise the order will not be canceled after pickup.
                </p>
              </div>
            </div>
          )}
        </div>
        {medicineOrder.length > 0 ? (
          medicineOrder.map((item) => (
            <div
              key={item.id}
              className="flex justify-between shadow-md border-[1px] border-[rgba(0, 0, 0, 0.20)] p-3 bg-[#FFF] rounded-[10px]"
            >
              <div className="flex">
                <img
                  src={Medicine1}
                  alt="Order"
                  className="w-16 h-16 md:w-[100px] md:h-[100px]"
                />

                <p className="text-[#4A4141] font-poppins text-xs md:text-lg xl:text-lg">
                  {item.images} <br />
                  {item.phone} <br />
                  Order Status:
                  {item.status === "0" && (
                    <span className="text-[#FF9900]"> Order Waiting</span>
                  )}
                  {item.status === "1" && (
                    <span className="text-[#07A50D]"> Order Accepted</span>
                  )}
                  {item.status === "2" && (
                    <span className="text-[#07A50D]"> Order Processing</span>
                  )}
                  {item.status === "3" && (
                    <span className="text-[#07A50D]"> Order Pickup</span>
                  )}
                  {item.status === "4" && (
                    <span className="text-[#07A50D]"> Shipped</span>
                  )}
                  <br />
                  <button
                    className="text-[#00B4D8] hover:bg-[#00B4D8] hover:text-[#FFF] font-poppins text-xs md:text-lg xl:text-lg p-1 lg:w-32 mt-2  sm:rounded-[10px] rounded-[5px] border-[1px] border-[#00B4D8]"
                    onClick={() =>
                      handleRoute(
                        `/myorders/prescriptionOrder/getDetails?groupid=${item.id}`
                      )
                    }
                  >
                    Get Details
                  </button>{" "}
                </p>
              </div>

              <div className=" flex flex-col w-20 sm:w-32 items-center justify-between ">
                <h2 className="text-md self-end sm:self-center line-clamp-1 overflow-hidden font-semibold text-center text-[#1A30A1] font-poppins  text-xs md:text-lg xl:text-lg">
                  {item.address}
                </h2>
                <button
                  className={`text-[#00B4D8] font-poppins text-[17px] p-1 lg:w-32 mt-2 sm:rounded-[10px] self-end sm:self-start rounded-[5px] border-[1px] text-xs md:text-lg xl:text-lg ${
                    item.url
                      ? "hover:bg-[#00B4D8] hover:text-[#FFF] border-[#00B4D8]"
                      : "bg-gray-300 text-gray-500 cursor-not-allowed border-gray-400"
                  }`}
                  onClick={() => {
                    if (item.url) window.open(item.url, "_blank");
                  }}
                  disabled={!item.url}
                >
                  Track Order
                </button>
              </div>
            </div>
          ))
        ) : (
          <EmptyPrescription />
        )}
      </div>
    </>
  );
};

export default PrescriptionOrder;
{
  /* Image and Track Order section 2 */
}
//  <div className="flex justify-between  p-3  border-[1px] border-[rgba(0, 0, 0, 0.20)] shadow-md bg-[#FFF]  rounded-[10px]">
//  <div className="flex  ">
//    <img
//      src={Medicine1}
//      alt="Order"
//      className="w-16 h-16  md:w-[100px] md:h-[100px]"
//    />
//    <p className="text-[#4A4141] font-poppins text-xs md:text-lg xl:text-lg">
//      5412122522.jpg
//      <br />
//      8564128752
//      <br />
//      Order Status:<span className="text-[#07A50D]">Shipped</span>{" "}
//      <br />
//      <button className="text-[#00B4D8] hover:bg-[#00B4D8] hover:text-[#FFF] font-poppins  p-1 lg:w-32 mt-2  sm:rounded-[10px] rounded-[5px] border-[1px] border-[#00B4D8] text-xs md:text-lg xl:text-lg  ">
//        Get Details
//      </button>{" "}
//    </p>
//  </div>
//  <div className=" pl-10">
//    <h2 className="text-md font-semibold text-[#1A30A1]  font-poppins text-xs md:text-lg xl:text-lg ">
//      Line Bazar, Purnea
//    </h2>
//    <button className="text-[#00B4D8] hover:bg-[#00B4D8] hover:text-[#FFF] ml-[12px] sm:ml-[18px] font-poppins text-[17px] p-1 lg:w-32 mt-2  sm:rounded-[10px] rounded-[5px]  border-[1px] border-[#00B4D8] text-xs md:text-lg xl:text-lg ">
//      {" "}
//      Track Order{" "}
//    </button>
//  </div>
// </div>
