import React, { useState } from "react";
import img1 from "../../images/Gallery/4.jpeg";
import img2 from "../../images/Gallery/9.jpeg";
import img3 from "../../images/Gallery/3.jpeg";
import img4 from "../../images/Gallery/5.jpeg";
import { useNavigate } from "react-router";
import Slider from "react-slick";

function Gallary() {
  const navigate = useNavigate();
  const handleRoute = (path) => {
    navigate(path);
  };

  const [slider, setSlider] = useState(null);
  const settings = {
    infinite: true,
    dots: false,
    speed: 200,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 800,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3.8,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3.4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1,
        },
        classes: "lg:gap-4",
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
        classes: "md:gap-3",
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
        classes: "sm:gap-2",
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
        classes: "gap-1",
      },
    ],
  };

  return (
    <>
      <div className="pt-14 relative">
        <div className="font-poppins pb-8 flex justify-center items-center">
          <h1 className="text-[#1A30A1] md:font-bold text-3xl text-center w-full">
            Gallery
          </h1>
        </div>

        <div className="w-full overflow-x-hidden">
          <Slider
            className="your-slider-styles"
            {...settings}
            ref={(slider) => setSlider(slider)}
          >
            <div className="mx-2">
              <img
                className="rounded-2xl sm:w-[350px] sm:h-[220px] w-[200px] h-[130px]"
                src={img1}
                alt="Error"
              />
            </div>
            <div className="mx-2">
              <img
                className="rounded-2xl sm:w-[350px] sm:h-[220px] w-[200px] h-[130px]"
                src={img2}
                alt="Error"
                srcSet=""
              />
            </div>
            <div className="mx-2">
              <img
                className="rounded-2xl sm:w-[350px] sm:h-[220px] w-[200px] h-[130px]"
                src={img3}
                alt="Error"
                srcSet=""
              />
            </div>
            <div className="mx-2">
              <img
                className="rounded-2xl sm:w-[350px] sm:h-[220px] w-[200px] h-[130px]"
                src={img4}
                alt="Error"
                srcSet=""
              />
            </div>
            <div className="mx-2">
              <img
                className="rounded-2xl sm:w-[350px] sm:h-[220px] w-[200px] h-[130px]"
                src={img4}
                alt="Error"
                srcSet=""
              />
            </div>
          </Slider>
        </div>

        <div className="flex justify-center items-center mt-6">
          <button
            className="bg-[#00B4D8] text-sm sm:text-md border-2 text-white font-bold py-2 px-2.5 sm:px-3 rounded-xl mb-4 cursor-pointer"
            onClick={() => handleRoute("/gallery")}
          >
            View all
          </button>
        </div>
      </div>
    </>
  );
}

export default Gallary;
