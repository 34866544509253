import React, { useEffect, useState } from "react";
import Header from "../Header";
import { useNavigate, useLocation } from "react-router-dom";
import siteConfig from "../../../urlFile";
import axios from "axios";
import PageLoader from "../../../Loader/PageLoader";

const AppointmentBook = () => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [selectedCategory, setSelectedCategory] = useState("");
  const [doctorDataList, setDoctorDataList] = useState([]);

  const navigate = useNavigate();
  // const handleRoute = (path) => {
  //   navigate(path);
  // };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const category = searchParams.get("category");
    setSelectedCategory(category);
  }, [location.search]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${siteConfig.BASE_URL}${siteConfig.GET_DOCTOR}`
        );
        setDoctorDataList(response.data.data);
        // console.log("Fetched Medical Specialties:", response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [selectedCategory]);

  // Filter doctors based on the  category
  const filteredDoctors = doctorDataList.filter((doctorDataList) =>
    doctorDataList.department.includes(selectedCategory)
  );

  const handleRoute = (path, doctorDetails) => {
    setLoading(true);
    setTimeout(() => {
      navigate(path, { state: doctorDetails });
    }, 1000);
  };
  // const data = selectedCategory;
  const getDoctorImageUrl = (imageName) =>
    `https://app.healthcrad.com/upload/${imageName}`;

  return (
    <>
      <Header />
      {loading && <PageLoader />}
      <div className="pt-24  ">
        {loading || filteredDoctors.length === 0 ? (
          <PageLoader />
        ) : (
          <div className=" flex flex-col justify-center items-center ">
            {filteredDoctors.map((doctorDetails) => (
              <div
                key={doctorDetails.id}
                className="  flex flex-col border-[1px] border-[rgba(0, 0, 0, 0.20)] p-7  mb-8 shadow-md bg-[#FFF] rounded-[10px] md:h-[90%]  sm:h-full w-[93%] lg:w-[80%]   gap-6  "
              >
                <div className="flex justify-between  ">
                  <div className="flex gap-1 sm:gap-0 ">
                    <div>
                      <img
                        // src={Nurse}
                        src={getDoctorImageUrl(doctorDetails.img_url)}
                        alt=""
                        className=" w-[80px] h-[68px]  md:w-[100px] md:h-[100px] rounded-full "
                      />
                    </div>
                    <div className=" mt-[-5px] ml-2  ">
                      <p className=" xl:text-lg sm:text-sm text-[11px] md:text-lg font-poppins text-[#4A4141] ">
                        <span className="text-[#000] sm:text-lg text-sm font-bold ">
                          {doctorDetails.dname}
                        </span>
                        <br />
                        <span className=" text-[#00B4D8] sm:text-lg text-sm ">
                          {selectedCategory}
                        </span>
                        <br />
                        <span className="text-[#07A50D]  ">
                          ({doctorDetails.profile})
                        </span>
                        <br />
                        <span className="text-[#000] font-bold xl:text-lg sm:text-sm text-[11px] md:text-lg font-poppins ">
                          Experience :
                        </span>{" "}
                        {doctorDetails.exp} <span>years</span>
                        <br />
                        <span className="text-[#000] font-bold xl:text-lg sm:text-sm text-[11px] md:text-lg font-poppins ">
                          Address :
                        </span>{" "}
                        {doctorDetails.city}
                      </p>
                      <p className="sm:hidden text-[#07A50D]  text-md font-poppins font-bold  ">
                        {/* <span className="text-[#1A30A1] font-bold xl:text-lg sm:text-sm text-[11px] md:text-lg font-poppins">
                    
                    </span>{" "} */}
                        <span className="text-[#07A50D]  font-bold">
                          &#8377;
                        </span>{" "}
                        {doctorDetails.fees}
                      </p>
                    </div>
                  </div>
                  <div className=" hidden sm:block mt-[-8px] mr-[-20px] sm:mr-[0px] ">
                    <p className="text-[#07A50D]  xl:text-lg sm:text-sm text-[11px] md:text-lg font-poppins font-bold  ">
                      <span className="text-[#1A30A1] font-bold xl:text-lg sm:text-sm text-[11px] md:text-lg font-poppins">
                        Fees:
                      </span>{" "}
                      Rs.{doctorDetails.fees}
                    </p>
                  </div>
                </div>
                <div className="underline-offset-8 w-[100%]   border-[1px]  color-[#0000002E] center-alignment"></div>
                <div className=" flex justify-between sm:gap-0 gap-2 ">
                  <div
                    className=" mb-2 group  rounded-[10px] flex justify-center items-center  border-[1px] border-[#00B4D8] w-32 h-10"
                    onClick={() =>
                      handleRoute(
                        `/doctors/viewProfile?data=${doctorDetails.id},data2=${selectedCategory}`
                      )
                    }
                  >
                    <button className="xl:text-lg sm:text-md text-sm font-poppins text-[#00B4D8] px-2 py-2 rounded-md ">
                      View Profile
                    </button>
                  </div>
                  <div
                    className=" mb-2 group rounded-[10px] flex justify-center items-center border-[1px] bg-[#00B4D8] cursor-pointer w-32 h-10"
                    // onClick={() => handleRoute(`/doctors/appointmentSchedule?data=${selectedCategory}`, doctorDetails)}
                    onClick={() => {
                      handleRoute(
                        `/doctors/appointmentSchedule?data=${selectedCategory}`,
                        doctorDetails
                      );
                    }}
                  >
                    <button className="xl:text-lg sm:text-md text-sm font-poppins text-[#FFF] px-2 py-2 rounded-md ">
                      Book Now
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default AppointmentBook;
