import React from "react";
import EmptyHisPic from "../../../../images/Empty_Pages_Images/history-icon-time-back-clock 1.png";

const EmptyHistory = () => {
  return (
    <>
      <div className="p-3 mt-10 mb-40">
        <div className="flex flex-col justify-center items-center gap-5">
          <div className="flex justify-start self-start ml-4 sm:ml-32 xl:ml-48 items-start">
            <p className="text-[#66616180] text-center font-poppins font-semibold text-lg sm:text-xl">
              Nothing is here !
            </p>
          </div>
          <div className="flex justify-center items-center">
            <img
              src={EmptyHisPic}
              alt=""
              srcset=""
              className="w-[150px] h-[150px] sm:h-[250px] sm:w-[250px]"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default EmptyHistory;
