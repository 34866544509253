import { useState, useEffect, useRef } from "react";
import Header from "../../Header";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import siteCofig from "../../../../urlFile";
import PageLoader from "../../../../Loader/PageLoader";
import EmptyInprocess from "./EmptyInprocess";

/*        ORDER STATUS CODE

            0 -> PENDING
            1 -> TRANSFER AGENCY
            2 -> ACCEPTED ORDER
            3 -> PICKUP
            4 -> SHIPPED
            5 -> DELIVERED
            6 -> RETURNED
            7 -> REPLACED
            8 -> REFUNDED
            9 -> CANCEL BY ADMIN
*/

const MyOrders = () => {
  const [loading, setLoading] = useState(false);
  const [medicineOrder, setMedicineOrder] = useState([]);
  const navigate = useNavigate();

  const handleRoute = (path) => {
    setLoading(true);
    setTimeout(() => {
      navigate(path);
    }, 1000);
  };

  const user_id = localStorage.getItem("userId");
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${siteCofig.BASE_URL}${siteCofig.INPROCESS_ORDER}${user_id}`
        );
        if (response.data.msg === "success" && response.data.error === "200") {
          setMedicineOrder(response.data.country);
          console.log(response.data.country);
        } else {
          console.error("Error fetching orders:", response.data.msg);
        }
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };

    if (user_id) {
      fetchData();
    }
  }, [user_id]);

  const getMedicineImageUrl = (imageName) =>
    `https://app.healthcrad.com/api/uploads/medicine/${imageName}`;

  //  OutsideClick PopUp Close Code
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const popupRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setIsPopupOpen(false);
      }
    };

    if (isPopupOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isPopupOpen]);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  return (
    <>
      <Header />
      {loading && <PageLoader />}
      <div className="flex flex-wrap font-poppins pt-20 lg:mx-8 justify-between mb-5">
        <div
          className="ml-2 mb-2 group bg-[#FFF] rounded-[10px] flex justify-center items-center hover:bg-[#00B4D8] border-[1px] border-[#00B4D8] xl:w-[230px] xl:h-[50px] md:w-[230px] md:h-[50px] w-[100px] h-35"
          onClick={() => handleRoute("/myorders")}
        >
          <button className="xl:text-lg sm:text-base md:text-lg font-poppins text-[#00B4D8] px-2 py-2 rounded-md group-hover:text-white">
            In Process Orders
          </button>
        </div>
        <div
          className="mb-2 group bg-[#FFF] rounded-[10px] flex justify-center items-center hover:bg-[#00B4D8] border-[1px] border-[#979797] xl:w-[230px] xl:h-[50px] md:w-[230px] md:h-[50px] w-[100px] h-35"
          onClick={() => handleRoute("/myorders/prescriptionOrder")}
        >
          <button className="xl:text-lg sm:text-base md:text-lg font-poppins text-[#979797] px-2 py-2 rounded-md group-hover:text-white">
            Prescription Orders
          </button>
        </div>
        <div
          className="mr-2 mb-2 group bg-[#FFF] rounded-[10px] flex justify-center items-center hover:bg-[#00B4D8] border-[1px] border-[#979797] xl:w-[230px] xl:h-[50px] md:w-[230px] md:h-[50px]  w-20 h-35"
          onClick={() => handleRoute("/myorders/history")}
        >
          <button className="xl:text-lg sm:text-base md:text-lg font-poppins text-[#979797] px-2 py-2 rounded-md group-hover:text-white">
            History
          </button>
        </div>
      </div>

      <div className="flex flex-col gap-[14px] lg:mx-16 p-2 -mt-[40px]">
        <div className="relative  mt-[2px] flex justify-end">
          <button
            className="ml-auto bg-[#00B4D8] text-[#fff] h-5 w-5 text-[15px] rounded-full cursor-pointer font-semibold font-lato"
            onClick={togglePopup}
          >
            i
          </button>
          {isPopupOpen && (
            <div
              ref={popupRef}
              className="fixed inset-0 flex items-center justify-center z-50"
            >
              <div
                className="absolute inset-0 bg-gray-800 opacity-50"
                onClick={togglePopup}
              ></div>
              <div className="relative bg-white shadow-md p-4 rounded-md w-[333px]">
                <h2 className="text-lg font-semibold mb-2">
                  How to cancel the order?
                </h2>
                <p>
                  To cancel the order, please go to the Help section, write your
                  query and phone number, and request a callback. We will assist
                  you in canceling the order. <br />
                  <span className="cursor-pointer">
                    Or call on{" "}
                    <a className="text-[#0924B0D9]" href="tel:+917779888289">
                      7779888289
                    </a>
                  </span>
                </p>
                <p className="text-red-500">
                  Note: Order must be canceled within 24 hours of placement,
                  otherwise the order will not be canceled after pickup.
                </p>
              </div>
            </div>
          )}
        </div>
        {medicineOrder.length > 0 ? (
          medicineOrder.map((item) => (
            <div
              key={item.id}
              className="flex justify-between shadow-md border-[1px] border-[rgba(0, 0, 0, 0.20)] p-3 bg-[#FFF] rounded-[10px]"
            >
              <div className="flex">
                <img
                  src={getMedicineImageUrl(item.image[0])}
                  alt="Order"
                  className="w-16 h-16 md:w-[100px] md:h-[100px]"
                />
                <p className="text-[#4A4141] font-poppins text-xs md:text-lg xl:text-lg">
                  {item.count} Items <br />
                  On {item.date_time} <br />
                  Order Status:
                  {item.status === "0" && (
                    <span className="text-[#FF9900]"> Order Waiting</span>
                  )}
                  {item.status === "1" && (
                    <span className="text-[#07A50D]">  Order Processing</span>
                  )}
                  {item.status === "2" && (
                    <span className="text-[#07A50D]"> Order Accepted</span>
                  )}
                  {item.status === "3" && (
                    <span className="text-[#07A50D]"> Order Pickup</span>
                  )}
                  {item.status === "4" && (
                    <span className="text-[#07A50D]"> Shipped</span>
                  )}
                  {item.status === "5" && (
                    <span className="text-[#07A50D]"> Order Delivered</span>
                  )}
                  {item.status === "6" && (
                    <span className="text-[#07A50D]"> Cancelled</span>
                  )}
                  {item.status === "7" && (
                    <span className="text-[#07A50D]"> Order Returned</span>
                  )}
                  {item.status === "8" && (
                    <span className="text-[#07A50D]"> Replaced</span>
                  )}
                   {item.status === "9" && (
                    <span className="text-[red]"> Refund</span>
                  )}
                  {item.status === "15" && (
                    <span className="text-[red]"> Cancelled by Admin</span>
                  )}
                  <br />
                  <button
                    className="text-[#00B4D8] hover:bg-[#00B4D8] hover:text-[#FFF] font-poppins text-xs md:text-lg xl:text-lg p-1 lg:w-32 mt-2 rounded-[5px] sm:rounded-[10px] border-[1px] border-[#00B4D8]"
                    onClick={() =>
                      handleRoute(
                        `/myorders/getDetails?groupid=${item.groupid}`
                      )
                    }
                  >
                    Get Details
                  </button>{" "}
                </p>
              </div>
              <div className=" items-center  w-28 sm:w-40 flex flex-col justify-between ">
                <h2 className="text-md self-end text-center  font-semibold text-[#1A30A1] font-poppins text-xs md:text-lg xl:text-lg mr-[6px]">
                  Rs. {parseInt(item.amount).toFixed(0)} |{" "}
                  <span className="text-[#07A50D]">{item.paymode}</span>
                </h2>
                <button
                  className={`text-[#00B4D8] font-poppins text-[17px] p-1 lg:w-32 mt-2 sm:rounded-[10px] self-center rounded-[5px] border-[1px] text-xs md:text-lg xl:text-lg ${
                    item.url
                      ? "hover:bg-[#00B4D8] hover:text-[#FFF] border-[#00B4D8]"
                      : "bg-gray-300 text-gray-500 cursor-not-allowed border-gray-400"
                  }`}
                  onClick={() => {
                    if (item.url) window.open(item.url, "_blank");
                  }}
                  disabled={!item.url}
                >
                  Track Order
                </button>
              </div>
            </div>
          ))
        ) : (
          <EmptyInprocess />
        )}
      </div>
    </>
  );
};

export default MyOrders;
