import React, { useState, useEffect } from "react";
// import Header from "../Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faCaretUp,
  faMapMarkerAlt,
  faCalendar,
} from "@fortawesome/free-solid-svg-icons";
// import Nurse from "../../../images/frontend UI materials/nurse_appointment.png";
import siteConfig from "../../../../urlFile";
import axios from "axios";
import { useLocation, useNavigate, Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Header from "../../Header";

const AppointmentSchedule = () => {
  const location = useLocation();
  // const doctorDetails = location.state.doctorDetails;
  const appointmentData = location.state;
  console.log("doctor details is", appointmentData);
  const [errorMessage, setErrorMessage] = useState(false);
  const [continueError, setContinueError] = useState(false);
  const [errorMessageslot, setErrorMessageslot] = useState(false);
  const [selectedSlots, setSelectedSlots] = useState({});
  const [doctorShiftDetails, setDoctorShiftDetails] = useState([]);
  const [selectedDates, setSelectedDates] = useState({});
  const navigate = useNavigate();
  const [openAnswerIndex, setOpenAnswerIndex] = useState(null);
  const [slotAvailable, setSlotAvailable] = useState(false);
  const [slotMessage, setSlotMessage] = useState("");

  const toggleAnswer = (index) => {
    setOpenAnswerIndex(openAnswerIndex === index ? null : index);
  };

  const handleSlotClick = (slotNumber, shiftId) => {
    setSelectedSlots((prevState) => {
      const updatedState = { ...prevState };
      Object.keys(updatedState).forEach((key) => {
        if (key !== shiftId) {
          updatedState[key] = null;
        }
      });
      return {
        ...updatedState,
        [shiftId]: slotNumber,
      };
    });
  };

  const handleDateChange = async (date, shiftId, doctorShiftDetails) => {
    const formatDate = (date) => {
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    };

    const currentDate = new Date();
    if (date < currentDate) {
      setErrorMessage(true);
      setTimeout(() => {
        setErrorMessage(false);
      }, 1500);
      return;
    }

    // Check if a slot is selected
    if (!selectedSlots[shiftId]) {
      setErrorMessageslot(true);
      setTimeout(() => {
        setErrorMessageslot(false);
      }, 1500);
      return;
    }

    const updatedSelectedDates = { ...selectedDates };
    updatedSelectedDates[shiftId] = date;
    setSelectedDates(updatedSelectedDates);

    const slotValue = parseInt(
      doctorShiftDetails.find((shift) => shift.id === shiftId)[
        `slot${selectedSlots[shiftId]}`
      ]
    );

    try {
      const formattedDate = formatDate(date);
      const response = await axios.post(
        `${siteConfig.BASE_URL}${siteConfig.CHECK_SLOT_AVAILABILITY}`,
        {
          date: formattedDate,
          slot: slotValue,
        }
      );

      if (response.data.error === "200") {
        const availableSlotCount = response.data["available slot"];
        if (availableSlotCount > 0) {
          // Slots are available, no need to clear the selected date
          const message = `${availableSlotCount} slots available on ${formattedDate}`;
          console.log(message);
          setSlotMessage(message);
          setSlotAvailable(true);
          setTimeout(() => {
            setSlotMessage("");
            setSlotAvailable(false);
          }, 2000);

          const doctorNewObjectWithDetails = {
            ...doctorNewObject,
            selectedShiftId: shiftId,
            selectedDate: formattedDate,
            selectedSlotTime: doctorShiftDetails.find(
              (shift) => shift.id === shiftId
            )[`slottime${selectedSlots[shiftId]}`],
          };
          console.log("Updated doctorNewObject:", doctorNewObjectWithDetails);
        } else {
          // No slots available, clear the selected date
          setSlotMessage(
            "No slots available for the selected date. Please select another date."
          );
          setSlotAvailable(false);
          setTimeout(() => {
            setSlotMessage("");
          }, 2000);
          updatedSelectedDates[shiftId] = null;
          setSelectedDates(updatedSelectedDates);
        }
      } else {
        console.error("Error response from API:", response.data);
      }
    } catch (error) {
      console.error("Error checking slot availability:", error);
    }
  };

  const mapWeekdayToNumber = (weekday) => {
    const weekdaysMap = {
      Sunday: 0,
      Monday: 1,
      Tuesday: 2,
      Wednesday: 3,
      Thursday: 4,
      Friday: 5,
      Saturday: 6,
    };
    return weekdaysMap[weekday];
  };

  useEffect(() => {
    const fetchDoctorShiftDetails = async () => {
      try {
        const response = await axios.post(
          `${siteConfig.BASE_URL}${siteConfig.DOCTOR_GET_SHIFT}`,
          {
            doctor_id: appointmentData.doctor,
          }
        );
        setDoctorShiftDetails(response.data.data);
      } catch (error) {
        console.error("Error fetching doctor shift details:", error);
      }
    };

    if (appointmentData && appointmentData.doctor) {
      fetchDoctorShiftDetails();
    }
  }, [appointmentData]);

  const calculateAvailableSlots = (shifts) => {
    let totalSlots = 0;
    for (const key in shifts) {
      if (/^slot\d+$/.test(key) && shifts[key] !== null) {
        totalSlots++;
      }
    }
    return totalSlots;
  };

  const getDoctorImageUrl = (imageName) =>
    `https://app.healthcrad.com/upload/${imageName}`;

  const { id, doctor, dname, amount, dprofile, dimg_url } = appointmentData;

  const doctorNewObject = {
    id,
    doctor,
    dname,
    amount,
    dprofile,
    dimg_url,
  };

  const handleRoute = (path, shiftId, formattedDate, day) => {
    const selectedTime = shiftId
      ? doctorShiftDetails.find((shift) => shift.id === shiftId)[
          `slottime${selectedSlots[shiftId]}`
        ]
      : null;

    const doctorNewObjectWithDetails = {
      ...doctorNewObject,
      selectedShiftId: shiftId,
      selectedDate: formattedDate,
      selectedDay: day,
      selectedSlotTime: selectedTime,
    };

    navigate(path, { state: doctorNewObjectWithDetails });
  };

  const handleContinueButtonClick = () => {
    const selectedShiftId = Object.keys(selectedSlots).find(
      (key) => selectedSlots[key] !== null
    );
    const selectedDate = selectedDates[selectedShiftId];
    const selectedDay = selectedShiftId
      ? doctorShiftDetails.find((shift) => shift.id === selectedShiftId).weekday
      : null;

    if (!selectedShiftId || !selectedDate) {
      setContinueError(true);
      setTimeout(() => {
        setContinueError(false);
      }, 2000);
      return;
    }
    handleRoute(
      `/myAppointment/reschedule/fillDetails`,
      selectedShiftId,
      selectedDate,
      selectedDay
    );
  };

  const handleKeyDown = (e) => {
    const allowedKeys = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "-",
      "/",
      "Backspace",
    ];
    if (!allowedKeys.includes(e.key)) {
      e.preventDefault();
    }
  };

  const handleInputChange = (e, shiftId) => {
    const value = e.target.value;
    const pattern = /^\d{2}-\d{2}-\d{4}$/; // Regular expression for "DD-MM-YYYY"
    if (!pattern.test(value)) {
      e.target.value = selectedDates[shiftId]
        ? selectedDates[shiftId].toLocaleDateString("en-GB")
        : ""; // Reset to previous value or empty
    }
  };

  return (
    <>
      <Header />
      <div className="pt-24">
        <div className="flex flex-col justify-center items-center">
          <div className="flex flex-col border-[1px] border-[rgba(0, 0, 0, 0.20)] p-[12px] sm:p-6 pt-12 mb-12 shadow-md bg-[#FFF] rounded-[10px] md:h-[90%] w-full sm:h-full lg:w-[80%] gap-6">
            <div className="flex justify-between">
              <div className="flex gap-3 ">
                <div>
                  <img
                    // src={Nurse}
                    src={getDoctorImageUrl(appointmentData.dimg_url)}
                    alt=""
                    className="w-[80px] h-[68px] md:w-[100px] md:h-[100px] rounded-[50px]"
                  />
                </div>
                <div className="self-center ml-2">
                  <p className="xl:text-lg sm:text-sm text-[14px] md:text-lg font-poppins text-[#4A4141]">
                    <span className="text-[#000]  sm:text-lg text-md font-bold">
                      {appointmentData.dname}
                    </span>
                    <br />
                  </p>
                  <span className=" text-[13px] sm:text-[16px] text-[#07A50D]">
                    {" "}
                    ({appointmentData.dprofile})
                  </span>
                  <br />
                  <p className="sm:hidden text-[#07A50D]  text-md font-poppins font-bold  ">
                    Fees :
                    <span className="text-[#07A50D]  font-bold"> &#8377;</span>{" "}
                    {appointmentData.amount}
                  </p>
                </div>
              </div>
              <div className="self-center  mr-[-20px] sm:mr-[0px] hidden sm:block">
                <p className="text-[#07A50D] xl:text-lg sm:text-sm text-[11px] md:text-lg font-poppins font-bold">
                  <span className="text-[#1A30A1] font-bold xl:text-lg sm:text-sm text-[11px] md:text-lg font-poppins">
                    Fees:
                  </span>{" "}
                  Rs. {appointmentData.amount}
                </p>
              </div>
            </div>
            <div className="underline-offset-8 w-[100%] border-[1px] color-[#0000002E] center-alignment"></div>
            <div className="flex items-center justify-center">
              <div className="cursor-default mb-2 group select-none bg-[#00B4D8] rounded-[10px] flex justify-center items-center hover:bg-[#00B4D8] border-[1px] border-[#979797] xl:h-[50px] sm:w-[300px] md:h-[50px] w-[260px] h-12">
                <p className="xl:text-lg sm:text-md md:text-lg font-poppins text-white px-2 py-2 rounded-md group-hover:text-white">
                  Select Appointment Schedule
                </p>
              </div>
            </div>

            {doctorShiftDetails.map((shifts, index) => (
              <div
                key={shifts.id}
                className="flex flex-col border-[1px] border-[rgba(0, 0, 0, 0.20)] p-6 pt-6 mb-6 sm:mb-12 shadow-md bg-[#FFF] rounded-[10px] md:h-[90%] sm:h-full lg:w-[100%]"
              >
                <div
                  className="flex justify-between items-center cursor-pointer"
                  onClick={() => toggleAnswer(index)}
                >
                  <div>
                    <p className="text-[#000] font-bold xl:text-lg sm:text-sm text-[12px] md:text-lg font-poppins">
                      Day:
                      <span className="text-[#4A4141] font-normal">
                        {" "}
                        {shifts.weekday}
                      </span>
                      <br />
                      Total No. of Slots:
                      <span className="text-[#4A4141] font-normal">
                        {" "}
                        {calculateAvailableSlots(shifts)}
                      </span>
                      <br />
                      Address:
                      <span className="text-[#4A4141] font-normal">
                        {" "}
                        <FontAwesomeIcon
                          icon={faMapMarkerAlt}
                          style={{ fontSize: "18px", color: "#07A50D" }}
                        />{" "}
                        {shifts.hospital_id}
                      </span>
                    </p>
                  </div>
                  <FontAwesomeIcon
                    className=" "
                    icon={openAnswerIndex === index ? faCaretUp : faCaretDown}
                    style={{ fontSize: "35px", color: "#10CAF2" }}
                  />
                </div>

                <div
                  className={`overflow-hidden transition-height duration-500 ease-in-out ${
                    openAnswerIndex === index ? "max-h-[500px]" : "max-h-0"
                  }`}
                >
                  <div className="mt-12">
                    <div className="flex justify-center items-center pb-4">
                      <p className="font-bold xl:text-lg sm:text-md text-[15px] md:text-xl font-poppins text-[#00B4D8]">
                        Choose Time, Date & Slot
                      </p>
                    </div>
                    <div className="flex justify-between gap-2 sm:gap-0">
                      {/* my slot start */}

                      {Array.from({ length: 5 }, (_, i) => i + 1).map(
                        (slotNumber) => {
                          const slotValue = parseInt(
                            shifts[`slot${slotNumber}`]
                          );
                          if (slotValue > 0) {
                            return (
                              <div
                                key={slotNumber}
                                className={`cursor-pointer select-none text-[#000] font-bold xl:text-lg sm:text-sm text-[11px] md:text-lg font-poppins rounded-[10px] border-[1px] p-2 ${
                                  selectedSlots[shifts.id] === slotNumber
                                    ? " bg-[#10CAF2] text-[#FFF]"
                                    : "border-[#393232] bg-white"
                                }`}
                                onClick={() =>
                                  handleSlotClick(slotNumber, shifts.id)
                                } // Pass the shiftId along with slot number
                              >
                                {/* Slot content */}
                                <p>
                                  Slot No.:
                                  <span className="text-[#4A4141] font-normal">
                                    {" "}
                                    {slotNumber}
                                  </span>
                                  <br />
                                  Time:
                                  <span className="text-[#4A4141] font-normal">
                                    {" "}
                                    {shifts[`slottime${slotNumber}`]}
                                  </span>
                                  <br />
                                  No. of Booking:
                                  <span className="text-[#4A4141] font-normal">
                                    {" "}
                                    {slotValue}
                                  </span>
                                </p>
                              </div>
                            );
                          }
                          return null;
                        }
                      )}
                      {/* my slot end */}
                    </div>
                    <div className="pt-4">
                      <p className="flex justify-center items-center font-bold xl:text-lg sm:text-md text-[15px] md:text-xl font-poppins text-[#000]">
                        Choose Appointment Date
                      </p>
                      <p className="flex justify-center items-center text-[#4A4141] font-normal xl:text-lg sm:text-sm text-[8px] md:text-lg font-poppins">
                        Date must be selected on the behalf of selected day
                      </p>
                    </div>
                    <div className="mt-10 flex justify-center items-center w-full h-full">
                    <div className="flex flex-col justify-center items-center p-3 bg-[#FFFFFF] border-[1px] border-[#CECBCB] rounded-[10px] shadow-md">
                        <label
                          className="text-[#000] font-bold xs:text-[13px] sm:text-lg font-poppins"
                          htmlFor="dateInput"
                        >
                          Select Date:
                        </label>
                        <div className="relative">
                          <DatePicker
                            className="cursor-pointer pr-8 text-[#4A4141] xl:text-lg sm:text-md text-[11px] font-semibold md:text-lg font-poppins focus:outline-none flex-grow"
                            selected={selectedDates[shifts.id]}
                            onChange={(date) =>
                              handleDateChange(
                                date,
                                shifts.id,
                                doctorShiftDetails
                              )
                            }
                            placeholderText="DD-MM-YYYY"
                            dateFormat="dd-MM-yyyy"
                            onKeyDown={handleKeyDown}
                            onChangeRaw={(e) => handleInputChange(e, shifts.id)}
                            filterDate={(date) =>
                              date.getDay() ===
                              mapWeekdayToNumber(shifts.weekday)
                            }
                            customInput={<input className="w-full" />}
                          />
                          <FontAwesomeIcon
                            icon={faCalendar}
                            className="absolute text-[#10CAF2] right-2 top-1/2 transform -translate-y-1/2 sm:w-6 sm:h-6 cursor-pointer"
                            onClick={() => {
                              document
                                .querySelector(
                                  ".react-datepicker-wrapper input"
                                )
                                .click();
                            }}
                          />
                        </div>
                        {errorMessage && (
                          <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50">
                            <div className="text-red-500 font-poppins text-sm md:text-base xl:text-lg p-2 bg-white rounded shadow-md">
                              <p className="w-52 sm:w-80 text-center font-lato">
                                Booking not allowed for past Dates !
                              </p>
                            </div>
                          </div>
                        )}
                        {errorMessageslot && (
                          <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50">
                            <div className="text-red-500 font-poppins text-sm md:text-base xl:text-lg p-2 bg-white rounded shadow-md">
                              <p className="w-52 sm:w-80 text-center font-lato">
                                Please select a slot before selecting a date !
                              </p>
                            </div>
                          </div>
                        )}
                        {continueError && (
                          <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50">
                            <div className="text-red-500 font-poppins text-sm md:text-base xl:text-lg p-2 bg-white rounded shadow-md">
                              <p className="w-52 sm:w-80 text-center font-lato">
                                Please select slot & date to proceed next step !
                              </p>
                            </div>
                          </div>
                        )}
                        {slotMessage && (
                          <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50">
                            <div
                              className={`font-poppins text-sm md:text-base xl:text-lg p-2 rounded shadow-md ${
                                slotAvailable
                                  ? "bg-green-500 text-white"
                                  : "bg-red-500 text-white"
                              }`}
                            >
                              <p className="w-52 sm:w-80 text-center font-lato">
                                {slotMessage}
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}

            <div className="self-center hidden sm:block flex sm:justify-center sm:items-center pb-6">
              <div
                className="select-none rounded-[10px] border-[1px] border-[#10CAF2] shadow-md bg-[#00B4D8] sm:w-[200px] md:h-[50px] w-full h-10  flex items-center justify-center cursor-pointer"
                onClick={handleContinueButtonClick}
              >
                <button className="xl:text-lg sm:text-md md:text-lg text-white font-poppins">
                  Continue
                </button>
                {/* </Link> */}
              </div>
            </div>
          </div>

          {/* mobile view button design start */}
          <div className=" sm:hidden fixed z-50 flex justify-around items-center bg-[#FAF1FF]   w-full bottom-0 ">
            <div className=" flex flex-col pt-2.5 ">
              <p className="text-[16px] font-lato  text-[#000000] ">Fees:</p>
              <p className=" text-[20px] font-lato font-semibold text-[#000000] ">
                Rs.{appointmentData.amount}
              </p>
            </div>
            <div
              className="select-none rounded-[4px] border-[1px] border-[#10CAF2] shadow-md bg-[#00B4D8] w-[170px]  h-11  flex items-center justify-center cursor-pointer"
              onClick={handleContinueButtonClick}
            >
              <button className=" text-white font-poppins">Continue</button>
            </div>
          </div>
          {/* mobile view button design end */}
        </div>
      </div>
    </>
  );
};

export default AppointmentSchedule;