import React, { useState, useEffect, useRef } from "react";
import { useInView } from "react-intersection-observer";
import Ordinance_Points from "../../images/Services Data/pharmacy-store.png";
import Pincodes_Serving from "../../images/Services Data/location-3d-icon.png";
import Happy_Customers from "../../images/Services Data/customer-satisfaction.png";
import HeathCradLogo from "../../images/Services Data/HealthCRAD Logo.png";
import DAYS from "../../images/Services Data/2 _3 DAYS.png";
import Doctor from "../../images/Services Data/Doctor.png";

const AnimatedCounter = ({ initialValue, finalValue, label }) => {
  const [count, setCount] = useState(initialValue);
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      const interval = setInterval(() => {
        if (count < finalValue) {
          setCount((prevCount) => prevCount + 20);
        } else {
          clearInterval(interval);
        }
      }, 2);
      return () => clearInterval(interval); // Cleanup on component unmount
    }
  }, [count, finalValue, inView]);

  return (
    <div className="flex items-center gap-4" ref={ref}>
      <img src={getImageSource(label)} alt="" className="h-[87px] w-[120px]" />
      <p className="lg:text-[20px] md:text-[18x] sm:text-[16px] text-[20px]font-medium text-[#464141]">
        {" "}
        <span className="text-[black] font-bold lg:text-[28px] md:text-[24px] sm:text-[20px] text-[16px]">
          {count}+
        </span>{" "}
        <br /> {label}
      </p>
    </div>
  );
};

const getImageSource = (label) => {
  switch (label) {
    case "Ordinance Points":
      return Ordinance_Points;
    case "Pincodes Serving":
      return Pincodes_Serving;
    case "Happy Customers":
      return Happy_Customers;
    default:
      return "";
  }
};

const ServicesData = () => {
  const [count, setCount] = useState(1);
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  return (
    <>
      <div className="xs:py-3 sm:py-12 px-[4px] sm:px-3 flex justify-around overflow-x-hidden">
        {/* first card */}
        <div>
          <div>
            <h2 className=" hidden sm:block text-[11px] sm:text-[14px] md:text-[16px] lg:text-[22px] xl:text-[24px] text-[#4A4141] font-lato pb-[10px]">
              Don’t self medicate. Book appointment in just 30 <br />
              sec and consult a doctor.
            </h2>
            <h2 className=" sm:hidden text-[9px] xs:text-[11px] sm:text-[14px] md:text-[16px] lg:text-[22px] xl:text-[24px] text-[#4A4141] font-lato pb-[10px]">
              Don’t self medicate. Book <br /> appointment in just 30 sec and
              consult <br /> a doctor.
            </h2>
          </div>
          <div className=" w-[148px] xs:w-[180px] h-[113px] sm:h-[200px] sm:w-[300px] md:h-[230px] lg:h-[300px] md:w-[340px] lg:w-[486px] xl:w-[550px]  bg-[#74B9C8] rounded-[10px] pl-[9px] sm:pl-[25px] pt-[11px] sm:pt-[30px] lg:pt-[65px]">
            <p className="text-[#fff] font-lato text-[10px] sm:text-[20px]">
              Book an appointment with a
            </p>
            <h2 className="text-[14px] sm:text-[28px] font-lato text-[#fff] font-semibold">
              DOCTOR
            </h2>
            <div className="flex justify-end">
              <img
                src={Doctor}
                alt=""
                className=" lg:w-[300px] xl:w-[340px]  h-[78px] w-[104px] sm:h-[130px] sm:w-[194px] md:w-[220px] md:h-[159px] lg:h-[238px] pr-[5px] lg:pr-[20px] xl:pr-[25px] -mt-[8px] sm:-mt-[23px] lg:-mt-[66px] "
              />
            </div>
          </div>
        </div>
        {/* Second card */}
        <div>
          <div>
            <h2 className=" hidden sm:block text-[11px] sm:text-[14px] md:text-[16px] lg:text-[22px] xl:text-[24px] text-[#4A4141] font-lato pb-[10px]">
              Tired of waiting in line? Just go to the medicine <br />
              section and buy medicine without going outside.
            </h2>
            <h2 className="sm:hidden text-[9px] xs:text-[11px] sm:text-[14px] md:text-[16px] lg:text-[22px] xl:text-[24px] text-[#4A4141] font-lato pb-[10px]">
              Tired of waiting in line? Just go to the <br /> medicine section
              and buy medicine <br /> without going outside.
            </h2>
          </div>
          <div className="w-[148px] xs:w-[180px] h-[113px] sm:h-[200px] sm:w-[300px] md:h-[230px] lg:h-[300px] md:w-[340px] lg:w-[486px] xl:w-[550px] bg-[#32BC93D4] rounded-[10px] pl-[9px] sm:pl-[25px] pt-[11px] sm:pt-[30px] lg:pt-[65px]">
            <p className="text-[#fff] font-lato text-[10px] sm:text-[20px]">
              Home Delivery in just
            </p>
            <h2 className=" text-[14px] sm:text-[28px] font-lato text-[#fff] font-semibold">
              2 - 3 DAYS
            </h2>
            <div className="flex justify-end">
              <img
                src={DAYS}
                alt=""
                className="w-[76px] h-[78px] xs:h-[93px] sm:w-[132px] md:w-[159px] sm:h-[140px] md:h-[168px] lg:w-[300] xl:w-[307px]  lg:h-[295px] pr-[5px] lg:pr-[20px] xl:pr-[25px] -mt-[8px] xs:-mt-[23px] sm:-mt-[33px] md:-mt-[32px] lg:-mt-[124px] "
              />
            </div>
          </div>
        </div>
      </div>
      <div className="underline-offset-8 w-full border-[1px] color-[#0000002E] center-alignment mt-[25px] sm:mt-1"></div>
      <div ref={ref} className="mt-[60px] font-poppins">
        <div className="flex flex-wrap items-center justify-center gap-4 lg:justify-between mx-10">
          <AnimatedCounter
            initialValue={0}
            finalValue={1500}
            label="Ordinance Points"
          />
          <AnimatedCounter
            initialValue={0}
            finalValue={450}
            label="Pincodes Serving"
          />
          <AnimatedCounter
            initialValue={0}
            finalValue={1800}
            label="Happy Customers"
          />
        </div>

        <div className="underline-offset-8 w-full border-[1px] color-[#0000002E] center-alignment mt-5"></div>
        <div className="lg:flex items-center justify-center gap-5 xl:mx-3  mt-[16px] sm:my-10">
          <img
            src={HeathCradLogo}
            alt="HeathCRADLogo"
            className="w-[251px] h-[70px] sm:w-[280px] sm:h-[85px] mx-auto"
          />
          <p className="text-[#524F4FBD] font-poppins font-light text-justify lg:text-[18px] md:text-[16px] text-[13px] mx-[8px] mb-[25px] mt-2.5 sm:mt-0 sm:mb-0 sm:mx-0">
            Healthcrad.com Is India's most trusted healthcare service provider
            company from easy doctor appointment to quick ambulance booking to
            easy path lab support to dispensing quality medicines at affordable
            prices.
          </p>
        </div>
      </div>
    </>
  );
};

export default ServicesData;
