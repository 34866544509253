import React, { useState } from "react";
import img1 from "../../images/frontend UI materials/testonomial_2pic.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import arrow from "../../images/Vector_arrow.png";
import arrow_left from "../../images/Vector_left.png";
import arrow_right from "../../images/Vector _right.png";

const Testimonial = () => {
  const [slider, setSlider] = useState(null);

  const goToPrev = () => {
    slider && slider.slickPrev();
  };

  const goToNext = () => {
    slider && slider.slickNext();
  };
  var settings = {
    infinite: false,
    dots: false,
    slidesToShow: 4, // Set this to an integer value
    draggable: true,
    slidesToScroll: 1,
    autoplay: false,
    speed: 800,
    swipe: true,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3.5,
          slidesToScroll: 1,
        },
        classes: "lg:gap-3",
      },
      {
        breakpoint: 850,
        settings: {
          slidesToShow: 2.4,
          slidesToScroll: 1,
        },
        classes: "md:gap-2",
      },
      {
        breakpoint: 760,
        settings: {
          slidesToShow: 1.8,
          slidesToScroll: 1,
        },
        classes: "sm:gap-2",
      },
      {
        breakpoint: 639,
        settings: {
          slidesToShow: 2.4,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 570,
        settings: {
          slidesToShow: 2.1,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 490,
        settings: {
          slidesToShow: 1.8,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 1.6,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1.4,
          slidesToScroll: 2,
        },
      },
    ],
  };

  return (
    <>
      <div className=" mt-6 mb-6 relative  ">
        <div className="flex flex-col justify-center items-center">
          <div className="self-start  p-1.5 ml-2 sm:ml-8">
            <img
              className="sm:w-[240px] sm:h-[70px] w-[150px] h-[40px]"
              src={img1}
              alt=""
              srcset=""
            />
          </div>
          <div className="w-full overflow-x-hidden">
            <Slider
              className="your-slider-styles"
              {...settings}
              ref={(slider) => setSlider(slider)}
            >
              {/* 1st */}
              <div className="mb-4 bg-[#FFFFFF] shadow-md sm:w-[280px] sm:h-[385px] w-[140px] flex flex-col justify-center items-center rounded-[10px] border-[1px] ">
                <div className=" flex justify-center items-center">
                  <iframe
                    className="sm:w-[280px]  sm:h-[250px] w-[173px] h-[160px] rounded-tl-[10px] rounded-tr-[10px] "
                    src="https://www.youtube.com/embed/d78t3S6mV78?si=WbWu-6zRxFwJBmfR"
                    title="Video 1"
                  ></iframe>
                </div>
                <div className="p-2 gap-1 flex flex-col">
                  <p className="font-semibold sm:mt-1 font-lato text-[#000000] text-[14px] sm:text-[16px] ">
                    Happy Customer
                  </p>
                  <span className="font-lato  font-medium text-[#666161] text-[11px] sm:text-[13px]  ">
                    Don’t wait order now !
                  </span>
                  <Link
                    to="https://youtube.com/playlist?list=PLXcqIYF_7eIOsFAWOZKgGoI-eCCOh6ckz&si=DbSUs1Iav66G1CKs"
                    target="_blank"
                  >
                    <div className=" flex justify-around items-center bg-[#556FF8] rounded-[8px] sm:mt-3  h-10 sm:h-11 ">
                      <button className=" text-[#FFF] font-lato font-semibold text-[15px] sm:text-[18px] ">
                        Watch
                      </button>

                      <img
                        src={arrow}
                        alt=""
                        srcset=""
                        className=" cursor-pointer sm:h-6 sm:w-6 h-4 w-4 "
                      />
                    </div>
                  </Link>
                </div>
              </div>
              {/* 2nd */}
              <div className="mb-4 bg-[#FFFFFF] shadow-md sm:w-[280px] sm:h-[385px] w-[140px] flex flex-col justify-center items-center rounded-[10px] border-[1px] ">
                <div className=" flex justify-center items-center">
                  <iframe
                    className="sm:w-[280px]  sm:h-[250px] w-[173px] h-[160px] rounded-tl-[10px] rounded-tr-[10px] "
                    src="https://www.youtube.com/embed/v7EUKyXiqhA?si=oGwhj8bxIhTxV_-N"
                    title="Video 2"
                  ></iframe>
                </div>
                <div className="p-2 gap-1 flex flex-col">
                  <p className="font-semibold sm:mt-1 font-lato text-[#000000] text-[14px] sm:text-[16px] ">
                    Happy Customer
                  </p>
                  <span className="font-lato  font-medium text-[#666161] text-[11px] sm:text-[13px]  ">
                    Don’t wait order now !
                  </span>
                  <Link
                    to="https://youtube.com/playlist?list=PLXcqIYF_7eIOsFAWOZKgGoI-eCCOh6ckz&si=DbSUs1Iav66G1CKs"
                    target="_blank"
                  >
                    <div className=" flex justify-around items-center bg-[#556FF8] rounded-[8px] sm:mt-3  h-10 sm:h-11 ">
                      <button className=" text-[#FFF] font-lato font-semibold text-[15px] sm:text-[18px] ">
                        Watch
                      </button>

                      <img
                        src={arrow}
                        alt=""
                        srcset=""
                        className=" cursor-pointer sm:h-6 sm:w-6 h-4 w-4 "
                      />
                    </div>
                  </Link>
                </div>
              </div>

              {/* 3rd */}
              <div className="mb-4 bg-[#FFFFFF] shadow-md sm:w-[280px] sm:h-[385px] w-[140px] flex flex-col justify-center items-center rounded-[10px] border-[1px] ">
                <div className=" flex justify-center items-center">
                  <iframe
                    className="sm:w-[280px]  sm:h-[250px] w-[173px] h-[160px] rounded-tl-[10px] rounded-tr-[10px] "
                    src="https://www.youtube.com/embed/CvL2Zax6Lgg?si=QtaUWyS-O9eao6BT"
                    title="Video 3"
                  ></iframe>
                </div>
                <div className="p-2 gap-1 flex flex-col">
                  <p className="font-semibold sm:mt-1 font-lato text-[#000000] text-[14px] sm:text-[16px] ">
                    Happy Customer
                  </p>
                  <span className="font-lato  font-medium text-[#666161] text-[11px] sm:text-[13px]  ">
                    Don’t wait order now !
                  </span>
                  <Link
                    to="https://youtube.com/playlist?list=PLXcqIYF_7eIOsFAWOZKgGoI-eCCOh6ckz&si=DbSUs1Iav66G1CKs"
                    target="_blank"
                  >
                    <div className=" flex justify-around items-center bg-[#556FF8] rounded-[8px] sm:mt-3  h-10  sm:h-11 ">
                      <button className=" text-[#FFF] font-lato font-semibold text-[15px] sm:text-[18px] ">
                        Watch
                      </button>

                      <img
                        src={arrow}
                        alt=""
                        srcset=""
                        className=" cursor-pointer sm:h-6 sm:w-6 h-4 w-4 "
                      />
                    </div>
                  </Link>
                </div>
              </div>

              {/* 4th */}
              <div className="mb-4 bg-[#FFFFFF] shadow-md sm:w-[280px] sm:h-[385px] w-[140px] flex flex-col justify-center items-center rounded-[10px] border-[1px] ">
                <div className=" flex justify-center items-center">
                  <iframe
                    className="sm:w-[280px]  sm:h-[250px] w-[173px] h-[160px] rounded-tl-[10px] rounded-tr-[10px] "
                    src="https://www.youtube.com/embed/o8o3tebG3-Q?si=baUi4Qr7hsUvvnPb"
                    title="Video 4"
                  ></iframe>
                </div>
                <div className="p-2 gap-1 flex flex-col">
                  <p className="font-semibold sm:mt-1 font-lato text-[#000000] text-[14px] sm:text-[16px] ">
                    Happy Customer
                  </p>
                  <span className="font-lato  font-medium text-[#666161] text-[11px] sm:text-[13px]  ">
                    Don’t wait order now !
                  </span>
                  <Link
                    to="https://youtube.com/playlist?list=PLXcqIYF_7eIOsFAWOZKgGoI-eCCOh6ckz&si=DbSUs1Iav66G1CKs"
                    target="_blank"
                  >
                    <div className=" flex justify-around items-center bg-[#556FF8] rounded-[8px] sm:mt-3  h-10 sm:h-11 ">
                      <button className=" text-[#FFF] font-lato font-semibold text-[15px] sm:text-[18px] ">
                        Watch
                      </button>

                      <img
                        src={arrow}
                        alt=""
                        srcset=""
                        className=" cursor-pointer sm:h-6 sm:w-6 h-4 w-4 "
                      />
                    </div>
                  </Link>
                </div>
              </div>

              {/* 5th */}
              <div className="mb-4 bg-[#FFFFFF] shadow-md sm:w-[280px] sm:h-[385px] w-[140px] flex flex-col justify-center items-center rounded-[10px] border-[1px] ">
                <div className=" flex justify-center items-center">
                  <iframe
                    className="sm:w-[280px]  sm:h-[250px] w-[173px] h-[160px] rounded-tl-[10px] rounded-tr-[10px] "
                    src="https://www.youtube.com/embed/NmX85rl8pHg?si=tQ0ss3X4uf0K2mNg"
                    title="Video 5"
                  ></iframe>
                </div>
                <div className="p-2 gap-1 flex flex-col">
                  <p className="font-semibold sm:mt-1 font-lato text-[#000000] text-[14px] sm:text-[16px] ">
                    Happy Customer
                  </p>
                  <span className="font-lato  font-medium text-[#666161] text-[11px] sm:text-[13px]  ">
                    Don’t wait order now !
                  </span>
                  <Link
                    to="https://youtube.com/playlist?list=PLXcqIYF_7eIOsFAWOZKgGoI-eCCOh6ckz&si=DbSUs1Iav66G1CKs"
                    target="_blank"
                  >
                    <div className=" flex justify-around items-center bg-[#556FF8] rounded-[8px] sm:mt-3  h-10 sm:h-11 ">
                      <button className=" text-[#FFF] font-lato font-semibold text-[15px] sm:text-[18px] ">
                        Watch
                      </button>

                      <img
                        src={arrow}
                        alt=""
                        srcset=""
                        className=" cursor-pointer sm:h-6 sm:w-6 h-4 w-4 "
                      />
                    </div>
                  </Link>
                </div>
              </div>
              {/* 6th */}
              <div className="mb-4 bg-[#FFFFFF] shadow-md sm:w-[280px] sm:h-[385px] w-[140px] flex flex-col justify-center items-center rounded-[10px] border-[1px] ">
                <div className=" flex justify-center items-center">
                  <iframe
                    className="sm:w-[280px]  sm:h-[250px] w-[173px] h-[160px] rounded-tl-[10px] rounded-tr-[10px] "
                    src="https://www.youtube.com/embed/d78t3S6mV78?si=WbWu-6zRxFwJBmfR"
                    title="Video 1"
                  ></iframe>
                </div>
                <div className="p-2 gap-1 flex flex-col">
                  <p className="font-semibold sm:mt-1 font-lato text-[#000000] text-[14px] sm:text-[16px] ">
                    Happy Customer
                  </p>
                  <span className="font-lato  font-medium text-[#666161] text-[11px] sm:text-[13px]  ">
                    Don’t wait order now !
                  </span>
                  <Link
                    to="https://youtube.com/playlist?list=PLXcqIYF_7eIOsFAWOZKgGoI-eCCOh6ckz&si=DbSUs1Iav66G1CKs"
                    target="_blank"
                  >
                    <div className=" flex justify-around items-center bg-[#556FF8] rounded-[8px] sm:mt-3  h-10 sm:h-11 ">
                      <button className=" text-[#FFF] font-lato font-semibold text-[15px] sm:text-[18px] ">
                        Watch
                      </button>

                      <img
                        src={arrow}
                        alt=""
                        srcset=""
                        className=" cursor-pointer sm:h-6 sm:w-6 h-4 w-4 "
                      />
                    </div>
                  </Link>
                </div>
              </div>
            </Slider>
          </div>
          <div className=" flex justify-center items-center sm:pt-2 gap-5">
            <img
              src={arrow_left}
              alt=""
              srcset=""
              className=" sm:w-10 sm:h-10 w-9 h-8 cursor-pointer "
              onClick={goToPrev}
            />
            <img
              src={arrow_right}
              alt=""
              srcset=""
              className="sm:w-10 sm:h-10 w-9 h-8 cursor-pointer "
              onClick={goToNext}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Testimonial;
