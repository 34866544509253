import React from "react";
import Header from "../Header";
import img1 from "../../../images/Gallery/3.jpeg";
import img2 from "../../../images/Gallery/4.jpeg";
import img3 from "../../../images/Gallery/5.jpeg";
import img4 from "../../../images/Gallery/6.jpeg";
import img5 from "../../../images/Gallery/7.jpeg";
import img6 from "../../../images/Gallery/8.jpeg";
import img7 from "../../../images/Gallery/9.jpeg";
import img8 from "../../../images/Gallery/10.jpeg";
import img9 from "../../../images/Gallery/11.jpg";
import img10 from "../../../images/Gallery/12.jpeg";
import img11 from "../../../images/Gallery/13.jpeg";
import img12 from "../../../images/Gallery/14.jpeg";
import img13 from "../../../images/Gallery/P1.jpeg";
import img14 from "../../../images/Gallery/P2.jpeg";
import img15 from "../../../images/Gallery/C1.jpeg";
import img16 from "../../../images/Gallery/C2.jpeg";
import img17 from "../../../images/Gallery/microsoft.jpeg";
import img18 from "../../../images/Gallery/IFBIC.jpeg";
import img19 from "../../../images/Gallery/Eure.jpeg";
import img20 from "../../../images/Gallery/startup bihar.jpeg";
import img21 from "../../../images/Gallery/startup india.jpeg";

const GalleryPage = () => {
  return (
    <>
      <Header />
      <div className="sm:ml-28 ml-4 pt-28">
        <p className="font-poppins text-[#000] text-2xl font-semibold">
          HealthCRAD Gallery
        </p>
      </div>
      <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-6 sm:ml-24 sm:mr-24 mt-8 ml-4 mr-4">
        <div className="bg-white rounded-md shadow-md">
          <img
            className="w-full h-full object-cover zoomable rounded-md p-1 sm:w-full sm:h-full sm:p-3 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-white duration-300 ..."
            src={img1}
            alt=""
            srcset=""
          />
        </div>
        <div className="bg-white rounded-md shadow-md">
          <img
            className="w-full h-full object-cover zoomable rounded-md p-1 sm:w-full sm:h-full sm:p-3 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-white duration-300 ..."
            src={img3}
            alt=""
            srcset=""
          />
        </div>
        <div className="bg-white rounded-md shadow-md">
          <img
            className="w-full h-full object-cover zoomable rounded-md p-1 sm:w-full sm:h-full sm:p-3 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-white duration-300 ..."
            src={img4}
            alt=""
            srcset=""
          />
        </div>
        <div className="bg-white rounded-md shadow-md w-full h-40">
          <img
            className="w-full h-40 object-cover zoomable rounded-md p-1 sm:w-full sm:h-full sm:p-3 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-white duration-300 ..."
            src={img5}
            alt=""
            srcset=""
          />
        </div>
        <div className="bg-white rounded-md shadow-md">
          <img
            className="w-full h-full object-cover zoomable rounded-md p-1 sm:w-full sm:h-full sm:p-3 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-white duration-300 ..."
            src={img6}
            alt=""
            srcset=""
          />
        </div>
        <div className="bg-white rounded-md shadow-md">
          <img
            className="w-full h-full object-cover zoomable rounded-md p-1 sm:w-full sm:h-full sm:p-3 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-white duration-300 ..."
            src={img7}
            alt=""
            srcset=""
          />
        </div>
        <div className="bg-white rounded-md shadow-md">
          <img
            className="w-full h-full object-cover zoomable rounded-md p-1 sm:w-full sm:h-full sm:p-3 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-white duration-300 ..."
            src={img8}
            alt=""
            srcset=""
          />
        </div>
        <div className="bg-white rounded-md shadow-md">
          <img
            className="w-full h-full object-cover zoomable rounded-md p-1 sm:w-full sm:h-full sm:p-3 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-white duration-300 ..."
            src={img9}
            alt=""
            srcset=""
          />
        </div>
        <div className="bg-white rounded-md shadow-md">
          <img
            className="w-full h-full object-cover zoomable rounded-md p-1 sm:w-full sm:h-full sm:p-3 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-white duration-300 ..."
            src={img10}
            alt=""
            srcset=""
          />
        </div>
        <div className="bg-white rounded-md shadow-md">
          <img
            className="w-full h-full object-cover zoomable rounded-md p-1 sm:w-full sm:h-full sm:p-3 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-white duration-300 ..."
            src={img11}
            alt=""
            srcset=""
          />
        </div>
        <div className="bg-white rounded-md shadow-md">
          <img
            className="w-full h-full object-cover zoomable rounded-md p-1 sm:w-full sm:h-full sm:p-3 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-white duration-300 ..."
            src={img12}
            alt=""
            srcset=""
          />
        </div>
        <div className="bg-white rounded-md shadow-md">
          <img
            className="w-full h-full object-cover zoomable rounded-md p-1 sm:w-full sm:h-full sm:p-3 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-white duration-300 ..."
            src={img13}
            alt=""
            srcset=""
          />
        </div>
        <div className="bg-white rounded-md shadow-md">
          <img
            className="w-full h-full object-cover zoomable rounded-md p-1 sm:w-full sm:h-full sm:p-3 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-white duration-300 ..."
            src={img14}
            alt=""
            srcset=""
          />
        </div>
        <div className="bg-white rounded-md shadow-md">
          <img
            className="w-full h-full object-cover zoomable rounded-md p-1 sm:w-full sm:h-full sm:p-3 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-white duration-300 ..."
            src={img15}
            alt=""
            srcset=""
          />
        </div>
        <div className="bg-white rounded-md shadow-md">
          <img
            className="w-full h-full object-cover zoomable rounded-md p-1 sm:w-full sm:h-full sm:p-3 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-white duration-300 ..."
            src={img16}
            alt=""
            srcset=""
          />
        </div>
        <div className="bg-white rounded-md shadow-md">
          <img
            className="w-96 h-full object-cover zoomable rounded-md p-1 sm:w-full sm:h-full sm:p-3 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-white duration-300 ..."
            src={img17}
            alt=""
            srcset=""
          />
        </div>
        <div className="bg-white rounded-md shadow-md">
          <img
            className="w-full h-full object-cover zoomable rounded-md p-1 sm:w-full sm:h-full sm:p-3 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-white duration-300 ..."
            src={img18}
            alt=""
            srcset=""
          />
        </div>
        <div className="bg-white rounded-md shadow-md">
          <img
            className="w-full h-full object-cover zoomable rounded-md p-1 sm:w-full sm:h-full sm:p-3 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-white duration-300 ..."
            src={img19}
            alt=""
            srcset=""
          />
        </div>
        <div className="bg-white rounded-md shadow-md">
          <img
            className="w-full h-full object-cover zoomable rounded-md p-1 sm:w-full sm:h-full sm:p-3 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-white duration-300 ..."
            src={img20}
            alt=""
            srcset=""
          />
        </div>
        <div className="bg-white rounded-md shadow-md">
          <img
            className="w-full h-full object-cover zoomable rounded-md p-1 sm:w-full sm:h-full sm:p-3 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-white duration-300 ..."
            src={img21}
            alt=""
            srcset=""
          />
        </div>
        <div className="bg-white rounded-md shadow-md  w-full h-40">
          <img
            className="w-full h-40 object-cover zoomable rounded-md p-1 sm:w-full sm:h-full sm:p-3 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-white duration-300 ..."
            src={img2}
            alt=""
            srcset=""
          />
        </div>
      </div>
    </>
  );
};

export default GalleryPage;
