import React, { useState, useEffect } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt, faSearch } from "@fortawesome/free-solid-svg-icons";
import {
  Autocomplete,
  GoogleMap,
  LoadScript,
  MarkerF,
} from "@react-google-maps/api";
import { renderToString } from "react-dom/server";

const AddAddress = () => {
  const [currentLocation, setCurrentLocation] = useState(null);
  const [address, setAddress] = useState(null);
  const [searchBox, setSearchBox] = useState(null);
  const [mapDimensions, setMapDimensions] = useState({
    width: "100%",
    height: "400px",
  });

  const [markers, setMarkers] = useState([]);

  useEffect(() => {
    const fetchLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            setCurrentLocation({ latitude, longitude });
            getAddressFromCoordinates(latitude, longitude);

            setMarkers([createMarker(latitude, longitude)]);
          },
          (error) => {
            console.error("Error getting location:", error.message);
          }
        );

        const watchId = navigator.geolocation.watchPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            setCurrentLocation((prevLocation) => ({
              ...prevLocation,
              latitude,
              longitude,
            }));
            getAddressFromCoordinates(latitude, longitude);

            setMarkers([createMarker(latitude, longitude)]);
          },
          (error) => {
            console.error("Error getting location:", error.message);
          }
        );

        return () => {
          navigator.geolocation.clearWatch(watchId);
        };
      } else {
        console.error("Geolocation is not supported by this browser");
      }
    };

    fetchLocation();
  }, []);

  const createMarker = (lat, lng) => {
    if (window.google && window.google.maps) {
      return (
        <MarkerF
          position={{
            lat,
            lng,
          }}
          icon={{
            url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(
              renderToString(
                <FontAwesomeIcon
                  icon={faMapMarkerAlt}
                  className=" "
                  style={{ color: "red", height: "30px", width: "15px" }}
                />
              )
            )}`,
            scaledSize: new window.google.maps.Size(30, 30),
          }}
        />
      );
    } else {
      // Handle the case where window.google.maps is not defined
      console.error("Google Maps API is not available");
      return null; // or any default marker, or an empty fragment
    }
  };

  const getAddressFromCoordinates = async (lat, lng) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyAn7_5pFRSJ_i1lbbiitBzZinnYY7VTGxk`
      );

      if (!response.ok) {
        throw new Error("Failed to fetch address");
      }

      const data = await response.json();
      console.log("Geocoding API response:", data);

      if (data.results && data.results.length > 0) {
        const formattedAddress = data.results[0].formatted_address;
        console.log("Formatted Address:", formattedAddress);
        setAddress(formattedAddress);
      }
    } catch (error) {
      console.error("Error fetching address:", error.message);
    }
  };

  const handlePlaceSelect = () => {
    if (searchBox) {
      const selectedPlace = searchBox.getPlace();

      if (selectedPlace && selectedPlace.geometry) {
        const newLocation = {
          latitude: selectedPlace.geometry.location.lat(),
          longitude: selectedPlace.geometry.location.lng(),
        };

        setCurrentLocation(newLocation);
        setAddress(selectedPlace.formatted_address);

        if (searchBox.setBounds) {
          searchBox.setBounds(selectedPlace.geometry.viewport);
        }

        // Update markers array
        setMarkers([createMarker(newLocation.latitude, newLocation.longitude)]);
      }
    }
  };

  const handleMapClick = (event) => {
    const clickedLocation = {
      latitude: event.latLng.lat(),
      longitude: event.latLng.lng(),
    };

    setCurrentLocation(clickedLocation);
    getAddressFromCoordinates(
      clickedLocation.latitude,
      clickedLocation.longitude
    );

    // Update markers array
    setMarkers([
      createMarker(clickedLocation.latitude, clickedLocation.longitude),
    ]);
  };

  //for search icon
  // const [isHidden, setIsHidden] = useState(false);

  // const handleClickSearchIcon = () => {
  //   setIsHidden(!isHidden);
  // };

  return (
    <>
      <Header />
      <div className="pt-16 ">
        <div className="flex justify-center items-center ">
          <div className="flex flex-col  mb-12  rounded-[10px] md:h-[90%] sm:h-full sm:w-[80%] lg:w-[80%] gap-6 ">
            <div className="flex flex-col justify-center items-center">
              <div className="sm:w-[95%] w-full md:w-[90%] lg:w-[90%] xl:w-[80%]">
                <div className="  flex justify-center items-center mt-2 mb-2 ">
                  <p className="text-[#10CAF2] font-bold self-center text-lg ">
                    Add Address
                  </p>
                </div>
                {currentLocation ? (
                  <>
                    {/* <p className=" font-poppins text-md text-[#000] font-semibold pb-2 self-center text-center">
                      Latitude: {currentLocation.latitude}, Longitude:{" "}
                      {currentLocation.longitude}
                    </p> */}

                    {/* Google Map */}
                    <div className="w-full sm:w-[100%] flex flex-col justify-center items-center">
                      <LoadScript
                        googleMapsApiKey="AIzaSyAn7_5pFRSJ_i1lbbiitBzZinnYY7VTGxk"
                        libraries={["places"]}
                      >
                        <Autocomplete
                          onLoad={(autocomplete) => setSearchBox(autocomplete)}
                          onPlaceChanged={handlePlaceSelect}
                        >
                          <div
                            className="flex w-[300px]  sm:w-[400px] lg:w-[500px] gap-3 border-[1px] mb-3 border-[#00000033] bg-[#FFF] rounded-[10px] p-2 "
                            style={{
                              boxShadow:
                                "0px 4px 11.6px 0px rgba(0, 0, 0, 0.15)",
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faSearch}
                              className="left-3  text-[#10CAF2] w-6 h-6 "
                            />
                            <input
                              type="text"
                              placeholder="Search for an address"
                              className=" focus:outline-none "
                            />
                          </div>
                        </Autocomplete>
                        <GoogleMap
                          mapContainerClassName="rounded-[10px]"
                          mapContainerStyle={mapDimensions}
                          zoom={15}
                          center={{
                            lat: currentLocation?.latitude || 0,
                            lng: currentLocation?.longitude || 0,
                          }}
                          onClick={handleMapClick}
                        >
                          {markers.map((marker, index) => (
                            <React.Fragment key={index}>
                              {marker}
                            </React.Fragment>
                          ))}
                        </GoogleMap>
                      </LoadScript>
                    </div>
                  </>
                ) : (
                  "Fetching location..."
                )}
              </div>

              {/* Footer Start */}
              <div className="w-full sm:w-[60%] lg:w-[50%] xl:w-[40%] bg-[#FFF] mt-4 mb-4 flex flex-col justify-center items-center">
                <div className="w-full ml-2 pb-2">
                  <p className="text-[black] font-bold text-xs sm:text-sm lg:text-md ml-3">
                    <FontAwesomeIcon
                      icon={faMapMarkerAlt}
                      className="text-[#FE0505] "
                    />{" "}
                    {address}
                  </p>
                </div>
                <div className="select-none w-[95%] h-12 bg-[#10CAF2] flex justify-center items-center rounded-lg cursor-pointer">
                  <p className="text-center text-[#FFF] font-bold ">
                    Save Address
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AddAddress;
