import React, { useState } from "react";
import Doctor from "../../../images/frontend UI materials/merchant_doctor.jpg";
import MedicineSeller from "../../../images/frontend UI materials/merchant_medicine_seller.jpg";
import Ambulance from "../../../images/frontend UI materials/Ambulance Pick.jpeg";
import Diagnostics from "../../../images/frontend UI materials/Diagnostics.png";
import Chevronright from "../../../images/frontend UI materials/chevron-right.png";
import { useNavigate } from "react-router-dom";
import PageLoader from "../../../Loader/PageLoader";

const Merchant = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleRoute = (path) => {
    setLoading(true);
    setTimeout(() => {
      navigate(path);
    }, 1000);
  };
  return (
    <div className="w-full ">
      {loading && <PageLoader />}
      <div className="pt-10  font-poppins">
        {/* Heading and SubHeading */}
        <div className="">
          <h1 className="text-[#2F2D2D] text-[18px] sm:text-[24px]  font-semibold font-lato pl-[7px] xs:pl-[13px] sm:pl-[28px] md:pl-[45px] lg:pl-[50px] xl:pl-[17px] 2xl:pl-[53px] ">
            Introducing our merchant section
          </h1>
          <p className="text-[#666161] text-[11px] sm:text-[13px] md:text-[16px]  font-medium pl-[7px] xs:pl-[13px] sm:pl-[28px] md:pl-[45px] lg:pl-[50px] xl:pl-[17px] 2xl:pl-[53px] mb-[15px] sm:-mb-[20px]">
            Join HealthCRAD and serve needy people
          </p>
        </div>
        {/* Parent Div Card */}
        <div className="w-full grid grid-cols-2  xs:gap-6 sm:gap-2 xl:grid-cols-4 sm:m-0 justify-items-center">
          {/* Card-1 */}
          <div className="sm:mt-[36px] w-[145px] xs:w-[164px] sm:w-[242px] md:w-[287px] lg:w-[390px] xl:w-[269px]  h-auto mb-[14px] sm:mb-0">
            <img
              src={Doctor}
              alt=""
              className=" w-[145px] xs:w-[164px] h-[119px] sm:w-[242px] sm:h-[167px] md:w-[287px] md:h-[218px] lg:h-[300px] lg:w-[390px] xl:w-[269px] xl:h-[268px] rounded-[10px]"
            />
            <h2 className="font-medium font-lato text-[14px] sm:text-[16px] lg:text-[28px] xl:text-[24px] text-[#000] pt-[20px] pb-[8px]">
              Doctors
            </h2>
            <p className="text-[#414146] text-[8px] xs:text-[11px] sm:text-[11px] md:text-[12px]] lg:text-[20px] xl:text-[15px] font-normal pb-[4px]">
              Doctors being the forefront stakeholders of the healthcare{" "}
              ecosystem
            </p>
            <div
              className="flex gap-2.5"
              onClick={() => handleRoute("/doctors-section")}
            >
              <button className="text-[#10CAF2] font-normal text-[11px] sm:text-[11px] md:text-[12px] lg:text-[20px] xl:text-[15px]">
                Visit now
              </button>
              <img
                src={Chevronright}
                alt=""
                className="mt-[4px] h-[8px] w-[6px]  sm:mt-[4px] sm:h-[9px] sm:w-[7px] lg:mt-[9px] lg:w-[9px] lg:h-[14px] xl:mt-[5px] xl:w-[8px] xl:h-[14px] "
              />
            </div>
          </div>
          {/* Card-2 */}
          <div className="sm:mt-[36px] w-[145px] xs:w-[164px] sm:w-[242px]  md:w-[287px]  lg:w-[390px] xl:w-[269px]  h-auto mb-[14px] sm:mb-0">
            <img
              src={Ambulance}
              alt=""
              className="w-[145px] xs:w-[164px] h-[119px] sm:w-[242px] sm:h-[167px] md:w-[287px] md:h-[218px] lg:h-[300px] lg:w-[390px] xl:w-[269px] xl:h-[268px] rounded-[10px]"
            />
            <h2 className="font-medium font-lato text-[14px] sm:text-[16px] lg:text-[28px] xl:text-[24px] text-[#000] pt-[20px] pb-[8px]">
              Ambulance Provider
            </h2>
            <p className=" text-[#414146] text-[8px] xs:text-[11px] sm:text-[11px] md:text-[12px] lg:text-[20px] xl:text-[15px] font-normal pb-[4px]">
              Ambulance service provider are one of the main pillar of
              Healthcare ecosystem
            </p>
            <div
              className="flex gap-2.5"
              onClick={() => handleRoute("/ambulance-provider")}
            >
              <button className="text-[#10CAF2] font-normal text-[11px] sm:text-[11px] md:text-[12px] lg:text-[20px] xl:text-[15px]">
                Visit now
              </button>
              <img
                src={Chevronright}
                alt=""
                className="mt-[4px] h-[8px] w-[6px]  sm:mt-[4px] sm:h-[9px] sm:w-[7px] lg:mt-[9px] lg:w-[9px] lg:h-[14px] xl:mt-[5px] xl:w-[8px] xl:h-[14px] "
              />
            </div>
          </div>
          {/* Card-3 */}
          <div className="sm:mt-[36px] w-[145px] xs:w-[164px] sm:w-[242px] md:w-[287px]  lg:w-[390px] xl:w-[269px] h-auto mb-[14px] sm:mb-0">
            <img
              src={MedicineSeller}
              alt=""
              className="w-[145px] xs:w-[164px] h-[119px]  sm:w-[242px] sm:h-[167px] md:w-[287px] md:h-[218px] lg:h-[300px] lg:w-[390px] xl:w-[269px] xl:h-[268px] rounded-[10px]"
            />
            <h2 className="font-medium font-lato text-[14px] sm:text-[16px]  lg:text-[28px] xl:text-[24px] text-[#000] pt-[20px] pb-[8px]">
              Medicine Sellers
            </h2>
            <p className="text-[#414146]  text-[8px] xs:text-[11px] sm:text-[11px] md:text-[12px]  lg:text-[20px] xl:text-[15px]  font-normal pb-[4px]">
              Medicine and Medical product providers are the backbone of the
              Healthcare
            </p>
            <div
              className="flex gap-2.5 xs:mt-[16px] xl:mt-0"
              onClick={() => handleRoute("/medicine-sellers")}
            >
              <button className="text-[#10CAF2] font-normal text-[11px] sm:text-[11px] md:text-[12px] lg:text-[20px] xl:text-[15px]">
                Visit now
              </button>
              <img
                src={Chevronright}
                alt=""
                className="mt-[4px] h-[8px] w-[6px]  sm:mt-[4px] sm:h-[9px] sm:w-[7px] lg:mt-[9px] lg:w-[9px] lg:h-[14px] xl:mt-[5px] xl:w-[8px] xl:h-[14px] "
              />
            </div>
          </div>
          {/* Card-4 */}
          <div className="sm:mt-[36px] w-[145px] xs:w-[164px] sm:w-[242px] md:w-[287px] lg:w-[390px] xl:w-[269px]  h-auto mb-[14px] sm:mb-0">
            <img
              src={Diagnostics}
              alt=""
              className="w-[145px] xs:w-[164px] h-[119px]  sm:w-[242px] sm:h-[167px]  md:w-[287px] md:h-[218px]  lg:h-[300px] lg:w-[390px] xl:w-[269px] xl:h-[268px] rounded-[10px]"
            />
            <h2 className="font-medium font-lato text-[14px] sm:text-[16px] lg:text-[28px] xl:text-[24px] text-[#000] pt-[20px] pb-[8px]">
              Diagnostics
            </h2>
            <p className="text-[#414146]  text-[8px] xs:text-[11px] sm:text-[11px] md:text-[12px] lg:text-[20px] xl:text-[15px] font-normal pb-[4px] ">
              Paving the way for precision in diagnostics, where every test
              leads to a healthier journey ahead
            </p>
            <div
              className="flex gap-2.5"
              onClick={() => handleRoute("/diagnostics")}
            >
              <button className="text-[#10CAF2] font-normal text-[11px] sm:text-[11px] md:text-[12px] lg:text-[20px] xl:text-[15px]">
                Visit now
              </button>
              <img
                src={Chevronright}
                alt=""
                className="mt-[4px] h-[8px] w-[6px]  sm:mt-[4px] sm:h-[9px] sm:w-[7px] lg:mt-[9px] lg:w-[9px] lg:h-[14px] xl:mt-[5px] xl:w-[8px] xl:h-[14px] "
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Merchant;
