import React, { useState } from "react";
import GeneralPhysician from "../../images/DoctorCategory/General Physician.png";
import HeartSpecialist from "../../images/DoctorCategory/Heart Specialist.png";
import KidneySpecialist from "../../images/DoctorCategory/Kidney Specialist.png";
import BlueEyes from "../../images/DoctorCategory/Blue-eyes.png";
import Dentist from "../../images/DoctorCategory/Dentist.png";
import ForwardIcon from "../../images/frontend UI materials/icon _chevron-right_.svg";
import { useNavigate } from "react-router-dom";
import PageLoader from "../../Loader/PageLoader";

const DoctorsCategory = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleRoute = (path) => {
    setLoading(true);
    setTimeout(() => {
      navigate(path);
    }, 1000);
  };

  return (
    <div className="w-full my-10">
      {loading && <PageLoader />}
      <div className="flex justify-between">
        <div>
          <h1 className="text-[#2F2D2D]  sm:pb-0 text-[18px] sm:text-[24px] font-semibold font-lato pl-[7px] xs:pl-[13px] sm:pl-[28px] md:pl-[37px] lg:pl-[50px] xl:pl-[28px] 2xl:pl-[53px] ">
            Doctors from top categories
          </h1>
          <p className="text-[#666161] text-[11px] md:text-[16px]  font-medium pl-[7px] xs:pl-[13px] sm:pl-[28px] md:pl-[37px] lg:pl-[50px] xl:pl-[28px] 2xl:pl-[53px] mb-[15px]">
            Verified doctors in all specialties
          </p>
        </div>
        <div className="flex gap-[2px] xs:gap-2 mt-1 mr-2 sm:mr-14">
          <h2
            className="text-[13px] sm:text-[20px] text-[#10CAF2] cursor-pointer"
            onClick={() => handleRoute("/doctors")}
          >
            See all
          </h2>
          <img
            src={ForwardIcon}
            alt=""
            className="h-[8px] sm:h-[13px] mt-[4px] sm:mt-[5px]"
          />
        </div>
      </div>
      {/* Parent Div Card */}
      <div className="w-full grid grid-cols-2 sm:grid-cols-3 xl:grid-cols-5 gap-6 sm:gap-2 sm:m-0 justify-items-center sm:p-0 p-[9px]">
        {/* Card-1*/}
        <div
          className="hidden xl:block bg-[#FFB1CB]  sm:h-[194px] sm:w-[200px] rounded-[25px] cursor-pointer transform hover:scale-105 transition-transform duration-300 ease-in-out"
          onClick={() =>
            handleRoute("/doctors/appointmentBook?category=General%20Physician")
          }
        >
          <h2 className="text-[20px] font-normal font-poppins text-[#000000] ml-[22px] pt-[18px]">
            General <br /> Physician
          </h2>
          <div className="flex justify-end">
            <img
              src={GeneralPhysician}
              alt=""
              className="h-[133px] w-[115px] -mt-[5px] mr-[6px]"
            />
          </div>
        </div>
        {/* Card-2 */}
        <div
          className=" bg-[#C8BBFF] w-[145px] xs:w-[176px] h-[121px] sm:h-[194px] sm:w-[200px] rounded-[15px] sm:rounded-[25px]  cursor-pointer transform hover:scale-105 transition-transform duration-300 ease-in-out"
          onClick={() => handleRoute("/doctors/appointmentBook?category=Heart")}
        >
          <h2 className="text-[13px] sm:text-[20px] font-normal font-poppins text-[#000000] mt-[16px] ml-[15px] sm:mt-[18px] sm:ml-[28px]">
            Heart <br />
            Specialist
          </h2>
          <div className="flex justify-end">
            <img
              src={HeartSpecialist}
              alt=""
              className="sm:w-[117px] sm:h-[117px] sm:mt-0 w-[92] h-[87px] xs:w-[100px] xs:h-[100px] -mt-[16px] xs:-mt-[32px]"
            />
          </div>
        </div>
        {/* Card-1 xl:hidden */}
        <div
          className="xl:hidden w-[145px] h-[188px] xs:w-[176px] sm:w-[200px] sm:h-[194px]  bg-[#FFB1CB]  rounded-[15px] sm:rounded-[25px]  cursor-pointer transform hover:scale-105 transition-transform duration-300 ease-in-out"
          onClick={() =>
            handleRoute("/doctors/appointmentBook?category=General%20Physician")
          }
        >
          <h2 className="text-[13px] sm:text-[20px] font-normal font-poppins text-[#000000] mt-[16px] sm:mt-[2px] ml-[15px] sm:ml-[22px] sm:pt-[18px]">
            General <br /> Physician
          </h2>
          <div className="flex justify-end">
            <img
              src={GeneralPhysician}
              alt=""
              className="h-[137px] sm:h-[125px] w-[118px] mt-[4px] sm:mt-[2px] "
            />
          </div>
        </div>
        {/* Card-3 */}
        <div
          className="hidden xl:block bg-[#FFC18E]  sm:h-[194px] sm:w-[200px] rounded-[25px]  cursor-pointer transform hover:scale-105 transition-transform duration-300 ease-in-out"
          onClick={() =>
            handleRoute("/doctors/appointmentBook?category=Kidney%20Issues")
          }
        >
          <h2 className="text-[20px] font-normal font-poppins text-[#000000] mt-[18px] ml-[26px]  sm:mb-[25px]">
            Kidney <br />
            Specialist
          </h2>
          <div className="flex justify-end">
            <img
              src={KidneySpecialist}
              alt=""
              className="w-[117px] h-[92px] mr-[19px]"
            />
          </div>
        </div>
        {/* Card-4 */}
        <div
          className="-mt-[74px] sm:mt-0 bg-[#9BC8FF] w-[145px] xs:w-[176px] h-[121px] sm:h-[194px] sm:w-[200px] rounded-[15px] sm:rounded-[25px] cursor-pointer transform hover:scale-105 transition-transform duration-300 ease-in-out"
          onClick={() =>
            handleRoute("/doctors/appointmentBook?category=Eye%20Specialist")
          }
        >
          <h2 className="text-[13px] sm:text-[20px]  font-normal font-poppins text-[#000000] mt-[16px] ml-[15px] mb-[5px] sm:mt-[18px] sm:ml-[34px]  sm:mb-[40px]">
            Eye <br />
            Specialist
          </h2>
          <div className="flex justify-end">
            <img
              src={BlueEyes}
              alt=""
              className="sm:w-[117px] sm:h-[76px] sm:mr-[14px] h-[56px] w-[93px] mr-[16px] rounded-full"
            />
          </div>
        </div>
        {/* Card-2 xl:hidden */}
        <div
          className="xl:hidden w-[145px] xs:w-[176px] sm:w-[200px] sm:h-[194px]  h-[188px] bg-[#FFC18E]  rounded-[15px] sm:rounded-[25px] cursor-pointer transform hover:scale-105 transition-transform duration-300 ease-in-out"
          onClick={() =>
            handleRoute("/doctors/appointmentBook?category=Kidney%20Issues")
          }
        >
          <h2 className="text-[13px] sm:text-[20px] font-normal font-poppins text-[#000000] mt-[16px] ml-[15px] sm:mt-[18px] sm:ml-[26px] sm:mb-[16px]">
            Kidney <br />
            Specialist
          </h2>
          <div className="flex justify-end">
            <img
              src={KidneySpecialist}
              alt=""
              className="w-[125px] h-[99px] mr-[11px] mt-[25px] sm:mt-[4px]"
            />
          </div>
        </div>
        {/* Card-5*/}
        <div
          className=" -mt-[146px] sm:mt-0 bg-[#FFDE81] w-[145px] xs:w-[176px] h-[121px] sm:h-[194px] sm:w-[200px] rounded-[15px] sm:rounded-[25px] cursor-pointer transform hover:scale-105 transition-transform duration-300 ease-in-out"
          onClick={() =>
            handleRoute("/doctors/appointmentBook?category=Dentist")
          }
        >
          <h2 className="text-[13px] sm:text-[20px]  font-normal font-poppins text-[#000000]  ml-[15px] mt-[33px] sm:ml-[32px] sm:mb-[36px]">
            Dentist
          </h2>
          <div className="flex justify-end">
            <img
              src={Dentist}
              alt=""
              className="sm:w-[104px] sm:h-[90px] sm:mr-[27px] h-[60px] w-[69px] mr-[16px]"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoctorsCategory;
