import React, { useEffect, useState } from "react";
import Header from "../../Header";
import { useNavigate } from "react-router-dom";
import IcomTimes from "../../../../images/frontend UI materials/icon _Times_.svg";
import siteCofig from "../../../../urlFile";
import axios from "axios";

const ManageAddress = () => {
  const [loading, setLoading] = useState(false);
  const [sliderOpen, setSliderOpen] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [userDataList, setUserDataList] = useState([]);
  const [SuccessMessage, setSuccessMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [SuccessMessage1, setSuccessMessage1] = useState("");
  const [formData, setFormData] = useState({
    pincode: "",
    city: "",
    name: "",
    address: "",
    landmark: "",
    mobilenumber: "",
  });
  const [errors, setErrors] = useState({
    pincode: "",
    city: "",
    name: "",
    address: "",
    landmark: "",
    mobilenumber: "",
  });

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  // const openModalEdit = () => {
  //   setShowModalEdit(true);
  // };
  const openModalEdit = (addressId) => {
    const addressToEdit = userDataList.find(
      (address) => address.id === addressId
    );
    if (addressToEdit) {
      setFormData({
        pincode: addressToEdit.pincode,
        city: addressToEdit.city,
        name: addressToEdit.name,
        address: addressToEdit.address,
        landmark: addressToEdit.landmark,
        mobilenumber: addressToEdit.phone,
      });
      setShowModalEdit(true);
    }
  };

  const closeModalEdit = () => {
    setShowModalEdit(false);
  };

  const navigate = useNavigate();
  const handleRoute = (path) => {
    navigate(path);
  };

  useEffect(() => {
    if (localStorage.getItem("userId")) {
      handleGetRequest();
    }
  }, []);

  const validateForm = () => {
    let isValid = true;
    const newErrors = { ...errors };

    if (formData.pincode.length !== 6) {
      newErrors.pincode = "Pincode must be 6 digits";
      isValid = false;
    }

    if (!/^\d{10}$/.test(formData.mobilenumber)) {
      newErrors.mobilenumber = "Mobile Number must be 10 digits";
      isValid = false;
    }

    if (!formData.city) {
      newErrors.city = "City is required";
      isValid = false;
    }

    if (!formData.name) {
      newErrors.name = "Name is required";
      isValid = false;
    }

    if (!formData.address) {
      newErrors.address = "Address is required";
      isValid = false;
    }

    if (!formData.landmark) {
      newErrors.landmark = "Landmark is required";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleButtonClick = async () => {
    if (validateForm()) {
      setFormData({
        pincode: "",
        city: "",
        name: "",
        address: "",
        landmark: "",
        mobilenumber: "",
      });
      console.log("Form is valid:", formData);
      try {
        await handlePostRequest();
        setSuccessMessage1(true);
        setTimeout(() => {
          setSuccessMessage1(false);
        }, 2000);
      } catch (error) {
        console.log("Error saving address");
      }
      await handleGetRequest();
      setShowModal(false);
    } else {
      console.log("Form is not valid");
    }
  };

  const handleInputChange1 = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const handleGetRequest = async () => {
    let userId = localStorage.getItem("userId");
    try {
      const response = await axios.get(
        `${siteCofig.BASE_URL}${siteCofig.GET_ADDRESS}${userId}`
      );

      const responseData = response.data;
      if (
        responseData.msg === "Sucsess" &&
        Array.isArray(responseData.data) &&
        responseData.data.length > 0
      ) {
        setUserDataList(responseData.data);
      }
    } catch (error) {}
  };

  //POST API
  const handlePostRequest = async () => {
    let reqBody = {
      user_id: localStorage.getItem("userId"),
      pincode: formData.pincode,
      city: formData.city,
      name: formData.name,
      address: formData.address,
      landmark: formData.landmark,
      phone: formData.mobilenumber,
    };
    try {
      const response = await axios.post(
        siteCofig.BASE_URL + siteCofig.SAVE_ADDRESS,
        reqBody
      );
      const userFormData = response.data.data;
      // console.log(userFormData, "ffffffffff");
    } catch (error) {}
  };

  const handleDelete = async (addressId) => {
    try {
      await axios.delete(
        `${siteCofig.BASE_URL}${siteCofig.DELETE_ADDRESS}${addressId}`
      );
      await handleGetRequest();
      setSuccessMessage(true);
      setTimeout(() => {
        setSuccessMessage(false);
      }, 2000);
    } catch (error) {
      console.error("Error deleting address:", error.message);
    }
  };

  const handleUpdateRequest = async (addressId) => {
    const updatedData = {
      id: addressId,
      pincode: formData.pincode,
      city: formData.city,
      name: formData.name,
      address: formData.address,
      landmark: formData.landmark,
      phone: formData.mobilenumber,
    };

    try {
      const response = await axios.post(
        // `${siteCofig.BASE_URL}${siteCofig.UPDATE_ADDRESS}/${addressId}`,
        `${siteCofig.BASE_URL}${siteCofig.UPDATE_ADDRESS}`,
        updatedData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Address updated successfully:", response.data);
      // Optionally, you can trigger a refresh of address data after successful update
      await handleGetRequest();
    } catch (error) {
      console.error("Error updating address:", error.message);
    }
  };

  return (
    <>
      <Header />

      <div className="pt-20 ">
        {SuccessMessage1 && (
          <div className="bg-green-500 text-white p-2 absolute top-1/3 z-50 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded">
            <p className="w-52 sm:w-80 text-center font-lato">
              Address saved successfully.
            </p>
          </div>
        )}
        {SuccessMessage && (
          <div className="bg-green-500 text-white p-2 absolute top-1/3 z-50 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded">
            <p className="w-52 sm:w-80 text-center font-lato">
              Address deleted successfully!
            </p>
          </div>
        )}
        <div className=" flex justify-center items-center">
          <p className="text-[#10CAF2] text-[22px] font-bold mb-6">
            Manage Addresses
          </p>
        </div>
        <div className=" flex justify-center sm:justify-start sm:ml-10 mb-4  items-center">
          <div
            className="flex justify-center items-center bg-[#10CAF2] w-[85%] sm:w-72 h-12  cursor-pointer border-[1px] border-[#00000033] rounded-[5px]   "
            style={{ boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.12)" }}
            onClick={openModal}
          >
            <button className="text-[#FFF] px-3 py-2 rounded-lg font-poppins font-semibold ">
              {" "}
              + Add new address{" "}
            </button>
          </div>
        </div>
        <div className="w-full  grid lg:grid-cols-3 xl:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-3 place-items-center">
          {userDataList.map((userAddress) => (
            <div
              key={userAddress.id}
              className="border-2 border-gray-300 bg-[#e5f8fc] rounded-lg p-3.5 w-[85%] sm:w-[90%] "
            >
              <h4 className="font-poppins mb-2 text-[20px] text-[rgb(9,36,176)] font-bold">
                Home
              </h4>
              <p className="font-poppins text-[#000] font-bold">
                {userAddress.name}
              </p>
              <p className="font-poppins text-[#4A4141] text-[14px]">
                {userAddress.address}, {userAddress.landmark},{" "}
                {userAddress.city}, {userAddress.pincode}
                <br />
                Phone :{userAddress.phone}
              </p>
              <div className="flex justify-end items-end mt-2">
                <div className="flex justify-center gap-4 items-center">
                  <button
                    onClick={() => handleDelete(userAddress.id)}
                    className="text-[18px] font-poppins font-normal text-[#00B4D8]"
                  >
                    Delete
                  </button>
                  <button
                    onClick={() => openModalEdit(userAddress.id)}
                    className="text-[18px] font-poppins font-normal text-[#00B4D8]"
                  >
                    Edit
                  </button>
                  {/* box model */}
                  {showModalEdit && (
                    <div className="fixed top-0 left-0 z-50 w-full h-full flex items-center justify-center">
                      <div className="absolute top-0 left-0 w-full h-full bg-gray-900 opacity-50"></div>
                      <div className=" w-full sm:w-[60%] md:w-[40%] gap-3   z-10 bg-white p-7 rounded-lg shadow-lg flex flex-col justify-center items-center  ">
                        <div className=" w-full flex justify-end items-end">
                          <button
                            className="text-gray-500 hover:text-gray-700 self-end "
                            onClick={closeModalEdit}
                          >
                            <img src={IcomTimes} alt="CrossIcon" className="" />
                          </button>
                        </div>
                        <div className="  p-1 pb-6  bg-[#FFF] rounded-[5px] shadow-sm border border-solid border-opacity-5 font-poppins  flex flex-col justify-center items-start ">
                          <div className="flex flex-col pt-2">
                            <label
                              className="font-poppins font-bold pl-7 text-[#10CAF2]"
                              htmlFor="name"
                            >
                              PIN CODE
                            </label>
                            <input
                              className="text-[15px] font-medium pl-7 focus:outline-none"
                              type="tel"
                              name="pincode"
                              value={formData.pincode}
                              onChange={handleInputChange1}
                              maxLength={6}
                              onKeyDown={(e) => {
                                // Allow only numeric keys, backspace, and arrow keys
                                if (
                                  !/^\d$/.test(e.key) &&
                                  e.key !== "Backspace" &&
                                  e.key !== "ArrowLeft" &&
                                  e.key !== "ArrowRight"
                                ) {
                                  e.preventDefault();
                                }
                              }}
                            />
                            <div>
                              {" "}
                              <p className="text-red-500 text-sm ml-7">
                                {errors.pincode}
                              </p>
                            </div>
                          </div>
                          <div
                            className="underline-offset-8 w-[295px] xl:w-[339px] ml-[30px] mb-2 border-[1px] 
                        color-[#0000002E] center-alignment"
                          ></div>
                          <div className="flex flex-col">
                            <label
                              className="font-poppins font-bold pl-7 text-[#10CAF2]"
                              htmlFor="name"
                            >
                              CITY/STATE
                            </label>
                            <input
                              type="text"
                              name="city"
                              value={formData.city}
                              onChange={handleInputChange1}
                              className="text-[15px] font-medium pl-7 focus:outline-none"
                            />
                            <div>
                              {" "}
                              <p className="text-red-500 text-sm ml-7">
                                {errors.city}
                              </p>
                            </div>
                          </div>
                          <div
                            className="underline-offset-8 w-[295px] xl:w-[339px] ml-[30px] mb-2 border-[1px] 
                        color-[#0000002E] center-alignment"
                          ></div>
                          <div className="flex flex-col">
                            <label
                              className="font-poppins font-bold pl-7 text-[#10CAF2]"
                              htmlFor="name"
                            >
                              NAME
                            </label>
                            <input
                              type="text"
                              name="name"
                              value={formData.name}
                              onChange={handleInputChange1}
                              className="text-[15px] font-medium pl-7 focus:outline-none"
                            />
                            <div>
                              {" "}
                              <p className="text-red-500 text-sm ml-7">
                                {errors.name}
                              </p>
                            </div>
                          </div>
                          <div
                            className="underline-offset-8 w-[295px] xl:w-[339px] ml-[30px] mb-2 border-[1px] 
                        color-[#0000002E] center-alignment"
                          ></div>
                          <div className="flex flex-col">
                            <label
                              className="font-poppins font-bold pl-7 text-[#10CAF2]"
                              htmlFor="name"
                            >
                              ADDRESS
                            </label>
                            <input
                              type="text"
                              name="address"
                              value={formData.address}
                              onChange={handleInputChange1}
                              className="text-[15px] font-medium pl-7 focus:outline-none"
                            />
                            <div>
                              {" "}
                              <p className="text-red-500 text-sm ml-7">
                                {errors.address}
                              </p>
                            </div>
                          </div>
                          <div
                            className="underline-offset-8 w-[295px] xl:w-[339px] ml-[30px] mb-2 border-[1px] 
                        color-[#0000002E] center-alignment"
                          ></div>
                          <div className=" flex flex-col">
                            <label
                              className="font-poppins font-bold pl-7 text-[#10CAF2]"
                              htmlFor="name"
                            >
                              LANDMARK
                            </label>
                            <input
                              type="text"
                              name="landmark"
                              value={formData.landmark}
                              onChange={handleInputChange1}
                              className="text-[15px] font-medium pl-7 focus:outline-none"
                            />
                            <div>
                              {" "}
                              <p className="text-red-500 text-sm ml-7">
                                {errors.landmark}
                              </p>
                            </div>
                          </div>
                          <div
                            className="underline-offset-8 w-[295px] xl:w-[339px] ml-[30px] mb-2 border-[1px] 
                        color-[#0000002E] center-alignment"
                          ></div>
                          <div className=" flex flex-col">
                            <label
                              className="font-poppins font-bold pl-7 text-[#10CAF2]"
                              htmlFor="name"
                            >
                              MOBILE NUMBER
                            </label>
                            <input
                              type="tel"
                              name="mobilenumber"
                              value={formData.mobilenumber}
                              onChange={handleInputChange1}
                              className="text-[15px] font-medium pl-7 focus:outline-none"
                              maxLength={10}
                              onKeyDown={(e) => {
                                // Allow only numeric keys, backspace, and arrow keys
                                if (
                                  !/^\d$/.test(e.key) &&
                                  e.key !== "Backspace" &&
                                  e.key !== "ArrowLeft" &&
                                  e.key !== "ArrowRight"
                                ) {
                                  e.preventDefault();
                                }
                              }}
                            />
                            <div>
                              {" "}
                              <p className="text-red-500 text-sm ml-7">
                                {errors.mobilenumber}
                              </p>
                            </div>
                          </div>
                          <div
                            className="underline-offset-8 w-[295px] xl:w-[339px] ml-[30px] border-[1px] 
                        color-[#0000002E] center-alignment"
                          ></div>

                          <div className=" mt-2 mb-2 p-1 w-full flex justify-center items-center">
                            <button
                              className=" flex justify-center items-center bg-[#10CAF2] text-[18px] font-semibold text-[#ffffff]    focus:outline-none rounded-[5px]  w-[60%] h-11 self-center "
                              style={{
                                boxShadow:
                                  "0px 4px 4px 0px rgba(0, 0, 0, 0.12)",
                              }}
                              type="submit"
                              onClick={() =>
                                handleUpdateRequest(userAddress.id)
                              }
                            >
                              Update Address
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* box model end */}
                </div>
              </div>
            </div>
          ))}

          {/* box model */}
          {showModal && (
            <div className="fixed top-0 left-0 z-50 w-full h-full flex items-center justify-center">
              <div className="absolute top-0 left-0 w-full h-full bg-gray-900 opacity-50"></div>
              <div className=" w-full sm:w-[60%] md:w-[40%] gap-3   z-10 bg-white p-7 rounded-lg shadow-lg flex flex-col justify-center items-center  ">
                <div className=" w-full flex justify-end items-end">
                  <button
                    className="text-gray-500 hover:text-gray-700 self-end "
                    onClick={closeModal}
                  >
                    <img src={IcomTimes} alt="CrossIcon" className="" />
                  </button>
                </div>
                <div className="  p-1 pb-6  bg-[#FFF] rounded-[5px] shadow-sm border border-solid border-opacity-5 font-poppins  flex flex-col justify-center items-start ">
                  <div className="flex flex-col pt-2">
                    <label
                      className="font-poppins font-bold pl-7 text-[#10CAF2]"
                      htmlFor="name"
                    >
                      PIN CODE
                    </label>
                    <input
                      className="text-[15px] font-medium pl-7 focus:outline-none"
                      type="tel"
                      name="pincode"
                      value={formData.pincode}
                      onChange={handleInputChange1}
                      maxLength={6}
                      onKeyDown={(e) => {
                        // Allow only numeric keys, backspace, and arrow keys
                        if (
                          !/^\d$/.test(e.key) &&
                          e.key !== "Backspace" &&
                          e.key !== "ArrowLeft" &&
                          e.key !== "ArrowRight"
                        ) {
                          e.preventDefault();
                        }
                      }}
                    />
                    <div>
                      {" "}
                      <p className="text-red-500 text-sm ml-7">
                        {errors.pincode}
                      </p>
                    </div>
                  </div>
                  <div
                    className="underline-offset-8 w-[295px] xl:w-[339px] ml-[30px] mb-2 border-[1px] 
                        color-[#0000002E] center-alignment"
                  ></div>
                  <div className="flex flex-col">
                    <label
                      className="font-poppins font-bold pl-7 text-[#10CAF2]"
                      htmlFor="name"
                    >
                      CITY/STATE
                    </label>
                    <input
                      type="text"
                      name="city"
                      value={formData.city}
                      onChange={handleInputChange1}
                      className="text-[15px] font-medium pl-7 focus:outline-none"
                    />
                    <div>
                      {" "}
                      <p className="text-red-500 text-sm ml-7">{errors.city}</p>
                    </div>
                  </div>
                  <div
                    className="underline-offset-8 w-[295px] xl:w-[339px] ml-[30px] mb-2 border-[1px] 
                        color-[#0000002E] center-alignment"
                  ></div>
                  <div className="flex flex-col">
                    <label
                      className="font-poppins font-bold pl-7 text-[#10CAF2]"
                      htmlFor="name"
                    >
                      NAME
                    </label>
                    <input
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange1}
                      className="text-[15px] font-medium pl-7 focus:outline-none"
                    />
                    <div>
                      {" "}
                      <p className="text-red-500 text-sm ml-7">{errors.name}</p>
                    </div>
                  </div>
                  <div
                    className="underline-offset-8 w-[295px] xl:w-[339px] ml-[30px] mb-2 border-[1px] 
                        color-[#0000002E] center-alignment"
                  ></div>
                  <div className="flex flex-col">
                    <label
                      className="font-poppins font-bold pl-7 text-[#10CAF2]"
                      htmlFor="name"
                    >
                      ADDRESS
                    </label>
                    <input
                      type="text"
                      name="address"
                      value={formData.address}
                      onChange={handleInputChange1}
                      className="text-[15px] font-medium pl-7 focus:outline-none"
                    />
                    <div>
                      {" "}
                      <p className="text-red-500 text-sm ml-7">
                        {errors.address}
                      </p>
                    </div>
                  </div>
                  <div
                    className="underline-offset-8 w-[295px] xl:w-[339px] ml-[30px] mb-2 border-[1px] 
                        color-[#0000002E] center-alignment"
                  ></div>
                  <div className=" flex flex-col">
                    <label
                      className="font-poppins font-bold pl-7 text-[#10CAF2]"
                      htmlFor="name"
                    >
                      LANDMARK
                    </label>
                    <input
                      type="text"
                      name="landmark"
                      value={formData.landmark}
                      onChange={handleInputChange1}
                      className="text-[15px] font-medium pl-7 focus:outline-none"
                    />
                    <div>
                      {" "}
                      <p className="text-red-500 text-sm ml-7">
                        {errors.landmark}
                      </p>
                    </div>
                  </div>
                  <div
                    className="underline-offset-8 w-[295px] xl:w-[339px] ml-[30px] mb-2 border-[1px] 
                        color-[#0000002E] center-alignment"
                  ></div>
                  <div className=" flex flex-col">
                    <label
                      className="font-poppins font-bold pl-7 text-[#10CAF2]"
                      htmlFor="name"
                    >
                      MOBILE NUMBER
                    </label>
                    <input
                      type="tel"
                      name="mobilenumber"
                      value={formData.mobilenumber}
                      onChange={handleInputChange1}
                      className="text-[15px] font-medium pl-7 focus:outline-none"
                      maxLength={10}
                      onKeyDown={(e) => {
                        // Allow only numeric keys, backspace, and arrow keys
                        if (
                          !/^\d$/.test(e.key) &&
                          e.key !== "Backspace" &&
                          e.key !== "ArrowLeft" &&
                          e.key !== "ArrowRight"
                        ) {
                          e.preventDefault();
                        }
                      }}
                    />
                    <div>
                      {" "}
                      <p className="text-red-500 text-sm ml-7">
                        {errors.mobilenumber}
                      </p>
                    </div>
                  </div>
                  <div
                    className="underline-offset-8 w-[295px] xl:w-[339px] ml-[30px] border-[1px] 
                        color-[#0000002E] center-alignment"
                  ></div>

                  <div className=" mt-2 mb-2 p-1 w-full flex justify-center items-center">
                    <button
                      className=" flex justify-center items-center bg-[#10CAF2] text-[18px] font-semibold text-[#ffffff]    focus:outline-none rounded-[5px]  w-[60%] h-11 self-center "
                      style={{
                        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.12)",
                      }}
                      type="submit"
                      onClick={handleButtonClick}
                    >
                      Save Address{" "}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* box model end */}
        </div>
      </div>
    </>
  );
};

export default ManageAddress;
