import "./App.css";
import React from "react";
import { RouterProvider } from "react-router-dom";
import { appRouter } from "./components/routes/Routes";
import Store from "./utils/Store";
import { Provider } from "react-redux";

function App() {
  return (
    <>
      <Provider store={Store}>
        <RouterProvider router={appRouter} />
      </Provider>
    </>
  );
}

export default App;
