import React, { useEffect, useState } from "react";
import Header from "../Header";
import { useLocation } from "react-router-dom";
import axios from "axios";
import siteCofig from "../../../urlFile";

const ViewProfile = () => {
  const [feedbackList, setFeedbackList] = useState([]);
  const location = useLocation();
  const [userid, setUserid] = useState("");
  const [doctorDetails, setDoctorDetails] = useState([]);
  const [department, setDepartment] = useState("");

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const data = searchParams.get("data");
    if (data) {
      const [userId, departmentData] = data.split(",");
      setUserid(userId);
      if (departmentData) {
        const [key, department] = departmentData.split("=");
        if (key === "data2") {
          setDepartment(department);
        }
      }
    }
  }, [location.search]);

  const getDoctorImageUrl = (imageName) =>
    `https://app.healthcrad.com/upload/${imageName}`;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${siteCofig.BASE_URL}${siteCofig.DOCTOR_VIEW_PROFILE}${userid}`
        );
        if (response.data.msg === "success" && response.data.error === "200") {
          setDoctorDetails(response.data.data || []);
          console.log(response.data.data);
        } else {
          console.error("Error fetching orders:", response.data.msg);
        }
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };

    if (userid) {
      fetchData();
    }
  }, [userid]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          `${siteCofig.BASE_URL}${siteCofig.SHOW_FEEDBACK}`,
          {
            docterid: userid,
          }
        );
        if (response.data.msg === "Sucsess" && response.data.error === 200) {
          setFeedbackList(response.data.country || []);
          console.log("Response data:", response.data.country);
        } else {
          console.error("Error fetching feedback:", response.data.msg);
        }
      } catch (error) {
        console.error("Error fetching feedback:", error);
      }
    };

    if (userid) {
      fetchData();
    }
  }, [userid]);

  console.log("feedback list is", feedbackList);

  const getUserImageUrl = (imageName) =>
    `https://app.healthcrad.com/api/uploads/${imageName}`;

  return (
    <>
      <Header />
      <div className="pt-24">
        <div className="flex flex-col border-[1px] border-[rgba(0, 0, 0, 0.20)] p-10 pt-12 mb-12 shadow-md bg-[#FFF] rounded-[10px] md:h-[90%] sm:h-full lg:w-[80%] lg:ml-32 gap-6">
          {/* doctor section and horizontal line start */}
          {doctorDetails.map((doctor) => (
            <div className="flex justify-between">
              <div className="flex gap-1 sm:gap-0">
                <div>
                  <img
                    // src={Nurse}
                    src={getDoctorImageUrl(doctor.img_url)}
                    alt=""
                    className="w-[80px] h-[68px] md:w-[100px] md:h-[100px] rounded-[50px]"
                  />
                </div>
                <div className="mt-[-5px] ml-2">
                  <p className="xl:text-lg sm:text-sm text-[11px] md:text-lg font-poppins text-[#4A4141]">
                    <span className="text-[#000] text-[17px] font-bold ">
                      {doctor.name}
                    </span>{" "}
                    <span className="text-[#07A50D] ">({doctor.profile})</span>
                    <br />
                    <span className="text-[#00B4D8] sm:text-lg text-sm">
                      {department}
                    </span>
                    <br />
                    <span className="text-[#000] font-bold xl:text-lg sm:text-sm text-[11px] md:text-lg font-poppins">
                      Experience:
                    </span>{" "}
                    {doctor.Exp} years
                    <br />{" "}
                    <span className="text-[#000] font-bold xl:text-lg sm:text-sm text-[11px] md:text-lg font-poppins">
                      Address:
                    </span>{" "}
                    {doctor.city}
                  </p>
                  <p className="sm:hidden text-[#07A50D]  text-md font-poppins font-bold  ">
                    <span className="text-[#07A50D]  font-bold">&#8377;</span>
                    {doctor.fees}
                  </p>
                </div>
              </div>
              <div className="mt-[-8px] mr-[-20px] sm:mr-[0px] hidden sm:block ">
                <p className="text-[#07A50D] xl:text-lg sm:text-sm text-[11px] md:text-lg font-poppins font-bold">
                  <span className="text-[#1A30A1] font-bold xl:text-lg sm:text-sm text-[11px] md:text-lg font-poppins">
                    Fees:
                  </span>{" "}
                  Rs. {doctor.fees}{" "}
                </p>
              </div>
            </div>
          ))}

          <div className="underline-offset-8 w-[100%] border-[1px] color-[#0000002E] center-alignment"></div>
          <div className="flex items-center justify-center">
            <div className="cursor-default mb-2 group  rounded-[10px] flex justify-center items-center border-[1px] bg-[#00B4D8] w-32 h-10">
              <p className="xl:text-lg sm:text-md md:text-lg font-poppins text-[#FFF] px-2 py-2 rounded-md">
                About
              </p>
            </div>
          </div>

          <div className=" ">
            {/* {doctorDetails.map((doctor) => (
            <div className="border-[1px] border-[#00000026] bg-[#FFF] shadow-md rounded-[10px] p-6">
              <p className="font-poppins sm:text-lg text-sm">
                {doctor.about}
              </p>
            </div>
          ))} */}
            {doctorDetails.map((doctor) => (
              <div className="border-[1px] border-[#00000026] bg-[#FFF] shadow-md rounded-[10px] p-6">
                <p className="font-poppins text-center sm:text-lg text-sm">
                  {doctor.about ? doctor.about : "About not available."}
                </p>
              </div>
            ))}
          </div>

          <div className="flex items-center justify-center">
            <div className="cursor-default mb-2 group rounded-[10px] flex justify-center items-center border-[1px] bg-[#00B4D8] w-48 h-10">
              <p className="xl:text-lg sm:text-md md:text-lg font-poppins text-[#FFF] px-2 py-2 rounded-md">
                Patient Review
              </p>
            </div>
          </div>
          <div className="flex flex-col  gap-4">
            {feedbackList.length > 0 ? (
              feedbackList.map((feedback) => (
                <div
                  key={feedback.id}
                  className="flex gap-4 sm:space-y-4 space-y-2"
                >
                  <img
                    // src={feedback.userimage}
                    src={getUserImageUrl(feedback.userimage)}
                    alt=""
                    srcset=""
                    className="sm:h-[100px] rounded-[100px] shadow-lg sm:w-[100px] h-[70px] w-[70px]"
                  />
                  <p className="text-[#4A4141] font-poppins xl:text-xl sm:text-md text-[13px] md:text-lg">
                    {feedback.uname}
                    <br />
                    <span className="text-[#666161] xl:text-lg md:text-md sm:text-[15px] text-[11px]">
                      {feedback.problem}
                    </span>
                  </p>
                </div>
              ))
            ) : (
              <p className=" font-lato text-center font-semibold">
                Reviews not available
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewProfile;
