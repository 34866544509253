import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import siteConfig from "../urlFile";
import axios from "axios";
import PageLoader from "../Loader/PageLoader";
import Header from "../pages/HomePage/Header";
import bg from "../images/Rectangle_102.png";

const AllItems = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  // const searchTerm = searchParams.get("search");
  const [loading, setLoading] = useState(false);
  const [medicineData, setMedicineData] = useState([]);
  const [doctorData, setDoctorData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [existItem, setExistItem] = useState({});
  const [addedToCartItems, setAddedToCartItems] = useState([]);
  const [showAddedToCartMessage, setShowAddedToCartMessage] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const [medicineResponse, doctorResponse] = await Promise.all([
          axios.get(`${siteConfig.BASE_URL}${siteConfig.GET_ALL_MEDICINE}`),
          axios.get(`${siteConfig.BASE_URL}${siteConfig.GET_DOCTOR}`),
        ]);
        setMedicineData(medicineResponse.data.data);
        setDoctorData(doctorResponse.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const filteredMedicine = medicineData.filter((medicine) =>
    medicine.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredDoctors = doctorData.filter(
    (doctor) =>
      doctor.dname.toLowerCase().includes(searchTerm.toLowerCase()) ||
      doctor.city.toLowerCase().includes(searchTerm.toLowerCase()) ||
      doctor.department.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleRoute = (path, medicineDetails, doctorDetails) => {
    setLoading(true);
    setTimeout(() => {
      navigate(path, { state: { medicineDetails, doctorDetails } });
    }, 1000);
  };

  const getMedicineImageUrl = (imageName) =>
    `https://app.healthcrad.com/api/uploads/medicine/${imageName}`;
  const getDoctorImageUrl = (imageName) =>
    `https://app.healthcrad.com/upload/${imageName}`;

  useEffect(() => {
    setSearchTerm(searchValue || "");
  }, [searchValue]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const searchValue = searchParams.get("search") || "";
    setSearchValue(searchValue);

    if (searchValue === "") {
      setSearchTerm("");
    }
  }, [location.search]);

  console.log("searched value is", searchValue);

  const handleAddToCart = async (medicineId) => {
    setLoading(true);

    try {
      const response = await axios.post(
        `${siteConfig.BASE_URL}${siteConfig.ADD_TO_CART}`,
        {
          id: localStorage.getItem("userId"),
          productid: medicineId,
          quantity: 1,
        }
      );

      if (response.data.msg === "Product is already exist") {
        setExistItem((prevState) => ({ ...prevState, [medicineId]: true }));
        setTimeout(() => {
          setExistItem((prevState) => ({ ...prevState, [medicineId]: false }));
        }, 2000);
      } else {
        setAddedToCartItems((prevItems) => [...prevItems, medicineId]);
        setShowAddedToCartMessage((prevState) => ({
          ...prevState,
          [medicineId]: true,
        }));
        setTimeout(() => {
          setShowAddedToCartMessage((prevState) => ({
            ...prevState,
            [medicineId]: false,
          }));
        }, 2000);
      }
    } catch (error) {
      console.error("Error adding item to cart:", error);
      alert(
        "An error occurred while adding the item to the cart. Please try again later."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Header />
      {loading && <PageLoader />}
      <div className="pt-20 pb-10 font-poppins">
        {filteredMedicine.length > 0 && (
          <div className="flex justify-center items-center ">
            <p className="font-lato text-[#00B4D8] text-2xl sm:text-3xl font-semibold">
              Medicines
            </p>
          </div>
        )}
        <div
          className="w-full overflow-x-hidden grid lg:grid-cols-4 xl:grid-cols-5 md:grid-cols-3 grid-cols-2 bg-repeat-x shadow-md p-3 pt-4 mt-6 xl:gap-6 gap-3 "
          style={{ backgroundImage: `url(${bg})` }}
        >
          {filteredMedicine.map((medicineDetails) => (
            <div
              key={medicineDetails.id}
              className="flex justify-center relative"
            >
              {showAddedToCartMessage[medicineDetails.id] && (
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50">
                  <div className="bg-green-500 text-white p-2 rounded">
                    <p className=" w-44 sm:w-60 text-center">
                      Item added to the cart!
                    </p>
                  </div>
                </div>
              )}
              {existItem[medicineDetails.id] && (
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50">
                  <div className="bg-green-500 text-white p-2 rounded">
                    <p className=" w-44 sm:w-60 text-center">
                      Item is already added in the cart!
                    </p>
                  </div>
                </div>
              )}
              <div
                className="sm:w-[230px] sm:h-[302px] mb-4 p-2 w-[205px] h-[245px] rounded-[10px] border-[1px] bg-[#FFF]"
                style={{ boxShadow: "0 4px 8px rgba(0,0,0,.12)" }}
              >
                <div
                  className="cursor-pointer flex justify-end"
                  onClick={() =>
                    handleRoute(
                      "/medicine/medicineDescription",
                      medicineDetails
                    )
                  }
                >
                  <div className="bg-[#008000] z-10 p-1 rounded-bl-[8px] rounded-br-[8px] mr-[4px] sm:mr-[8px] -mt-[9px] flex justify-center items-center w-10 sm:w-14">
                    <p className="text-[#FFF]  font-bold sm:text-lg text-sm font-poppins text-center">
                      {medicineDetails.discount}%<br />
                      OFF
                    </p>
                  </div>
                </div>
                <div className="cursor-pointer flex flex-col gap-1 p-2 -mt-6 justify-center items-center">
                  <img
                    className="sm:w-[105px] sm:h-[105px] w-[80px] h-[80px]"
                    src={getMedicineImageUrl(medicineDetails.image[0])}
                    alt=""
                    onClick={() =>
                      handleRoute(
                        "/medicine/medicineDescription",
                        medicineDetails
                      )
                    }
                  />
                  <p className="text-xs sm:text-sm font-poppins text-[#000] font-medium overflow-hidden line-clamp-1">
                    {medicineDetails.name}
                  </p>
                </div>
                <div className="underline-offset-8 w-full border-[1px] color-[#0000002E] center-alignment"></div>
                <div className="pt-2 text-[#0000008A] text-sm sm:text-[14px] flex font-poppins">
                  <span className="font-bold mr-[2px]">Rs.</span>
                  <del>{medicineDetails.price}</del>
                </div>
                {medicineDetails.prescription === "1" && (
                  <div className="absolute ml-[93px]  sm:ml-[188px] -mt-3 ">
                    <p className="text-[15px] font-bold rounded-[5px] w-7 text-center border-[1px] border-[#10CAF2] text-[#10CAF2]">
                      Rx
                    </p>
                  </div>
                )}
                <div className="pt-0.5 text-[#000] font-bold text-sm sm:text-[17px] font-poppins">
                  <p>
                    <span className="mr-[2px]">Rs.</span>
                    {medicineDetails.discounted_amount}
                  </p>
                </div>
                <div className="flex justify-center items-center mt-1.5 sm:mt-0 sm:pt-2.5 pb-1">
                  <div
                    className={`w-full relative ${
                      addedToCartItems.includes(medicineDetails.id)
                        ? "bg-[#008000]"
                        : "bg-[#10CAF2]"
                    }
                                       h-8 sm:h-10 rounded-[6px] cursor-pointer flex justify-center items-center`}
                  >
                    {addedToCartItems.includes(medicineDetails.id) ? (
                      <button
                        className="text-sm sm:text-lg text-white font-bold"
                        onClick={() => navigate("/cart")} // Go to cart
                      >
                        Go to cart
                      </button>
                    ) : (
                      <button
                        className="text-sm sm:text-lg text-white font-bold"
                        onClick={() => handleAddToCart(medicineDetails.id)}
                      >
                        Add to cart
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Doctors Section */}
        {filteredDoctors.length > 0 && (
          <div className="flex justify-center items-center mt-10">
            <p className=" font-lato text-[#00B4D8] text-2xl sm:text-3xl font-semibold ">
              Doctors
            </p>
          </div>
        )}
        <div className="flex flex-col justify-center items-center mt-6">
          {filteredDoctors.map((doctorDetails) => (
            <div
              key={doctorDetails.id}
              className="flex flex-col border-[1px] border-[rgba(0, 0, 0, 0.20)] p-7 mb-8 shadow-md bg-[#FFF] rounded-[10px] md:h-[90%] sm:h-full w-[93%] lg:w-[80%] gap-6"
            >
              <div className="flex justify-between">
                <div className="flex gap-1 sm:gap-0">
                  <div>
                    <img
                      src={getDoctorImageUrl(doctorDetails.img_url)}
                      alt=""
                      className="w-[80px] h-[68px] md:w-[100px] md:h-[100px] rounded-full"
                    />
                  </div>
                  <div className="mt-[-5px] ml-2">
                    <p className="xl:text-lg sm:text-sm text-[11px] md:text-lg font-poppins text-[#4A4141] ">
                      <span className="text-[#000] sm:text-lg text-sm font-bold">
                        {doctorDetails.dname}
                      </span>
                      <br />
                      <span className="text-[#00B4D8] sm:text-lg text-sm">
                        {doctorDetails.department
                          .split(",")
                          .slice(1)
                          .join(",")
                          .trim()}
                      </span>
                      <br />
                      <span className="text-[#07A50D]">
                        ({doctorDetails.profile})
                      </span>
                      <br />
                      <span className="text-[#000] font-bold xl:text-lg sm:text-sm text-[11px] md:text-lg font-poppins">
                        Experience :
                      </span>{" "}
                      {doctorDetails.exp} years
                      <br />
                      <span className="text-[#000] font-bold xl:text-lg sm:text-sm text-[11px] md:text-lg font-poppins">
                        Address :
                      </span>{" "}
                      {doctorDetails.city}
                    </p>
                    <p className="sm:hidden text-[#07A50D] text-md font-poppins font-bold">
                      <span className="text-[#07A50D] font-bold">&#8377;</span>{" "}
                      {doctorDetails.fees}
                    </p>
                  </div>
                </div>
                <div className="hidden sm:block mt-[-8px] mr-[-20px] sm:mr-[0px]">
                  <p className="text-[#07A50D] xl:text-lg sm:text-sm text-[11px] md:text-lg font-poppins font-bold">
                    <span className="text-[#1A30A1] font-bold xl:text-lg sm:text-sm text-[11px] md:text-lg font-poppins">
                      Fees:
                    </span>{" "}
                    Rs.{doctorDetails.fees}
                  </p>
                </div>
              </div>
              <div className="underline-offset-8 w-[100%] border-[1px] color-[#0000002E] center-alignment"></div>
              <div className="flex justify-between sm:gap-0 gap-2">
                <div
                  className="mb-2 group rounded-[10px] flex justify-center items-center border-[1px] border-[#00B4D8] w-32 h-10"
                  onClick={() =>
                    handleRoute(
                      `/doctors/viewProfile?data=${
                        doctorDetails.id
                      },data2=${doctorDetails.department
                        .split(",")
                        .slice(1)
                        .join(",")
                        .trim()}`
                    )
                  }
                >
                  <button className="xl:text-lg sm:text-md text-sm font-poppins text-[#00B4D8] px-2 py-2 rounded-md">
                    View Profile
                  </button>
                </div>
                <div
                  className="mb-2 group rounded-[10px] flex justify-center items-center border-[1px] bg-[#00B4D8] cursor-pointer w-32 h-10"
                  onClick={() =>
                    navigate(
                      `/doctors/appointmentSchedule?data=${doctorDetails.department}`,
                      { state: doctorDetails }
                    )
                  }
                >
                  <button className="xl:text-lg sm:text-md text-sm font-poppins text-[#FFF] px-2 py-2 rounded-md">
                    Book Now
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default AllItems;
