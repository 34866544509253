import React from "react";
import Header from "../../Header";
import { useLocation } from "react-router-dom";

const MyAppointmentGetDetails = () => {
  const location = useLocation();
  const appointmentData = location.state;
  console.log("appointment data list is", appointmentData);
  const getDoctorImageUrl = (imageName) =>
    `https://app.healthcrad.com/upload/${imageName}`;
  return (
    <>
      <Header />
      <div className="pt-20">
        <div className=" flex  justify-center items-center">
          <div className="mb-3 flex justify-center items-center rounded-[10px] border-[1px] border-[#10CAF2] bg-[#FFF] xl:w-[230px] xl:h-[50px] md:w-[230px] md:h-[50px] w-[200px] h-10  cursor-default">
            {" "}
            <p className="xl:text-lg sm:text-md md:text-lg text-[#00B4D8] font-poppins">
              Appointment Details
            </p>
          </div>
        </div>
        <div className="flex justify-center items-center ">
          <div className="flex flex-col border-[1px] border-[rgba(0, 0, 0, 0.20)] p-6 pt-12 mb-12 shadow-md bg-[#FFF] rounded-[10px] md:h-[90%] sm:h-full lg:w-[80%] gap-6">
            <div className="flex justify-between">
              <div className="flex justify-center items-center gap-1 sm:gap-0">
                <div className="  ">
                  <img
                    src={getDoctorImageUrl(appointmentData.dimg_url)}
                    alt=""
                    className="w-[80px] h-[68px] md:w-[100px] md:h-[100px] rounded-[50px]"
                  />
                </div>
                <div className=" ml-2">
                  <p className="xl:text-lg sm:text-sm text-[11px] md:text-lg font-poppins text-[#4A4141]">
                    <span className="text-[#000] text-[14px] sm:text-[19px] font-bold ">
                      {appointmentData.dname}
                    </span>
                    <br />
                    <span className="text-[#07A50D] ">
                      {appointmentData.dprofile}
                    </span>
                    <br />
                    {appointmentData.week_day}
                    {appointmentData.day} | {appointmentData.time_slot} |{" "}
                    {appointmentData.bookdate}
                  </p>
                </div>
              </div>
              <div className=" self-center hidden sm:block">
                <p className="text-[#07A50D] xl:text-lg sm:text-sm text-[11px] md:text-lg font-poppins font-bold">
                  <span className="text-[#1A30A1] font-bold xl:text-lg sm:text-sm text-[11px] md:text-lg font-poppins">
                    Fees:
                  </span>{" "}
                  Rs. {appointmentData.amount} <br />{" "}
                  <span className=" self-start text-[#1A30A1] font-bold xl:text-sm sm:text-sm text-[11px] md:text-sm font-poppins">
                    Payment:
                  </span>
                  <span className="xl:text-sm sm:text-sm text-[11px] md:text-sm">
                    {" "}
                    {/* COD */}
                    {appointmentData.payment}
                  </span>
                </p>
              </div>
            </div>
            <div className="underline-offset-8 w-[100%] border-[1px] color-[#0000002E] center-alignment"></div>

            <div
              className="flex flex-row w-full justify-between border-[1px] rounded-[10px] p-3 border-[#00000026] sm:hidden md:hidden lg:hidden xl:hidden"
              style={{ boxShadow: "0px 4px 43.1px 0px rgba(0, 0, 0, 0.10)" }}
            >
              <p className="text-[#07A50D] xl:text-lg sm:text-sm text-[13px] md:text-lg font-poppins font-bold">
                <span className="text-[#1A30A1] font-bold xl:text-lg sm:text-sm text-[13px] md:text-lg font-poppins">
                  Fees:
                </span>{" "}
                Rs. {appointmentData.amount}{" "}
              </p>
              <p className="text-[#07A50D] xl:text-lg sm:text-sm text-[13px] md:text-lg font-poppins font-bold">
                <span className="text-[#1A30A1] font-bold xl:text-sm sm:text-sm text-[13px] md:text-sm font-poppins sm:ml-2 ml-0">
                  Payment:
                </span>
                <span className="xl:text-sm sm:text-sm text-[11px] md:text-sm">
                  {" "}
                  {/* COD */}
                  {appointmentData.payment}
                </span>
              </p>
            </div>
            {/* doctor section and horizontal line End */}
            {/* complete details button start */}
            <div className="flex items-center justify-center">
              <div className="mb-2 group bg-[#00B4D8] rounded-[10px] flex justify-center items-center hover:bg-[#00B4D8] border-[1px] border-[#979797] xl:w-[230px] xl:h-[50px] md:w-[230px] md:h-[50px] w-[200px] h-35 cursor-default">
                <p className="xl:text-lg sm:text-md md:text-lg font-poppins text-white px-2 py-2 rounded-md group-hover:text-white">
                  Booking Details
                </p>
              </div>
            </div>
            {/* complete details button end */}
            {/* patient details start */}
            <div className="flex justify-between gap-6 items-start p-2">
              <div className="flex flex-col justify-start gap-2 text-[#000] xl:text-lg sm:text-md text-[13.5px] md:text-lg font-poppins font-semibold">
                <p className="min-w-[110px]">Patient Name :</p>
                <p className="min-w-[110px]">Patient Age :</p>
                <p className="min-w-[110px]">Patient Phone :</p>
                <p className="min-w-[110px]">Slot I’d :</p>
                <p className="min-w-[110px]">Date :</p>
                <p className="min-w-[110px]">Day :</p>
                <p className="min-w-[110px]">Slot From :</p>
                <p className="min-w-[110px]">Slot to :</p>
                <p className="min-w-[110px]">Doctor Name :</p>
                <p className="min-w-[115px]">Patient Address :</p>
              </div>
              <div className="flex flex-col justify-start gap-2 text-[#4A4141] xl:text-lg sm:text-md text-[13.5px] md:text-lg font-poppins">
                <p className="min-w-[100px] overflow-hidden line-clamp-1 ">
                  {appointmentData.name}
                </p>
                <p className="min-w-[100px]">{appointmentData.age}</p>
                <p className="min-w-[100px]">{appointmentData.phone}</p>
                <p className="min-w-[100px]">{appointmentData.slot_id}</p>
                <p className="min-w-[100px]">{appointmentData.bookdate}</p>
                <p className="min-w-[100px]">
                  {appointmentData.week_day}
                  {appointmentData.day}
                </p>
                <p className="min-w-[100px]">{appointmentData.start_time}</p>
                <p className="min-w-[100px]">{appointmentData.end_time}</p>
                <p className="min-w-[100px] overflow-hidden line-clamp-1 ">
                  {appointmentData.dname}
                </p>
                <p className="min-w-[100px] overflow-hidden line-clamp-1">
                  {appointmentData.address}
                </p>
              </div>
            </div>
          </div>
          {/* patient details End */}
        </div>
      </div>
    </>
  );
};

export default MyAppointmentGetDetails;
