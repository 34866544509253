import React, { useEffect, useState } from "react";
import siteConfig from "../../urlFile";
import axios from "axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PageLoader from "../../Loader/PageLoader";
import { useNavigate } from "react-router";

const OurDoctors = () => {
  const [loading, setLoading] = useState(false);
  const [doctorDataList, setDoctorDataList] = useState([]);
  const navigate = useNavigate();

  // GET Doctor API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${siteConfig.BASE_URL}${siteConfig.GET_DOCTOR}`
        );
        setDoctorDataList(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const settings = {
    infinite: false,
    dots: false,
    speed: 1800,
    slidesToShow: 5.5,
    slidesToScroll: 4,
    autoplay: false,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 4.4,
          slidesToScroll: 3,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3.5,
          slidesToScroll: 3,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2.9,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2.8,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2.8,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 760,
        settings: {
          slidesToShow: 2.2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 639,
        settings: {
          slidesToShow: 2.4,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 570,
        settings: {
          slidesToShow: 2.1,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 490,
        settings: {
          slidesToShow: 1.8,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 1.6,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1.4,
          slidesToScroll: 2,
        },
      },
    ],
  };

  // const handleRoute = (path) => {
  //   setLoading(true);
  //   setTimeout(() => {
  //     navigate(path);
  //   }, 2000);
  // }
  // const handleRoute = (path, doctorDetails) => {
  //   navigate(path, { state: doctorDetails });
  // };

  const handleRoute = (path, doctorDetails) => {
    setLoading(true);
    setTimeout(() => {
      navigate(path, { state: doctorDetails });
    }, 1000);
  };
  const getDoctorImageUrl = (imageName) =>
    `https://app.healthcrad.com/upload/${imageName}`;
  return (
    <div className="mt-4 sm:mt-0 overflow-x-hidden">
      {loading && <PageLoader />}
      {doctorDataList.length > 0 && (
        <div>
          <div className="cursor-default flex flex-row justify-between sm:p-3 items-center">
            <div className="flex-col hidden sm:block p-2">
              <p className="text-[#2F2D2D] text-[18px] sm:text-[24px] font-semibold font-lato">
                Book appointment to top doctors online for any health concern
              </p>
              <span className="text-[#666161] text-[9px] sm:text-[13px] md:text-[16px] lg:text-[20px] xl:text-[15px] font-medium">
                Find experienced HealthCRAD doctors across all specialties
              </span>
            </div>
            <div className=" sm:hidden p-2 flex flex-col ">
              <p className=" text-[#2F2D2D] text-[18px] sm:text-[16px] lg:text-[24px] xl:text-[30px] font-semibold font-lato">
                Book appointment to top doctors
              </p>
              <p className="  text-[#666161]  text-[14px]  font-lato ">
                Find experienced HealthCRAD doctors
              </p>
            </div>
          </div>
          <Slider {...settings}>
            {doctorDataList.map((doctorDetails) => (
              <div key={doctorDetails.id} className="p-2">
                <div className="sm:w-[240px] sm:h-[402px] w-[200px] h-[335px] bg-[#FFF] mb-4 border-[0.1px] border-[#00000015] rounded-[20px] shadow-md">
                  <div className="bg-[#96D3BF] flex justify-center items-center rounded-tr-[20px] rounded-tl-[20px]">
                    <img
                      // src={Dr}
                      src={getDoctorImageUrl(doctorDetails.img_url)}
                      alt=""
                      className="w-[199px] sm:w-[270px] rounded-tl-[20px] rounded-tr-[20px] sm:h-[195px] h-[170px]"
                    />
                  </div>
                  <div className="select-none justify-center flex mt-2.5 sm:mt-2.5">
                    <p className="text-center flex flex-col md:-space-y-[22px] -space-y-[13px] xl:text-xl sm:text-md text-[11px] md:text-lg font-poppins text-[#4A4141]">
                      <span className="text-[#000] font-bold leading-none text-[15px] sm:text-xl">
                        {doctorDetails.dname}
                      </span>
                      <br />
                      <span className="text-[#00B4D8] font-medium sm:text-sm text-xs">
                        {
                          decodeURIComponent(doctorDetails.department)
                            .split(",")
                            .filter((item) => item.trim() !== "0")
                            .map((item) => item.trim())[0]
                        }
                      </span>
                      <br />
                      <span className="sm:text-sm text-xs ml-1">
                        ({doctorDetails.profile})
                      </span>
                      <br />
                      <span className="sm:text-sm text-xs">
                        Experience: {doctorDetails.exp} <span>years</span>
                      </span>
                      <br />
                      <span className="sm:text-sm text-xs">
                        Fees: Rs. {doctorDetails.fees}
                      </span>
                    </p>
                  </div>
                  <div className="flex justify-center gap-2.5 items-center pt-3">
                    <div
                      className="rounded-[20px] cursor-pointer bg-[#10CAF2] w-[90%] h-9 flex justify-center items-center"
                      onClick={() => {
                        // handleRoute("/doctors/appointmentSchedule", doctorDetails);
                        handleRoute(
                          `/doctors/appointmentSchedule?data=${doctorDetails.department}`,
                          doctorDetails
                        );
                      }}
                    >
                      <button className="text-[#FFF] sm:text-[17px] font-medium text-[15px] font-poppins">
                        Book Appointment
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      )}
    </div>
  );
};

export default OurDoctors;
