import { useState, useEffect, useRef } from "react";
import Bin from "../../../images/frontend UI materials/bin.png";
import UploadArrow from "../../../images/frontend UI materials/Cameraicon.png";
import CheckmarkIcon from "../../../images/frontend UI materials/VerifyIcon.svg";
import Header from "../Header";
import { useNavigate } from "react-router-dom";
import ProceedIcon from "../../../images/frontend UI materials/ArrowForward.png";
import CartPayment from "../../../images/frontend UI materials/Cart.png";
import { Modal, Typography } from "@mui/material";
import siteconfig from "../../../urlFile";
import axios from "axios";
import PageLoader from "../../../Loader/PageLoader";
import EmptyCart from "./EmptyCart";

const Cart = () => {
  const navigate = useNavigate();
  const [openD, setOpenD] = useState(false);
  const maxQuantityLimit = 20;
  const [loading, setLoading] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const [itemIdToDelete, setItemIdToDelete] = useState(null);
  const [selectedQuantity, setSelectedQuantity] = useState("");
  const [validationError, setValidationError] = useState("");
  const [optionsArray, setOptionsArray] = useState(["1", "2", "3"]);
  const [file, setFile] = useState(null);
  const [prescription, setPrescription] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [selectedQuantities, setSelectedQuantities] = useState({});
  const [inputValues, setInputValues] = useState({});
  const [openModals, setOpenModals] = useState({});
  const [fileMsg, setFileMsg] = useState(false);

  const submitPrescriptionOrder = async (image) => {
    try {
      const userId = localStorage.getItem("userId");
      let medicineId = null;

      if (!userId) {
        throw new Error("User ID not found in local storage");
      }

      for (const item of cartItems) {
        if (item.prescription === "1") {
          medicineId = item.id;
          break;
        }
      }

      if (!medicineId) {
        throw new Error("No cart item with prescription value 1 found");
      }

      const body = JSON.stringify({
        userid: userId,
        medicineid: medicineId,
        image: image,
      });

      const headers = {
        "Content-Type": "application/json",
      };

      const response = await fetch(
        `${siteconfig.BASE_URL}${siteconfig.PRESCRIPTION_ORDER_CART}`,
        {
          method: "POST",
          headers: headers,
          body: body,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit prescription order");
      }
      const data = await response.json();
      console.log("Prescription order submitted successfully:", data);
    } catch (error) {
      console.error("Error submitting prescription order:", error.message);
    }
  };

  // const handleFileUpload = (event) => {
  //   const uploadedFile = event.target.files[0];
  //   if (uploadedFile) {
  //     saveImageToLocal(uploadedFile);
  //     setFile(uploadedFile);
  //   }
  // };

  const handleFileUpload = (event) => {
    const uploadedFile = event.target.files[0];
    if (uploadedFile) {
      const fileType = uploadedFile.type;
      // Check if the file type is JPG or PNG
      if (
        fileType === "image/jpeg" ||
        fileType === "image/png" ||
        fileType === "image/jpg"
      ) {
        saveImageToLocal(uploadedFile);
        setFile(uploadedFile);
      } else {
        setFileMsg(true);
        setTimeout(() => {
          setFileMsg(false);
        }, 4000);
        event.target.value = null;
      }
    }
  };

  const saveImageToLocal = (file) => {
    const reader = new FileReader();
    reader.onload = function (event) {
      const image = event.target.result;
      const croppedImageUrl = cropBase64Prefix(image);
      localStorage.setItem("prescriptionImageUrl", croppedImageUrl);
      submitPrescriptionOrder(croppedImageUrl);
    };
    reader.readAsDataURL(file);
  };

  const cropBase64Prefix = (image) => {
    return image.replace(/^data:image\/[a-z]+;base64,/, "");
  };

  const resetFile = () => {
    setFile(null);
  };

  useEffect(() => {
    fetchCartItems();
  }, []);

  const fetchCartItems = async () => {
    try {
      const response = await axios.get(
        `${siteconfig.BASE_URL}${siteconfig.VIEW_CART_ITEMS}`,
        {
          params: { userid: localStorage.getItem("userId") },
        }
      );
      if (response.data && response.data.length > 0) {
        setCartItems(response.data);
        // Initialize selectedQuantities and openModals state based on cart items
        const selectedQuantitiesInit = {};
        const inputValuesInit = {};
        const openModalsInit = {};
        response.data.forEach((item) => {
          selectedQuantitiesInit[item.id] = item.quantity;
          inputValuesInit[item.id] = "";
          openModalsInit[item.id] = false;
        });
        setSelectedQuantities(selectedQuantitiesInit);
        setInputValues(inputValuesInit);
        setOpenModals(openModalsInit);
      } else {
        setCartItems([]);
      }
    } catch (error) {
      console.error("Error fetching cart items:", error);
    }
  };

  useEffect(() => {
    if (cartItems.length > 0) {
      setSelectedQuantity(cartItems[0].quantity);
    }
  }, [cartItems]);

  const handleOptionSelect = (event, item) => {
    const selectedValue = event.target.value;
    if (selectedValue === "more..") {
      handleOpen(item.id);
    } else {
      updateMedicineQuantity(item.id, selectedValue);
    }
  };

  const handleApply = (itemId) => {
    const inputValue = inputValues[itemId];
    if (parseInt(inputValue, 10) <= maxQuantityLimit) {
      updateMedicineQuantity(itemId, inputValue);
      handleClose(itemId);
    } else {
      setValidationError("We’re sorry! 20 units allowed in each order");
    }
  };

  const updateMedicineQuantity = async (itemId, quantity) => {
    try {
      const response = await axios.put(
        `${siteconfig.BASE_URL}${siteconfig.CART_UPDATE}`,
        {
          medicineid: itemId,
          userid: localStorage.getItem("userId"),
          quantity: quantity,
        }
      );
      console.log("Medicine quantity updated:", response.data);
      const updatedCartItems = cartItems.map((cartItem) => {
        if (cartItem.id === itemId) {
          return { ...cartItem, quantity: quantity };
        }
        return cartItem;
      });
      setCartItems(updatedCartItems);
      setSelectedQuantities({ ...selectedQuantities, [itemId]: quantity });
    } catch (error) {
      console.error("Error updating medicine quantity:", error);
    }
  };

  const handleOpen = (itemId) => {
    setOpenModals({ ...openModals, [itemId]: true });
  };

  const handleClose = (itemId) => {
    setOpenModals({ ...openModals, [itemId]: false });
    setOpenModals(false);
  };

  const handleInputChange = (event, itemId) => {
    setInputValues({ ...inputValues, [itemId]: event.target.value });
  };
  const deleteCartItem = async (itemId) => {
    try {
      const userid = localStorage.getItem("userId");
      const requestData = {
        userid,
        id: itemId,
      };

      const response = await axios.delete(
        `${siteconfig.BASE_URL}${siteconfig.DELETE_CART_ITEM}`,
        {
          data: requestData,
          headers: { "Content-Type": "application/json" },
        }
      );
      console.log("Delete response:", response.data);
      fetchCartItems();
    } catch (error) {
      console.error("Error deleting cart item:", error);
    }
  };

  const handleOpenDelete = (itemId) => {
    setItemIdToDelete(itemId);
    setOpenD(true);
  };

  const handleDeleteConfirm = async () => {
    if (itemIdToDelete) {
      handleCloseDelete();
      try {
        await deleteCartItem(itemIdToDelete);
        const updatedCartItems = cartItems.filter(
          (item) => item.id !== itemIdToDelete
        );
        setCartItems(updatedCartItems);
      } catch (error) {
        console.error("Error deleting cart item:", error);
      }
    }
  };

  const handleCloseDelete = () => {
    setItemIdToDelete(null);
    setOpenD(false);
  };

  const hasPrescriptionItem = () => {
    return cartItems.some((item) => item.prescription === "1");
  };

  const isImageUploaded = () => {
    return file !== null;
  };

  const shouldAllowProceed = () => {
    if (hasPrescriptionItem()) {
      return isImageUploaded();
    } else {
      return true;
    }
  };
  const handleRoute = (path, cartItems) => {
    if (hasPrescriptionItem()) {
      setLoading(false);

      if (isImageUploaded()) {
        console.log("Proceeding to order review");
        setLoading(true);
        setTimeout(() => {
          setLoading(false);
          navigate(`/order-review`, { state: cartItems });
        }, 1000);
      } else {
        setPrescription(true);
        setTimeout(() => {
          setPrescription(false);
          setLoading(false);
        }, 3000);
        console.log("Upload prescription file");
      }
    } else {
      setLoading(true);
      console.log("Proceeding to order review");
      setTimeout(() => {
        setLoading(false);
        navigate(`/order-review`, { state: cartItems });
      }, 1000);
    }
  };

  useEffect(() => {
    let total = 0;
    for (const item of cartItems) {
      total += parseFloat(item.discountedPrice * item.quantity);
    }
    setTotalAmount(total);
  }, [cartItems]);
  const getMedicineImageUrl = (imageName) =>
    `https://app.healthcrad.com/api/uploads/medicine/${imageName}`;

  //  OutsideClick PopUp Close Code
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const popupRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setIsPopupOpen(false);
      }
    };

    if (isPopupOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isPopupOpen]);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };
  return (
    <>
      <Header />
      {loading && <PageLoader />}
      <div className="py-20 container mx-auto ">
        {cartItems.length > 0 ? (
          <div className=" flex flex-col items-center justify-center w-full">
            <div className="grid grid-cols-1  xl:grid-cols-2 w-[95%] sm:w-[70%] lg:w-[55%] xl:w-[90%] ">
              <div className="flex justify-between xl:justify-start xl:gap-3 ">
                <p className="text-[#2F2D2D] self-start text-[18px] sm:text-[24px] font-semibold font-lato pb-3 2xl:pl-[38px] ">
                  Order Summary
                </p>
                <div className="relative xl:mt-[8px] mt-[2px]">
                  <button
                    className=" font-semibold ml-auto bg-[#00B4D8] text-[#fff] h-5 w-5 text-[15px] rounded-full cursor-pointer"
                    onClick={togglePopup}>
                    i
                  </button>
                  {isPopupOpen && (
                    <div
                      ref={popupRef}
                      className="fixed inset-0 flex items-center justify-center z-50">
                      <div
                        className="absolute inset-0 bg-gray-800 opacity-50"
                        onClick={togglePopup}></div>
                      <div className="relative bg-white shadow-md p-4 rounded-md w-[333px]">
                        <h2 className="text-lg font-semibold mb-2">
                          How to cancel the order?
                        </h2>
                        <p>
                          To cancel the order, please go to the Help section,
                          write your query and phone number, and request a
                          callback. We will assist you in canceling the order.{" "}
                          <br />
                          <span className="cursor-pointer">
                            Or call on{" "}
                            <a
                              className="text-[#0924B0D9] underline"
                              href="tel:+917779888289">
                              7779888289
                            </a>
                          </span>
                        </p>
                        <p className="text-red-500">
                          Note: Order must be cancelled within 24 hours of
                          placement, otherwise the order will not be cancelled
                          after pickup.
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {/* prescription */}
              <div className=" flex flex-col ">
                <div
                  className="mb-6 flex rounded-[10px] border-[2px] border-solid border-[#00B4D8] justify-between items-center xl:w-[90%] 2xl:w-[76%]  sm:h-[55px] h-[55px] p-1 xl:ml-[55px] 2xl:ml-[118px] "
                  onClick={resetFile}>
                  {!file ? (
                    <>
                      <label htmlFor="file-upload">
                        <p className="font-normal text-center py-[5px] sm:py-[2px] pl-[14px] xs:pl-[32px] text-[11px]  sm:text-[14px] text-[#666161]">
                          Some of the medicines require a prescription. Please
                          attach to proceed.
                        </p>
                      </label>
                      <input
                        type="file"
                        accept=".pdf,.jpg,.png"
                        className="hidden"
                        onChange={handleFileUpload}
                        id="file-upload"
                      />
                      <label htmlFor="file-upload">
                        <img
                          src={UploadArrow}
                          alt=""
                          className="h-[25px] w-[29px] my-[10px] mr-[36px] sm:h-[40px] sm:w-[40px] sm:my-[6px] sm:mr-[25px] xs:mr-[40px] ml-[20px] cursor-pointer"
                        />
                      </label>
                    </>
                  ) : (
                    <>
                      <p className="font-normal  mt-[1px] py-[2px]  pl-[32px] text-[13px] sm:text-[16px] text-[#666161]">
                        Prescription file uploaded
                      </p>
                      <img
                        src={CheckmarkIcon}
                        alt=""
                        className="h-[25px] w-[29px] my-[10px] mr-[25px] sm:mr-[30px] sm:h-[40px] sm:w-[30px] sm:my-[5px]  xs:mr-[40px] cursor-pointer"
                        onClick={resetFile}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
            {prescription && (
              <div className="bg-[red]  text-white p-2 absolute top-1/3 z-50 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded">
                <p className="w-52 sm:w-80 text-center font-lato ">
                  Prescription is required
                  <br />
                  {/* You have an added medicine that prescription required */}
                  You need a doctor's note to get this medicine.
                </p>
              </div>
            )}
            {fileMsg && (
              <div className="bg-[red]  text-white p-2 absolute top-1/3 z-50 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded">
                <p className="w-52 sm:w-80 text-center font-lato ">
                  Please upload valid image !
                </p>
              </div>
            )}
            {/* Card */}
            <div className="flex justify-center items-center w-full ">
              <div className="grid grid-cols-1 place-items-center  xl:grid-cols-2  w-full">
                {cartItems.map((item) => (
                  <div key={item.id} className="flex">
                    <div className="relative sm:w-[520px] sm:h-[auto] w-[305px] xs:w-[360px] bg-[#ffffff] rounded-lg sm:px-4 py-2 mb-3.5  border-[1px] border-solid border-[#00000033]">
                      <h2 className="text-[#000000] font-medium text-[16px] sm:text-[20px] pl-2.5 ">
                        Products
                      </h2>
                      {item.prescription === "1" && (
                        <p className=" right-1  top-1 z-10 font-bold absolute text-[15px] rounded-[5px] w-7 text-center border-[1px] border-[#10CAF2] text-[#10CAF2]">
                          Rx
                        </p>
                      )}
                      <div className="flex p-1  ">
                        <div className=" flex items-center  justify-center w-[54px] sm:w-[100px] mb-[32px] ">
                          <img
                            src={getMedicineImageUrl(item.image[0])}
                            alt=""
                          />
                        </div>
                        <div className="font-poppins sm:w-2/3 sm:pl-3 ">
                          <p className="text-[#4A4141]  text-[13px] sm:text-[16px] leading-6 sm:mb-[18px] mt-[5px]">
                            {item.name}
                          </p>
                          <p className=" font-normal text-[#0000008A] sm:text-[16px] text-[12px]">
                            Rs.{" "}
                            <span className="line-through">{item.amount}</span>{" "}
                            <span className="text-[#07A50D] sm:text-[16px] text-[13px] font-poppins font-medium">
                              off
                            </span>{" "}
                            <span className="text-[#07A50D] sm:text-[20px] text-[14px] font-poppins font-bold">
                              {item.discount}%
                            </span>
                          </p>
                          <p className="font-semibold sm:text-[20px] text-[14px] text-[#000000]">
                            Rs. {item.discountedPrice}
                          </p>

                          <div className="flex gap-[10px] xs:gap-[30px]">
                            <p className="sm:text-[12px] text-[9px] font-light mt-[11px] text-[#979797]">
                              Delivery between 2-3 working days.
                            </p>
                            <div className=" flex -ml-[9px]  gap-[5px] xs:gap-[20px] ">
                              <img
                                onClick={() => handleOpenDelete(item.id)}
                                src={Bin}
                                alt=""
                                className=" sm:hidden w-[21px] h-[20px] mt-[4px]"
                              />
                              <div className="sm:hidden">
                                <button className=" cursor-pointer text-[12px] font-normal rounded-[5px] font-poppins  text-[#00B4D8]  border-1 border-solid border-[#00B4D8] pt-[2px]  h-[25px] w-[62px]  relative">
                                  <select
                                    value={selectedQuantities[item.id]}
                                    onChange={(event) =>
                                      handleOptionSelect(event, item)
                                    }
                                    id="options"
                                    className="  focus:outline-none font-poppins font-bold cursor-pointer">
                                    <option className="bg-[#FFF] text-gray-500 cursor-not-allowed">
                                      {item.quantity}
                                    </option>
                                    {optionsArray.map((option, index) => (
                                      <option key={index} value={option}>
                                        {option}
                                      </option>
                                    ))}
                                    <option
                                      onClick={() => handleOpen(item.id)}
                                      value="more..">
                                      more..
                                    </option>
                                  </select>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="hidden sm:block">
                          <img
                            onClick={() => handleOpenDelete(item.id)}
                            src={Bin}
                            alt=""
                            className="  w-[21px] h-[20px] mt-[30px] ml-24 mb-11 cursor-pointer"
                          />

                          <div className="ml-10">
                            <button className="  cursor-pointer text-[12px] font-normal rounded-[5px] font-poppins  text-[#00B4D8]  border-1 border-solid border-[#00B4D8] pt-[2px]  h-[25px] w-[85px]  relative">
                              <select
                                value={selectedQuantities[item.id]}
                                onChange={(event) =>
                                  handleOptionSelect(event, item)
                                }
                                id="options"
                                className="ml-4 focus:outline-none font-poppins font-bold cursor-pointer">
                                <option className="bg-[#FFF] text-gray-500 cursor-not-allowed">
                                  {item.quantity}
                                </option>
                                {optionsArray.map((option, index) => (
                                  <option key={index} value={option}>
                                    {option}
                                  </option>
                                ))}
                                <option
                                  onClick={() => handleOpen(item.id)}
                                  value="more..">
                                  more..
                                </option>
                              </select>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* paragraph and payment details */}

            {/* modal box start */}
            {cartItems.map((item) => (
              <Modal
                key={item.id}
                open={openModals[item.id]}
                onClose={() => handleClose(item.id)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="fixed z-10 inset-0 overflow-y-auto">
                <div className="flex items-center justify-center min-h-screen">
                  <div className="bg-white w-[290px] lg:w-[390px]  rounded-lg text-center   ">
                    <div className=" ">
                      <div className="pt-3 select-none mb-2 flex justify-center items-center">
                        <p className=" text-center  text-[#4A4141] font-poppins font-semibold sm:text-xl text-md  ">
                          How many quantities you buy
                          <br />
                          medicines?
                        </p>
                      </div>
                      <Typography className="mb-4">
                        <div className=" flex flex-col gap-2 justify-center items-center">
                          <p className="select-none font-poppins text-[#07A50D] self-start ml-6 lg:ml-8  font-bold text-[15px] lg:text-[18px]  ">
                            Enter Quantity
                          </p>
                          <div
                            className=" border-[1px]  w-[240px] lg:w-[340px] flex justify-start items-start p-3
                         rounded-[10px] ">
                            <input
                              type="text"
                              id="inputbox"
                              value={inputValues[item.id]}
                              onChange={(event) =>
                                handleInputChange(event, item.id)
                              }
                              onKeyDown={(e) => {
                                // Allow only numeric keys, backspace, and arrow keys
                                if (
                                  !/^\d$/.test(e.key) &&
                                  e.key !== "Backspace" &&
                                  e.key !== "ArrowLeft" &&
                                  e.key !== "ArrowRight"
                                ) {
                                  e.preventDefault();
                                }
                              }}
                              className="  font-semibold w-28  focus:outline-none placeholder:text-[#B7B7B7]    font-poppins  "
                              placeholder="Quantity"
                            />
                          </div>

                          {validationError && (
                            <p className="text-red-500 text-[12px] font-poppins self-start ml-4 lg:ml-8">
                              {validationError}
                            </p>
                          )}
                        </div>
                      </Typography>
                    </div>
                    <div className="flex mb-3  justify-around items-center  ">
                      {" "}
                      <div
                        className="select-none cursor-pointer flex justify-center items-center w-24 lg:w-32 h-12 bg-[#10CAF2] rounded-[10px] "
                        onClick={() => handleApply(item.id)}>
                        <button className=" text-[18px] font-poppins font-bold text-[#FFF]">
                          APPLY
                        </button>{" "}
                      </div>
                      <div
                        className="select-none cursor-pointer flex justify-center items-center w-24 lg:w-32 h-12  bg-[#FE0505] rounded-[10px]"
                        onClick={handleClose}>
                        <button className="    text-[18px] font-poppins font-bold text-[#FFF] ">
                          CANCEL
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            ))}
            {/* modal box end */}
            {/* modal box for Delete start */}
            <Modal
              open={openD}
              onClose={handleCloseDelete}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              className="fixed z-10 inset-0 overflow-y-auto">
              <div className="flex items-center justify-center min-h-screen">
                <div className="bg-white w-[290px] sm:w-[390px] p-2 gap-2  rounded-lg text-center   ">
                  <div className=" ">
                    <div className="pt-3 select-none mb-4 flex justify-center items-center">
                      <p className=" text-center  text-[#4A4141] font-poppins font-semibold sm:text-lg text-sm  ">
                        Do you want to delete the added
                        <br />
                        medicine?{" "}
                      </p>
                    </div>
                  </div>
                  <div className="flex mb-3  justify-around items-center  ">
                    <div
                      className="select-none flex justify-center items-center w-24 lg:w-32 h-12 bg-[#10CAF2] rounded-[10px] "
                      onClick={handleDeleteConfirm}>
                      <button className=" text-[18px] font-poppins font-bold text-[#FFF]">
                        YES
                      </button>{" "}
                    </div>
                    <div
                      className="select-none flex justify-center items-center w-24 lg:w-32 h-12  bg-[#FE0505] rounded-[10px]"
                      onClick={handleCloseDelete}>
                      <button className="    text-[18px] font-poppins font-bold text-[#FFF] ">
                        NO
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
            {/* modal box for Delete end */}
            {/* mobile view button design start */}
            <div className=" sm:hidden fixed z-40 flex  w-full bottom-0 border-t-[0.5px] border-[#d8d4d4]  ">
              <div className="flex justify-between items-center bg-[#F5F5F5] px-3 w-full py-2">
                <div className="flex ">
                  <div className="flex flex-col  ml-[15px]">
                    <p className="text-[12px] font-lato font-medium text-[#00B4D8] ">
                      Total Payable
                    </p>
                    <p className="text-[16px] font-lato font-bold text-[#000000]  ">
                      Rs. {totalAmount.toFixed(2)}
                    </p>
                  </div>
                </div>
                <div
                  className="select-none rounded-[4px]  h-[40px] w-[142px] bg-[#10CAF2]  flex items-center justify-center cursor-pointer mr-[15px]"
                  onClick={() => handleRoute(`/order-review`, cartItems)}>
                  <button className="text-[#ffffff] font-lato font-medium text-[16px]">
                    PROCEED
                  </button>
                </div>
              </div>
            </div>
            {/* mobile view button design end */}

            {/* Desktop view button design start */}
            <div className="hidden sm:flex fixed z-40 justify-between items-center bg-[#F5F5F5] w-full bottom-0  xl:px-[50px] 2xl:px-[174px] ">
              <div>
                <p className="text-[#191919]">
                  {" "}
                  <span className="text-[#07A50D]">Note:</span> If your order is
                  less than Rs. 1099 you have to pay <br />
                  <span className="pl-[42px]">Rs. 80 delivery charges</span>.
                </p>
              </div>

              <div className="flex gap-[130px] justify-between items-center leading-6 bg-[#FFFFFF] my-[12px] h-[64px] rounded-[5px] px-12 border-[0.5px] border-[#d8d4d4] ">
                <div className="flex">
                  <div className="flex flex-col  ">
                    <p className="text-[14px] font-lato font-medium text-[#10CAF2] ">
                      Total Payable
                    </p>
                    <p className="text-[20px] font-lato font-bold text-[#000] ml-[3px] ">
                      Rs. {totalAmount.toFixed(2)}
                    </p>
                  </div>
                </div>
                <div
                  className="select-none rounded-[4px] h-[40px] w-[130px] bg-[#10CAF2]  flex items-center justify-center cursor-pointer"
                  onClick={() => handleRoute(`/order-review`, cartItems)}>
                  <button className="text-white font-lato font-medium text-[16px]">
                    PROCEED
                  </button>
                </div>
              </div>
            </div>
            {/* Desktop view button design end */}
          </div>
        ) : (
          <EmptyCart />
        )}
      </div>
    </>
  );
};

export default Cart;
