import React from "react";
import EmptyPic from "../../../../images/Empty_Pages_Images/appointment 1.png";

const EmptyAppointment = () => {
  return (
    <>
      <div className="flex justify-center items-center p-2 mt-10 mb-36">
        <div className="flex flex-col justify-center items-center gap-5">
          <div className="flex justify-center items-center">
            <p className="text-[#66616180] text-center font-poppins font-semibold text-md sm:text-lg">
              No any upcoming appointment. Book your appointment now !
            </p>
          </div>
          <div className="flex justify-center items-center">
            <img
              src={EmptyPic}
              alt=""
              srcset=""
              className="w-[200px] h-[200px] sm:h-[350px] sm:w-[350px]"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default EmptyAppointment;
