import React, { useState, useEffect } from "react";
import Header from "../Header";
import img2 from "../../../images/frontend UI materials/Request a call/user.png";
import img4 from "../../../images/frontend UI materials/Request a call/phone.png";
import img5 from "../../../images/frontend UI materials/Request a call/edit.png";
import img6 from "../../../images/frontend UI materials/Vector.png";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import siteConfig from "../../../urlFile";
import AppointmentPlaced from "../../HomePage/OrderPlaced/AppointmentPlaced";
import PageLoader from "../../../Loader/PageLoader";

const AppointmentFillDetails = () => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const doctorNewobject = location.state;
  console.log("doctor details is", doctorNewobject);
  const [appointmentBook, setAppointmentBook] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    age: "",
    address: "",
    phoneNumber: "",
  });

  const [errors, setErrors] = useState({});

  useEffect(() => {
    // Get user_id from local storage and set it in state
    const userid = localStorage.getItem("userId");
    if (userid) {
      setFormData((prevState) => ({ ...prevState, userid }));
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log("handleSubmit called");
    setFormData({ ...formData, [name]: value });
    // Clear validation error if the field is being updated
    setErrors({ ...errors, [name]: "" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = {};

    if (!formData.name.trim()) {
      validationErrors.name = "Name is required";
    }

    if (!formData.age.trim() || isNaN(formData.age)) {
      validationErrors.age = "Please enter a valid age";
    }

    if (!formData.address.trim()) {
      validationErrors.address = "Address is required";
    }

    if (
      !formData.phoneNumber.trim() ||
      isNaN(formData.phoneNumber) ||
      formData.phoneNumber.length !== 10
    ) {
      validationErrors.phoneNumber = "Enter a valid phone number";
    }

    if (Object.keys(validationErrors).length === 0) {
      console.log("Form submitted successfully:", formData);
      let userId = localStorage.getItem("userId");
      // API call to book appointment
      try {
        const response = await fetch(
          `${siteConfig.BASE_URL}${siteConfig.DOCTOR_BOOK_APPOINTMENT}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json; charset=UTF-8",
            },
            body: JSON.stringify({
              paymode: "COD", // Replace '$_mode' with the appropriate value
              user_id: userId, // Use the user_id from the form data
              slot_id: doctorNewobject.selectedShiftId,
              doctor_id: doctorNewobject.id,
              amount: doctorNewobject.fees,
              name: formData.name,
              age: formData.age,
              address: formData.address,
              phone: formData.phoneNumber,
              bookdate: formattedDate,
              time: doctorNewobject.selectedSlotTime,
            }),
          }
        );

        const data = await response.json();
        console.log("API response:", data);
        // alert("Appointment Booked Successfully");
        // navigate("/");
        // Clear form data on successful submission
        setLoading(true);
        setTimeout(() => {
          setLoading(false);
          setAppointmentBook(true);
          // window.history.replaceState(null, '', '/');
        }, 2000);
        setFormData({ name: "", age: "", address: "", phoneNumber: "" });
      } catch (error) {
        console.error("Error:", error);
        // Handle error
      }
    } else {
      setErrors(validationErrors);
    }
  };
  useEffect(() => {
    if (appointmentBook) {
      window.history.replaceState(null, "", "/");
    }
  }, [appointmentBook]);

  const getDoctorImageUrl = (imageName) =>
    `https://app.healthcrad.com/upload/${imageName}`;
  const selectedDate = new Date(doctorNewobject.selectedDate);
  const day = selectedDate.getDate().toString().padStart(2, "0");
  const month = (selectedDate.getMonth() + 1).toString().padStart(2, "0");
  const year = selectedDate.getFullYear();
  const formattedDate = `${day}-${month}-${year}`;

  return (
    <>
      <Header />
      {loading && <PageLoader />}
      {!appointmentBook && (
        <div className="pt-24">
          <div className="flex justify-center items-center">
            <div className="cursor-default mb-3 rounded-[10px] border-[1px] border-[#10CAF2] bg-[#FFF] sm:w-[250px] md:h-[50px] w-[200px] h-12 flex items-center justify-center">
              <p className="xl:text-lg sm:text-md md:text-lg text-[#00B4D8] font-poppins">
                Schedule Appointment
              </p>
            </div>
          </div>
          <div className="flex justify-center items-center">
            <div className="flex flex-col border-[1px] border-[rgba(0, 0, 0, 0.20)] p-6 pt-12 mb-12 shadow-md bg-[#FFF] rounded-[10px] md:h-[90%] sm:h-full lg:w-[80%] gap-6">
              {/* doctor section and horizontal line start */}
              <div className="flex justify-between">
                <div className="flex gap-1 sm:gap-0">
                  <div>
                    <img
                      // src={Nurse}
                      src={getDoctorImageUrl(doctorNewobject.img_url)}
                      alt=""
                      className="w-[80px] h-[68px] md:w-[100px] md:h-[100px] rounded-[50px]"
                    />
                  </div>
                  <div className="mt-[-5px] ml-2">
                    <p className="xl:text-lg sm:text-sm text-[11px] md:text-lg font-poppins text-[#4A4141]">
                      <span className="text-[#000]  sm:text-lg text-[15px] font-bold">
                        {doctorNewobject.dname}
                      </span>
                      <span className=" ml-[4px] text-[#07A50D]">
                        ({doctorNewobject.profile})
                      </span>
                      <br />
                      <span className="text-[#00B4D8] font-semibold sm:text-xl text-[15px]">
                        {doctorNewobject.category.dept}
                      </span>
                      <br />
                      <span className="text-[#000] font-bold xl:text-lg sm:text-sm text-[12px] md:text-lg font-poppins">
                        Experience:
                      </span>{" "}
                      {doctorNewobject.exp} years
                      <br />{" "}
                      <span className="text-[#000] font-bold xl:text-lg sm:text-sm text-[12px] md:text-lg font-poppins">
                        Address:
                      </span>{" "}
                      {doctorNewobject.city}
                    </p>
                    <p className="sm:hidden text-[#07A50D]  text-md font-poppins font-bold  ">
                      <span className="text-[#07A50D]  font-bold">&#8377;</span>
                      {doctorNewobject.fees}
                    </p>
                  </div>
                </div>
                <div className="mr-[-10px] sm:mr-[0px] hidden sm:block">
                  <p className="text-[#07A50D] xl:text-lg sm:text-sm text-[11px] md:text-lg font-poppins font-bold">
                    <span className="text-[#1A30A1] font-bold xl:text-lg sm:text-sm text-[11px] md:text-lg font-poppins">
                      Fees:
                    </span>{" "}
                    Rs. {doctorNewobject.fees}
                  </p>
                </div>
              </div>
              <div className="underline-offset-8 w-[100%] border-[1px] color-[#0000002E] center-alignment"></div>

              <div className="flex justify-between ">
                <div>
                  <p className="text-center text-[#4A4141] font-bold xl:text-lg sm:text-md text-[14px] md:text-lg font-poppins">
                    Appointment On
                    <br />
                    <span className="text-[12px] sm:text-[13px] font-bold text-[#979797">
                      {" "}
                      {doctorNewobject.selectedSlotTime}
                      <br />
                      {formattedDate}
                    </span>
                  </p>
                </div>

                <div>
                  {" "}
                  <div className="bg-[#00B4D8] border-[1px] rounded-[10px] border-[#FFF] flex justify-center items-center sm:w-[12vw] sm:h-[6vh] w-[23vw] h-[6vh]">
                    {" "}
                    <p className="cursor-default text-white font-semibold sm:text-sm text-[14px] md:text-lg font-poppins">
                      {/* Monday */}
                      {doctorNewobject.selectedDay}
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-center">
                <div className="cursor-default mb-2 group rounded-[10px] flex justify-center items-center border-[1px] border-[#00B4D8] xl:h-[50px] sm:w-[300px] md:h-[50px] w-[200px] h-35">
                  <p className="xl:text-lg sm:text-md md:text-lg font-poppins text-[#00B4D8] px-2 py-2 rounded-md">
                    Appointment For
                  </p>
                </div>
              </div>
              <div className="bg-[#FFF] border-[1px] border-[#A6A6A6] rounded-[10px] shadow-md p-3">
                {/* first input box section start */}
                <div className="grid sm:grid-cols-2 grid-cols-1 justify-between p-3 gap-4">
                  {/* first */}
                  <div className="flex flex-col">
                    <div className="space-x-2 flex border-1 justify-center items-center rounded-[10px] bg-[#F5F2F2] border-[1px] border-[#CECBCB] shadow-md sm:p-1 sm:w-[90%]">
                      <span className="ml-[2px]">
                        <img
                          className="w-[20px] h-[20px] sm:w-[27px] sm:h-[27px] ml-1"
                          src={img2}
                          alt="Error"
                        />
                      </span>
                      <input
                        className="py-3 bg-[#F5F2F2] rounded-[10px] h-[50px] placeholder:text-sm sm:placeholder:text-lg lg:placeholder:text-xl w-full font-poppins focus:outline-none sm:text-lg text-sm font-normal text-[rgba(0, 0, 0, 0.20)] pl-2"
                        type="text"
                        placeholder="Name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                      />
                    </div>
                    <div>
                      <p className=" ">
                        {" "}
                        {errors.name && (
                          <span className="ml-2 text-[12px] sm:text-[18px] text-danger">
                            {errors.name}
                          </span>
                        )}
                      </p>
                    </div>
                  </div>
                  {/* second */}
                  <div className="flex flex-col">
                    <div className="space-x-2 flex border-1 justify-center items-center rounded-[10px] bg-[#F5F2F2] border-[1px] border-[#CECBCB] shadow-md sm:p-1  sm:w-[90%]">
                      <span className="ml-[2px]">
                        <img
                          className="w-[20px] h-[20px] sm:w-[27px] sm:h-[27px] ml-1"
                          src={img6}
                          alt="Error"
                        />
                      </span>
                      <input
                        className="py-3 bg-[#F5F2F2] rounded-[10px] placeholder:text-sm sm:placeholder:text-lg lg:placeholder:text-xl w-full h-[50px] font-poppins text-[rgba(0, 0, 0, 0.20)] font-normal focus:outline-none sm:text-lg text-sm pl-2"
                        placeholder="Age"
                        type="tel"
                        name="age"
                        value={formData.age}
                        onChange={handleChange}
                        maxLength={3}
                        onKeyDown={(e) => {
                          // Allow only numeric keys, backspace, and arrow keys
                          if (
                            !/^\d$/.test(e.key) &&
                            e.key !== "Backspace" &&
                            e.key !== "ArrowLeft" &&
                            e.key !== "ArrowRight"
                          ) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                    <div>
                      <p className="">
                        {" "}
                        {errors.age && (
                          <span className="text-[12px] sm:text-[18px] text-danger ml-2">
                            {errors.age}
                          </span>
                        )}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="grid sm:grid-cols-2 grid-cols-1 justify-between p-3 gap-4">
                  <div className="flex flex-col">
                    <div className="space-x-2 flex border-1 justify-center items-center rounded-[10px] bg-[#F5F2F2] border-[1px] border-[#CECBCB] shadow-md sm:p-1 sm:w-[90%]">
                      <span className="ml-[2px]">
                        <img
                          className="w-[20px] h-[20px] sm:w-[27px] sm:h-[27px] ml-1"
                          src={img5}
                          alt="Error"
                        />
                      </span>
                      <input
                        className="py-3 bg-[#F5F2F2] rounded-[10px] placeholder:text-sm sm:placeholder:text-lg lg:placeholder:text-xl w-full h-[50px] font-poppins text-[rgba(0, 0, 0, 0.20)] font-normal focus:outline-none sm:text-lg text-sm pl-2"
                        placeholder="Address"
                        type="text"
                        name="address"
                        value={formData.address}
                        onChange={handleChange}
                      />
                    </div>
                    <div>
                      <p className="  ">
                        {errors.address && (
                          <span className="text-[12px] ml-2 sm:text-[18px] text-danger">
                            {errors.address}
                          </span>
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div className="space-x-2 flex border-1 justify-center items-center rounded-[10px] bg-[#F5F2F2] border-[1px] border-[#CECBCB] shadow-md sm:p-1 sm:w-[90%]">
                      <span className="ml-[2px]">
                        <img
                          className="w-[20px] h-[20px] sm:w-[27px] sm:h-[27px] ml-1"
                          src={img4}
                          alt="Error"
                        />
                      </span>
                      <input
                        className="py-3 bg-[#F5F2F2] rounded-[10px] placeholder:text-sm sm:placeholder:text-lg lg:placeholder:text-xl w-full h-[50px] font-poppins text-[rgba(0, 0, 0, 0.20)] font-normal focus:outline-none sm:text-lg text-sm pl-2"
                        placeholder="Phone No."
                        type="tel"
                        name="phoneNumber"
                        value={formData.phoneNumber}
                        onChange={handleChange}
                        maxLength={10}
                        onKeyDown={(e) => {
                          // Allow only numeric keys, backspace, and arrow keys
                          if (
                            !/^\d$/.test(e.key) &&
                            e.key !== "Backspace" &&
                            e.key !== "ArrowLeft" &&
                            e.key !== "ArrowRight"
                          ) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                    <div>
                      <p className="  ">
                        {" "}
                        {errors.phoneNumber && (
                          <span className="text-[12px] sm:text-[18px] ml-2 text-danger">
                            {errors.phoneNumber}
                          </span>
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* button reschedule */}
              <div className="self-center hidden sm:block  mt-2 flex items-center justify-center">
                <div
                  className="mb-2 group bg-[#00B4D8] rounded-[10px] flex justify-center items-center hover:bg-[#00B4D8] border-[1px] border-[#979797]  xl:h-[50px] sm:w-[300px] md:h-[50px] cursor-pointer select-none"
                  onClick={handleSubmit}
                >
                  <button className="xl:text-lg sm:text-md md:text-lg font-poppins text-white px-2 py-2 rounded-md group-hover:text-white">
                    Confirm Booking
                  </button>
                </div>
              </div>
            </div>

            {/* mobile view button design start */}
            <div className=" sm:hidden fixed z-50 flex justify-around items-center bg-[#FAF1FF]   w-full bottom-0 ">
              <div className=" flex flex-col pt-2.5">
                <p className="text-[16px] font-lato font- text-[#000000] ">
                  Fees:
                </p>
                <p className=" text-[20px] font-lato font-semibold text-[#000000] ">
                  Rs.{doctorNewobject.fees}
                </p>
              </div>
              <div
                className="select-none rounded-[4px] border-[1px] border-[#10CAF2] shadow-md bg-[#00B4D8] w-[170px]  h-11  flex items-center justify-center cursor-pointer"
                onClick={handleSubmit}
              >
                <button className=" text-white font-poppins font-medium ">
                  Confirm Booking
                </button>
              </div>
            </div>
            {/* mobile view button design end */}
          </div>
        </div>
      )}
      {appointmentBook && <AppointmentPlaced />}
    </>
  );
};

export default AppointmentFillDetails;
