import React, { useEffect, useState, useRef } from "react";
import Logo from "../images/frontend UI materials/HealthCRAD Logo.png";
import CartIcon from "../images/NavbarProfileIcon/cart.png";
import Hamberger from "../images/NavbarProfileIcon/hamberger.png";
import { Link } from "react-router-dom";
import SearchBar from "../GlobalSearch/SearchBar.jsx";
import { useNavigate, useLocation } from "react-router";
import ForwardIcon from "../images/frontend UI materials/icon _chevron-right_.svg";
import UserIcon from "../images/frontend UI materials/icon _User_.svg";
import ArrowIcon from "./../images/NavbarProfileIcon/Arrow.png";
import MyOrder from "./../images/NavbarProfileIcon/MyOrder.png";
import ManageAddress from "./../images/NavbarProfileIcon/ManageAddress.png";
import MyAppointment from "./../images/NavbarProfileIcon/MyAppointment.png";
import Pathlab from "./../images/NavbarProfileIcon/Pathlab.png";
import AmbulanceBooking from "./../images/NavbarProfileIcon/AmbulanceBooking.png";
import Faqs from "./../images/NavbarProfileIcon/Faqs.png";
import HelpEarn from "./../images/NavbarProfileIcon/HelpEarn.png";
import HelpSupport from "./../images/NavbarProfileIcon/HelpSupport.png";
import DownloadApp from "./../images/NavbarProfileIcon/DownloadApp.png";
import LogOut from "./../images/NavbarProfileIcon/Logout.png";
import { Modal } from "@mui/material";
import PageLoader from "../Loader/PageLoader.jsx";
import siteconfig from "../urlFile";
import axios from "axios";

const Navigation = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [logoutSuccess, setLogoutSuccess] = useState(false);
  const [isScrollingDisabled, setIsScrollingDisabled] = useState(false);

  const [productCount, setProductCount] = useState(0);

  useEffect(() => {
    const fetchCartItems = async () => {
      try {
        // Retrieve user ID from localStorage
        const userId = localStorage.getItem("userId");
        if (!userId) {
          console.error("User ID not found in localStorage.");
          return;
        }
        // Fetch cart items using Axios
        const response = await axios.get(
          `${siteconfig.BASE_URL}${siteconfig.VIEW_CART_ITEMS}`,
          {
            params: { userid: userId },
          }
        );

        if (response.data && Array.isArray(response.data)) {
          setProductCount(response.data.length);
        } else {
          // console.error("Invalid cart items data received:", response.data);
        }
      } catch (error) {
        // console.error("Error fetching cart items:", error);
      }
    };

    fetchCartItems();
    const interval = setInterval(fetchCartItems, 1000);
    return () => clearInterval(interval);
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
    setIsScrollingDisabled(!isSidebarOpen); // Disable scrolling when sidebar is open
  };

  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = useLocation();

  const handleRoute = (path) => {
    if (pathname !== path) {
      setLoading(true);
      setTimeout(() => {
        navigate(path);
      }, 1000);
    }
  };

  const [userFname, setUserFname] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userDetails, setUserDetails] = useState({
    name: "",
    image: "",
  });

  const [isProfilePopupOpen, setIsProfilePopupOpen] = useState(false);
  const togglePop = () => {
    setIsProfilePopupOpen((prevState) => !prevState);
  };

  useEffect(() => {
    if (isSidebarOpen) {
      document.body.style.overflow = "hidden"; // Disable scrolling when sidebar is open
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling when sidebar is closed
    }
  }, [isSidebarOpen]);

  const handleAuthenticationSuccess = (name, image) => {
    setUserDetails({ name, image });
    setIsAuthenticated(true);
  };

  const mobNumber = localStorage.getItem("mobileNumber");

  const clearLocalStorage = () => {
    localStorage.clear();
    navigate("/");
    setIsAuthenticated(false);
  };

  useEffect(() => {
    if (mobNumber) {
      handleAuthenticationSuccess("", { UserIcon });
    } else {
      setIsAuthenticated(false);
    }
  }, [mobNumber]);

  const uName = localStorage.getItem("userName");
  const firstName = uName ? uName.split(" ")[0] : "";

  const popupRef = useRef(null);

  //  OutSideClick TO Close PopUp Code
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setIsProfilePopupOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const { userName } = location.state || {};
    const fName = userName ? userName.split(" ")[0] : "";
    setUserFname(fName);
  }, [location.state]);

  // for Logout model
  const handleOpenLogout = () => {
    setOpen(true);
  };

  const handleCloseLogout = () => {
    setOpen(false);
  };

  const handleConfirmLogout = () => {
    console.log("Logout...");
    // Call the clearLocalStorage function here
    clearLocalStorage();
    setOpen(false);
    setIsSidebarOpen(false);
    setLogoutSuccess(true);
    setTimeout(() => {
      setLogoutSuccess(false);
    }, 3000);
  };

  return (
    <div className="flex fixed w-full top-0 z-50 bg-[#ffffff] items-center justify-between h-[60px] shadow-[0px_2px_4px_0px_rgba(0,0,0,0.1)] font-poppins ">
      {logoutSuccess && (
        <div className="bg-green-500  text-white p-2 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded">
          <p className="w-52 sm:w-60 text-center"> Logout successfully!</p>
        </div>
      )}
      <div onClick={() => handleRoute("/")} className="pl-3">
        <img src={Logo} alt="" className="h-[50px] w-[170px] cursor-pointer" />
      </div>
      {loading && <PageLoader />}
      {/* =========== Large screen show Navbar======== */}
      <div className="hidden lg:inline-block ">
        <ul className="flex gap-4 pr-3">
          {pathname !== "/" && <SearchBar />}
          <li className="flex items-center cursor-pointer">
            <Link
              to="https://play.google.com/store/apps/details?id=com.foundercode.healthcrad_user"
              target="_blank"
            >
              <button className="text-[16px] text-[#00B4D8] font-normal pt-2 mb-2 pl-[42px] ">
                Download App
              </button>
            </Link>
          </li>
          {isAuthenticated ? (
            <div className="relative hidden lg:inline">
              <div
                className="flex items-center  cursor-pointer  "
                onClick={togglePop}
              >
                <img
                  style={{ width: "25px", height: "65px" }}
                  className="  inline-block "
                  src={UserIcon}
                  alt="user-icon"
                />
                <span
                  className="font-normal font-poppins mt-0.5 pl-1 text-[#00B4D8]"
                  onClick={togglePop}
                >
                  {" "}
                  {userFname || firstName}
                </span>
              </div>
              {isProfilePopupOpen && (
                <div ref={popupRef} className=" ">
                  <div className="absolute  lg:right-0 top-full bg-[#fff] border border-gray-300 p-2 shadow-md rounded-lg w-[300px]  pl-6">
                    <div className="leading-6">
                      <h2 className="text-[16px] text-[#000000]  font-poppins ml-2">
                        {uName}
                      </h2>
                      <p className="text-[16px] text-[#00000059] ml-2 ">
                        {mobNumber}
                      </p>

                      {/*Add more details */}
                      <div className="flex mb-2 ">
                        <div>
                          <button
                            onClick={() => handleRoute("/your-account")}
                            className="font-medium text-[16px]  text-[#00B4D8] mr-2  ml-2 -mt-1"
                          >
                            Your Account
                          </button>
                        </div>
                        <div className="">
                          {" "}
                          <img
                            src={ForwardIcon}
                            alt="Forward Arrow"
                            className="w-[7px]  mt-[7px] ml-2"
                          />
                        </div>
                      </div>
                      <div
                        className="underline-offset-8 lg:w-[280px]  border-[1px] 
                            color-[#0000002E] center-alignment ml-2 mt-2"
                      ></div>

                      {/* My-order */}
                      <div
                        className="flex justify-between  ml-2 "
                        onClick={() => handleRoute("/myorders")}
                      >
                        <div className="flex mt-1">
                          <div className="w-4 h-auto mt-1.5">
                            <img src={MyOrder} alt="My-order Icon" />
                          </div>
                          <div>
                            {" "}
                            <button className="text-[#666161]  ml-2 mb-[6px]  font-medium font-poppins text-[15px]">
                              My Orders
                            </button>
                          </div>
                        </div>
                        <div className="w-[8px] h-[10px] mr-6 mt-2.5">
                          <img src={ForwardIcon} alt="" />
                        </div>
                      </div>
                    </div>

                    {/* Manage Addresses */}
                    <div
                      className="flex justify-between  ml-2 mb-[6px] "
                      onClick={() => handleRoute("/manage-address")}
                    >
                      <div className="flex ">
                        <div className="w-4 h-auto mt-1 ">
                          <img src={ManageAddress} alt="My-order Icon" />
                        </div>
                        <div>
                          {" "}
                          <button className="text-[#666161]  ml-2  font-medium font-poppins text-[15px]">
                            Manage Addresses
                          </button>
                        </div>
                      </div>
                      <div className="w-[8px] h-[10px] mr-6 mt-2">
                        <img src={ForwardIcon} alt="" />
                      </div>
                    </div>

                    {/* My Appointment */}
                    <div
                      className="flex justify-between  ml-2 mb-[6px]"
                      onClick={() => handleRoute("/myAppointment")}
                    >
                      <div className="flex ">
                        <div className="w-4 h-auto mt-1 ">
                          <img src={MyAppointment} alt="My-order Icon" />
                        </div>
                        <div>
                          {" "}
                          <button className="text-[#666161]  ml-2  font-medium font-poppins text-[15px]">
                            My Appointment
                          </button>
                        </div>
                      </div>
                      <div className="w-[8px] h-[10px] mr-6 mt-2">
                        <img src={ForwardIcon} alt="" />
                      </div>
                    </div>

                    {/* Ambulance Booking */}
                    <div
                      className="flex justify-between  ml-2 mb-[17px]"
                      onClick={() => handleRoute("/ambulanceBooking")}
                    >
                      <div className="flex ">
                        <div className="w-4 h-5 mt-[6px] ">
                          <img src={AmbulanceBooking} alt="My-order Icon" />
                        </div>
                        <div>
                          {" "}
                          <button className="text-[#666161]  ml-2  font-medium font-poppins text-[15px]">
                            Ambulance Booking
                          </button>
                        </div>
                      </div>
                      <div className="w-[8px] h-[10px] mr-6 mt-2">
                        <img src={ForwardIcon} alt="" />
                      </div>
                    </div>

                    {/* Pathlab -----> My Report*/}
                    <div
                      className="flex justify-between  ml-2 mb-[6px] "
                      onClick={() => handleRoute("/pathlab/myReport")}
                    >
                      <div className="flex -mt-3">
                        <div className="w-4 h-auto mt-1">
                          <img src={Pathlab} alt="My-order Icon" />
                        </div>
                        <div>
                          {" "}
                          <button className="text-[#666161]  ml-2  font-medium font-poppins text-[15px]">
                            My Report
                          </button>
                        </div>
                      </div>
                      <div className="w-[8px] h-[10px] mr-6  -mt-1">
                        <img src={ForwardIcon} alt="" />
                      </div>
                    </div>

                    {/* FAQs */}
                    <div
                      className="flex justify-between  ml-2  mb-[6px]"
                      onClick={() => handleRoute("/faqs")}
                    >
                      <div className="flex">
                        <div className="w-4 h-auto mt-1">
                          <img src={Faqs} alt="My-order Icon" />
                        </div>
                        <div>
                          {" "}
                          <button className="text-[#666161]  ml-2  font-medium font-poppins text-[15px]">
                            FAQs
                          </button>
                        </div>
                      </div>
                      <div className="w-[8px] h-[10px] mr-6 mt-2">
                        <img src={ForwardIcon} alt="" />
                      </div>
                    </div>

                    {/* Refer & Earn */}
                    <div className="flex justify-between  ml-2 mb-[6px]">
                      <div className="flex  ">
                        <div className="w-4 h-auto mt-1 ">
                          <img src={HelpEarn} alt="My-order Icon" />
                        </div>
                        <div>
                          {" "}
                          <button className="text-[#666161]  ml-2  font-medium font-poppins text-[15px]">
                            Help 2 Earn
                          </button>
                        </div>
                      </div>
                      <div className="w-[8px] h-[10px] mr-6 mt-2">
                        <img src={ForwardIcon} alt="" />
                      </div>
                    </div>

                    {/* Help & Support */}
                    <div
                      className="flex justify-between  ml-2 mb-[6px]"
                      onClick={() => handleRoute("/helpAndSupport")}
                    >
                      <div className="flex  ">
                        <div className="w-4 h-auto mt-1">
                          <img src={HelpSupport} alt="My-order Icon" />
                        </div>
                        <div>
                          {" "}
                          <button className="text-[#666161]  ml-2  font-medium font-poppins text-[15px]">
                            Help & Support
                          </button>
                        </div>
                      </div>
                      <div className="w-[8px] h-[10px] mr-6 mt-2">
                        <img src={ForwardIcon} alt="" />
                      </div>
                    </div>

                    <div onClick={() => handleRoute("/terms&condition")}>
                      <button className="text-[#B4B4B4] font-medium text-[15px] ml-2.5 mb-[6px]">
                        Terms & Conditions
                      </button>
                    </div>

                    <div
                      className="text-[#B4B4B4] font-medium -mt-1 mb-2 ml-3 text-[15px]"
                      onClick={() => handleRoute("/privacy&policy")}
                    >
                      <button>Privacy Policy</button>
                    </div>

                    <div
                      className="underline-offset-8 lg:w-[280px]  border-[1px] 
                            color-[#0000002E] center-alignment ml-2"
                    ></div>

                    {/* login button */}
                    <div className="flex mt-[6px]" onClick={handleOpenLogout}>
                      {/* onClick={clearLocalStorage} */}
                      <div className="w-[15px] h-[25px] ml-2 mt-[6px] ">
                        <img src={LogOut} alt="" />
                      </div>
                      <div>
                        <button className="text-[#666161]  ml-2  font-medium font-poppins text-[15px]">
                          Log-out
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <li>
              <div className="border-[1px] border-[#00B4D8] rounded-[6px] mt-[4px] group text-[#00B4D8] hover:bg-[#00B4D8] cursor-pointer">
                <button
                  onClick={() => handleRoute("/login")}
                  className=" text-[15px] my-1.5 mx-1.5  font-medium group-hover:text-white transition-colors duration-300"
                >
                  Sign In | Sign Up
                </button>
              </div>
            </li>
          )}

          <li
            className="flex items-center cursor-pointer gap-2"
            onClick={() => handleRoute("/cart")}
          >
            <img className="w-[26px] h-[26px]" src={CartIcon} alt="cart-icon" />
            {productCount > 0 && (
              <span className=" w-5 h-5 text-center  font-lato font-bold lg:-mt-5 -ml-4 border-[1px] border-[red] bg-[red] text-[#FFF] text-sm  rounded-[20px] ">
                {productCount}
              </span>
            )}
            <button
              onClick={() => handleRoute("/cart")}
              className="text-[16px] text-[#00B4D8] font-normal"
            >
              Cart
            </button>
          </li>
        </ul>
      </div>

      {/* modal box for Delete start */}
      <Modal
        open={open}
        onClose={handleCloseLogout}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="fixed z-10 inset-0 overflow-y-auto"
      >
        <div className="flex items-center justify-center min-h-screen">
          <div className="bg-white w-[290px] sm:w-[390px] p-2 gap-2  rounded-lg text-center   ">
            <div className=" ">
              <div className="pt-3 select-none mb-4 flex justify-center items-center">
                <p className=" text-center  text-[#4A4141] font-poppins font-semibold sm:text-lg text-sm  ">
                  Oh no! You're leaving...
                  <br />
                  Are you sure?{" "}
                </p>
              </div>
            </div>
            <div className="flex mb-3  justify-around items-center  ">
              <div
                className="select-none flex justify-center items-center w-24 lg:w-32 h-12 bg-[#10CAF2] rounded-[10px] "
                onClick={handleConfirmLogout}
              >
                <button className=" text-[18px] font-poppins font-bold text-[#FFF]">
                  Yes
                </button>{" "}
              </div>
              <div
                className="select-none flex justify-center items-center w-24 lg:w-32 h-12  bg-[#FE0505] rounded-[10px]"
                onClick={handleCloseLogout}
              >
                <button className="  text-[16px]  sm:text-[18px] font-poppins font-bold text-[#FFF] ">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {/* modal box for Delete end */}

      {/* small screen show navbar */}
      <div className="lg:hidden flex gap-[16px] overflow-y-auto ">
        {/* Search bar component */}
        {pathname !== "/" && <SearchBar />}
        <img
          onClick={() => handleRoute("/cart")}
          className="w-[26px] h-[26px]  ml-12 "
          src={CartIcon}
          alt="cart-icon"
        />
        {productCount > 0 && (
          <span className=" w-4 h-4 text-center  font-lato font-bold  -ml-6 border-[1px] border-[red] bg-[red] text-[#FFF]  rounded-[20px] text-[10px] ">
            {productCount}
          </span>
        )}
        <button
          className="text-[24px] text-[#00B4D8] font-normal mr-2"
          onClick={toggleSidebar}
        >
          <img src={Hamberger} alt="" className="w-[26px] h-[26px]" />
        </button>
      </div>
      {isSidebarOpen && (
        <div
          className="fixed inset-0 bg-gray-800  bg-opacity-50  backdrop-filter backdrop-blur-sm z-10"
          onClick={toggleSidebar} // Close sidebar when overlay is clicked
        ></div>
      )}
      {/* Sidebar */}
      <div
        className={`${
          isSidebarOpen
            ? "fixed inset-0 left-0 w-[265px] pl-2 h-full bg-white ease-in duration-300 transition-all z-20"
            : "fixed left-0 top-0 w-[265px] pl-2 h-full bg-white ease-out duration-300 transform -translate-x-full transition-all z-20"
        } lg:hidden`}
      >
        <button
          className="lg:hidden text-[24px] text-gray-600 font-normal absolute top-3 right-3"
          onClick={toggleSidebar}
        >
          {" "}
          ✕
        </button>
        {/* Sidebar Content Start */}
        <div className="flex flex-col  ">
          {isAuthenticated ? (
            <>
              {/* Slide Area Start */}
              <div className="lg:hidden text-left mt-3">
                <div className="leading-6">
                  <h2 className="text-[14px] text-[#414146] font-semibold  font-poppins ml-2">
                    {uName}
                  </h2>
                  <p className="text-[14px] text-[#414146] ml-2 ">
                    {mobNumber}
                  </p>

                  {/*Add more details */}
                  <div className="flex mb-2 ">
                    <div>
                      <button
                        onClick={() => handleRoute("/your-account")}
                        className="font-medium text-[14px]  text-[#666161] mr-2  ml-2"
                      >
                        Your Account
                      </button>
                    </div>
                    <div className="">
                      {" "}
                      <img
                        src={ArrowIcon}
                        alt="Forward Arrow"
                        className="w-[6px] text-[#666161] mt-2 ml-2"
                      />
                    </div>
                  </div>
                  <div
                    className="underline-offset-8 w-[200px]  border-[1px] 
                            color-[#0000002E] center-alignment ml-2"
                  ></div>

                  {/* My-order */}
                  <div
                    className="flex justify-between  ml-2 my-3 "
                    onClick={() => handleRoute("/myorders")}
                  >
                    <div className="flex mt-1">
                      <div className="w-4 h-auto mt-1.5">
                        <img src={MyOrder} alt="My-order Icon" />
                      </div>
                      <div>
                        {" "}
                        <button className="text-[#666161]  ml-2 -mb-2  font-medium  font-poppins text-[14px]">
                          My Orders
                        </button>
                      </div>
                    </div>
                    <div className="w-[8px] h-[10px] mr-6 mt-2.5">
                      <img src={ArrowIcon} alt="" />
                    </div>
                  </div>
                </div>

                {/* Manage Addresses */}
                <div
                  className="flex justify-between  ml-2 my-3 "
                  onClick={() => handleRoute("/manage-address")}
                >
                  <div className="flex ">
                    <div className="w-4 h-auto mt-1 ">
                      <img src={ManageAddress} alt="Icon2" />
                    </div>
                    <div>
                      {" "}
                      <button className="text-[#666161]  ml-2  font-medium font-poppins text-[14px]">
                        Manage Addresses
                      </button>
                    </div>
                  </div>
                  <div className="w-[8px] h-[10px] mr-6 mt-[7px]">
                    <img src={ArrowIcon} alt="" />
                  </div>
                </div>

                {/* My Appointment */}
                <div
                  className="flex justify-between  ml-2 my-3"
                  onClick={() => handleRoute("/myAppointment")}
                >
                  <div className="flex ">
                    <div className="w-4 h-auto mt-1 ">
                      <img src={MyAppointment} alt="Icon3" />
                    </div>
                    <div>
                      {" "}
                      <button className="text-[#666161]  ml-2  font-medium font-poppins text-[14px]">
                        My Appointment
                      </button>
                    </div>
                  </div>
                  <div className="w-[8px] h-[10px] mr-6 mt-[7px]">
                    <img src={ArrowIcon} alt="" />
                  </div>
                </div>

                {/* Ambulance Booking */}
                <div
                  className="flex justify-between  ml-2 mb-2.5 my-3"
                  onClick={() => handleRoute("/ambulanceBooking")}
                >
                  <div className="flex ">
                    <div className="w-4 h-5 mt-[6px] ">
                      <img src={AmbulanceBooking} alt="Icon5" />
                    </div>
                    <div>
                      {" "}
                      <button className="text-[#666161]  ml-2  font-medium  font-poppins text-[14px]">
                        Ambulance Booking
                      </button>
                    </div>
                  </div>
                  <div className="w-[8px] h-[10px] mr-6 mt-[7px]">
                    <img src={ArrowIcon} alt="" />
                  </div>
                </div>

                {/* Pathlab---> My Report */}
                <div
                  className="flex justify-between  ml-2 mt-4 "
                  onClick={() => handleRoute("/pathlab/myReport")}
                >
                  <div className="flex -mt-3">
                    <div className="w-4 h-auto mt-1">
                      <img src={Pathlab} alt=" Icon6" />
                    </div>
                    <div>
                      {" "}
                      <button className="text-[#666161]  ml-2  font-medium  font-poppins text-[14px]">
                        My Report
                      </button>
                    </div>
                  </div>
                  <div className="w-[8px] h-[10px] mr-6 -mt-[5px]">
                    <img src={ArrowIcon} alt="" />
                  </div>
                </div>

                {/* FAQs */}
                <div
                  className="flex justify-between  ml-2 my-3"
                  onClick={() => handleRoute("/faqs")}
                >
                  <div className="flex">
                    <div className="w-4 h-auto mt-1">
                      <img src={Faqs} alt=" Icon7" />
                    </div>
                    <div>
                      {" "}
                      <button className="text-[#666161]  ml-2  font-medium font-poppins text-[14px]">
                        FAQs
                      </button>
                    </div>
                  </div>
                  <div className="w-[8px] h-[10px] mr-6 mt-[6px]">
                    <img src={ArrowIcon} alt="" />
                  </div>
                </div>

                {/* Refer & Earn */}
                <div className="flex justify-between  ml-2 my-3 ">
                  <div className="flex  ">
                    <div className="w-4 h-auto mt-1 ">
                      <img src={HelpEarn} alt="Icon8" />
                    </div>
                    <div>
                      {" "}
                      <button className="text-[#666161]  ml-2  font-medium  font-poppins text-[14px]">
                        Help 2 Earn
                      </button>
                    </div>
                  </div>
                  <div className="w-[8px] h-[10px] mr-6 mt-[6px]">
                    <img src={ArrowIcon} alt="" />
                  </div>
                </div>

                {/* Help & Support */}
                <div
                  className="flex justify-between  ml-2 my-3"
                  onClick={() => handleRoute("/helpAndSupport")}
                >
                  <div className="flex  ">
                    <div className="w-4 h-auto mt-1">
                      <img src={HelpSupport} alt="Icon9" />
                    </div>
                    <div>
                      {" "}
                      <button className="text-[#666161]  ml-2  font-medium  font-poppins text-[14px]">
                        Help & Support
                      </button>
                    </div>
                  </div>
                  <div className="w-[8px] h-[10px] mr-6 mt-[6px]">
                    <img src={ArrowIcon} alt="" />
                  </div>
                </div>
                <div onClick={() => handleRoute("/terms&condition")}>
                  <button className="text-gray-600 font-medium text-[12px] ml-2.5 my-2">
                    Terms & Conditions
                  </button>
                </div>
                <div
                  className="text-gray-600 font-medium  -mt-1 mb-2 ml-3 text-[12px] my-2"
                  onClick={() => handleRoute("/privacy&policy")}
                >
                  <button>Privacy Policy</button>
                </div>
                {/* download App */}
                <Link
                  to="https://play.google.com/store/apps/details?id=com.foundercode.healthcrad_user"
                  target="_blank"
                >
                  <div className="flex justify-between gap-1 ml-[10px] my-3 ">
                    <div className="flex gap-1">
                      <img
                        src={DownloadApp}
                        alt=""
                        className="w-[31px] h-[31px]"
                      />
                      <p className="text-[#666161] font-medium  text-[14px] pt-[7px]">
                        Download App
                      </p>
                    </div>
                    <div className="w-[8px] h-[10px] mr-6 mt-[13px]">
                      <img src={ArrowIcon} alt="" />
                    </div>
                  </div>
                </Link>
                <div className="underline-offset-8 w-[200px]  border-[1px] color-[#0000002E] center-alignment ml-2"></div>
                {/* login button */}
                <div
                  className="flex justify-between "
                  onClick={handleOpenLogout}
                >
                  <div className="flex my-3">
                    <div className="w-[15px] h-[25px] ml-2 mt-[9px] ">
                      <img src={LogOut} alt="" />
                    </div>
                    <div>
                      <button className="text-[#666161]  ml-2  font-medium font-poppins text-[14px]">
                        Log-out
                      </button>
                    </div>
                  </div>
                  <div className="w-[8px] h-[10px] mr-6 mt-4">
                    <img src={ArrowIcon} alt="" />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div>
              <div className="border-[1px] border-[#00B4D8] w-[145px] h-[41px] rounded-[6px] mt-[30px] ml-[8px] group hover:bg-[#00B4D8] cursor-pointer">
                <button
                  onClick={() => handleRoute("/login")}
                  className=" text-[#00B4D8] text-[15px]  ml-[12px] mt-[8px] font-medium group-hover:text-white transition-colors duration-300"
                >
                  Sign In | Sign Up
                </button>
              </div>
              <div className="underline-offset-8 w-[200px]  border-[1px] color-[#0000002E] center-alignment ml-2 mt-3"></div>

              {/* FAQs */}
              <div
                className="flex justify-between  ml-2 mb-3 mt-4"
                onClick={() => handleRoute("/faqs")}
              >
                <div className="flex">
                  <div className="w-4 h-auto mt-1">
                    <img src={Faqs} alt="My-order Icon" />
                  </div>
                  <div>
                    {" "}
                    <button className="text-[#666161]  ml-2  font-medium font-poppins text-[14px]">
                      FAQs
                    </button>
                  </div>
                </div>
                <div className="w-[8px] h-[10px] mr-6 mt-[8px]">
                  <img src={ArrowIcon} alt="" />
                </div>
              </div>

              {/* Refer & Earn */}
              <div className="flex justify-between  ml-2 my-4 ">
                <div className="flex  ">
                  <div className="w-4 h-auto mt-1 ">
                    <img src={HelpEarn} alt="My-order Icon" />
                  </div>
                  <div>
                    {" "}
                    <button className="text-[#666161]  ml-2  font-medium  font-poppins text-[14px]">
                      Help 2 Earn
                    </button>
                  </div>
                </div>
                <div className="w-[8px] h-[10px] mr-6 mt-[8px]">
                  <img src={ArrowIcon} alt="" />
                </div>
              </div>

              {/* Help & Support */}
              <div
                className="flex justify-between  ml-2 my-4"
                onClick={() => handleRoute("/helpAndSupport")}
              >
                <div className="flex  ">
                  <div className="w-4 h-auto mt-1">
                    <img src={HelpSupport} alt="My-order Icon" />
                  </div>
                  <div>
                    {" "}
                    <button className="text-[#666161]  ml-2  font-medium  font-poppins text-[14px]">
                      Help & Support
                    </button>
                  </div>
                </div>
                <div className="w-[8px] h-[10px] mr-6 mt-[8px]">
                  <img src={ArrowIcon} alt="" />
                </div>
              </div>
              <div onClick={() => handleRoute("/terms&condition")}>
                <button className="text-[#666161] font-medium text-[12px] ml-2.5 my-3">
                  Terms & Conditions
                </button>
              </div>
              <div
                className="text-[#414146] font-medium  -mt-1 mb-2 ml-3 text-[12px] my-3"
                onClick={() => handleRoute("/privacy&policy")}
              >
                <button>Privacy Policy</button>
              </div>
              {/* download App */}
              <Link
                to="https://play.google.com/store/apps/details?id=com.foundercode.healthcrad_user"
                target="_blank"
              >
                <div className="flex justify-between  ml-[10px] my-4 ">
                  <div className="flex gap-1">
                    <img
                      src={DownloadApp}
                      alt=""
                      className="w-[31px] h-[31px]"
                    />
                    <p className="text-[#666161] font-medium  text-[14px] pt-[7px]">
                      Download App
                    </p>
                  </div>
                  <div className="w-[8px] h-[10px] mr-6 mt-[7px]">
                    <img src={ArrowIcon} alt="" />
                  </div>
                </div>
              </Link>
            </div>
          )}
        </div>
        {/* Sidebar Content End */}
      </div>
    </div>
  );
};
export default Navigation;
