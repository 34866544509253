import React, { useEffect, useState } from "react";
import Header from "../Header";
import { useNavigate } from "react-router-dom";
import siteConfig from "../../../urlFile";
import axios from "axios";
import PageLoader from "../../../Loader/PageLoader";

const Doctors = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  // const handleRoute = (path) => {
  //   navigate(path);
  // };
  const handleRoute = (path) => {
    setLoading(true);
    setTimeout(() => {
      navigate(path);
    }, 1000);
  };
  // GET Doctor Category API
  const [doctorDataList, setDoctorDataList] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${siteConfig.BASE_URL}${siteConfig.DOCTOR_CATEGORY}`
        );
        setDoctorDataList(response.data.data);
        console.log("Fetched Medical Specialties:", response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const getDoctorImageUrl = (imageName) =>
    `https://app.healthcrad.com/api//uploads/${imageName}`;

  return (
    <>
      <Header />
      {loading && <PageLoader />}
      <div className="pt-24 pb-10">
        <div className="flex justify-center items-center pb-2">
          <div className="mb-3 cursor-default rounded-[10px] border-[1px] border-[#10CAF2] bg-[#FFF] sm:w-[230px] w-[190px] h-11 flex items-center justify-center">
            <p className="xl:text-lg sm:text-md md:text-lg text-[#00B4D8] font-poppins">
              Book an Appointment
            </p>
          </div>
        </div>
        <div className="flex flex-col p-4 mt-[-20px] gap-3 xl:ml-4">
          {loading || doctorDataList.length === 0 ? (
            <PageLoader />
          ) : (
            <div className="grid lg:grid-cols-3 xl:grid-cols-4 md:grid-cols-3 grid-cols-2 gap-5">
              {doctorDataList.map((doctorDetails) => (
                <div
                  key={doctorDetails.id}
                  className="flex flex-col sm:flex-row py-4 shadow-xl items-center sm:gap-2 lg:gap-10 gap-6 justify-center bg-[#FFF] border-[1px] border-[#00000033] rounded-[10px] cursor-pointer"
                  onClick={() =>
                    handleRoute(
                      `/doctors/appointmentBook?category=${doctorDetails.name}`
                    )
                  }
                >
                  <img
                    src={getDoctorImageUrl(doctorDetails.image)}
                    alt="Doctor Default"
                    className="rounded-full h-[50px] w-[50px] sm:h-[80px] sm:w-[80px]"
                  />
                  <p className="sm:w-32 w-full font-semibold text-center text-[#4A4141] font-poppins sm:text-sm md:text-md lg:text-lg text-xs">
                    {doctorDetails.name}
                  </p>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Doctors;
