const siteCofig = {
  BASE_URL: "https://app.healthcrad.com/api/index.php/api/Mobile_app/",
  CHECKOUT_URL: "https://app.healthcrad.com/api/index.php/api/Check_out/",

  // Auth
  USER_LOGIN: "userlogin",
  USER_REGISTER: "reg",
  RESEND_OTP: "resendotp",
  VIEW_PROFILE: "profile",
  UPDATE_PROFILE: "update_images",

  // Home Page
  HELP: "help",
  GET_BANNER: "getbanner",
  OFFER_SLIDER: "offerslider",

  // Address
  SAVE_ADDRESS: "address",
  UPDATE_ADDRESS: "address_update",
  DELETE_ADDRESS: "address_delete?address_id=",
  GET_ADDRESS: "get_addresss?user_id=",

  //Medicine
  GET_ALL_MEDICINE: "medicebyall",
  MEDICINE_CATEGORY: "medicinecategory",
  GET_MEDICINE_BY_CATEGORY: "medicebycategory",
  GET_MEDICINE_BY_SUBCATEGORY: "medicalsubcategory",

  // Cart
  ADD_TO_CART: "mediaddcart",
  VIEW_CART_ITEMS: "medicart?userid=",
  DELETE_CART_ITEM: "addtocartdelete",
  CART_UPDATE: "cartmedicineupdate",
  PRESCRIPTION_ORDER_CART: "precriptionorder",

  // Order Ny Prescription
  ORDER_BY_PRESCROPTION: "https://app.healthcrad.com/order_prescription.php",

  // My Order
  INPROCESS_ORDER: "inprocess_order?user_id=",
  GET_PRESCRIPTION_ORDER: "prescription_order?userid=",
  ORDER_GET_DETAILS: "view_item?groupid=",
  ORDER_DELIVERED: "deliverd_order?user_id=",
  PRESCRIPTION_GET_DETAILS: "prescription_order?orderid=",

  // Checkout
  ORDER_CHECKOUT: "https://app.healthcrad.com/api/index.php/api/Check_out/update_address",

  // Ambulance
  AMBULANCE_TYPE: "ambulance_type",
  BOOK_AMBULANCE: "book_ambulance",
  AMBULANCE_HISTORY: "ambulancehistory?user_id=",

  // Doctor
  DOCTOR_CATEGORY: "doctorcategory",
  GET_DOCTOR: "doctor",
  DOCTOR_GET_SHIFT: "doctor_getshift",
  DOCTOR_FEEDBACK: "docterfeedback",
  SHOW_FEEDBACK: "show_feedback",
  FEEDBACK: "feedback",
  GET_UPCOMING_APPOINTMENT: "upcoming_appointments?userid=",
  GET_PAST_APPOINTMENT: "past_appointments?userid=",
  CANCEL_APPOINTMENT: "cancel_appointment",
  DOCTOR_VIEW_PROFILE: "getdocter?userid=",
  CHECK_SLOT_AVAILABILITY: "checkslot",
  DOCTOR_BOOK_APPOINTMENT: "book_appointment",
  DOCTOR_RESCHEDULE_APPOINTMENT: "reshedule_appointment",

  // Pathlab
  SHOW_REPORT: "show_report?user_id=",
  REPORT_REQUEST: "labs",
  
};

export default siteCofig;
