import React, { useEffect, useState } from "react";
import Header from "../pages/HomePage/Header";
import { useNavigate } from "react-router-dom";
import Dr from "../images/Login and Signup materials/Ultimate Healthcare2.png";
import Female_Dr from "../images/Login and Signup materials/10-109014_female-doctor-transparent-images-medical-assistant.png";
import Hlogo from "../images/Login and Signup materials/logo.png";
import siteCofig from "../urlFile";
import axios from "axios";
import Bg from "../images/Light-Blue-.png";
import PageLoader from "../Loader/PageLoader";

function LoginForm() {
  const navigate = useNavigate();
  const [isWideScreen, setIsWideScreen] = useState(false);
  // const [mobileNumber, setMobileNumber] = useState(0);
  const [alertMessage, setAlertMessage] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const customStyles = {
    ".react-tel-input .form-control .iti__selected-dial-code": {
      fontWeight: "bold",
    },
  };

  // const handleRoute = (path) => {
  //   navigate(path); // Navigates to the 'any' route
  // };
  const handleRoute = (path) => {
    setLoading(true);
    setTimeout(() => {
      navigate(path);
    }, 1000);
  };

  useEffect(() => {
    function handleResize() {
      setIsWideScreen(window.innerWidth >= 1535);
    }

    // Initially check the screen size and add event listener for resizing
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getOtp = async () => {
    if (!mobileNumber || mobileNumber.length !== 10) {
      setAlertMessage("Enter a valid 10-digit mobile number.");
      setTimeout(() => {
        setAlertMessage(""); // Reset alertMessage after 10 seconds
      }, 10000); // 10 seconds in milliseconds
      return;
    }
    setTimeout(async () => {
      //500 milisecond later msg will show
      setAlertMessage(<p className="text-green-500">OTP sent successfully</p>);
      // 1 second later api call
      setTimeout(async () => {
        let reqBody = {
          mobile: mobileNumber,
        };
        setLoading(true);
        try {
          const response = await axios.post(
            siteCofig.BASE_URL + siteCofig.USER_LOGIN,
            reqBody
          );
          const userData = response.data;

          if (
            userData.msg === "Successfully Login" ||
            userData.msg === "Your mobile number Not register"
          ) {
            setAlertMessage("");
            navigate("/otp-verify", {
              state: { userData: userData, mobileNumber: mobileNumber },
            });
          }
          console.log(userData);
        } catch (error) {
          setAlertMessage("");
          setLoading(false);
        }
      });
    }, 500);
  };

  return (
    <>
      <Header />
      {loading && <PageLoader />}
      <div
        className="pt-[130px] pb-40"
        style={{
          backgroundImage: `url(${Bg})`,
          height: "100vh",
        }}>
        <div className="pt-12 flex justify-center items-center">
          <div
            className="flex flex-row bg-[#FFF] rounded-[10px]"
            style={{ boxShadow: "4px 4px 18.9px 6px rgba(0, 0, 0, 0.20)" }}>
            <div className=" w-[100vw] sm:w-auto flex flex-col items-center gap-6 pb-4">
              <div className=" mt-2">
                <img
                  src={Hlogo}
                  alt="Logo"
                  className="w-64 h-16 mb-[34px] mt-[46px]"
                />
              </div>

              <div className=" flex flex-col justify-center items-center gap-4 p-4 ">
                <div className=" flex flex-col justify-center items-center p-2 ">
                  <p className="text-md font-bold text-[#7e7a79] font-poppins self-start pl-2.5 mb-1">
                    Mobile Number
                  </p>
                  <div
                    className={`border-${
                      isFocused ? "green-500" : "gray-300"
                    } border-[2px] group rounded-[6px] bg-[#FFFFFF] ml-3 w-[260px] focus:outline-none flex flex-row`}
                    style={{ borderColor: isFocused ? "#10CAF2" : "#CCC" }}>
                    <span className="ml-1 mt-[9px] font-semibold">+91</span>
                    <input
                      type="text"
                      className="bg-[#FFFFFF] border-2 w-52 p-2 rounded-[6px]  font-poppins  focus:outline-none  border-[#FFF]"
                      placeholder="Enter mobile number"
                      maxLength={10}
                      value={mobileNumber}
                      onFocus={() => setIsFocused(true)}
                      onBlur={() => setIsFocused(false)}
                      onChange={(e) => setMobileNumber(e.target.value)}
                    />
                  </div>
                </div>
                <div className=" flex justify-center items-center mb-[8px] pl-2">
                  <div className=" w-[260px] h-11 flex justify-center items-center  bg-[#10CAF2] rounded-md ">
                    <button
                      type="submit"
                      className="    text-white   transition duration-300  font-bold  font-poppins  text-xl  "
                      onClick={(e) => {
                        e.preventDefault();
                        getOtp();
                      }}>
                      Get OTP
                    </button>
                  </div>
                </div>
                <div className="text-[9px]  flex justify-center items-center">
                  <p className="text-center font-poppins">
                    By continuing you agree to our{" "}
                    <a class="text-blue-500 hover:text-blue-700 cursor-pointer" >
                      <span  onClick={() => handleRoute("/terms&condition")}>Terms and Conditions/</span>
                      <span   onClick={() => handleRoute("/privacy&policy")}>Privacy and Policy</span>
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="relative hidden md:block">
              <img
                className="h-[450px] w-[293px] rounded-tr-[10px] rounded-br-[10px] style={{background: 'linear-gradient(89deg, #00B4D8 0.5%, #037CB3 45.18%, #081972 99.57%)'}}"
                src={Dr}
                alt=""
                style={{
                  background:
                    "linear-gradient(89deg, #00B4D8 0.5%, #037CB3 45.18%, #081972 99.57%)",
                }}
              />
              <img
                className="absolute top-[316px] w-[450px] h-[270px] -ml-10 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                src={Female_Dr}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default LoginForm;
