import React, { useState } from "react";
import Header from "../../Header";
import { FaPlus, FaMinus } from "react-icons/fa";

const Faqs = () => {
  const faqs = [
    {
      id: 1,
      question: "1. How to login in App?",
      answer:
        "To login the App, simply enter your phone number to generate OTP, then enter the OTP to proceed.",
    },
    {
      id: 2,
      question: "2. How to book an Appointment?",
      answer:
        "Open the HealthCRAD App, Go to doctor and choose te category of which you want to book doctor from, then search the doctor.",
    },
    {
      id: 3,
      question: "3. How to cancel an Appointment?",
      answer: "Go to more > My Appointments > Cancel Appointment.",
    },
    {
      id: 4,
      question: "4. What if i failed to book an Appointment?",
      answer:
        "If the selected doctor is not available on the selected date and day, the booking might fail.",
    },
    {
      id: 5,
      question: "5. How to payment?",
      answer:
        "For now we are only providing offline payment method, either you book an appointment, order any product or book any service.",
    },
    {
      id: 6,
      question: "6. How to order medicines & other medical products?",
      answer:
        "Go to medicine and essentials feature, then search the medicine or the medical products you want to buy, then add to cart buy the products.",
    },
    {
      id: 7,
      question: "7. How to cancel the order?",
      answer:
        "To cancel the order go to Help section and the wrte your query and phone number and request callback we will cancel the order.",
    },
    {
      id: 8,
      question: "8. How to book an Ambulance?",
      answer:
        "To book an ambulance go to ambulance feature the search the pickup and destination, after then select the type of emergency.",
    },
    {
      id: 9,
      question: "9. How to cancel the ambulance booking?",
      answer:
        "Once you book an ambulance we will call you to confirm the booking, you can cancel the booking at that time or if you want to then proceed further.",
    },
  ];
  const [activeFAQ, setActiveFAQ] = useState(null);

  const toggleFAQ = (id) => {
    setActiveFAQ(activeFAQ === id ? null : id);
  };

  return (
    <>
      <Header />
      <div className="bg-gray-100 min-h-screen py-8 pt-24 pb-8 ">
        <div className="flex justify-center items-center">
          <div className=" cursor-default mb-3 flex justify-center items-center rounded-[10px] border-[1px] border-[#10CAF2] bg-[#FFF]  sm:w-[120px] sm:h-[45px] w-[100px] h-10  lg:ml-[2px] ml-1  ">
            <p className="xl:text-lg sm:text-md md:text-lg text-[#00B4D8] font-poppins   ">
              FAQs
            </p>
          </div>
        </div>
        <div className="max-w-3xl mx-auto">
          <div className="space-y-4">
            {faqs.map((faq) => (
              <div key={faq.id} className="bg-white rounded-md shadow-md p-3">
                <div
                  className="flex justify-between items-center cursor-pointer"
                  onClick={() => toggleFAQ(faq.id)}
                >
                  <h2 className="text-lg font-semibold text-[#000] font-poppins ">
                    {faq.question}
                  </h2>
                  {activeFAQ === faq.id ? (
                    <FaMinus className="text-[#000] font-poppins " />
                  ) : (
                    <FaPlus className="text-[#000] font-poppins " />
                  )}
                </div>
                <div
                  className={`transition-all duration-500 ease-in-out overflow-hidden ${
                    activeFAQ === faq.id ? "h-auto py-4 px-6" : "h-0 py-0 px-6"
                  }`}
                >
                  {activeFAQ === faq.id && (
                    <p className="text-[#4A4141] font-poppins mt-0 ml-[-8px] ">
                      {faq.answer}
                    </p>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Faqs;
