import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";
import bg from "../../images/red-special-offer.png";
import PageLoader from "../../Loader/PageLoader";
import siteConfig from "../../urlFile";
import axios from "axios";
// import { useDispatch } from 'react-redux';
// import { updateCartCount } from '../../../utils/actions';

const MedicineCard = () => {
  // const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [medicineData, setMedicineData] = useState([]);
  const [counter, setCounter] = useState({});
  const [existItem, setExistItem] = useState({});
  const [addedToCartItems, setAddedToCartItems] = useState([]);
  const [showAddedToCartMessage, setShowAddedToCartMessage] = useState(false);

  const handleRoute = (path, medicineDetails) => {
    setLoading(true);
    setTimeout(() => {
      navigate(path, { state: { medicineDetails } });
    }, 1000);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${siteConfig.BASE_URL}${siteConfig.GET_ALL_MEDICINE}`
        );

        // Sort the medicine data based on the discount
        const sortedMedicineData = response.data.data.sort((a, b) => {
          return parseFloat(b.discount) - parseFloat(a.discount);
        });

        // Select the top 20 items
        const top20MedicineData = sortedMedicineData.slice(0, 20);

        setMedicineData(top20MedicineData);
        initializeCounter(top20MedicineData);

        console.log("Fetched Top 20 Medical Specialties:", top20MedicineData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const initializeCounter = (data) => {
    const initialCounter = {};
    data.forEach((item) => {
      initialCounter[item.id] = 0;
    });
    setCounter(initialCounter);
  };

  const handleAddToCart = async (medicineId) => {
    setLoading(true);

    try {
      const response = await axios.post(
        `${siteConfig.BASE_URL}${siteConfig.ADD_TO_CART}`,
        {
          id: localStorage.getItem("userId"),
          productid: medicineId,
          quantity: 1,
        }
      );

      if (response.data.msg === "Product is already exist") {
        setExistItem((prevState) => ({ ...prevState, [medicineId]: true }));
        setTimeout(() => {
          setExistItem((prevState) => ({ ...prevState, [medicineId]: false }));
        }, 2000);
      } else {
        setAddedToCartItems((prevItems) => [...prevItems, medicineId]);
        setShowAddedToCartMessage((prevState) => ({
          ...prevState,
          [medicineId]: true,
        }));
        setTimeout(() => {
          setShowAddedToCartMessage((prevState) => ({
            ...prevState,
            [medicineId]: false,
          }));
        }, 2000);
      }
    } catch (error) {
      console.error("Error adding item to cart:", error);
      alert(
        "An error occurred while adding the item to the cart. Please try again later."
      );
    } finally {
      setLoading(false);
    }
  };

  var settings = {
    infinite: false,
    dots: false,
    speed: 500,
    slidesToShow: 5.5,
    slidesToScroll: 4,
    autoplay: false,
    speed: 1800,

    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 4.4,
          slidesToScroll: 3,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3.5,
          slidesToScroll: 3,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2.9,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2.8,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2.8,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 760,
        settings: {
          slidesToShow: 2.2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 639,
        settings: {
          slidesToShow: 2.4,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 570,
        settings: {
          slidesToShow: 2.1,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 490,
        settings: {
          slidesToShow: 1.8,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 1.6,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1.4,
          slidesToScroll: 2,
        },
      },
    ],
  };
  const getMedicineImageUrl = (imageName) =>
    `https://app.healthcrad.com/api/uploads/medicine/${imageName}`;
  return (
    <>
      <div
        className="pb-4 mt-14 relative"
        style={{
          backgroundImage: `linear-gradient(98.41deg, rgba(216, 237, 242, 0.32) 0.02%, rgba(31, 212, 255, 0.32) 99.91%)`,
        }}
      >
        {loading && <PageLoader />}

        <div
          className="absolute bottom-0 sm:right-56 hidden sm:block -z-10 w-[400px] h-[468px] lg:h-[487px]  top-0 bg-no-repeat"
          style={{ backgroundImage: `url(${bg})` }}
        ></div>
        <div className="overflow-x-hidden gap-2.5 z-0 xl:gap-10 space-x-2.5 sm-mt-0 -mt-3">
          <div className="flex  sm:justify-between items-center p-3 ml-6 sm:ml-0 sm:p-8">
            <div className=" flex flex-col ">
              <p className="self-start  text-[#2F2D2D] text-[18px] sm:text-[16px] lg:text-[24px] xl:text-[30px] font-semibold font-lato  -ml-2 xl:ml-2 sm:ml-0 ">
                Limited Time Deals
              </p>
              <span className=" -ml-2 xl:ml-2 sm:ml-0  text-[#666161] text-[11px] sm:text-[13px] md:text-[16px] lg:text-[20px] xl:text-[15px] font-medium font-lato ">
                Garb the deals!
              </span>
            </div>
            <p className="self-end text-[#666161] sm:text-md  hidden sm:block ">
              Best Sellers
            </p>
          </div>
          {medicineData.length > 0 && (
            <Slider {...settings}>
              {medicineData.map((medicineDetails) => (
                <div
                  key={medicineDetails.id}
                  className="flex justify-center relative"
                >
                  {showAddedToCartMessage[medicineDetails.id] && (
                    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50">
                      <div className="bg-green-500 text-white p-2 rounded">
                        <p className=" w-44 sm:w-60 text-center">
                          Item added to the cart!
                        </p>
                      </div>
                    </div>
                  )}
                  {existItem[medicineDetails.id] && (
                    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50">
                      <div className="bg-green-500 text-white p-2 rounded">
                        <p className=" w-44 sm:w-60 text-center">
                          Item is already added in the cart!
                        </p>
                      </div>
                    </div>
                  )}
                  <div
                    className=" sm:w-[230px] sm:h-[302px] mb-4 p-2 w-[205px] h-[245px] rounded-[10px] border-[1px] bg-[#FFF]"
                    style={{
                      boxShadow: "0 4px 8px rgba(0,0,0,.12)",
                    }}
                  >
                    <div
                      className="cursor-pointer flex justify-end"
                      onClick={() =>
                        handleRoute(
                          "/medicine/medicineDescription",
                          medicineDetails
                        )
                      }
                    >
                      <div className="bg-[#008000] z-10 p-1 rounded-bl-[8px] rounded-br-[8px] mr-[4px] sm:mr-[8px] -mt-[9px] flex justify-center items-center w-auto sm:w-14">
                        <p className="text-[#FFF]  font-bold sm:text-lg text-sm font-poppins text-center">
                          {" "}
                          {medicineDetails.discount}%
                          <br />
                          OFF{" "}
                        </p>
                      </div>
                    </div>
                    <div
                      className="cursor-pointer flex flex-col gap-1 p-2 -mt-6 justify-center items-center"
                      onClick={() =>
                        handleRoute(
                          "/medicine/medicineDescription",
                          medicineDetails
                        )
                      }
                    >
                      <img
                        className="sm:w-[105px] sm:h-[105px] w-[80px] h-[80px]"
                        src={getMedicineImageUrl(medicineDetails.image[0])}
                        // src={medicineDetails.image}
                        alt=""
                      />
                      <p className="text-xs sm:text-sm font-poppins text-[#000] font-medium overflow-hidden line-clamp-1 ">
                        {medicineDetails.name}
                      </p>
                    </div>
                    <div className="underline-offset-8 w-full border-[1px] color-[#0000002E] center-alignment"></div>
                    <div className="  pt-2 text-[#0000008A] text-sm sm:text-[14px] flex font-poppins">
                      {" "}
                      <span className="font-bold mr-[2px]">Rs.</span>
                      <del className="">{medicineDetails.price}</del>
                    </div>

                    {medicineDetails.prescription === "1" && (
                      <div className="absolute ml-[93px]  sm:ml-[188px] -mt-3 ">
                        <p className="text-[15px] font-bold rounded-[5px] w-7 text-center border-[1px] border-[#10CAF2] text-[#10CAF2]">
                          Rx
                        </p>
                      </div>
                    )}
                    <div className=" pt-0.5 text-[#000] font-bold text-sm sm:text-[17px] font-poppins">
                      <p className="">
                        {" "}
                        <span className="mr-[2px]">Rs.</span>
                        {medicineDetails.discounted_amount}
                      </p>
                    </div>
                    <div className="flex justify-center items-center mt-1.5 sm:mt-0 sm:pt-2.5 pb-1">
                      <div
                        className={`w-full relative ${
                          addedToCartItems.includes(medicineDetails.id)
                            ? "bg-[#008000]"
                            : "bg-[#10CAF2]"
                        } h-8 sm:h-10 rounded-[6px] cursor-pointer flex justify-center items-center`}
                      >
                        {addedToCartItems.includes(medicineDetails.id) ? (
                          <button
                            className="text-sm sm:text-lg text-white font-bold"
                            onClick={() => navigate("/cart")} // Go to cart
                          >
                            Go to cart
                          </button>
                        ) : (
                          <button
                            className="text-sm sm:text-lg text-white font-bold"
                            onClick={() => handleAddToCart(medicineDetails.id)}
                          >
                            Add to cart
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          )}
        </div>

        <div className="flex  justify-center w-full mt-2">
          <button
            className="text-center h-[30px] w-[70px] z-0 rounded-[5px] border-[1px] border-[#10CAF2] text-[#10CAF2] bg-[#CCF3FC]  text-md"
            onClick={() => handleRoute("/allmedicines")}
          >
            {" "}
            More
          </button>
        </div>
      </div>
    </>
  );
};

export default MedicineCard;
