import React, { useState } from "react";
import MedicalOrdiancePoint from "../../../images/frontend UI materials/MedicalStore.jpeg";
import MedicalStore from "../../../images/frontend UI materials/medicine_store.jpg";
import Chevronright from "../../../images/frontend UI materials/chevron-right.png";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import PageLoader from "../../../Loader/PageLoader";

const Store = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleRoute = (path) => {
    setLoading(true);
    setTimeout(() => {
      navigate(path);
    }, 1000);
  };
  return (
    <>
      <div className="w-full">
        {loading && <PageLoader />}
        <div className="pt-[20px] xl:pt-10 font-poppins">
          {/* Heading and SubHeading */}
          <div className="">
            <h1 className=" xs:hidden text-[#2F2D2D] text-[14px] sm:text-[24px]  font-semibold font-lato pl-[9px] xs:pl-[13px] sm:pl-[28px] md:pl-[45px] lg:pl-[50px] xl:pl-[20px] 2xl:pl-[53px] mb-[15px] sm:-mb-[20px]">
              Pharmacy outlets and Ordinance points
            </h1>
            <h1 className="hidden xs:block text-[#2F2D2D] text-[15px] sm:text-[24px]  font-semibold font-lato pl-[8px] sm:pl-[28px] md:pl-[45px] lg:pl-[50px] xl:pl-[20px] 2xl:pl-[53px] mb-[15px] sm:-mb-[20px]">
              HealthCRAD Pharmacy outlets and Ordinance points
            </h1>
          </div>
          {/* Parent Div Card */}
          <div className=" w-full grid grid-cols-2  xs:gap-6 sm:gap-2 xl:gap-[155px] 2xl:gap-[275px]  sm:m-0 justify-items-center">
            {/* Card-1 */}
            <div className="sm:mt-[36px] w-[145px] xs:w-[164px] sm:w-[242px] md:w-[287px] lg:w-[390px] xl:w-[512px]  h-auto">
              <img
                src={MedicalStore}
                alt=""
                className="w-[145px] xs:w-[164px] h-[119px] sm:w-[242px] sm:h-[167px] md:w-[287px] md:h-[218px] lg:h-[300px] lg:w-[390px]  xl:w-[512px] xl:h-[330px] rounded-[10px]"
              />
              <h2 className="font-medium font-lato text-[13px] xs:text-[14px] sm:text-[16px] lg:text-[27px] xl:text-[24px] text-[#000] pt-[20px] pb-[8px]">
                HealthCRAD Pharmacy Outlets in BIHAR
              </h2>
              <p className="text-[#414146] text-[8px] xs:text-[11px] sm:text-[11px] md:text-[12px] lg:text-[20px] xl:text-[15px] font-normal pb-[4px]">
                Become HealthCRAD dedicated Pharmacy outlet Partner and increase
                your sales
              </p>
              <div
                className="flex gap-2.5 mt-[13px] lg:mt-[25px]"
                onClick={() => handleRoute("/pharmacy-outlets")}
              >
                <button className="text-[#10CAF2] font-normal text-[11px] sm:text-[11px] md:text-[12px] lg:text-[20px] xl:text-[15px]">
                  Check out
                </button>
                <img
                  src={Chevronright}
                  alt=""
                  className="mt-[4px] h-[8px] w-[6px]  sm:mt-[4px] sm:h-[9px] sm:w-[7px] lg:mt-[9px] lg:w-[9px] lg:h-[14px] xl:mt-[5px] xl:w-[8px] xl:h-[14px] "
                />
              </div>
              <Link to="https://wa.me/message/532QZXEFUYFWK1" target="/_blank">
                <button className="mt-[11px] xs:mt-[13px] sm:mt-[15px] rounded-[6px] sm:rounded-[10px] text-[#10CAF2] border-[1px] border-[#10CAF2]  h-[31px] w-[148px] xs:w-[164px] sm:h-[55px] sm:w-[243px]  md:w-[309px]  lg:w-[390px] xl:w-[512px] font-normal text-[10px] xs:text-[11px] sm:text-[13px] md:text-[13px] lg:text-[18px]">
                  Franchises Enquiry
                </button>
              </Link>
            </div>
            {/* Card-2 */}
            <div className="sm:mt-[36px] w-[145px] xs:w-[164px] sm:w-[242px]  md:w-[287px]  lg:w-[390px] xl:w-[512px]  h-auto">
              <img
                src={MedicalOrdiancePoint}
                alt=""
                className="w-[145px] xs:w-[164px] h-[119px] sm:w-[242px] sm:h-[167px] md:w-[287px] md:h-[218px] lg:h-[300px] lg:w-[390px] xl:w-[512px] xl:h-[330px] rounded-[10px]"
              />
              <h2 className="font-medium font-lato text-[13px] xs:text-[14px] sm:text-[16px] lg:text-[28px] xl:text-[24px] text-[#000] pt-[20px] pb-[8px]">
                HealthCRAD Ordinance Points in BIHAR
              </h2>
              <p className="text-[#414146] text-[8px] xs:text-[11px] sm:text-[11px] md:text-[12px] lg:text-[20px] xl:text-[15px] font-normal pb-[4px]">
                Join HealthCRAD Ordinance Points Chain and become a dedicated
                partner in creating rural healthcare ecosystem
              </p>
              <div
                className="flex gap-2.5 mb-[13px] xs:mt-[14px] xl:mb-[15px] xl:mt-[25px]"
                onClick={() => handleRoute("/ordinance-points")}
              >
                <button className="  text-[#10CAF2] font-normal text-[11px] sm:text-[11px] md:text-[12px] lg:text-[20px] xl:text-[15px]">
                  Check out
                </button>
                <img
                  src={Chevronright}
                  alt=""
                  className="mt-[4px] h-[8px] w-[6px]  sm:mt-[4px] sm:h-[9px] sm:w-[7px] lg:mt-[9px] lg:w-[9px] lg:h-[14px] xl:mt-[5px] xl:w-[8px] xl:h-[14px] "
                />
              </div>
              <Link to="https://wa.me/message/532QZXEFUYFWK1" target="/_blank">
                <button className="text-[#10CAF2] border-[1px] border-[#10CAF2] rounded-[6px] sm:rounded-[10px] h-[31px] w-[148px] xs:w-[164px] sm:h-[55px] sm:w-[243px] md:w-[309px] lg:w-[390px] xl:w-[512px] font-normal text-[10px] xs:text-[11px] sm:text-[13px]  md:text-[13px] lg:text-[18px]">
                  Order medicine as a retailer
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Store;
