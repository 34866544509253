import React, { useState, useEffect, useRef } from "react";
import { Search, Close } from "@mui/icons-material";
import SearchIcon from "../images/NavbarProfileIcon/search.png";
import axios from "axios";
import siteConfig from "../urlFile";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

const SearchBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const slideBarRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (searchTerm === "") {
      setSuggestions([]);
      setShowSuggestions(false);
      return;
    }

    const fetchSuggestions = async () => {
      try {
        const responseMedicine = await axios.get(
          `${siteConfig.BASE_URL}${siteConfig.GET_ALL_MEDICINE}`
        );
        const medicines = responseMedicine.data.data.map(
          (medicine) => medicine.name
        );

        const responseDoctor = await axios.get(
          `${siteConfig.BASE_URL}${siteConfig.GET_DOCTOR}`
        );
        const doctors = responseDoctor.data.data.map((doctor) => doctor.dname);
        const doctorsAdd = responseDoctor.data.data.map(
          (doctor) => doctor.city
        );
        const doctorsdept = responseDoctor.data.data.map((doctor) => {
          const departments = doctor.department.split(",");
          const department = departments.slice(1).join(",").trim();
          return department;
        });

        const allSuggestions = [
          ...medicines,
          ...doctors,
          ...doctorsAdd,
          ...doctorsdept,
        ];
        const filteredSuggestions = allSuggestions
          .filter((suggestion) =>
            suggestion.toLowerCase().includes(searchTerm.toLowerCase())
          )
          .map((suggestion) => suggestion.split(" ").slice(0, 3).join(" "))
          .slice(0, 10); // Limit suggestions to a maximum of 10 and show only first two words

        setSuggestions(filteredSuggestions);
        setShowSuggestions(filteredSuggestions.length > 0);
      } catch (error) {
        console.error("Error fetching suggestions:", error);
      }
    };

    fetchSuggestions();
  }, [searchTerm]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
  };

  const handleSuggestionClick = (suggestion) => {
    setSearchTerm(suggestion);
    setShowSuggestions(false);
    // navigate(`/allitems?search=${encodeURIComponent(suggestion)}`);
    if (searchTerm.trim() !== "") {
      navigate(`/allitems?search=${encodeURIComponent(searchTerm)}`);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (slideBarRef.current && !slideBarRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative z-10 ">
      <button
        className="fixed top-0 mr-[10px] p-2 mt-[2px]"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="flex items-center justify-center bg-white rounded-full h-[36px] w-[36px] shadow-sm ">
          <img className="h-5 w-5" src={SearchIcon} alt="Search Icon" />
        </div>
      </button>
      {isOpen && (
        <div
          className="fixed top-0 right-0 w-full bg-[#00B4D8] transition-all duration-300 "
          ref={slideBarRef}
        >
          <div className="p-2 relative">
            <div className="relative">
              <input
                type="text"
                placeholder="Search..."
                // value={searchValue}
                // onChange={(e) => setSearchValue(e.target.value)}
                className="w-full px-12 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-200"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <div className="absolute top-10 border-[1px] w-full  rounded-[5px] bg-[#fffdfd] text-[black] z-10">
                {suggestions.map((suggestion, index) => (
                  <div
                    key={index}
                    className="py-2 px-4 cursor-pointer hover:bg-gray-100"
                    onClick={() => handleSuggestionClick(suggestion)}
                  >
                    <div className="flex gap-4 items-center">
                      <FontAwesomeIcon icon={faSearch} className="h-5 w-5" />
                      <p className="overflow-hidden line-clamp-1 font-lato font-medium ">
                        {suggestion}
                      </p>
                    </div>
                    {/* <hr className="ml-10" /> */}
                  </div>
                ))}

                {!showSuggestions && searchTerm && (
                  <p className="text-[#FF0000] p-2 text-center font-lato xs:text-[13px] sm:text-[16px] font-medium ">
                    Your Product is not listed, kindly{" "}
                    <span className=" text-[#0924B0D9] cursor-pointer">
                      {" "}
                      <a
                        className="text-[#0924B0D9] underline"
                        href="tel:+917779888289"
                      >
                        Call us
                      </a>
                    </span>{" "}
                    or
                    <span className=" text-[#3db53d] cursor-pointer mr-1">
                      {" "}
                      <a
                        className="text-[#3db53d] underline "
                        target="_blank"
                        href="https://api.whatsapp.com/message/532QZXEFUYFWK1?autoload=1&app_absent=0"
                      >
                        Whatsapp
                      </a>
                    </span>
                    directly.
                  </p>
                )}
              </div>
              {/* Inside the search box */}
              <Search
                className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 cursor-pointer"
                onClick={() => setSearchValue("")} // Clear search input value when search icon is clicked
              />
              <button
                className="absolute top-2 right-3 focus:outline-none"
                onClick={() => setIsOpen(false)} // Close the slide bar when close icon is clicked
              >
                <Close />
              </button>
            </div>
          </div>
          {/* Additional content inside slide bar */}
        </div>
      )}
    </div>
  );
};

export default SearchBar;
