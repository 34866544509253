import React, { useEffect, useState } from "react";
import Header from "../../Header";
import { useNavigate } from "react-router-dom";
import PageLoader from "../../../../Loader/PageLoader";
import axios from "axios";
import siteCofig from "../../../../urlFile";
import EmptyOrderHistory from "./EmptyOrderHistory";

const OrderHistory = () => {
  const [loading, setLoading] = useState(false);
  const [historyOrder, setHistoryOrder] = useState([]);
  const navigate = useNavigate();
  // const handleRoute = (path) => {
  //   navigate(path);
  // };
  const handleRoute = (path) => {
    setLoading(true);
    setTimeout(() => {
      navigate(path);
    }, 1000);
  };

  const user_id = localStorage.getItem("userId");
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${siteCofig.CHECKOUT_URL}${siteCofig.ORDER_DELIVERED}${user_id}`
        );
        if (response.data.msg === "success" && response.data.error === "200") {
          setHistoryOrder(response.data.country);
          console.log(response.data.country);
        } else {
          console.error("Error fetching orders:", response.data.msg);
        }
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };

    if (user_id) {
      fetchData();
    }
  }, [user_id]);

  const getMedicineImageUrl = (imageName) =>
    `https://app.healthcrad.com/api/uploads/medicine/${imageName}`;
  const getImageName = (imageString) => {
    const cleanedString = imageString.substring(2, imageString.length - 2);
    return cleanedString;
  };

  return (
    <>
      <Header />
      {loading && <PageLoader />}
      <div className="flex flex-wrap font-poppins pt-20 lg:mx-8 justify-between mb-5">
        <div
          className="ml-2 mb-2 group bg-[#FFF] rounded-[10px] flex justify-center items-center hover:bg-[#00B4D8] border-[1px] border-[#979797] xl:w-[230px] xl:h-[50px] md:w-[230px] md:h-[50px] w-[100px] h-35"
          onClick={() => handleRoute("/myorders")}
        >
          <button className="xl:text-lg sm:text-base md:text-lg font-poppins text-[#979797] px-2 py-2 rounded-md group-hover:text-white">
            In Process Orders
          </button>
        </div>
        <div
          className="mb-2 group bg-[#FFF] rounded-[10px] flex justify-center items-center hover:bg-[#00B4D8] border-[1px] border-[#979797] xl:w-[230px] xl:h-[50px] md:w-[230px] md:h-[50px] w-[100px] h-35"
          onClick={() => handleRoute("/myorders/prescriptionOrder")}
        >
          <button className="xl:text-lg sm:text-base md:text-lg font-poppins text-[#979797] px-2 py-2 rounded-md group-hover:text-white">
            Prescription Orders
          </button>
        </div>
        <div className="mr-2 mb-2 group bg-[#FFF] rounded-[10px] flex justify-center items-center hover:bg-[#00B4D8] border-[1px] border-[#00B4D8] xl:w-[230px] xl:h-[50px] md:w-[230px] md:h-[50px] w-20 h-35">
          <button className="xl:text-lg sm:text-base md:text-lg font-poppins text-[#00B4D8] px-2 py-2 rounded-md group-hover:text-white">
            History
          </button>
        </div>
      </div>

      <div className="flex flex-col gap-6 lg:mx-16 p-2 pb-3">
        {historyOrder.length > 0 ? (
          historyOrder.map((item) => (
            <div
              key={item.id}
              className="flex justify-between shadow-md border-[1px] border-[rgba(0, 0, 0, 0.20)] p-3 bg-[#FFF] rounded-[10px]"
            >
              <div className="flex">
                <img
                  src={getMedicineImageUrl(getImageName(item.image))}
                  alt="Order"
                  className="w-16 h-16 md:w-[100px] md:h-[100px]"
                />

                <p className="text-[#4A4141] font-poppins text-xs md:text-lg xl:text-lg">
                  {item.quantity} Item <br />
                  On {item.date_time} <br />
                  Order Status:
                  {/* <span className="text-[#07A50D]"> Delivered</span> <br /> */}
                  {item.status === "1" && (
                    <span className="text-[#07A50D]"> Delivered</span>
                  )}
                  <br />
                  <button
                    className="text-[#00B4D8]  hover:bg-[#00B4D8] hover:text-[#FFF] font-poppins text-xs md:text-lg xl:text-lg p-1 lg:w-32 mt-2 sm:rounded-[10px] rounded-[5px] border-[1px] border-[#00B4D8]"
                    onClick={() =>
                      handleRoute(
                        `/myorders/getDetails?groupid=${item.groupid}`
                      )
                    }
                  >
                    Get Details
                  </button>{" "}
                </p>
              </div>

              <div className=" items-center  w-28 sm:w-40 flex flex-col justify-between  ">
                <h2 className="text-md self-end font-semibold text-[#1A30A1] font-poppins text-xs md:text-lg xl:text-lg mr-[24px]">
                  Rs. {item.totalamount} |{" "}
                  <span className="text-[#07A50D]">{item.paymode}</span>
                </h2>
                <button
                  className={`text-[#00B4D8] font-poppins text-[17px] p-1 lg:w-32 mt-2 sm:rounded-[10px] self-center rounded-[5px] border-[1px] text-xs md:text-lg xl:text-lg ${
                    item.url
                      ? "hover:bg-[#00B4D8] hover:text-[#FFF] border-[#00B4D8]"
                      : "bg-gray-300 text-gray-500 cursor-not-allowed border-gray-400"
                  }`}
                  onClick={() => {
                    if (item.url) window.open(item.url, "_blank");
                  }}
                  disabled={!item.url}
                >
                  Track Order
                </button>
              </div>
            </div>
          ))
        ) : (
          <EmptyOrderHistory />
        )}
      </div>
    </>
  );
};

export default OrderHistory;
