import React, { useState, useRef, useEffect } from "react";
import EmergencyIcon from "../../images/Emergency/EmergencyIcon.jfif";
import alertSound from "../../images/Emergency/Ambulance Alarm.wav";

const Emergency = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const alertAudioRef = useRef(null);

  // Disable scrolling when the popup is open
  useEffect(() => {
    if (isPopupOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isPopupOpen]);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
    if (!isPopupOpen) {
      playAlertSound(); // Play sound when opening the popup
    } else {
      stopAlertSound(); // Stop sound when closing the popup
    }
  };

  const handleNoButtonClick = () => {
    setIsPopupOpen(false);
    stopAlertSound(); // Stop sound when closing the popup
  };

  const playAlertSound = () => {
    if (alertAudioRef.current) {
      alertAudioRef.current.play();
    }
  };

  const stopAlertSound = () => {
    if (alertAudioRef.current) {
      alertAudioRef.current.pause();
      alertAudioRef.current.currentTime = 0; // Reset audio to the beginning
    }
  };

  const handleYesButtonClick = () => {
    // Redirect to the phone call URL
    window.location.href = "tel:+917779888289"; // Replace "911" with the emergency phone number you want to call
  };

  return (
    <div className="fixed bottom-4 right-4">
      <div>
        <img
          src={EmergencyIcon}
          alt=""
          className="w-[51px] h-[50px] sm:w-[70px] sm:h-[70px] rounded-full cursor-pointer shadow-md"
          onClick={togglePopup}
        />
      </div>
      {isPopupOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-8 rounded-lg shadow-lg transform transition-all duration-300">
            {/* Popup content */}
            <div className="flex justify-center">
              <img
                src={EmergencyIcon}
                alt=""
                className="h-[70px] w-[70px] rounded-full my-1 shadow-sm "
              />
            </div>
            <p className="text-[#FE0505] text-center text-[24px] font-lato">
              Are you in emergency?
            </p>
            <div className="flex justify-center mt-4">
              <button
                className="mr-4 w-[135px] h-[57px] px-4 py-2 bg-[#20CD27] font-lato text-white text-[24px] rounded-[8px]"
                onClick={handleYesButtonClick}
              >
                Yes
              </button>
              <button
                className="px-4 py-2 w-[135px] h-[57px] bg-[#FE0505] font-lato text-white text-[24px] rounded-[8px] "
                onClick={handleNoButtonClick}
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
      <audio ref={alertAudioRef} src={alertSound} />
    </div>
  );
};

export default Emergency;
