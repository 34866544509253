import React from "react";
import Header from "../../Header";

const PrivacyPolicy = () => {
  return (
    <>
      <Header />
      <div className=" flex flex-col justify-center items-center pt-28 mb-20 gap-10 cursor-default">
        <div className="flex justify-center items-center ">
          <p className="font-poppins text-[#00B4D8]  font-normal text-3xl">
            Privacy and Policy
          </p>
        </div>
        <div className="flex justify-center items-center ">
          <div className="bg-[#FFF] sm:w-[90%] w-[100%] h-[80%]  rounded-[10px] border-[1px] shadow-md sm:p-0 p-2 sm:ml-0 ml-1 ">
            <p className="font-poppins text-sm p-4 sm:p-0">
              <p className="font-poppins text-xl sm:text-2xl">
                HealthCRAD Privacy Policy
              </p>
              <br />
              This Privacy Policy governs (we,“HealthCRAD” ,“Company”, “we”,
              “us” or "our”) collect, use, share and process your information,
              that you provide to us through your use of the app HealthCRAD and
              Website https://www.healthcrad.com in the course of providing
              services(“Services”) as defined in the Terms and Conditions terms
              to you. <br />
              HealthCRAD website and app (“Website” / “App” respectively),
              respects your privacy, and seeks to comply with applicable legal
              requirements in respect of data collection, processing and
              transfer. Please read this Privacy Policy carefully. By accessing
              or using this website / app, you agree to be bound by the terms
              described herein and all the terms incorporated by reference. If
              you do not agree to all these terms, do not use this website /
              app. <br /> <br />
              <p className="font-poppins text-2xl">
                1.What is Personal Information?
              </p>
              <br />
              Personal information is that information which can be used to
              directly or indirectly identify you. It includes de-identified
              data that, when linked to other information available to us, would
              enable us to identify you. Personal data does not include data
              that has been irreversibly anonymized or aggregated so that we
              cannot identify you through it, even in conjugation conjunction
              with other information.
              <br />
              “Sensitive Personal Data or Information” means Personal
              Information of any individual relating to password; financial
              information such as bank account or credit card or debit card or
              other payment instrument details; physical, physiological and
              mental health condition; sexual orientation; medical records and
              history; biometric information; any detail relating to the above
              as provided to or received by HealthCRAD for processing or
              storage. However, any data/ information relating to an individual
              that is freely available or accessible in public domain or
              furnished under the Right to Information Act, 2005 or any other
              law shall not qualify as Sensitive Personal Data or Information.
              By clicking "I accept" when downloading the App or proceeding to
              the Website, and/or using our services you represent that you
              voluntarily provide us with personal information including medical
              and financial information and consent to their collection, use and
              disclosure in accordance with this Privacy Policy. You also
              represent that you are duly authorised by any third party
              (including a child or an employer) whose information you share
              with us. We shall act as per your representation of authority and
              shall not make any independent enquiries to ascertain the veracity
              of your authorisation. In the event you do not have sufficient
              authorisation you shall be solely responsible for your acts and
              omissions including sharing of information with us by you and the
              consequential processing and actions taken by us in accordance
              with this Privacy Policy. <br /> <br />
              <p className="font-poppins text-2xl">
                2.What types of data do we collect?
              </p>
              <br />
              HealthCRAD, like many other websites uses “Cookies”. Cookies are
              small data files that a website stores on your web browser. These
              are used for the purpose of storing your preferences, previous
              activities browsing activities, profiling and tracking behaviour
              on this Website. By visiting HealthCRAD, you acknowledge, accept,
              and expressly authorize the placement of cookies on your web
              browser. We recommend that you clear the cookies stored on your
              browser from time to time. When you sign up or register on the
              HealthCRAD App or Website to use our Services, and during the
              course of actual usage of our Services, the types of information
              that will be collected by us include the following: <br />
              Contact information: Name, Address, Contact details, Email ID,
              Phone Number, 3 emergency contacts; <br />
              Demographic information: Gender, Age, Date of Birth; <br />
              Data regarding your usage of the services such as search history
              and history of the appointments made by you through the use of
              Services; Any other detail relating to the above as voluntarily
              provided to HealthCRAD by you, for providing value added service;
              <br />
              Medical records and history which you voluntarily provide or is
              generated on usage of any of the services availed by you from any
              entity in the HealthCRAD; Physical, physiological and mental
              health condition which you voluntarily provide or is generated on
              usage of our services through the app/website; <br />
              Data regarding your medical qualifications, registrations and
              certifications; Any additional information that you voluntarily
              choose to provide to HealthCRAD through any mode of communication
              or during any interaction. <br /> <br />
              <p className="font-poppins text-2xl">
                3.Where do we collect your data from?
              </p>
              <br />
              <p className="font-poppins text-2xl">For end users:</p>
              <br />
              Any information that you voluntarily choose to provide to us
              through app, website, email, during interaction with us on call or
              chat and other modes of communication; Information that we collect
              from healthcare service providers such as doctors, hospitals,
              diagnostic centres, chemists, ambulance providers etc. in the
              HealthCRAD, to whom you have permitted the sharing of your
              personal information;
              <br />
              Data you have provided to any group company of the Company,
              affiliates, associates, subsidiary, holding company of the
              Company, associates and subsidiaries of holding company of the
              Company, to whom you have given consent for sharing of such
              information. During your usage of the App / Website, we may
              collect information based on your use of the Services;
              <br />
              We may collect other information that you voluntarily choose to
              provide to us through app, website, email, during interaction with
              us on call or chat and other modes of communication; We may
              collect information from any group company, affiliates,
              associates, subsidiary, holding company of the Company, associates
              and subsidiaries of holding company of the Company to whom you
              have given consent for sharing of information for availing value
              added service. <br /> <br />
              <p className="font-poppins text-2xl">
                4.How do we use your data?{" "}
              </p>
              <br />
              We use your Personal Information for purposes that include the
              following:
              <br />
              General (end users and doctors): <br />
              Your registration for the purpose of receiving our Services,
              identification, communication, notification and for fulfilment of
              the Terms and Conditions [Insert link of terms and conditions];
              Analysis, research, training and disclosure (where required) to
              its affiliates, agents and government authorities. <br />
              Offering you personalized Services and targeted advertisements of
              various healthcare and wellness plans and offering you customised
              health insights; Improvement of our products and services;
              Research and analysis for the development of commercial solutions;{" "}
              <br />
              Addressing your requests, queries and complaints, in any,
              pertaining to our Services; other customer care related
              activities; Investigating, enforcing, and resolving disputes; For
              the purpose of contacting you to provide you with information on
              new Services and offers, taking feedback, assisting you with
              completion of transactions or other issues relating to the use of
              Services; To send notices, communications, alerts, new offers
              relevant to use of the Services offered by HealthCRAD on
              Website/app; Carrying out our obligations in relation to any
              agreement with our business partners
              <br />
              or contractors; Technical administration and customization of
              Website, and other general administrative and business purposes;
              To send you information about special promotions or offers (either
              offered by HealthCRAD or by its business partners), new features
              or products both of the HealthCRAD as well as third-party offers
              or products with whom HealthCRAD has a tie-up; <br /> <br />
              <p className="font-poppins text-2xl">For end users only: </p>
              <br />
              Creation and maintenance of health records in electronic form in
              the Personal Health Record (PHR) database; For sharing with your
              chosen HSP in the HealthCRAD like doctors, hospitals, diagnostic
              centres, chemists, ambulance providers who may provide you
              services under the HealthCRAD Platform. Processing any orders you
              may place using our Services; <br /> <br />
              <p className="font-poppins text-2xl">
                5.How long will we retain your data?
              </p>
              <br />
              We store your personal information in accordance with applicable
              laws, which means we keep your data for as long as necessary to
              provide you with our Services or as may be required under any law.
              We shall store your personal information for lawful purposes only.
              We keep de-identified data for research and statistical purposes
              for a longer period.
              <br />
              If you close your account, we have no obligation to retain your
              data, and we may delete any or all of your data without liability.
              However, we may retain data related to you if we believe it may be
              necessary to prevent fraud or future abuse, or if required by law,
              or for other legitimate purposes. We may continue to store your
              data in anonymised form for analytical and research purposes.
              <br /> <br />
              <p className="font-poppins text-2xl">
                6.Disclosure and transfer of your data
              </p>
              <br />
              We may disclose and in some cases transfer your personal
              information to such entities as required to provide services to
              you and to provide value added services or other third party
              products and services, to the extent permitted by applicable law.
              These entities may be located outside India, which you hereby
              consent to. We require such entities to protect your information
              through equivalent security measures as what we would adopt. An
              indicative list of entities we may disclose or transfer
              information to, are provided below: <br />
              Service Providers: We share personal information with companies
              that provide Services on our behalf, such as website hosting, data
              storage, software services, email services, marketing, fulfilling
              customer orders, providing payment services, data analytics, data
              mining, providing customer services, and conducting surveys. These
              companies may be located within or outside India, but in any case,
              are obligated to protect your data. We may also share information
              with employees, data processors, consultants, business partners
              and technology partners on a need-to-know basis. Such entities
              would be contractually obligated to maintain confidentiality in
              relation to your data.
              <br />
              If you are an end user, your personal information will also be
              shared with your chosen HSPs. Business Affiliates: We may disclose
              or transfer some of your information to entities in the
              HealthCRAD, affiliates, associates, subsidiary, holding company of
              the Company, associates, and subsidiary of holding company of the
              Company including foreign entities, and in particular group
              companies and affiliates who are involved in the provision of
              products and services, to the extent permitted by applicable law.
              <br />
              In the event of a merger, reorganization, acquisition, joint
              venture, assignment, spin-off, transfer, asset sale, or sale or
              disposition of all or any portion of our business, including in
              connection with any bankruptcy or similar proceedings, we may
              transfer all personal information to the relevant third party with
              the same rights of access and use. <br />
              Law Enforcement Agencies: We may share information with law
              enforcement agencies pursuant to lawful requests for information,
              and otherwise as required under any law applicable at the given
              time, both in India and outside India. <br /> <br />
              <p className="font-poppins text-2xl">Other Third Parties: </p>
              <br />
              We may also disclose personal information if we determine in good
              faith that disclosure is reasonably necessary to protect our
              rights and pursue available remedies, enforce our terms and
              conditions, investigate fraud, or protect our operations or users.
              Anonymised, aggregated data that cannot re-identify you, may be
              shared with advertisers, research firms and other partners. <br />{" "}
              <br />
              <p className="font-poppins text-2xl">
                7.How do we protect your data?
              </p>
              <br />
              We are committed towards maintaining the privacy of the
              information uploaded by you on the website and complies with the
              industry standard security safeguards for the purpose of securing
              the website and the information provided/uploaded by you. <br />
              We use reasonable technical, administrative, and physical security
              measures for the purpose of safeguarding all data you share with
              us. We also have comprehensive internal policies in place to
              prevent unauthorized access to your data. We take adequate steps
              to ensure that third parties we share data with also adopt
              reasonable level of security practices and procedures to ensure
              the privacy and security of your information. <br />
              However, we are not responsible for any loss, unauthorised access,
              safety issue or any harm caused to you by any misuse of your
              personal information, unless it is a direct and foreseeable
              consequence of negligence and non-compliance on our part only. You
              hereby acknowledge that we are not responsible, in particular, for
              any third party action or action on your part leading to loss,
              damage or harm to you or any other person. <br />
              For any data loss or theft due to unauthorized access to your
              electronic devices through which you avail our Services, Company
              shall not be held liable for any loss whatsoever incurred by you.
              Further, you are liable to indemnify the Company as per the Terms
              of Use. <br /> <br />
              <p className="font-poppins text-2xl">8.What are your rights?</p>
              <br />
              We take reasonable steps to ensure that your personal information
              is accurate, complete, and up to date. However, you have the sole
              responsibility of ensuring that you review the accuracy of
              information provided by you and contact us in case of
              discrepancies, or in case you wish to discontinue the use of our
              services. You have the following rights with regard to your
              personal information:
              <br />
              You have the right to access your personal information, and
              request correction and deletion. If your personal information
              changes, you may correct, delete inaccuracies, or amend
              information by making the change on our member information page or
              by contacting us through [Insert link of relevant email address
              for customer care]. We will make good faith efforts to make
              requested changes in our then active databases as soon as
              reasonably practicable. If you provide any information that is
              untrue, inaccurate, out of date or incomplete (or subsequently
              becomes untrue, inaccurate, out of date or incomplete), or we have
              reasonable grounds to suspect that the information provided by you
              is untrue, inaccurate, out of date or incomplete, we may, at our
              sole discretion, discontinue the provision of the Services to you.
              There may be circumstances where we will not correct, delete or
              update your Personal Information, including (a) where the Personal
              Information is opinion data that is kept solely for evaluative
              purpose; and (b) the Personal Information is in documents related
              to a prosecution if all proceedings relating to the prosecution
              have not been completed. You are free to not to share any medical
              or other information that you consider confidential and withdraw
              consent for us to use data that you have already provided. In the
              event that you refuse to share any information or withdraw consent
              to process information that you have previously given to us, we
              reserve the right to restrict or deny the provision of our
              services for which we consider such information to be necessary.{" "}
              <br />
              You may contact mailto:support@healthcrad.com for any questions or
              for exercise of these rights. <br />
              We will respond to your request within a reasonable time
              <br /> <br />
              <p className="font-poppins text-2xl">
                9.Third Party Websites and Services
              </p>
              <br />
              Our Website and App may contain links to third party services, and
              give you the ability to access such third-party websites,
              products, and services. Please note that you may proceed to the
              use of such third party website or service at your own risk and
              the Company will not be held liable for any outcome or harm
              arising as a result of your use of such third party websites or
              services. Please read the privacy policies of any third party
              before proceeding to use their websites, products, or services.{" "}
              <br /> <br />
              <p className="font-poppins text-2xl">
                10.Changes to this Privacy Policy
              </p>
              <br />
              We may periodically revise or update this Privacy Policy. Your
              continued use of our products and services after the effective
              date of the Privacy Policy means that you accept the revised
              Privacy Policy. If you do not agree with any such revised terms,
              please refrain from using our Services and contact us to close any
              account you may have created.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
