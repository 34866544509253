import React, { useState } from "react";
import Ellipse from "../../../images/Upcomming/Ellipse 9.png";
import SosIcon from "../../../images/Upcomming/SosIcon.png";
import Locator from "../../../images/Upcomming/Locator.png";
import CMS from "../../../images/Upcomming/CMS.png";
import Home from "../../../images/Upcomming/Home.png";
import CommingSoon from "../../../images/Upcomming/CommingSoon.png";
import Pin from "../../../images/Upcomming/Pin.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const UpcommingService = () => {
  var settings = {
    infinite: false,
    dots: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: false,
    speed: 1800,

    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2.9,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2.8,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2.8,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 760,
        settings: {
          slidesToShow: 2.2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 639,
        settings: {
          slidesToShow: 2.6,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 570,
        settings: {
          slidesToShow: 2.6,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 490,
        settings: {
          slidesToShow: 2.6,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 2.6,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 2.1,
          slidesToScroll: 2,
        },
      },
    ],
  };

  const onSuccess = () => {
    console.log("Successfully Swiped!");
  };

  return (
    <>
      <div className="pt-[50px]">
        <div className=" h-auto  ">
          <div>
            <h1 className="text-[#2F2D2D]  sm:pb-0 text-[18px] sm:text-[24px] font-semibold font-lato pl-[13px] sm:pl-[28px] md:pl-[37px] lg:pl-[50px] xl:pl-[20px] 2xl:pl-[53px] ">
              Upcoming...
            </h1>
          </div>

          {/* slider part */}
          <div className="mt-3 ">
            <div className="w-[100%] overflow-x-hidden">
              <Slider {...settings}>
                {/* First Card */}
                <div className="w-[121px] h-[141px] sm:w-[269px] sm:h-[325px] shadow-[0px_2px_4px_0px_rgba(0,0,0,0.15)]  cursor-pointer rounded-[20px] sm:rounded-[50px] bg-gradient-to-t from-[#1EA4BF] to-[#96EBFC] mb-[8px]">
                  <div className="flex justify-between p-1">
                    <div>
                      <img
                        src={Home}
                        alt=""
                        className="sm:h-[98px] sm:w-[100px] h-[35px] w-[35px] pl-[12px] sm:pl-[20px] pt-[12px] sm:pt-[22px] sm:mb-[55px] mb-[23px]"
                      />
                    </div>
                    <div>
                      <img
                        src={Ellipse}
                        alt=""
                        className="h-[12px] w-[12px] sm:h-[23px] sm:w-[23px] mt-[21px] sm:mt-[45px] mr-[13px] sm:mr-[20px]"
                      />
                    </div>
                  </div>
                  <div className="relative">
                    <h2 className="blur-[8px] flex justify-center items-center text-[#fff] font-medium sm:font-semibold text-[16px] sm:text-[24px] sm:mb-[60px] mb-[18px] z-10">
                      E-Clinic
                    </h2>
                    <img
                      src={Pin}
                      alt=""
                      className=" absolute h-[30px] sm:h-[67px] sm:w-[67px] w-[25px] ml-[38px] sm:ml-[65px] -mt-[95px] sm:-mt-[220px] z-10  "
                    />
                    <img
                      src={CommingSoon}
                      alt=""
                      className="absolute inset-0 m-auto sm:w-[194px] sm:h-[188px] w-[78px] h-[75px] top-[-40px] sm:top-[-90px] -rotate-12  z-0"
                    />{" "}
                  </div>

                  <div className="h-[28px] w-[107px] sm:h-[55px] sm:w-[230px] rounded-[50px] bg-[#fff] flex items-center justify-center sm:mx-[20px] mx-2.5 font-lato sm:mt-0 mt-[20px]">
                    <h2 className="flex justify-center items-center text-[#00B4D8F0] font-medium text-[14px] sm:text-[20px]">
                      Stay Tuned
                    </h2>
                  </div>
                </div>
                {/* Second Card */}
                <div className="w-[121px] h-[141px] sm:w-[269px] sm:h-[325px] shadow-[0px_2px_4px_0px_rgba(0,0,0,0.15)]  cursor-pointer rounded-[20px] sm:rounded-[50px] bg-gradient-to-t from-[#4E69F7] to-[#8D9FFFE3]">
                  <div className="flex justify-between p-1">
                    <div>
                      <img
                        src={Locator}
                        alt=""
                        className="sm:h-[98px] sm:w-[100px] h-[35px] w-[35px] pl-[12px] sm:pl-[20px] pt-[12px] sm:pt-[22px] sm:mb-[55px] mb-[13px]"
                      />
                    </div>
                    <div>
                      <img
                        src={Ellipse}
                        alt=""
                        className="h-[12px] w-[12px] sm:h-[23px] sm:w-[23px] mt-[21px] sm:mt-[45px] mr-[13px] sm:mr-[20px]"
                      />
                    </div>
                  </div>
                  <div className="relative">
                    <h2 className="blur-[8px] flex justify-center items-center text-[#fff] font-medium sm:font-semibold text-[16px] sm:text-[24px] sm:mb-[60px] mb-[18px] z-10">
                      E-Clinic
                    </h2>
                    <img
                      src={Pin}
                      alt=""
                      className=" absolute h-[30px] sm:h-[67px] sm:w-[67px] w-[25px] ml-[38px] sm:ml-[65px] -mt-[87px] sm:-mt-[220px] z-10  "
                    />
                    <img
                      src={CommingSoon}
                      alt=""
                      className="absolute inset-0 m-auto sm:w-[194px] sm:h-[188px] w-[78px] h-[75px] top-[-25px] sm:top-[-90px] -rotate-12   z-0 pr-2"
                    />{" "}
                  </div>

                  <div className="h-[28px] w-[107px] sm:h-[55px] sm:w-[230px] rounded-[50px] bg-[#fff] flex items-center justify-center sm:mx-[20px] mx-2.5 font-lato sm:mt-0 mt-[30px]">
                    <h2 className="flex justify-center items-center text-[#4F69F7] font-medium text-[14px] sm:text-[20px]">
                      Stay Tuned
                    </h2>
                  </div>
                </div>
                {/* Third Card */}
                <div className="w-[121px] h-[141px] sm:w-[269px] sm:h-[325px] shadow-[0px_2px_4px_0px_rgba(0,0,0,0.15)]  cursor-pointer rounded-[20px] sm:rounded-[50px] bg-gradient-to-t from-[#813995] to-[#D977F4A6]">
                  <div className="flex justify-between p-1">
                    <div>
                      <img
                        src={CMS}
                        alt=""
                        className="sm:h-[98px] sm:w-[100px] h-[35px] w-[35px] pl-[12px] sm:pl-[20px] pt-[12px] sm:pt-[22px] sm:mb-[55px] mb-[25px]"
                      />
                    </div>
                    <div>
                      <img
                        src={Ellipse}
                        alt=""
                        className="h-[12px] w-[12px] sm:h-[23px] sm:w-[23px] mt-[21px] sm:mt-[45px] mr-[13px] sm:mr-[20px]"
                      />
                    </div>
                  </div>
                  <div className="relative">
                    <h2 className="blur-[8px] flex justify-center items-center text-[#fff] font-medium sm:font-semibold text-[16px] sm:text-[24px] sm:mb-[60px] mb-[18px] z-10">
                      E-Clinic
                    </h2>
                    <img
                      src={Pin}
                      alt=""
                      className=" absolute h-[30px] sm:h-[67px] sm:w-[67px] w-[25px] ml-[38px] sm:ml-[65px] -mt-[95px] sm:-mt-[220px] z-10  "
                    />
                    <img
                      src={CommingSoon}
                      alt=""
                      className="absolute inset-0 m-auto sm:w-[194px] sm:h-[188px] w-[78px] h-[75px] top-[-40px] sm:top-[-90px] -rotate-12 z-0 pr-2"
                    />{" "}
                  </div>

                  <div className="h-[28px] w-[107px] sm:h-[55px] sm:w-[230px] rounded-[50px] bg-[#fff] flex items-center justify-center sm:mx-[20px] mx-2.5 font-lato ">
                    <h2 className="flex justify-center items-center text-[#9D55B0] font-medium text-[14px] sm:text-[20px]">
                      Stay Tuned
                    </h2>
                  </div>
                </div>
                {/* Fourth Card */}
                <div className=" w-[121px] h-[141px] sm:w-[269px] sm:h-[325px] shadow-[0px_2px_4px_0px_rgba(0,0,0,0.15)] cursor-pointer rounded-[20px] sm:rounded-[50px] bg-gradient-to-t from-[#FF2B2B] to-[#FF9B9B]">
                  <div className="flex justify-between p-1">
                    <div className="w-[40px] h-[40px] sm:h-[70px] sm:w-[70px] rounded-full bg-[#fff] mt-[10px] sm:mt-[26px] ml-[14px] sm:ml-[23px] sm:mb-[52px] mb-2.5">
                      <img
                        src={SosIcon}
                        alt=""
                        className="sm:h-[30px] sm:w-[30px] h-[10px] w-[10px] flex items-center  mx-[14px] my-1 sm:pl-1"
                      />
                      <h2 className="blur-[8px] hidden text-[#FF3E3E] text-[13px] sm:text-[20px] text-center ">
                        SOS
                      </h2>
                    </div>
                    <div>
                      <img
                        src={Ellipse}
                        alt=""
                        className="h-[12px] w-[12px] sm:h-[23px] sm:w-[23px] mt-[21px] sm:mt-[45px] mr-[13px] sm:mr-[20px]"
                      />
                    </div>
                  </div>
                  <div className="relative">
                    <h2 className="blur-[8px] flex justify-center items-center text-[#fff] font-medium sm:font-semibold text-[16px] sm:text-[24px] sm:mb-[60px] mb-[18px] z-10">
                      E-Clinic
                    </h2>
                    <img
                      src={Pin}
                      alt=""
                      className=" absolute h-[30px] sm:h-[67px] sm:w-[67px] w-[25px] ml-[38px] sm:ml-[65px] -mt-[95px] sm:-mt-[220px] z-10  "
                    />
                    <img
                      src={CommingSoon}
                      alt=""
                      className="absolute inset-0 m-auto sm:w-[194px] sm:h-[188px] w-[78px] h-[75px] top-[-40px] sm:top-[-90px] -rotate-12  z-0 "
                    />{" "}
                  </div>

                  <div className="h-[28px] w-[107px] sm:h-[55px] sm:w-[230px] rounded-[50px] bg-[#fff] flex items-center justify-center sm:mx-[20px] mx-2.5 font-lato">
                    <h2 className="flex justify-center items-center text-[#FF3838] font-medium text-[14px] sm:text-[20px]">
                      Stay Tuned
                    </h2>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpcommingService;
