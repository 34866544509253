import React, { useState } from "react";
import img1 from "../../../images/Pathlab/medical-report ICON 1.png";
import img2 from "../../../images/Pathlab/3d-render-cartoon-character-smart-600nw-2247628867-removebg-preview 1.png";
import img3 from "../../../images/Pathlab/medicine-removebg-preview 1.png";
import Header from "../Header";
import { useNavigate } from "react-router-dom";
import PageLoader from "../../../Loader/PageLoader";

const Pathlab = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  // const handleRoute = (path) => {
  //   navigate(path);
  // };
  const handleRoute = (path) => {
    setLoading(true);
    setTimeout(() => {
      navigate(path);
    }, 1000);
  };

  return (
    <>
      <Header />
      {loading && <PageLoader />}
      <div className="pt-28 pb-1 relative">
        <div className="cursor-default flex items-center justify-center">
          <p className="w-[150px] h-[40px] rounded-[10px] border-[1px] flex justify-center items-center border-[#10CAF2] bg-[#FFFFFF] text-[#10CAF2] text-2xl font-semibold">
            Pathlab
          </p>
        </div>
        <div className="flex flex-col">
          <div className="flex sm:flex-row flex-col justify-center items-center gap-12 sm:gap-20 p-6 my-6 sm:my-12">
            <div
              className="flex items-center gap-1 justify-center sm:w-[320px] sm:h-[120px] w-[250px] h-[110px] border-[1px] rounded-[10px] bg-[#FFFFFF] shadow-md cursor-pointer hover:transform hover:scale-110 transition-transform duration-300"
              onClick={() => handleRoute("/pathlab/myReport")}
            >
              <img
                className="sm:w-[180px] sm:h-[160px] w-[110px] h-[130px] sm:-ml-12 ml-5 sm:mb-8 mb-2"
                src={img1}
                alt=""
                srcset=""
              />
              <p className="font-poppins text-[#666161] sm:text-2xl text-xl font-semilight text-center">
                My Report
              </p>
            </div>
            <div
              className="flex items-center gap-1 justify-center sm:w-[320px] sm:h-[120px] w-[250px] h-[110px] border-[1px] rounded-[10px] bg-[#FFFFFF] shadow-md sm:mt-3 cursor-pointer hover:transform hover:scale-110 transition-transform duration-300"
              onClick={() => handleRoute("/pathlab/requestReport")}
            >
              <img
                className="sm:w-[120px] sm:h-[150px] w-[110px] h-[130px] mb-11 sm:mb-14 sm:-ml-14 sm:mt-2 mt-4"
                src={img2}
                alt=""
                srcset=""
              />
              <p className="font-poppins text-[#666161] sm:text-2xl text-xl font-semilight text-center">
                Request a <br /> Report
              </p>
            </div>
          </div>
          <div className=" hidden sm:block ">
            <img
              className="sm:w-[700px] sm:h-[400px] w-[600px] h-[300px]"
              src={img3}
              alt=""
              srcset=""
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Pathlab;
