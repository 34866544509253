import React, { useEffect, useState } from "react";
import img1 from "../../images/Offer_Section_Banner/3.png";
import img2 from "../../images/Offer_Section_Banner/Appointment offer.png";
import img3 from "../../images/Offer_Section_Banner/medicine offer.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import siteCofig from "../../urlFile";
import offer3 from "../../images/Offer_Section_Banner/desktop_view.png";

const OfferSlider = () => {
  var settings = {
    infinite: true,
    dots: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [images, setImages] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          siteCofig.BASE_URL + siteCofig.OFFER_SLIDER
        );
        console.log(response.data);
        const imageUrls = response.data?.data.map((item) => item.image);
        setImages(imageUrls);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <div className="pb-0 sm:pb-12  sm:mt-32 relative">
        <div
          className="absolute -top-28 left-1/2 transform -translate-x-1/2 w-[580px] hidden sm:block lg:w-[620px] h-[100px] justify-center items-center bg-no-repeat"
          style={{ backgroundImage: `url(${offer3})` }}
        ></div>

        <div
          className="flex justify-center items-center w-full mt-4 mb-2 sm:hidden lg:hidden xl:hidden "
          // absolute -top-16  h-[100px] w-[380px] transform -translate-x-1/2 left-1/2 justify-center items-center
        >
          <span className=" text-[#10CAF2] text-[14px] font-extrabold inline-block transform -rotate-90 font-lato ">
            UPTO
          </span>
          <span className="flex justify-center items-center text-[#10CAF2] text-[24px] -ml-[10px] mr-[2px]  font-extrabold font-lato ">
            {" "}
            55% OFF
          </span>
          <span className="flex justify-center items-center bg-[#10CAF2] font-extrabold font-lato text-[#FFF] w-[219px] text-[9px]  rounded-[5px] p-2 ">
            {" "}
            SAVE MORE WITH HEALTHCRAD OFFERS
          </span>
        </div>
        <div className="mt-3">
          <div className="w-full overflow-x-hidden">
            <Slider {...settings}>
              <div className="px-1.5">
                <img
                  src={img1}
                  alt="Image 1"
                  className="sm:h-[230px] sm:w-[550px] h-[165px] w-[360px] rounded-lg"
                />
              </div>
              <div className="px-1.5">
                <img
                  src={img2}
                  alt="Image 2"
                  className="sm:h-[230px] sm:w-[550px] h-[165px] w-[360px] rounded-lg"
                />
              </div>
              <div className="px-1.5">
                <img
                  src={img3}
                  alt="Image 1"
                  className="sm:h-[230px] sm:w-[550px] h-[165px] w-[360px] rounded-lg"
                />
              </div>
            </Slider>
          </div>
        </div>
        {/* <div className=' flex justify-center items-center p-4 '>
          <div className=' bg-[#00B4D8] rounded-[10px] shadow-md h-10 w-24 flex justify-center items-center ' ><button className=' text-center text-[#FFF] font-poppins text-md sm:text-lg ' >View All</button></div>
        </div> */}
      </div>
    </>
  );
};

export default OfferSlider;
