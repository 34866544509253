import React from "react";
import MedicineSeller from "../../../images/frontend UI materials/merchant_medicine_seller.jpg";
import MedicalAgency from "../../../images/frontend UI materials/Medical Agency.jpeg";
import OrderMangement from "../../../images/frontend UI materials/Order Mangement.jpeg";
import NoHiddenCharge from "../../../images/frontend UI materials/NoHiddenCharge.png";
import Support from "../../../images/frontend UI materials/Support.png";
import GetOrder from "../../../images/frontend UI materials/Get Order.png";
import VillageArea from "../../../images/frontend UI materials/Village Area.jpeg";

import { Link } from "react-router-dom";
import Header from "../Header";

const MedicineSellers = () => {
  return (
    <>
      <Header />

      <div className="w-full font-poppins my-10 lg:px-[52px] sm:p-3   mb-4">
        {/* Parent Container */}
        <div className="flex  flex-wrap  flex-col-reverse xl:flex-row xl:justify-around pt-10">
          {/* Heading Buttom and Paragraph */}
          <div>
            {/* Heading */}
            <div className="sm:pb-[10px] pb-2 p-3">
              <h1 className="text-[#000] sm:text-3xl text-[19px] font-medium flex justify-center xl:justify-start ">
                <span className="text-blue-900">Health</span>{" "}
                <span className="text-[#00B4D8]">CRAD</span>{" "}
                <span className="text-[#EE8829] pl-[4px]">
                  Medicine Sellers
                </span>
              </h1>
            </div>
            {/* Paragraph */}
            <div>
              <p className="m-3 sm:m-0 lg:text-xl md:text-[18px] sm:text-[16px] text-[11px] xs:text-[13px] lg:w-[799px] font-normal text-[#666161] mt-2 mb-2 text-justify ">
                <span className="text-[#4A4141] font-semibold font-poppins">
                  Medicine and Medical product providers{" "}
                </span>
                are the backbone of the Healthcare ecosystem. We are pleased to
                offer you an opportunity to be a part of India's FIRST rural
                centric Healthcare  company with{" "}
                <span className="text-[#19C915] font-semibold font-poppins">
                  {" "}
                  10,000+
                </span>{" "}
                Ordinance Points chain in entire Bihar. <br /> <br />
                We truly need your partnership and support in creating{" "}
                <span className="text-[#000000] font-semibold font-poppins">
                  RURAL HEALTHCARE REVOLUTION.{" "}
                </span>{" "}
                <br /> <br />
                <div className="text-[#000000] text-[16px] sm:text-[24px] font-semibold font-poppins text-center">
                  Key service Offerings are:
                </div>
                {/* =============== Key service Offerings are Start===================== */}
                <div className="flex justify-around sm:justify-start gap-6 mt-4">
                  <div>
                    <img
                      src={MedicalAgency}
                      alt=""
                      className="h-[86px] w-[50px] sm:w-[150px] sm:h-[150px]"
                    />
                  </div>
                  <div className="flex items-center justify-center">
                    <h2 className="font-medium font-poppins text-[13px]  sm:text-[20px] text-[#7A7A7A]">
                      1. Dedicated Mobile App for Medical Agency{" "}
                      <span className="pl-[13px] sm:pl-0">merchants</span>.
                    </h2>
                  </div>
                </div>
                <div className="flex justify-around sm:justify-start gap-6 mt-4">
                  <div className="flex items-center justify-center">
                    <h2 className="font-medium font-poppins text-[13px] sm:text-[20px] text-[#7A7A7A]">
                      2. Comprehensive platform for daily Order{" "}
                      <span className="pl-[20px] sm:pl-0">management. </span>{" "}
                    </h2>
                  </div>
                  <div>
                    <img
                      src={OrderMangement}
                      alt=""
                      className="h-[70px] w-[47px] sm:w-[150px] sm:h-[150px]"
                    />
                  </div>
                </div>
                <div className="flex justify-around sm:justify-start gap-6 mt-4">
                  <div>
                    <img
                      src={NoHiddenCharge}
                      alt=""
                      className="h-[42px] w-[67px] sm:w-[150px] sm:h-[150px]"
                    />
                  </div>
                  <div className="flex items-center justify-center">
                    <h2 className="font-medium font-poppins text-[13px] sm:text-[20px] text-[#7A7A7A]">
                      3. ZERO setup & maintenance charges, NO{" "}
                      <span className="pl-[17px] sm:pl-0">hidden charges.</span>
                    </h2>
                  </div>
                </div>
                <div className="flex justify-around sm:justify-start gap-6 mt-4">
                  <div className="flex items-center justify-center">
                    <h2 className="font-medium font-poppins text-[13px] sm:text-[20px] text-[#7A7A7A]">
                      4. <span className="text-[#FE0505]">24x7</span> Support,
                      Quick & Easy Onboarding{" "}
                      <span className="pl-[17px] sm:pl-0">process. </span>{" "}
                    </h2>
                  </div>
                  <div>
                    <img
                      src={Support}
                      alt=""
                      className="h-[55px] w-[60px] sm:w-[150px] sm:h-[150px]"
                    />
                  </div>
                </div>
                <div className="flex justify-around sm:justify-start gap-6 mt-4">
                  <div>
                    <img
                      src={GetOrder}
                      alt=""
                      className="h-[44px] w-[50px] sm:w-[150px] sm:h-[150px]"
                    />
                  </div>
                  <div className="flex items-center justify-center">
                    <h2 className="font-medium font-poppins text-[13px] sm:text-[20px] text-[#7A7A7A]">
                      5. Get orders from HealthCRAD's mass user{" "}
                      <span className="pl-[18px] sm:pl-0">base.</span>.
                    </h2>
                  </div>
                </div>
                <div className="flex justify-around sm:justify-start gap-6 mt-4">
                  <div className="flex items-center justify-center">
                    <h2 className="font-medium font-poppins text-[13px] sm:text-[20px] text-[#7A7A7A]">
                      6. Increase your reach to rural areas and{" "}
                      <span className="pl-[17px] sm:pl-0">
                        grow your business.{" "}
                      </span>{" "}
                    </h2>
                  </div>
                  <div>
                    <img
                      src={VillageArea}
                      alt=""
                      className="h-[46px] w-[75px] sm:w-[150px] sm:h-[150px]"
                    />
                  </div>
                </div>
                {/* =============== Key service Offerings are End===================== */}
              </p>
            </div>
            {/* Button  */}
            <div className="p-3 flex w-3/6 gap-[60px] sm:gap-[90px] text-center mt-10 mb-5 ">
              <div className="hidden sm:block">
                <Link
                  to="https://play.google.com/store/apps/details?id=com.foundercodes.health_crad_seller"
                  target="_blank"
                >
                  <button className="bg-[#00B4D8F0] rounded-[5px] sm:rounded-lg font-medium  text-white shadow-md lg:h-[40px] lg:w-[200px] md:h-[40px] md:w-[200px] sm:h-[40px] sm:w-[200px] h-[40px] w-[145px] lg:text-[16px] md:text-[16px] sm:text-[16px] text-[13px]">
                    Download & Register
                  </button>
                </Link>
              </div>

              <div className="hidden sm:block">
                <Link
                  to="https://youtube.com/@HealthCRAD?si=HaCTDpC-vg-KdR8P"
                  target="_blank"
                >
                  <button
                    className="bg-[#00B4D8F0] rounded-[5px] sm:rounded-lg font-medium text-white shadow-[0px_4px_4px_0px_rgba(0,0,0,0.20)] 
                     lg:h-[40px] lg:w-[200px] md:h-[40px] md:w-[200px] sm:h-[40px] sm:w-[200px] h-[40px] w-[147px] lg:text-[16px] md:text-[16px] sm:text-[16px] text-[13px]"
                  >
                    More Information
                  </button>
                </Link>
              </div>
            </div>
          </div>
          {/* image  */}
          <div className="sm:mt-20 ">
            <img
              src={MedicineSeller}
              alt=""
              className=" w-full sm:w-[280px] h-[300px] sm:rounded-md mb-[25px] sm:mb-[70px]"
            />
          </div>
        </div>
      </div>

      {/* mobile view button design start */}
      <div className=" sm:hidden fixed z-10 flex justify-around  items-center bg-gray-100 h-[60px] py-1  w-full bottom-0 ">
        <div className="sm:hidden">
          <Link
            to="https://play.google.com/store/apps/details?id=com.foundercodes.health_crad_seller"
            target="_blank"
          >
            <button className="bg-[#00B4D8F0] rounded-[5px] sm:rounded-lg font-medium  text-white shadow-md lg:h-[40px] lg:w-[200px] md:h-[40px] md:w-[200px] sm:h-[40px] sm:w-[200px] h-[40px] w-[145px] lg:text-[16px] md:text-[16px] sm:text-[16px] text-[13px]">
              Download & Register
            </button>
          </Link>
        </div>

        <div className="sm:hidden">
          <Link
            to="https://youtube.com/@HealthCRAD?si=HaCTDpC-vg-KdR8P"
            target="_blank"
          >
            <button
              className="bg-[#00B4D8F0] rounded-[5px] sm:rounded-lg font-medium text-white shadow-[0px_4px_4px_0px_rgba(0,0,0,0.20)] 
                     lg:h-[40px] lg:w-[200px] md:h-[40px] md:w-[200px] sm:h-[40px] sm:w-[200px] h-[40px] w-[147px] lg:text-[16px] md:text-[16px] sm:text-[16px] text-[13px]"
            >
              More Information
            </button>
          </Link>
        </div>
      </div>
      {/* mobile view button design end */}
    </>
  );
};

export default MedicineSellers;
