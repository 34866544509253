import { useState, useEffect, useRef } from "react";
import Header from "../../Header";
import { useLocation } from "react-router-dom";
import axios from "axios";
import siteCofig from "../../../../urlFile";

const GetDetails = () => {
  const location = useLocation();
  const [groupid, setGroupid] = useState("");
  const [itemDetails, setItemDetails] = useState([]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const groupid = searchParams.get("groupid");
    setGroupid(groupid);
  }, [location.search]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${siteCofig.CHECKOUT_URL}${siteCofig.ORDER_GET_DETAILS}${groupid}`
        );
        if (response.data.msg === "success" && response.data.error === "200") {
          setItemDetails(response.data.country);
          console.log(response.data.country);
        } else {
          console.error("Error fetching orders:", response.data.msg);
        }
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };

    if (groupid) {
      fetchData();
    }
  }, [groupid]);

  const getMedicineImageUrl = (imageName) =>
    `https://app.healthcrad.com/api/uploads/medicine/${imageName}`;
  const getImageName = (imageString) => {
    let imageNameArray = JSON.parse(imageString);
    if (Array.isArray(imageNameArray) && imageNameArray.length > 0) {
      return imageNameArray[0];
    } else {
      return "";
    }
  };

  //  OutsideClick PopUp Close Code
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const popupRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setIsPopupOpen(false);
      }
    };

    if (isPopupOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isPopupOpen]);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  return (
    <>
      <Header />
      <div className="flex justify-center items-center gap-4 pt-20 pb-3">
        {" "}
        <p className="text-[20px] sm:text-[28px] text-[#00B4D8] font-poppins cursor-default">
          List of items
        </p>
        <div className="relative  mt-[2px] flex justify-end">
          <button
            className="ml-auto bg-[#00B4D8] text-[#fff] h-5 w-5 text-[15px] rounded-full cursor-pointer font-semibold font-lato"
            onClick={togglePopup}
          >
            i
          </button>
          {isPopupOpen && (
            <div
              ref={popupRef}
              className="fixed inset-0 flex items-center justify-center z-50"
            >
              <div
                className="absolute inset-0 bg-gray-800 opacity-50"
                onClick={togglePopup}
              ></div>
              <div className="relative bg-white shadow-md p-4 rounded-md w-[333px]">
                <h2 className="text-lg font-semibold mb-2">
                  How to cancel the order?
                </h2>
                <p>
                  To cancel the order, please go to the Help section, write your
                  query and phone number, and request a callback. We will assist
                  you in canceling the order. <br />
                  <span className="cursor-pointer">
                    Or call on{" "}
                    <a className="text-[#0924B0D9]" href="tel:7779888289">
                      7779888289
                    </a>
                  </span>
                </p>
                <p className="text-red-500">
                  Note: Order must be canceled within 24 hours of placement,
                  otherwise the order will not be canceled after pickup.
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-col border-[1px] border-[rgba(0, 0, 0, 0.20)] p-6 pt-12 mb-16 shadow-md bg-[#FFF] rounded-[10px] md:h-[90%] sm:h-full lg:w-[80%] lg:ml-32 gap-[14px]">
        {itemDetails.map((item, index) => (
          <div key={item.id} className="flex justify-between">
            <div className="flex gap-2 ">
              <div>
                <img
                  src={getMedicineImageUrl(getImageName(item.image))}
                  alt=""
                  className="w-16 h-16 md:w-[100px] md:h-[100px]"
                />
              </div>
              <div className="pt-1.5 ">
                <p className="font-poppins text-xs font-semibold md:text-lg xl:text-lg text-[#000] leading-5 sm:leading-[0px]">
                  <span className="text-[14px] xs:text-[16px] md:text-lg ">
                    {" "}
                    {item.name}
                  </span>
                  <br />
                  <span className="line-through font-medium">
                    Rs. {item.baseprice}
                  </span>
                  <span className="text-[#07A50D] font-medium">
                    {" "}
                    Off {item.mdiscount}%
                  </span>
                  <span className="font-medium">
                    {" "}
                    Rs {item.discountedprice}
                  </span>
                  <br />
                  Quantity :{" "}
                  <span className="font-normal">{item.mquantity}</span>
                </p>
              </div>
            </div>
            {index === 0 && (
              <div className="flex flex-col pl-2 hidden sm:block">
                <p className="text-[#000] font-poppins text-xs font-semibold md:text-lg xl:text-lg">
                  Total Cost:
                  <span className="text-[#1A30A1]"> {item.amount} |</span>
                  <span className="text-[#07A50D] "> {item.paymode}</span>
                </p>
                <button className="text-[#00B4D8] font-poppins ml-[1px] md:ml-7 sm:mr-8 md:mr-6 mr-[1px] text-xs md:text-lg xl:text-lg p-1 lg:w-32 mt-2 border-[1px] border-[#00B4D8] hover:bg-[#00B4D8] hover:text-[#FFF] rounded-[10px]">
                  Track Order
                </button>
              </div>
            )}
          </div>
        ))}
        {itemDetails.length > 0 && (
          <div
            className="flex flex-row w-full justify-between items-center p-3 rounded-[10px] bg-[#FFF] border-[1px] border-[#00000026] sm:hidden md:hidden lg:hidden xl:hidden"
            style={{ boxShadow: "0px 4px 43.1px 0px rgba(0, 0, 0, 0.10)" }}
          >
            <p className="text-[#666161] font-poppins text-sm md:text-lg xl:text-lg">
              Total Cost:
              <span className="text-[#1A30A1]"> {itemDetails[0].amount} |</span>
              <span className="text-[#07A50D] -mt-2 ">
                {" "}
                {itemDetails[0].paymode}
              </span>
            </p>

            <button
              className={`text-[#00B4D8] font-poppins text-[17px] p-1 lg:w-32 mt-2 sm:rounded-[10px] self-start rounded-[5px] border-[1px] text-xs md:text-lg xl:text-lg ${
                itemDetails[0].url
                  ? "hover:bg-[#00B4D8] hover:text-[#FFF] border-[#00B4D8]"
                  : "bg-gray-300 text-gray-500 cursor-not-allowed border-gray-400"
              }`}
              onClick={() => {
                if (itemDetails[0].url)
                  window.open(itemDetails[0].url, "_blank");
              }}
              disabled={!itemDetails[0].url}
            >
              Track Order
            </button>
          </div>
        )}
        {/* <button className="text-[#00B4D8] font-poppins text-xs md:text-lg xl:text-lg p-1 lg:w-32 border-[1px] border-[#00B4D8] rounded-[5px]">
              Track Order
            </button> */}
        {/* address section */}
        {itemDetails.length > 0 && (
          <div className="flex p-2 justify-center items-center rounded-[10px] w-full sm:w-[470px] h-[100px] md:h-[120px] pl-3 border-[1px] border-[#10CAF2]">
            <p className="font-poppins text-[#979797] text-xs md:text-lg xl:text-lg">
              <span className="text-[#00B4D8] text-lg md:text-2xl xl:text-2xl">
                Address:
              </span>
              <br />
              {itemDetails[0].address}
            </p>
          </div>
        )}
        {/* order time and status */}
        {itemDetails.length > 0 && (
          <div className="flex flex-wrap justify-between sm:gap-6 gap-2">
            <p className="font-poppins  text-[#4A4141] ml-2  text-xs md:text-lg xl:text-lg">
              Order Date & Time:{" "}
              <span className="text-[#07A50D]">{itemDetails[0].date_time}</span>
            </p>
            <p className="font-poppins text-[#4A4141] mr-2 ml-2 sm:ml-0 text-xs md:text-lg xl:text-lg">
              Order Status:
              {itemDetails[0].status === "0" && (
                <span className="text-[#FF9900]"> Order Waiting</span>
              )}
              {itemDetails[0].status === "1" && (
                <span className="text-[#07A50D]"> Order Accepted</span>
              )}
              {itemDetails[0].status === "2" && (
                <span className="text-[#07A50D]"> Order Processing</span>
              )}
              {itemDetails[0].status === "3" && (
                <span className="text-[#07A50D]"> Order Pickup</span>
              )}
              {itemDetails[0].status === "4" && (
                <span className="text-[#07A50D]"> Order Shipped</span>
              )}
              {itemDetails[0].status === "5" && (
                <span className="text-[#07A50D]"> Order Delivered</span>
              )}
              {itemDetails[0].status === "6" && (
                <span className="text-[#07A50D]"> Order Returned</span>
              )}
              {itemDetails[0].status === "7" && (
                <span className="text-[#07A50D]"> Order Replaced</span>
              )}
              {itemDetails[0].status === "8" && (
                <span className="text-[#07A50D]"> Refunded</span>
              )}
              {itemDetails[0].status === "9" && (
                <span className="text-[red]"> Cancelled by Admin</span>
              )}
            </p>
          </div>
        )}
        {/* mobile view button design start */}
        {/* <div className=" sm:hidden fixed z-50 flex justify-around items-center bg-[#FAF1FF]   w-full bottom-0 ">
            <div className=" flex flex-col pt-2.5 ">
              <p className="text-[16px] font-lato  text-[#000000] ">Fees:</p>
              <p className=" text-[20px] font-lato font-semibold text-[#000000] ">Rs.{doctorDetails.fees}</p>
            </div>
            <div className="select-none rounded-[4px] border-[1px] border-[#10CAF2] shadow-md bg-[#00B4D8] w-[170px]  h-11  flex items-center justify-center cursor-pointer"
              onClick={handleContinueButtonClick}
            >
              <button className=" text-white font-poppins">Continue</button>
            </div>
          </div> */}
        {/* mobile view button design end */}
      </div>
    </>
  );
};

export default GetDetails;
