import React, { useEffect, useState, useRef } from "react";
import Header from "../../../Header";
import { useLocation } from "react-router-dom";
import axios from "axios";
import siteCofig from "../../../../../urlFile";

const PrescriptionOrderGetDetails = () => {
  const location = useLocation();
  const [orderid, setOrderid] = useState("");
  const [prescriptionOrder, setPrescriptionOrder] = useState([]);
  const [fullImage, setFullImage] = useState(null);
  const imageRef = useRef(null);
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const groupid = searchParams.get("groupid");
    setOrderid(groupid);
  }, [location.search]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${siteCofig.BASE_URL}${siteCofig.PRESCRIPTION_GET_DETAILS}${orderid}`
        );
        if (response.data.msg === "success" && response.data.error === "200") {
          setPrescriptionOrder(response.data.data || []); // Ensure prescriptionOrder is always an array
          console.log(response.data.data);
        } else {
          console.error("Error fetching orders:", response.data.msg);
        }
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };

    if (orderid) {
      fetchData();
    }
  }, [orderid]);

  const getImageUrl = (imageName) =>
    `https://app.healthcrad.com/upload/${imageName}`;
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (imageRef.current && !imageRef.current.contains(event.target)) {
        setFullImage(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <>
      <Header />
      <div>
        <div className="text-[#00B4D8] sm:pb-0 text-[18px] sm:text-[24px] font-semibold font-lato pl-[13px] sm:pl-[28px] md:pl-[37px] lg:pl-[75px] xl:pl-[75px] 2xl:pl-[75px] mt-[80px]">
          <h2>Order Details</h2>
        </div>
        {prescriptionOrder.map((item) => (
          <div
            key={item.id}
            className="h-auto  sm:mt-[25px] rounded-[10px] shadow-md border-[1px] border-[#00000033] sm:mx-20 m-[13px]"
          >
            <div className="flex justify-between font-poppins font-medium p-2">
              <div className="text-[13px] sm:text-[24px]">
                <p className="text-[#666161] ">
                  Name:{" "}
                  <span className="text-[#0924B0] text-[13px] sm:text-[24px]">
                    {item.name}
                  </span>
                </p>
              </div>
              <div>
                <div className="text-[11px] sm:text-[16px]">
                  <p className="text-[#666161]">
                    Total Cost:{" "}
                    <span className="text-[#1A30A1] text-[11px] sm:text-[16px]">
                      Not Avil. |{" "}
                    </span>
                    <span className="text-[#07A50D] text-[11px] sm:text-[16px]">
                      COD
                    </span>
                  </p>
                </div>
                <div className="text-[#10CAF2] text-[18px]">
                  <button
                    className={`text-[#00B4D8] font-poppins text-[17px] p-1 lg:w-32 mt-2 mb-10 sm:mb-0 sm:rounded-[10px] rounded-[5px] border-[1px] text-xs md:text-lg xl:text-lg ${
                      item.url
                        ? "hover:bg-[#00B4D8] hover:text-[#FFF] border-[#00B4D8]"
                        : "bg-gray-300 text-gray-500 cursor-not-allowed border-gray-400"
                    }`}
                    onClick={() => {
                      if (item.url) window.open(item.url, "_blank");
                    }}
                    disabled={!item.url}
                  >
                    Track Order
                  </button>
                </div>
              </div>
            </div>
            <div
              className=" font-poppins font-normal p-3 -mt-10 sm:-mt-0
          "
            >
              <div className=" border border-solid border-[#666161] rounded-[10px] font-poppins font-normal p-3 -mt-5 sm:-mt-0">
                <div className="relative" ref={imageRef}>
                  <img
                    src={getImageUrl(item.images)}
                    onClick={() => setFullImage(getImageUrl(item.images))}
                    alt="Prescription Image"
                    className="p-2 w-[400px] h-[400px]"
                  />
                </div>
              </div>
            </div>
            <div className=" w-[90%] sm:w-[55%] xl:w-[50%] p-2 rounded-[10px] border-2 border-solid border-[#10CAF2] mt-[26px]   m-[13px] ">
              <p className="text-[#979797] text-[13px] sm:text-[20px] font-medium font-poppins p-[13px] sm:p-[0px]">
                <span className="text-[#00B4D8] text-[20px] sm:text-[28px]  ">
                  Address:
                </span>{" "}
                <br />
                {item.name}, {item.address}, {item.city}, {item.pincode} <br />
                <span className="text-[#07A50D]">Landmark : </span>
                {item.landmark}
                <br />
                <span>Phone : {item.phone}</span>
              </p>
            </div>
            <div className="flex flex-wrap justify-between mt-[23px] sm:mx-3">
              <div>
                <h2 className="text-[#4A4141] font-poppins font-medium text-[13px] sm:text-[20px] pl-[13px] sm:pl-0 pb-1">
                  Order Date & Time:{" "}
                  <span className="text-[#07A50D]">{item.date_time}</span>
                </h2>
              </div>
              <div>
                <h2 className="text-[#4A4141] font-poppins font-medium text-[13px] sm:text-[20px] pl-[13px] sm:pl-0 mb-3">
                  Order Status:{" "}
                  {item.status === "0" && (
                    <span className="text-[#FF9900]"> Order Waiting</span>
                  )}
                  {item.status === "1" && (
                    <span className="text-[#07A50D]"> Order Accepted</span>
                  )}
                  {item.status === "2" && (
                    <span className="text-[#07A50D]"> Order Processing</span>
                  )}
                  {item.status === "3" && (
                    <span className="text-[#07A50D]"> Order Pickup</span>
                  )}
                  {item.status === "4" && (
                    <span className="text-[#07A50D]"> Shipped</span>
                  )}
                  {item.status === "5" && (
                    <span className="text-[#07A50D]"> Order Delivered</span>
                  )}
                  {item.status === "6" && (
                    <span className="text-[#07A50D]"> Order Returned</span>
                  )}
                  {item.status === "7" && (
                    <span className="text-[#07A50D]"> Order Replaced</span>
                  )}
                  {item.status === "8" && (
                    <span className="text-[#07A50D]"> Refunded</span>
                  )}
                  {item.status === "9" && (
                    <span className="text-[red]"> Cancelled by Admin</span>
                  )}
                </h2>
              </div>
            </div>
          </div>
        ))}
      </div>
      {fullImage && (
        <div className="fixed top-0 left-0 z-50 w-full h-full bg-black bg-opacity-50 flex justify-center items-center">
          <img
            src={fullImage}
            alt="Full Size Image"
            className="max-w-full max-h-full"
            onClick={() => setFullImage(null)}
          />
        </div>
      )}
    </>
  );
};

export default PrescriptionOrderGetDetails;
